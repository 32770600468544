import { RootStoreInstance } from 'stores';
import { BaseHttpError, isUnauthorizedError, isBadRequestError } from './errors';
import { isHandleable, genericMessages } from './messages';
import { ContainerInterface, ErrorReportClientInterface } from '../types';
import './index.css';

export function configureErrorHandler(errorReportClient?: ErrorReportClientInterface, container?: ContainerInterface) {
  return function errorHandler(error: BaseHttpError & Error): BaseHttpError {
    if (error?.type) {
      let message: string | null = null;
      const response = error?.response ?? null;
      const hasServerError = error?.response?.status && error.response.status >= 500 && error.response.status < 600;

      if (hasServerError && response) {
        if (container) {
          const rootStore = container.get<RootStoreInstance>('rootStore');
          const { setError } = rootStore.session;

          setError(true);
        }
      }

      if (response) {
        message = isHandleable(response);
      }

      if (!message) {
        message = genericMessages[error?.type] ?? genericMessages.generic;
      }

      if (error.type !== 'canceled' && message) {
        console.log(error);
      }
    }

    if (!isUnauthorizedError(error) && !isBadRequestError(error)) {
      errorReportClient?.recordError(error);
    }

    return error;
  };
}
