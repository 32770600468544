import React, { memo, useMemo, useRef, useState, useEffect } from 'react';
import { useMountedState } from 'react-use';
import debounce from 'lodash.debounce';
import { Select } from 'components/common/form/select';
import { Spinner } from 'components/common/spinner';
import { useForm } from 'hooks';
import config from 'config';
import { SearchFieldProps } from './types';

export const SearchField = memo<SearchFieldProps>(
  ({
    fetch,
    fetchInitialOptions,
    debounceTimeout = config.options.searchField.debounceTimeout,
    options: optionsFromProps = [] as SearchFieldProps['options'],
    requestParams,
    deps,
    ...props
  }) => {
    const isMounted = useMountedState();
    const [fetching, setFetching] = useState(false);
    const [isSearchCalled, setSearchCalled] = useState(false);
    const [options, setOptions] = useState(optionsFromProps);
    const fetchRef = useRef(0);

    const { values } = useForm();
    const value = (values as any)[props.name];

    useEffect(() => {
      setOptions([]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deps]);

    useEffect(() => {
      (async () => {
        const isValueSetViaFormInitialValues = value && !isSearchCalled;

        if (isValueSetViaFormInitialValues && fetchInitialOptions) {
          if (!isMounted()) {
            return;
          }
          setSearchCalled(true);

          const initialOptions = await fetchInitialOptions({ value });

          if (!isMounted()) {
            return;
          }
          setOptions(initialOptions);
        }
      })();
    }, [value, isSearchCalled, fetchInitialOptions, isMounted]);

    const isValidInput = (inputValue: string) => {
      const regex = /^[A-Za-z]+$/;
      return regex.test(inputValue);
    };

    const debounceFetcher = useMemo(() => {
      const loadOptions = (value: string) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        setSearchCalled(true);

        fetch(value, requestParams).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            return;
          }

          setOptions(newOptions);
          setFetching(false);
        });
      };

      return debounce(loadOptions, debounceTimeout);
    }, [fetch, debounceTimeout, requestParams]);

    const handleSearch = (value: string) => {
      if (isValidInput(value)) {
        debounceFetcher(value);
      }
    };

    return (
      <Select
        showSearch
        filterOption={false}
        onSearch={handleSearch}
        notFoundContent={fetching ? <Spinner size={10} /> : null}
        options={options}
        {...props}
        onInputKeyDown={(e) => {
          if (!isValidInput(e.key)) {
            e.preventDefault();
          }
        }}
      />
    );
  },
);
