import React, { memo } from 'react';
import { Box } from 'components/common/box';
import { VerticalBar } from 'components/common/vertical-bar';
import { LayoutProps } from './types';

export const Layout = memo<LayoutProps>(({ children, ...props }) => (
  <Box height='100%' display='flex' justifyContent='space-evenly' position='relative' paddingTop={35} {...props}>
    {children}
    <VerticalBar />
  </Box>
));
