import { SnapshotOut, Instance, applySnapshot, types, toGenerator } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { GetUserResponse } from 'services/api/response-types';
import BaseModel from 'stores/models/base';
import Image from 'stores/models/image';
import { apiFlow, nullable, stringDate } from 'stores/mst-types';
import { MSTAddStaticMethods } from 'stores/utils';
import { fromItemResponse } from 'services/api';
import config from 'config';
import {
  ChangePasswordApi,
  FacebookAdAccountApi,
  GoogleManagerAccountApi,
  ImageFormValue,
  PatchUserProfileApi,
} from 'types';
import { isEmpty } from 'utils/is';
import { serializeSimplifiedImage } from 'utils/serializers';
import {
  deserializeFacebookPlatform,
  deserializeSupportedUser,
  deserializeSupportedOrganization,
} from 'utils/deserializers';
import { toJSONDeep } from 'utils/stores';
import ImageWithOldMarker from './image-with-old-marker';

const { agent, brokerage, multifamily } = config.api.constants.organizations.kindNames;
const { organizations } = config.api.constants;

export interface PatchProfileFormValues {
  firstName: string;
  lastName: string;
  avatar: Nullable<ImageFormValue>;
  contactPhone: string;
  website: string;
  bio: string;
  featuredPhotos: Nullable<ImageFormValue[]>;
  state: string;
  city: string;
}

export interface ChangePasswordFormValues {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

export interface FacebookAdAccountFormValues {
  businessId: string;
  accountId: string;
}

export interface GoogleManagerAccountFormValues {
  managerId: string;
  managerName: string;
}

const staticMethods = {
  fromResponseData: (data: GetUserResponse): UserSnapshot => ({
    ...pick(data, ['email', 'bio']),
    id: String(data.id),
    firstName: data.first_name,
    lastName: data.last_name,
    // @ts-ignore
    avatar: isEmpty(data.main_photo) ? null : { src: data.main_photo },
    contactPhone: data.contact_phone,
    website: data.website,
    profileId: data.profile_id,
    role: data.role,
    featuredPhotos: data.featured_photos?.map(ImageWithOldMarker.fromResponseData) ?? undefined,
    organizationId: data.organization_id,
    organizationKindName: data.organization_kind_name,
    hasAffectedCampaigns: null,
    facebook: deserializeFacebookPlatform(data.facebook),
    google: {
      isConnected: Boolean(data.google?.is_connected),
    },
    state: data.state ? String(data.state) : undefined,
    city: data.city ? String(data.city) : undefined,
    supportedOrganization: data.supported_organization
      ? deserializeSupportedOrganization(data.supported_organization)
      : undefined,
    supportedUser: data.supported_user ? deserializeSupportedUser(data.supported_user) : undefined,
  }),

  toPatchRequestContactInfoData: (values: Partial<PatchProfileFormValues>): Partial<PatchUserProfileApi> => ({
    first_name: values.firstName,
    last_name: values.lastName,
    contact_phone: values.contactPhone || '',
    website: values.website,
    bio: values.bio,
    state: Number(values.state),
    city: Number(values.city),
  }),

  toPatchRequestAvatarData: (
    values: Pick<PatchProfileFormValues, 'avatar'>,
  ): Pick<PatchUserProfileApi, 'main_photo'> => ({
    main_photo: values.avatar ? serializeSimplifiedImage(values.avatar) : null,
  }),

  toPatchRequestFeaturedPhotosData: (
    values: Pick<PatchProfileFormValues, 'featuredPhotos'>,
  ): Pick<PatchUserProfileApi, 'featured_photos'> => ({
    featured_photos: values.featuredPhotos?.length ? values.featuredPhotos : null,
  }),

  toChangePasswordRequestData: (values: ChangePasswordFormValues): ChangePasswordApi => ({
    old_password: values.currentPassword,
    new_password: values.password,
    new_password_confirm: values.confirmPassword,
  }),

  toFacebookAdAccountRequestData: (values: FacebookAdAccountFormValues): FacebookAdAccountApi => ({
    business_id: values.businessId,
    account_id: values.accountId,
  }),

  toGoogleManagerAccountRequestData: (values: GoogleManagerAccountFormValues): GoogleManagerAccountApi => ({
    manager_id: Number(values.managerId),
    manager_name: values.managerName,
  }),
};

const User = BaseModel.named('User')
  .props({
    id: nullable(types.string),
    firstName: nullable(types.string),
    lastName: nullable(types.string),
    email: nullable(types.string),
    website: nullable(types.string),
    avatar: nullable(Image),
    contactPhone: nullable(types.string),
    bio: nullable(types.string),
    profileId: nullable(types.string),
    role: nullable(types.string),
    organizationId: nullable(types.string),
    organizationKindName: nullable(types.enumeration('organizationKindName', [agent, brokerage, multifamily])),
    featuredPhotos: types.maybe(types.array(ImageWithOldMarker)),
    hasAffectedCampaigns: nullable(types.boolean),
    facebook: nullable(
      types.model({
        isConnected: types.boolean,
        accessTokenExpiredDate: nullable(stringDate),
      }),
    ),
    google: nullable(
      types.model({
        isConnected: types.boolean,
      }),
    ),
    state: nullable(types.string),
    city: nullable(types.string),
    supportedOrganization: types.maybe(
      types.model({
        value: types.string,
        label: types.string,
      }),
    ),
    supportedUser: types.maybe(
      types.model({
        value: types.string,
        label: types.string,
        active: types.boolean,
      }),
    ),
  })
  .views((self) => ({
    get fullName() {
      return `${self.firstName} ${self.lastName}`;
    },

    get hasOrganization() {
      return Boolean(self.organizationId);
    },

    get isOrganizationOwner() {
      const { superuser, owner, superAdmin } = organizations.roles;

      return self.role && [superuser.name, owner.name, superAdmin.name].includes(self.role);
    },

    get isOrganizationDetailsAvailable() {
      const { brokerage, multifamily } = organizations.kindNames;
      return self.organizationKindName && [brokerage, multifamily].includes(self.organizationKindName);
    },

    get isSuperAdmin() {
      const { superAdmin } = organizations.roles;

      return self.role && [superAdmin.name].includes(self.role);
    },

    get initialOrganizationUserValues() {
      return self
        ? toJSONDeep({
            organization: self.supportedOrganization?.label,
            user: self.supportedUser?.label,
          })
        : {};
    },
  }))
  .actions((self) => ({
    refresh: apiFlow(function* refresh() {
      const response = yield* toGenerator(self.api.getUser());
      const { data } = fromItemResponse({
        response: response.data,
      });

      applySnapshot(self, staticMethods.fromResponseData(data));
    }),
  }));

export type UserSnapshot = SnapshotOut<typeof User>;
export type UserInstanceType = Instance<typeof User>;

export default MSTAddStaticMethods(User, staticMethods);
