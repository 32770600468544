import React, { useCallback } from 'react';
import classnames from 'classnames';
import { TimePicker as AntdTimePicker } from 'antd';
import 'antd/es/date-picker/style/index';
import { useField } from 'hooks';
import { withFormItem } from 'hocs/with-form-item';
import { TimeRangePickerProps } from './types';
import styles from './styles.module.scss';

export const TimeRangePicker = withFormItem<TimeRangePickerProps>()((props) => {
  const { field, error, componentProps } = useField(props);
  const { name, onChange, onBlur } = field;

  const className = classnames(styles.root, {
    [styles.error]: error,
  });

  const handleChange: TimeRangePickerProps['onChange'] = useCallback(
    (value) => {
      onChange({ target: { name, value } });

      const isReset = !value;

      if (isReset) {
        setTimeout(() => {
          onBlur({ target: { name } });
        });
      }
    },
    [name, onBlur, onChange],
  );

  const handleBlur: TimeRangePickerProps['onBlur'] = useCallback(() => {
    onBlur({ target: { name } });
  }, [name, onBlur]);

  return (
    <AntdTimePicker.RangePicker
      className={className}
      {...field}
      onChange={handleChange}
      onBlur={handleBlur}
      {...componentProps}
    />
  );
});
