import React, { memo } from 'react';
import { Box } from 'components/common/box';
import { Typography } from 'components/common/typography';
import { Avatar } from 'components/common/avatar';
import { Select, SelectProps } from 'components/common/form/select';
import { Truncated } from 'components/common/truncated';
import { SelectWithDetailedOptionsProps } from './types';

const detailedOptionRender: NonNullable<SelectProps['optionRender']> = ({ label, value, picture }) => (
  <Box display='flex' alignItems='center'>
    {picture ? (
      <Box marginRight={16}>
        <Avatar src={picture} size={46} />
      </Box>
    ) : null}
    <Box flex={1} minWidth={0}>
      <Typography variant='body-13' marginBottom={4}>
        <Truncated titleAsContentText>{label}</Truncated>
      </Typography>
      <Typography variant='body-7' color='grey5'>
        <Truncated titleAsContentText>
          ID:
          {value}
        </Truncated>
      </Typography>
    </Box>
  </Box>
);

export const SelectWithDetailedOptions = memo<SelectWithDetailedOptionsProps>((props) => (
  <Select optionRender={detailedOptionRender} {...props} />
));
