import React, { useCallback, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd';
import { useField } from 'hooks';
import { withFormItem } from 'hocs/with-form-item';
import { sortAlphabetically } from 'utils/sorting';
import { Icon, IconControl } from 'components/common/icon';
import { Box } from 'components/common/box';
// @ts-ignore
import { OptionData } from 'rc-select/lib/interface';
import { SelectProps } from './types';
import styles from './styles.module.scss';

type Option = Required<SelectProps>['options'][0];

const cleanOption = ({ isOld, isCustom, isInvalid, ...option }: Option): Option => option;

export const Select = withFormItem<SelectProps>()(
  ({
    className: classNameFromProps = '',
    sorting = true,
    nestedSorting = true,
    options: optionsFromProps = [],
    filterOptions,
    optionsModifier,
    menuStartAdornment,
    menuEndAdornment,
    tagRender: tagRenderFromProps,
    optionRender,
    ...props
  }) => {
    const { field, error, isTouched, componentProps } = useField(props);

    const selectRef = useRef<any>();
    const className = classnames(styles.root, {
      [styles.error]: error,
      [styles.touched]: isTouched,
    });

    const { value } = field;

    const optionsModifierDeps = optionsModifier ? value : undefined;

    const options = useMemo(() => {
      const filteredOptions = filterOptions ? optionsFromProps?.filter(filterOptions) : optionsFromProps;

      const modifiedOptions = optionsModifier ? optionsModifier(filteredOptions) : filteredOptions;

      const optionsWithResolvedNestedSorting = modifiedOptions?.map(({ options, ...item }) => {
        if (!options) {
          return item;
        }

        return {
          ...item,
          options: nestedSorting
            ? sortAlphabetically({
                items: options,
                field: 'label',
              })
            : options,
        };
      });

      if (!sorting || !optionsWithResolvedNestedSorting) {
        return optionsWithResolvedNestedSorting;
      }

      return sortAlphabetically({
        items: optionsWithResolvedNestedSorting,
        field: 'label',
      });
      // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [
      optionsModifierDeps,
      filterOptions,
      nestedSorting,
      optionsFromProps,
      optionsModifier,
      sorting,
      value,
    ]) as SelectProps['options'];

    const dropdownRender = useCallback<NonNullable<SelectProps['dropdownRender']>>(
      (menu) => (
        <Box>
          {menuStartAdornment}
          {menu}
          {menuEndAdornment}
        </Box>
      ),
      [menuStartAdornment, menuEndAdornment],
    );

    const allOptions = useMemo(
      () => options?.reduce((result, option) => result.concat(option.options || option), []),
      [options],
    );

    const tagRender = useCallback<NonNullable<SelectProps['tagRender']>>(
      (tagProps) => {
        const option = allOptions?.find(({ value }: any) => tagProps.value === value);
        return tagRenderFromProps ? tagRenderFromProps(tagProps, option) : <></>;
      },
      [allOptions, tagRenderFromProps],
    );

    const { name, onChange, onBlur } = field;

    const handleChange: AntdSelectProps<any>['onChange'] = useCallback(
      (value) => {
        onChange({ target: { name, value } });

        selectRef.current?.selectRef.current.focus();
      },
      [onChange, name],
    );

    const handleBlur: AntdSelectProps<any>['onBlur'] = useCallback(() => {
      setTimeout(() => {
        onBlur({ target: { name } });
      });
    }, [onBlur, name]);

    const processedOptions = useMemo(
      () =>
        options?.map((option) =>
          option.options ? { ...option, options: option.options.map(cleanOption) } : cleanOption(option),
        ),
      [options],
    );

    return (
      <AntdSelect
        ref={selectRef}
        className={className}
        dropdownClassName={styles.dropdown}
        {...field}
        showArrow
        menuItemSelectedIcon={<Icon name='check' size={11} color='primaryColor' />}
        suffixIcon={<IconControl name='arrow-down' size={12} color='primaryColor' />}
        removeIcon={<Icon name='close' size={12} color='primaryColor' />}
        onChange={handleChange}
        onBlur={handleBlur}
        options={optionRender ? undefined : processedOptions}
        dropdownRender={dropdownRender}
        tagRender={tagRenderFromProps && tagRender}
        optionLabelProp='label'
        {...({ autoComplete: 'new-password' } as any)}
        {...componentProps}
      >
        {optionRender
          ? processedOptions?.map((option) => {
              const { label, value } = option as OptionData;
              return (
                <AntdSelect.Option key={value} label={label || value} value={value}>
                  {optionRender(option as OptionData)}
                </AntdSelect.Option>
              );
            })
          : undefined}
      </AntdSelect>
    );
  },
);
