import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import { nullable } from 'stores/mst-types';
import { MSTAddStaticMethods } from 'stores/utils';
import { KeywordFormValueApi } from 'types';
import { deserializeKeyword } from 'utils/deserializers';

const staticMethods = {
  fromResponseData: (data: KeywordFormValueApi): KeywordSnapshot => deserializeKeyword(data),
};

const Keyword = types.model('Keyword', {
  label: types.string,
  value: types.string,
  isCustom: nullable(types.boolean),
  isOld: nullable(types.boolean),
});

export type KeywordSnapshot = SnapshotOut<typeof Keyword>;
export type KeywordInstanceType = Instance<typeof Keyword>;

export default MSTAddStaticMethods(Keyword, staticMethods);
