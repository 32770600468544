import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import { nullable } from 'stores/mst-types';

const OptionEntity = types.model('Option', {
  label: types.string,
  value: types.string,
  initializing_status: nullable(types.string),
});

export type OptionEntitySnapshot = SnapshotOut<typeof OptionEntity>;
export type OptionEntityInstanceType = Instance<typeof OptionEntity>;

export default OptionEntity;
