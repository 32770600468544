import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { stringify } from 'utils/query-string';
import { ApiConfig } from '../types';
import { HttpClientInterface } from './types';

const prepareConfig = ({ data, config = {} }: { data: unknown; config?: AxiosRequestConfig }): AxiosRequestConfig => {
  const isFormData = data instanceof FormData;

  return isFormData
    ? {
        ...config,
        headers: {
          ...config?.headers,
          'Content-Type': 'multipart/form-data' as const,
        },
      }
    : config;
};
/**
 * Manages all requests to the API.
 */
export class BaseHttpClient implements HttpClientInterface {
  /**
   * Axios instance
   */
  client: AxiosInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig) {
    this.config = config;

    // construct the axios instance
    this.client = axios.create({
      baseURL: this.config.url,
      timeout: this.config.timeout ?? 90000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      // it doesn't work with mocks https://github.com/ctimmerm/axios-mock-adapter/issues/120 but it works with real api
      paramsSerializer: (params) => stringify(params, { encodeValuesOnly: true }),
    });
  }

  /**
   * Passthrought any request here
   *
   * @type {AxiosInstance['request']}
   * @memberof BaseHttpClient
   */
  request: AxiosInstance['request'] = (params) => this.client.request(params);

  /**
   * Wrapper for post requests
   */
  post: HttpClientInterface['post'] = (url: string, data?: any, config?: AxiosRequestConfig) =>
    this.client.request({
      method: 'post',
      url,
      data,
      ...prepareConfig({ data, config }),
    });

  /**
   * Wrapper for get requests
   */
  get: HttpClientInterface['get'] = (url: string, params?: any, config?: AxiosRequestConfig) =>
    this.client.request({
      method: 'get',
      url,
      params,
      ...config,
    });

  /**
   * Wrapper for put requests
   */
  put: HttpClientInterface['put'] = (url: string, data?: any, config?: AxiosRequestConfig) =>
    this.client.request({
      method: 'put',
      url,
      data,
      ...prepareConfig({ data, config }),
    });

  /**
   * Wrapper for patch requests
   */
  patch: HttpClientInterface['patch'] = (url: string, data?: any, config?: AxiosRequestConfig) =>
    this.client.request({
      method: 'patch',
      url,
      data,
      ...prepareConfig({ data, config }),
    });

  /**
   * Wrapper for delete requests
   */
  delete: HttpClientInterface['delete'] = (url: string, config?: AxiosRequestConfig) =>
    this.client.request({ method: 'delete', url, ...config });
}
