import React, { memo, useCallback, useState } from 'react';
import classnames from 'classnames';
import { useField } from 'hooks';
import { SelectRemote, SelectRemoteProps } from 'components/common/form/select-remote';
import { Tag } from 'components/common/tag';
import { TagSelectOption } from 'types';
import { getDiffByField } from 'utils/arrays';
import { TagSelectProps } from './types';
import {
  filterOption,
  groupOptions,
  filterEmpty,
  createTagSelectCustomOption,
  isNewTagSelectCustomOption,
} from './helper';
import styles from './styles.module.scss';

const defaultIsValidTag = () => true;

export const TagSelect = memo<TagSelectProps>(
  ({ className: classNameFromProps = '', grouping = true, isValidTag = defaultIsValidTag, ...props }) => {
    const [newCustomOptions, setNewCustomOptions] = useState([] as TagSelectOption[]);

    const { field, componentProps } = useField(props);
    const { name, onChange } = field;

    const className = classnames(styles.root, classNameFromProps);

    const optionsModifier: TagSelectProps['optionsModifier'] = useCallback(
      (options) => {
        if (!options || !grouping) {
          return options;
        }

        const { popular, custom, all } = groupOptions({
          options,
          newCustomOptions: getDiffByField({
            target: newCustomOptions,
            array: options,
            field: 'value',
          }),
        });

        return filterEmpty(
          [
            { label: 'Popular', options: popular },
            { label: 'Custom', options: custom },
            { label: 'All', options: all },
          ].map(({ options, ...item }) => ({
            ...item,
            options: options?.map((option) => ({
              ...option,
            })),
          })),
        );
      },
      [newCustomOptions, grouping],
    );

    const handleChange: SelectRemoteProps['onChange'] = useCallback(
      ({ target: { value } }) => {
        const nextValue = (value as TagSelectOption[]).map((valueItem) => {
          const { isCacheable, label } = valueItem;

          return isCacheable ? createTagSelectCustomOption(label) : valueItem;
        });

        setNewCustomOptions(nextValue.filter(isNewTagSelectCustomOption) as TagSelectOption[]);

        onChange({
          target: {
            name,
            value: nextValue.length ? nextValue : undefined,
          },
        });
      },
      [onChange, name],
    );

    const tagRender = useCallback<NonNullable<SelectRemoteProps['tagRender']>>(
      ({ label, closable, onClose }, option: TagSelectOption) => (
        <Tag
          isOld={Boolean(option?.isOld)}
          isInvalid={option && !isValidTag(option)}
          closable={closable}
          onClose={onClose}
        >
          {label}
        </Tag>
      ),
      [isValidTag],
    );

    return (
      <SelectRemote
        className={className}
        mode='tags'
        sorting={false}
        filterOption={filterOption}
        optionsModifier={optionsModifier}
        onChange={handleChange}
        labelInValue
        tagRender={tagRender}
        {...componentProps}
      />
    );
  },
);
