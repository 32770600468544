import React, { ComponentType, memo, useMemo, useState } from 'react';
import { Modal, ModalProps } from 'components/common/modal';
import { ModalContext } from 'components/common/modal/context';
import { excludeEmpty } from 'utils/objects';
import { Optional } from 'utility-types';

export interface WithModalModalProps
  extends Pick<
    ModalProps,
    'visible' | 'onCancel' | 'title' | 'footer' | 'variant' | 'scrollbar' | 'contentProps' | 'width'
  > {}

export const withModal =
  <P extends Record<string, any>>(defaultModalProps: Partial<WithModalModalProps> = {}) =>
  (
    WrappedComponent: ComponentType<Pick<WithModalModalProps, 'onCancel'> & P>,
  ): ComponentType<Optional<WithModalModalProps, keyof typeof defaultModalProps> & P> =>
    memo<Optional<WithModalModalProps, keyof typeof defaultModalProps> & P>(
      ({ visible, onCancel, footer, variant, title, width, scrollbar, ...componentProps }) => {
        const [overrvidedDefaultProps, overrideDefaultProps] = useState<Partial<WithModalModalProps> | null>(null);

        const modalProps = useMemo(
          () => ({
            ...defaultModalProps,
            ...overrvidedDefaultProps,
            // @ts-ignore
            footer: false,
            ...excludeEmpty({
              visible,
              onCancel,
              footer,
              variant,
              title,
              width,
              scrollbar,
            }),
          }),
          [footer, onCancel, overrvidedDefaultProps, scrollbar, title, variant, visible, width],
        );

        const contextValue = useMemo(
          () => ({
            props: modalProps,
            close: onCancel,
            overrideDefaultProps,
          }),
          [modalProps, onCancel],
        );

        return (
          <ModalContext.Provider value={contextValue}>
            <Modal {...modalProps}>{(<WrappedComponent {...(componentProps as P)} />) as any}</Modal>
          </ModalContext.Provider>
        );
      },
    );
