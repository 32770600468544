import React, { memo, useMemo } from 'react';
import get from 'lodash.get';
import { useForm } from 'hooks';
import { IfProps } from './types';

export const If = memo<IfProps>(({ field, value, children }) => {
  const { values } = useForm<Record<string, any>>();
  const visible = useMemo(
    () => ([] as string[]).concat(field).some((fieldName) => get(values, fieldName) === value),
    [field, value, values],
  );

  return <>{visible ? children : null}</>;
});
