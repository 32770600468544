import React, { memo, useRef, useMemo } from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import classnames from 'classnames';
import { useCssVariables } from 'hooks';
import { CheckboxBaseProps } from './types';
import styles from './styles.module.scss';

export const CheckboxBase = memo<CheckboxBaseProps>(({ className: classNameFromProps, size = 22, ...props }) => {
  const ref = useRef<any>();
  const className = classnames(styles.root, classNameFromProps);

  useCssVariables({
    getElement: () => ref.current?.input?.closest('.ant-checkbox'),
    cssVariables: useMemo(
      () => ({
        '--checkbox-size': `${size}px`,
      }),
      [size],
    ),
  });

  return <AntdCheckbox ref={ref} className={className} checked={props.value} {...props} />;
});
