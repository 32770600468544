import React from 'react';
import { useAsync } from 'react-use';
import { observer } from 'mobx-react-lite';
import { Typography } from 'components/common/typography';
import { useTranslation, useStores } from 'hooks';
import packageVersion from 'utils/versions';
import themeColors from 'utils/theme-colors';
import { VersionProps } from './types';

export const Version = observer<VersionProps>((props) => {
  const { t } = useTranslation();

  const { common } = useStores();
  const { getApiVersion } = common;

  const { value } = useAsync(getApiVersion);

  return (
    <Typography
      variant='body-4'
      position='fixed'
      left={0}
      top={0}
      zIndex={1000}
      paddingX={10}
      borderRadius={3}
      background={themeColors.black3}
      color={themeColors.white1}
      {...props}
    >
      {t('Common.Version', {
        packageVersion,
        apiVersion: value?.apiVersion,
      })}
    </Typography>
  );
});
