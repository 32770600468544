import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import { nullable } from 'stores/mst-types';
import { MSTAddStaticMethods } from 'stores/utils';
import { OptionFromValue, TitleApi, TitleRequestApi } from 'types';
import { deserializeTitleOption } from 'utils/deserializers';
import { serializeTitleOption } from 'utils/serializers';

const staticMethods = {
  fromResponseData: (data: TitleApi): TitleOptionSnapshot => deserializeTitleOption(data) as TitleOptionSnapshot,

  toRequestData: (data: OptionFromValue): TitleRequestApi => serializeTitleOption(data),
};

const TitleOption = types.model('TitleOption', {
  value: nullable(types.string),
  use_case_group: nullable(types.string),
  asset_number: nullable(types.number),
  label: types.optional(types.string, ''),
});

export type TitleOptionSnapshot = SnapshotOut<typeof TitleOption>;
export type TitleOptionInstanceType = Instance<typeof TitleOption>;

export default MSTAddStaticMethods(TitleOption, staticMethods);
