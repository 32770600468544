import { Location } from 'history';
import { parse } from 'utils/query-string';

const createDestParamForLocation = (location: Location<unknown>): string | null => {
  const { dest: destFromUrl } = parse(location.search || '');

  const dest = (destFromUrl as string | undefined) ?? encodeURI(`${location.pathname}${location.search}`);

  return dest === '/' ? null : dest;
};

export { createDestParamForLocation };
