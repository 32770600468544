import { cast, Instance, SnapshotOut, toGenerator, types } from 'mobx-state-tree';
import { apiFlow } from 'stores/mst-types';
import Base from 'stores/models/base';
import config from 'config';
import { locations } from 'navigation';
import ABTestingOverview from 'stores/models/ab-testing/ab-testing';
import ABTestingResults from 'stores/models/ab-testing/ab-testing-result';
import { CampaignInstanceType } from 'stores/models/campaigns/campaign';
import { fromItemResponse } from 'services/api';
import { CampaignApi } from 'types';
import variantAbTesting from 'stores/models/variant-ab-testing';
import Image from 'stores/models/image';
import { CampaignManagerStore } from './campaign-manager-store';

const { types: campaignTypes } = config.api.constants.campaigns;
const { campaignSocialNetworksAvailability } = config.options;

const ABTestingResult = types.model('ABTestingResult', {
  magik_kpi: types.optional(types.string, ''),
  impressions: types.optional(types.string, ''),
  clicks: types.optional(types.string, ''),
  is_winner: types.optional(types.boolean, false),
});

export const ABTestingStore = Base.named('ABTestingStore')
  .props({
    campaignManager: types.optional(CampaignManagerStore, {}),
    abTestingOverview: types.optional(ABTestingOverview, {}),
    isSkipABTesting: types.optional(types.boolean, false),
    isInitialization: types.optional(types.boolean, true),
    isABTesting: types.optional(types.boolean, false),
    showABToggle: types.optional(types.boolean, true),
    platformABInst: types.optional(types.boolean, false),
    buildingId: types.optional(types.string, ''),
    variantA: types.optional(variantAbTesting, {}),
    variantTestingA: types.optional(variantAbTesting, {}),
    variantTestingB: types.optional(variantAbTesting, {}),
    selectedImages: types.array(Image),
    variant: types.optional(variantAbTesting, {}),
    disableWhileAB: types.optional(types.boolean, false),
    FBValue: types.optional(types.string, ''),
    InstValue: types.optional(types.string, ''),
    statusAB: types.optional(types.string, ''),
    resultsAB: types.optional(ABTestingResults, {}),
    resultA: types.optional(ABTestingResult, {}),
    resultB: types.optional(ABTestingResult, {}),
    campaignStatus: types.optional(types.string, ''),
    regeneratedDescription: types.optional(types.string, ''),
    toggleActive: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get defaultVariantAValue() {
      return {
        variantA: {
          titles: self.abTestingOverview.variantA.titles?.slice(0, 1),
          descriptions: self.abTestingOverview.variantA.descriptions?.slice(0, 1),
          images: self.abTestingOverview.variantA.images,
        },
      };
    },

    get defaultVariantBValue() {
      return {
        variantB: {
          titles: self.abTestingOverview.variantB.titles?.slice(0, 1),
          descriptions: self.abTestingOverview.variantB.descriptions?.slice(0, 1),
          images: self.abTestingOverview.variantB.images,
        },
      };
    },

    get defaultAdsValue() {
      const { defaultVariantAValue, defaultVariantBValue } = this;

      return {
        variantA: {
          titles: defaultVariantAValue.variantA.titles,
          descriptions: defaultVariantAValue.variantA.descriptions,
          images: defaultVariantAValue.variantA.images,
        },
        variantB: {
          titles: defaultVariantBValue.variantB.titles,
          descriptions: defaultVariantBValue.variantB.descriptions,
          images: defaultVariantBValue.variantB.images,
        },
      };
    },

    get initializationValues() {
      const { campaignValues } = self.campaignManager;

      return {
        ...campaignValues,
        ...this.defaultAdsValue,
      };
    },
  }))
  .actions((self) => ({
    setRegenerateDescription(values: any) {
      self.abTestingOverview.variantB.descriptions = [...values];
    },

    setRegenerateTitle(values: any) {
      self.abTestingOverview.variantB.titles = [...values];
    },

    setSkipABTesting(value: boolean) {
      self.isSkipABTesting = value;
    },

    setInitialization(state: boolean) {
      self.isInitialization = state;
    },
    setIsABTesting(value: boolean) {
      self.isABTesting = value;
    },
    setABToggle(value: boolean) {
      self.showABToggle = value;
    },
    setABIstagramUsed(value: boolean) {
      self.platformABInst = value;
    },
    setBuildingId(value: string) {
      self.buildingId = value;
    },

    setVariantOption(value: any) {
      self.variantA = value;
    },

    setSelectedImages(value: any) {
      self.selectedImages = value;
    },

    setVariant(value: any) {
      self.variant = value;
    },

    setDisableWhileAB(value: any) {
      self.disableWhileAB = value;
    },
    setFBValue(value: any) {
      self.FBValue = value;
    },
    setInstValue(value: any) {
      self.InstValue = value;
    },
    setStatusAB(value: string) {
      self.statusAB = value;
    },
    setVariantB(value: any) {
      self.variantTestingB = value;
      self.abTestingOverview.variantB = value;
    },
    setVariantA(value: any) {
      self.variantTestingA = value;
      self.abTestingOverview.variantA = value;
    },
    setResultA(value: any) {
      self.resultA = value;
    },
    setResultB(value: any) {
      self.resultB = value;
    },
    setCampaignStatus(value: string) {
      self.campaignStatus = value;
    },

    setRegeneratedDescriptionForAB(value: any) {
      self.regeneratedDescription = value;
    },
    setToggleActive(value: boolean) {
      self.toggleActive = value;
    },
  }))
  .actions((self) => ({
    initCampaign: apiFlow(function* initCampaign({ id }: Partial<Pick<CampaignInstanceType, 'id'>>) {
      yield self.campaignManager.init({
        id,
        socialNetworksAvailability: campaignSocialNetworksAvailability.building,
        campaignKindId: campaignTypes.building.id,
        notFoundUpdateFallbackUrl: locations.campaigns.newBuildingCampaign.toUrl(),
      });

      const targetId = self.campaignManager.campaign?.targetId;

      if (targetId) {
        yield self.campaignManager.loadTarget({ id: targetId });
      }
    }),

    loadABTestingVariants: apiFlow(function* loadABTestingVariants(values) {
      const response = yield* toGenerator(
        self.api.getAbTestingVariants({
          id: self.buildingId,
          data: values,
        }),
      );

      const { data } = fromItemResponse({
        response: response.data,
      });
      self.abTestingOverview.variantA = cast(data.variant_a);
      self.abTestingOverview.variantB = cast(data.variant_b);
    }),
  }))
  .actions((self) => ({
    init: apiFlow(function* init({ id }: Pick<CampaignApi, 'id'>) {
      yield Promise.all([self.initCampaign({ id })]);

      self.setInitialization(false);
    }),

    startABTesting: apiFlow(
      function* startABTesting(values) {
        const id = self.campaignManager.campaign?.id;

        if (!id) {
          return;
        }

        const { variantA, variantB } = values;

        yield self.api.patchABTesting({
          id,
          data: ABTestingOverview.toRequestData({
            variantA,
            variantB,
          }),
        });
      },
      {
        isUpdate: true,
        formName: 'ab-testing',
        successAlert: 'CampaignForm.CampaignABTesting.Create.Alerts.Created',
      },
    ),

    destroy: () => {
      self.campaignManager.destroy();
      self.abTestingOverview.destroy();
      self.isSkipABTesting = false;
      self.isInitialization = true;
    },
  }));

export type ABTestingStoreType = SnapshotOut<typeof ABTestingStore>;
export type ABTestingStoreInstanceType = Instance<typeof ABTestingStore>;
