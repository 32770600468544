import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';

const Jwt = BaseModel.named('Jwt').props({
  accessToken: types.string,
  refreshToken: types.string,
});

export type JwtSnapshot = SnapshotOut<typeof Jwt>;
export type JwtInstanceType = Instance<typeof Jwt>;

export default Jwt;
