import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { MSTAddStaticMethods } from 'stores/utils';
import { ImageFormValueApi } from 'types';

const staticMethods = {
  fromResponseData: (data: ImageFormValueApi): ImageSnapshot => pick(data, 'src'),
};

const Image = types.model('Image', {
  src: types.string,
});

export type ImageSnapshot = SnapshotOut<typeof Image>;
export type ImageInstanceType = Instance<typeof Image>;

export default MSTAddStaticMethods(Image, staticMethods);
