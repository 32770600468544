import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import { nullable } from 'stores/mst-types';
import { MSTAddStaticMethods } from 'stores/utils';
import { AdGroupApi } from 'types';
import TitleOption from 'stores/models/ads/title-option';
import DescriptionOption from 'stores/models/ads/description-option';
import keyword from '../keyword';
import GroupOption from '../group-option';

const staticMethods = {
  fromResponseData: (data: AdGroupApi): AdGroupOptionSnapshot => ({
    id: data.id,
    group: data.group,
    titles: data.titles && data.titles.map(TitleOption.fromResponseData),
    descriptions: data.descriptions && data.descriptions.map(DescriptionOption.fromResponseData),
    keywords: data.keywords,
    type: data.type,
  }),

  toRequestData: (data: AdGroupApi): AdGroupOptionSnapshot => ({
    id: data.id,
    group: data.group,
    titles: data.titles && data.titles.map(TitleOption.fromResponseData),
    descriptions: data.descriptions && data.descriptions.map(DescriptionOption.fromResponseData),
    keywords: data.keywords,
    type: data.type,
  }),
};

const AdGroupOption = types.model('AdGroupOption', {
  id: nullable(types.string),
  group: nullable(GroupOption),
  titles: nullable(types.array(TitleOption)),
  descriptions: nullable(types.array(DescriptionOption)),
  keywords: nullable(types.array(keyword)),
  type: nullable(types.string),
});

export type AdGroupOptionSnapshot = SnapshotOut<typeof AdGroupOption>;
export type AdGroupOptionInstanceType = Instance<typeof AdGroupOption>;

export default MSTAddStaticMethods(AdGroupOption, staticMethods);
