import { Instance, types, SnapshotOut } from 'mobx-state-tree';
import { PieChartItemApi } from 'types';
import Base from 'stores/models/base';
import { MSTAddStaticMethods } from 'stores/utils';
import PieItem from './pie-item';

const staticMethods = {
  fromResponseData: (data: PieChartItemApi[]): PieItemsSnapshot => ({
    entries: data.map(({ label, value }) => ({
      type: label,
      value: Number(value),
    })),
  }),
};

const PieItems = Base.named('PieItems').props({
  entries: types.optional(types.array(PieItem), []),
});

export type PieItemsInstanceType = Instance<typeof PieItems>;
export type PieItemsSnapshot = SnapshotOut<typeof PieItems>;

export default MSTAddStaticMethods(PieItems, staticMethods);
