import React, { useMemo, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash.isequal';
import { useAsync } from 'react-use';
import { observer } from 'mobx-react-lite';
import { useStores, useField, useTranslation } from 'hooks';
import { Box } from 'components/common/box';
import { GridLayout } from 'components/common/grid-layout';
import { Icon } from 'components/common/icon';
import { Typography } from 'components/common/typography';
import { withFormItem } from 'hocs/with-form-item';
import { ApartmentParametersFieldFormValue } from 'types';
import { ApartmentParametersFieldProps } from './types';
import { ApartmentParametersRow } from './apartment-parameters-row';

const emptyRowValue = {};
const defaultValue = [emptyRowValue] as NonNullable<ApartmentParametersFieldProps['value']>;

export const ApartmentParametersField = withFormItem<ApartmentParametersFieldProps>()(
  observer((props) => {
    const { t } = useTranslation();
    const { field } = useField<ApartmentParametersFieldProps['value']>(props);
    const { name, value = defaultValue, onChange } = field;
    const { common } = useStores();
    const { getApartmentParameterTypeOptions } = common;

    const { value: options } = useAsync(getApartmentParameterTypeOptions);

    const indexedValueItems = useMemo<(ApartmentParametersFieldFormValue & { id: string })[]>(
      () =>
        (value as NonNullable<ApartmentParametersFieldProps['value']>).map((item) => ({
          id: uuidv4(),
          ...item,
        })),
      // eslint-disable-next-line  react-hooks/exhaustive-deps
      [value.length],
    );

    const onAdd = useCallback(() => {
      onChange({
        target: { name, value: [...value, emptyRowValue] },
      });
    }, [name, onChange, value]);

    const onRemove = useCallback(
      (rowIndex: number) => {
        const nextRawValue = value && value.filter((_: any, index: number) => index !== rowIndex);
        const nextValue = nextRawValue?.some((valueItem: any) => !isEqual(valueItem, emptyRowValue))
          ? nextRawValue
          : undefined;

        onChange({ target: { name, value: nextValue } });
      },
      [name, onChange, value],
    );

    const showRemoveButton = true;

    const items = useMemo(
      () =>
        indexedValueItems.map(({ id }, index) => (
          <ApartmentParametersRow
            key={id}
            rootFieldName={name}
            rowIndex={index}
            options={options}
            onRemove={onRemove}
            showRemoveButton={showRemoveButton}
          />
        )),
      [indexedValueItems, name, onRemove, options, showRemoveButton],
    );

    return (
      <Box>
        <GridLayout columns={1} gridRowGap={20} marginBottom={20}>
          {items}
        </GridLayout>
        <Box>
          <Typography variant='body-4' color='grey5' display='inline-block' cursor='pointer' onClick={onAdd}>
            <Icon name='plus' size={16} circle color='primaryColor' marginRight={25} verticalAlign='middle' />
            <Box is='span' verticalAlign='middle'>
              {t('ApartmentParametersField.AddButton')}
            </Box>
          </Typography>
        </Box>
      </Box>
    );
  }),
);
