import {
  INSTAGRAM_FIELD,
  FACEBOOK_FIELD,
} from 'components/campaigns/campaign-form/ad-social-networks-selector/constants';

interface VisibleABTesting {
  isVisible: boolean;
}

export const useVisibleABTesting = (textPickerFieldName?: string): VisibleABTesting => {
  const isVisible =
    textPickerFieldName === FACEBOOK_FIELD ||
    textPickerFieldName === 'variantAbTesting' ||
    textPickerFieldName === INSTAGRAM_FIELD ||
    textPickerFieldName === 'variantAbTesting';

  return { isVisible };
};
