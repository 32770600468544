import React, { memo, useCallback, useMemo, useState } from 'react';
import { useForm, useTranslation } from 'hooks';
import { OptionWithOldMarker } from 'types';
import themeColors from 'utils/theme-colors';
import { Textarea } from 'components/common/form/textarea';
import { Box } from 'components/common/box';
import config from 'config';
import { v4 as uuidv4 } from 'uuid';
import { useTextPickerContext } from 'components/common/form/text-picker/hooks';
import { Typography } from 'components/common/typography';
import { AD_TITLE_FIELD } from 'components/campaigns/campaign-form/constants';
import {
  GOOGLE_ADWORDS_FIELD,
  FACEBOOK_FIELD,
  INSTAGRAM_FIELD,
} from 'components/campaigns/campaign-form/ad-social-networks-selector';
import { Icon } from 'components/common/icon';
import get from 'lodash.get';
import { Trans } from 'react-i18next';
import { Title } from 'components/common/title';
import { MAX_LENGTH, MAX_LENGTH_TITLE } from './constants';
import { TextPickerRowProps } from './types';
import regenerate from './regenerate.svg';
import './index.css';

const { textarea } = config.options;

export const GroupTextPickerRow = memo<TextPickerRowProps>(
  ({
    rootFieldName,
    rowIndex,
    selectedOptionIndex,
    loading,
    total,
    controls,
    borderColor = themeColors.grey13,
    onRemove: onRemoveFromProps,
    options,
    setOptions,
    selectedOption,
    selectOption,
    activeGroup,
    onRegenerateDescriptionsClick,
    onRegenerateTitlesClick,
  }) => {
    const { t } = useTranslation();

    const { tabName, placeholder } = useTextPickerContext();
    const { values } = useForm();

    const valueKeywords = get(values, 'keywords');
    const hasValues = !!values;
    const isDescriptionsTab = tabName === 'descriptions';
    const groupDescriptions = valueKeywords[activeGroup]?.descriptions;
    const groupTitles = valueKeywords[activeGroup]?.titles;
    const rowLabel = groupDescriptions?.[rowIndex]?.label;
    const rowLabelTitles = groupTitles?.[rowIndex]?.label;

    const rowLength = hasValues && isDescriptionsTab && rowLabel ? rowLabel.length : 0;
    const rowLengthTitles = hasValues && rowLabelTitles ? rowLabelTitles.length : 0;

    const [value, setValue] = useState('');

    const namePrefix = `${rootFieldName}[${rowIndex}]`;
    const selectFieldName = `${namePrefix}.label`;

    const items = useMemo(
      () => [
        t('AdTextPicker.Items.First'),
        t('AdTextPicker.Items.Second'),
        t('AdTextPicker.Items.Third'),
        t('AdTextPicker.Items.Fourth'),
        t('AdTextPicker.Items.Fifth'),
        t('AdTextPicker.Items.Sixth'),
        t('AdTextPicker.Items.Seventh'),
        t('AdTextPicker.Items.Eighth'),
        t('AdTextPicker.Items.Ninth'),
        t('AdTextPicker.Items.Tenth'),
        t('AdTextPicker.Items.Eleventh'),
        t('AdTextPicker.Items.Twelfth'),
        t('AdTextPicker.Items.Thirteenth'),
        t('AdTextPicker.Items.Fourteenth'),
        t('AdTextPicker.Items.Fifteenth'),
      ],
      [t],
    );

    const showControls = true;

    const ordinalText = useCallback(
      (index: number) => {
        if (items[index]) {
          return items[index];
        }
        return tabName === AD_TITLE_FIELD ? t('AdTextPicker.Labels.Title') : t('AdTextPicker.Labels.Description');
      },
      [items, tabName, t],
    );

    const size = useMemo(
      () =>
        tabName === AD_TITLE_FIELD
          ? {
              minRows: textarea.rowsMin,
              maxRows: textarea.rowsMin,
            }
          : {
              minRows: textarea.rows,
              maxRows: textarea.rows,
            },
      [tabName],
    );

    const onRemove = useCallback(() => {
      onRemoveFromProps(rowIndex);
    }, [onRemoveFromProps, rowIndex]);

    const onRegenerate = useCallback(() => {
      onRegenerateDescriptionsClick(
        rowIndex,
        groupDescriptions?.[rowIndex]?.label,
        selectedOption(rowIndex)?.use_case_group,
        selectedOption(rowIndex)?.asset_number,
      );
    }, [onRegenerateDescriptionsClick, rowIndex, selectedOption, groupDescriptions]);

    const onRegenerateTitle = useCallback(() => {
      onRegenerateTitlesClick(
        rowIndex,
        groupTitles?.[rowIndex]?.label,
        selectedOption(rowIndex)?.use_case_group,
        selectedOption(rowIndex)?.asset_number,
      );
    }, [onRegenerateTitlesClick, rowIndex, selectedOption, groupTitles]);

    const showButton = tabName === 'titles' ? rowIndex > 2 : rowIndex > 1;

    const onChange = useCallback(
      (e: any) => {
        const newOptions = options.map((option: OptionWithOldMarker) => ({
          ...option,
          label: option.label,
        }));

        selectOption(
          {
            value: uuidv4(),
            label: e.target.value,
          },
          rowIndex,
        );

        setOptions(newOptions);

        setValue(e.target.value);
      },
      [options, rowIndex, selectOption, setOptions],
    );

    const showCount = useMemo(
      () =>
        tabName === 'descriptions' ||
        tabName === GOOGLE_ADWORDS_FIELD ||
        tabName === FACEBOOK_FIELD ||
        tabName === INSTAGRAM_FIELD,
      [tabName],
    );

    return (
      <Box display='flex' alignItems='start' position='relative'>
        {showButton ? (
          <Box display='flex' position='absolute' right='100%' top='30%' marginRight={20}>
            <Icon name='minus' size={20} circle color='primaryColor' cursor='pointer' onClick={onRemove} />
          </Box>
        ) : null}
        <Box display='flex'>
          <Box display='flex' style={{ alignItems: 'center' }}>
            <Textarea
              name={selectFieldName}
              placeholder={t(placeholder, {
                current: ordinalText(rowIndex),
              })}
              id='textarea'
              style={{
                border: `1px solid ${borderColor}`,
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: 25,
                paddingRight: tabName === 'descriptions' ? 50 : 80,
                maxHeight: '100px',
                minHeight: '100px',
                lineHeight: 'initial',
              }}
              autoSize={size}
              value={{ ...selectedOption(rowIndex) }.label}
              onChange={onChange}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {showControls && tabName === 'descriptions' ? (
                <Box
                  style={{
                    marginLeft: '-34px',
                    marginRight: '15px',
                    marginTop: '40px',
                    zIndex: '1',
                  }}
                >
                  <button
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                    type='button'
                    onClick={onRegenerate}
                  >
                    <img src={regenerate} alt='' />
                  </button>
                </Box>
              ) : null}
              {tabName === 'titles' ? (
                rowIndex <= 4 ? (
                  <Box
                    style={{
                      marginLeft: '-40px',
                      marginRight: '10px',
                      marginTop: '12px',
                      zIndex: '1',
                    }}
                  >
                    <div>
                      <Title
                        style={{ marginBottom: '0px' }}
                        variant='h3'
                        tooltip={<Trans i18nKey='BuildingForm.TooltipRegenerationInfo' />}
                      />
                    </div>
                  </Box>
                ) : (
                  <Box
                    style={{
                      marginLeft: '-40px',
                      marginRight: '15px',
                      marginTop: '12px',
                      zIndex: '1',
                    }}
                  >
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                      type='button'
                      onClick={onRegenerateTitle}
                    >
                      <img src={regenerate} alt='' />
                    </button>
                  </Box>
                )
              ) : null}
              {showCount ? (
                <Typography
                  color='greyTransparent2'
                  variant='body-18'
                  style={{
                    whiteSpace: 'nowrap',
                    marginLeft: '-55px',
                    marginRight: '15px',
                    zIndex: '1',
                    marginTop: '30px',
                    bottom: '5px',
                  }}
                >
                  {`${rowLength || value.length} / ${MAX_LENGTH}`}
                </Typography>
              ) : (
                <Typography
                  color='greyTransparent2'
                  variant='body-18'
                  style={{
                    whiteSpace: 'nowrap',
                    marginLeft: '-85px',
                    marginRight: '13px',
                    zIndex: '1',
                    marginTop: '-10px',
                    bottom: '10px',
                  }}
                >
                  {`${rowLengthTitles || value.length} / ${MAX_LENGTH_TITLE}`}
                </Typography>
              )}
            </div>
            {showControls ? <Box display='flex'>{controls(rowIndex)}</Box> : null}
          </Box>
        </Box>
      </Box>
    );
  },
);
