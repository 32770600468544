class Deferred {
  promise: Promise<any>;

  resolve: (value: any) => void = () => undefined;

  reject: (value: any) => void = () => undefined;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

export { Deferred };
