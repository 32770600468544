import React, { useMemo, forwardRef } from 'react';
import classnames from 'classnames';
import { Box } from 'components/common/box';
import { withThemeColor } from 'hocs/with-theme-color';
import { withTooltip } from 'hocs/with-tooltip';
import { IconProps } from './types';
import styles from './styles.module.scss';

const imageStyle = {
  verticalAlign: 'middle',
  objectFit: 'contain' as const,
};

export const Icon = withTooltip<IconProps>()(
  withThemeColor<IconProps>()(
    forwardRef<any, IconProps>(
      (
        {
          name,
          activeName,
          src,
          activeSrc,
          className: classNameFromProps = '',
          size = 10,
          circle,
          circleScale = 0.36,
          circleOpacity = 0.04,
          color,
          active,
          ...props
        },
        ref,
      ) => {
        const className = classnames(
          styles.root,
          {
            [styles['has-circle']]: circle,
            [`icon-${name}`]: !active && name,
            [`icon-${activeName}`]: active && activeName,
          },
          classNameFromProps,
        );

        const iconSize = circle ? size * circleScale : size;

        const hasImage = src || activeSrc;

        const imageProps = useMemo(
          () => ({
            src: active ? activeSrc : src,
            width: size,
            height: size,
            style: imageStyle,
          }),
          [active, activeSrc, size, src],
        );

        return (
          <Box
            ref={ref}
            is='span'
            fontSize={`${iconSize}px`}
            className={className}
            color={color}
            display='inline-block'
            {...props}
          >
            {hasImage ? <img {...imageProps} alt='icon' /> : null}
            {circle ? (
              <Box className={styles.circle} background={color} width={size} opacity={circleOpacity} height={size} />
            ) : null}
          </Box>
        );
      },
    ),
  ),
);
