import React, { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Typography } from 'components/common/typography';
import { TextField } from 'components/common/form';
import { AuthForm } from 'components/auth/auth-form';
import { RouterLink } from 'components/common/link';
import locations from 'navigation/locations';
import { useTranslation, useStores, usePreloader } from 'hooks';
import { ForgotPasswordFormValues } from 'stores/session-store';
import { validationSchema } from './validation-schemas';

const alternativeActionComponents = [<RouterLink to={locations.signInWithPassword.toUrl()} />];

export const ForgotPassword = observer(() => {
  const { t } = useTranslation();

  const [signedUpEmail, setSignedUpEmail] = useState<string | null>(null);

  const { session } = useStores();
  const { forgotPassword } = session;

  usePreloader({ pending: false });

  const onSubmit = useCallback(
    async (values: ForgotPasswordFormValues) => {
      await forgotPassword(values);

      setSignedUpEmail(values.email);
    },
    [forgotPassword],
  );

  const success = useMemo(
    () =>
      signedUpEmail ? (
        <AuthForm
          title={t('Auth.ForgotPassword.SuccessTitle')}
          description={
            <Trans
              i18nKey='Auth.ForgotPassword.SuccessDescription'
              components={[<Typography variant='inherit' color='primaryColor' is='span' />]}
              values={{ email: signedUpEmail }}
            />
          }
        />
      ) : null,
    [signedUpEmail, t],
  );

  return (
    success ?? (
      <AuthForm
        title={t('Auth.ForgotPassword.Title')}
        description={t('Auth.ForgotPassword.Description')}
        alternativeAction={
          <Trans i18nKey='Auth.ForgotPassword.AlternativeAction' components={alternativeActionComponents} />
        }
        submitText={t('Auth.ForgotPassword.SubmitButton')}
        onSubmit={onSubmit}
        validation={validationSchema}
        name='forgot-password'
      >
        <TextField
          name='email'
          label={t('Auth.ForgotPassword.Labels.Email')}
          placeholder={t('Auth.ForgotPassword.Placeholders.Email')}
        />
      </AuthForm>
    )
  );
});
