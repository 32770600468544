import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import { MSTAddStaticMethods } from 'stores/utils';
import { deserializeDescriptionOption } from 'utils/deserializers';
import { serializeDescriptionOption } from 'utils/serializers';
import { DescriptionApi, DescriptionRequestApi, OptionFromValue } from 'types';
import { nullable } from 'stores/mst-types';

const staticMethods = {
  fromResponseData: (data: DescriptionApi): DescriptionOptionSnapshot =>
    deserializeDescriptionOption(data) as DescriptionOptionSnapshot,

  toRequestData: (data: OptionFromValue): DescriptionRequestApi => serializeDescriptionOption(data),
};

const DescriptionOption = types.model('DescriptionOption', {
  value: nullable(types.string),
  label: types.optional(types.string, ''),
  use_case_group: nullable(types.string),
  asset_number: nullable(types.number),
});

export type DescriptionOptionSnapshot = SnapshotOut<typeof DescriptionOption>;
export type DescriptionOptionInstanceType = Instance<typeof DescriptionOption>;

export default MSTAddStaticMethods(DescriptionOption, staticMethods);
