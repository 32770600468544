import { random } from 'faker';

const randomNumber = ({
  min = 0,
  max = 1000,
  exclude = [],
}: {
  min?: number;
  max?: number;
  exclude?: number | number[];
}): number => {
  const excludedElements = ([] as number[]).concat(exclude);
  const elements = Array.from({ length: max - min }, (_, index) => index + min);

  return random.arrayElement(elements.filter((element) => !excludedElements.includes(element)));
};

export { randomNumber };
