import { cast, detach, Instance, SnapshotOut, types } from 'mobx-state-tree';
import { apiFlow } from 'stores/mst-types';
import Base from 'stores/models/base';
import config from 'config';
import { locations } from 'navigation';
import AdGroupOption from 'stores/models/ads/ad-group';
import { CampaignInstanceType } from 'stores/models/campaigns/campaign';
import { CampaignApi } from 'types';
import googleSearchAd from 'stores/models/ads/google-search-ad';
import Option from 'stores/models/option';
import { CampaignManagerStore } from './campaign-manager-store';

const { types: campaignTypes } = config.api.constants.campaigns;
const { campaignSocialNetworksAvailability } = config.options;

export const GoogleSearchStore = Base.named('GoogleSearchStore')
  .props({
    adGroups: types.optional(types.array(AdGroupOption), []),
    buldingGroups: types.optional(types.array(AdGroupOption), []),
    generatedDescriptions: types.optional(types.array(Option), []),
    generatedTitles: types.optional(types.array(Option), []),
    campaignManager: types.optional(CampaignManagerStore, {}),
    isBuildingUpdated: types.optional(types.boolean, false),
    campaignTargetId: types.optional(types.string, ''),
    buildingTargetId: types.optional(types.number, 0),
  })
  .views((self) => ({
    get defaultAdGroups() {
      return {
        adGroup: self.adGroups,
      };
    },

    get initializationValues() {
      const { campaignValues } = self.campaignManager;

      return {
        ...campaignValues,
        ...this.defaultAdGroups,
      };
    },
  }))
  .actions((self) => ({
    setAdGroupsForGoogleSearch(values: any) {
      self.adGroups = values;
    },

    removeOlddAdGroups(values: any) {
      detach(self.adGroups);
      self.adGroups = values;
    },

    setGoogleSearchTitles(values: any) {
      self.generatedTitles = values;
    },

    setGoogleSearchDescriptions(values: any) {
      self.generatedDescriptions = values;
    },

    setBuildingGroups(values: any) {
      self.buldingGroups = values;
    },
    setIsBuildingUpdated(values: any) {
      self.isBuildingUpdated = values;
    },
    setCampaignTargetId(value: any) {
      self.campaignTargetId = value;
    },
    setBuildingTargetId(value: any) {
      self.buildingTargetId = value;
    },
    setAdGroups(value: any) {
      detach(self.adGroups);
      self.adGroups = value;
    },
  }))

  .actions((self) => ({
    initCampaign: apiFlow(function* initCampaign({ id }: Partial<Pick<CampaignInstanceType, 'id'>>) {
      yield self.campaignManager.init({
        id,
        socialNetworksAvailability: campaignSocialNetworksAvailability.building,
        campaignKindId: campaignTypes.building.id,
        notFoundUpdateFallbackUrl: locations.campaigns.newBuildingCampaign.toUrl(),
      });

      const targetId = self.campaignManager.campaign?.targetId;

      if (targetId) {
        yield self.campaignManager.loadTarget({ id: targetId });
      }
    }),

    backSyncGoogleSearch: apiFlow(function* backSyncGoogleSearch({ id }: Partial<Pick<CampaignInstanceType, 'id'>>) {
      const campaignId: any = id;

      yield Promise.all([self.api.backSyncGoogleSearch({ id: campaignId })]);
    }),
  }))
  .actions((self) => ({
    init: apiFlow(function* init({ id }: Pick<CampaignApi, 'id'>) {
      yield Promise.all([self.initCampaign({ id })]);
    }),

    postAdGroups: apiFlow(
      function* postAdGroups(values) {
        const id = self.campaignManager.campaign?.id;

        if (!id) {
          return;
        }

        const { geoTarget, status, building, name, startDate, endDate, adGroups } = values;

        yield self.api.postAdGroups({
          id,
          data: googleSearchAd.toRequestData({
            geoTarget,
            name,
            status,
            building,
            startDate,
            endDate,
            adGroups,
          }),
        });
      },
      {
        isUpdate: true,
        formName: 'ab-testing',
        successAlert: 'CampaignForm.CampaignABTesting.Create.Alerts.Created',
      },
    ),

    destroy: () => {
      self.campaignManager.destroy();
    },
  }));

export type GoogleSearchStoreType = SnapshotOut<typeof GoogleSearchStore>;
export type GoogleSearchStoreInstanceType = Instance<typeof GoogleSearchStore>;
