import * as yup from 'yup';
import { Trans } from 'react-i18next';
import get from 'lodash.get';
import { addYears } from 'utils/dates';
import { toNumberWithComma } from 'utils/formatters';
import {
  FACEBOOK_IS_ACTIVE_FIELD,
  INSTAGRAM_IS_ACTIVE_FIELD,
  GOOGLE_ADS_IS_ACTIVE_FIELD,
  GOOGLE_ADWORDS_IS_ACTIVE_FIELD,
} from 'components/campaigns/campaign-form/ad-social-networks-selector';
import config from 'config';
import { ImageSaved, OptionFromValue, ValidDate } from 'types';
import { isEmptyObject } from './is';

const { validations } = config.options;
const { facebook, ads, adWords } = config.platforms;

yup.setLocale({
  mixed: {
    // @ts-ignore
    required: <Trans i18nKey='Common.Validations.Required' />,
  },
  string: {
    // @ts-ignore
    email: <Trans i18nKey='Common.Validations.EmailFormat' />,
    min: ({ min }: { min: number }) => <Trans i18nKey='Common.Validations.StringMinLength' values={{ amount: min }} />,
    max: ({ max }: { max: number }) => <Trans i18nKey='Common.Validations.StringMaxLength' values={{ amount: max }} />,
  },
  number: {
    max: ({ max }: { max: number }) => (
      <Trans i18nKey='Common.Validations.NumberMax' values={{ amount: toNumberWithComma(max) }} />
    ),
  },
});

const string = (): yup.StringSchema => yup.string().trim();
const shortString = (): yup.StringSchema => string().max(validations.shortStringLength);
const longString = (): yup.StringSchema => string().max(validations.longStringLength);

const neighborhood = (): yup.StringSchema =>
  string()
    .matches(/^[a-zA-Z0-9_ ]*$/, {
      message: (<Trans i18nKey='Common.Validations.SpecialChar' />) as unknown as string,
    })
    .max(validations.maxLengthNeighborhood);

const email = (): yup.StringSchema => string().email();

const phoneNumber = (): yup.StringSchema =>
  string().matches(/^\+1[0-9]{10}$/, {
    message: (<Trans i18nKey='Common.Validations.PhoneFormat' />) as unknown as string,
  });

const address = (): yup.StringSchema => string().min(validations.addressMinLength).max(validations.addressMaxLength);

const description = (): yup.StringSchema =>
  string().max(validations.descriptionMaxLength).min(validations.descriptionMinLength);

const bio = (): yup.StringSchema => string().max(validations.bioMaxLength);

const zip = (): yup.StringSchema =>
  string()
    .matches(/^[0-9]+$/, {
      message: (<Trans i18nKey='Common.Validations.ZipFormat' />) as unknown as string,
    })
    .min(validations.zipMinLength)
    .max(validations.zipMaxLength);

const positiveNumber = (): yup.NumberSchema =>
  yup.number().positive((<Trans i18nKey='Common.Validations.PositiveNumber' />) as unknown as string);

const facebookPrice = (): yup.NumberSchema =>
  positiveNumber()
    .test(
      'fbDailyBudget',
      (
        <Trans
          i18nKey='Common.Validations.MinimumBudget'
          values={{
            platform: facebook,
            price: validations.priceMinimumFb,
          }}
        />
      ) as unknown as string,
      (value) => {
        const isCorrectPrice = value && value >= validations.priceMinimumFb;

        return Boolean(isCorrectPrice);
      },
    )
    .max(validations.priceMaximum);

const adsPrice = (): yup.NumberSchema =>
  positiveNumber()
    .test(
      'adsDailyBudget',
      (
        <Trans
          i18nKey='Common.Validations.MinimumBudget'
          values={{
            platform: ads,
            price: validations.priceMinimumAds,
          }}
        />
      ) as unknown as string,
      (value) => {
        const isCorrectPrice = value && value >= validations.priceMinimumAds;

        return Boolean(isCorrectPrice);
      },
    )
    .max(validations.priceMaximum);

const adWordsPrice = (): yup.NumberSchema =>
  positiveNumber()
    .test(
      'adwordsDailyBudget',
      (
        <Trans
          i18nKey='Common.Validations.MinimumBudget'
          values={{
            platform: adWords,
            price: validations.priceMinimumAdWords,
          }}
        />
      ) as unknown as string,
      (value) => {
        const isCorrectPrice = value && value >= validations.priceMinimumAdWords;

        return Boolean(isCorrectPrice);
      },
    )
    .max(validations.priceMaximum);

const positiveInteger = (): yup.NumberSchema => positiveNumber().integer();

const url = (
  message: string = (<Trans i18nKey='Common.Validations.UrlFormat' />) as unknown as string,
): yup.StringSchema => yup.string().url(message).trim();

const runDate = ({
  validFrom,
}: {
  validFrom?: ValidDate;
} = {}): yup.AnySchema =>
  yup
    .mixed()
    .test('startDate1', (<Trans i18nKey='Common.Validations.StartDateRequired' />) as unknown as string, (value) => {
      const selectedStartDate = value;
      const isSelectedOriginalValue = validFrom && String(validFrom) === String(selectedStartDate);

      return isSelectedOriginalValue || Boolean(selectedStartDate instanceof Date);
    });

const runDates = ({
  validFrom,
  validTo,
}: {
  validFrom?: ValidDate;
  validTo?: ValidDate;
  isDraft?: boolean;
} = {}): yup.AnySchema =>
  yup
    .mixed()
    .test('startDate', (<Trans i18nKey='Common.Validations.StartDateRequired' />) as unknown as string, (value) => {
      // @ts-ignore
      const selectedStartDate = value && value[0];
      const isSelectedOriginalValue = validFrom && String(validFrom) === String(selectedStartDate);

      return isSelectedOriginalValue || Boolean(selectedStartDate instanceof Date);
    })
    .test('endDate', (<Trans i18nKey='Common.Validations.EndDateRequired' />) as unknown as string, (value) => {
      // @ts-ignore
      const selectedEndDate = value && value[1];
      const isSelectedOriginalValue = validTo && String(validTo) === String(selectedEndDate);

      return isSelectedOriginalValue || Boolean(selectedEndDate instanceof Date);
    })
    .test(
      'datesCannotBeTheSame',
      (<Trans i18nKey='Common.Validations.DateCannotBeTheSame' />) as unknown as string,
      (value) => {
        // @ts-ignore
        const [from, to] = value || [];

        return Boolean(from && to && from.getTime() !== to.getTime());
      },
    )
    .test(
      'datesMustBeThisYearOrNextYear',
      (<Trans i18nKey='Common.Validations.DateMustBeThisYearOrNextYear' />) as unknown as string,
      (value) => {
        // @ts-ignore
        const [from, to] = value || [];
        const nextYear = addYears(new Date(), 1);

        return [from, to].every((value) => value && value < nextYear);
      },
    );

// @ts-ignore
const geoTargeting = (): yup.ArraySchema<yup.AnySchema> =>
  yup
    .array()
    .nullable()
    .of(
      yup.object({
        state: yup.object({
          value: string(),
          label: string(),
          abbr: string(),
        }),
        city: yup.object({
          value: string(),
          label: string(),
        }),
      }),
    );

const apartmentParameters = (): yup.AnySchema =>
  yup.array().of(
    yup
      .object({
        type: string().required(),
        minPrice: positiveNumber().required(),
        maxPrice: positiveNumber().required(),
      })
      .test(
        'apartmentParameters',
        'Min price should be less then max price',
        (value) => value.minPrice < value.maxPrice,
      ),
  );

const apartmentPrices = (): yup.AnySchema =>
  yup.array().of(
    yup.object({
      type: string(),
      apartmentPricesMin: positiveNumber().required(),
      apartmentPricesMax: positiveNumber().required(),
    }),
  );

const tags = (values: OptionFromValue[]): yup.AnySchema =>
  yup.array().of(
    yup.object({
      label: yup
        .string()
        .required()
        .test(
          'amenity-duplicate',
          (<Trans i18nKey='Common.Validations.AmenitiesDuplication' />) as unknown as string,
          (value) => {
            const isDuplication = values
              ?.map(({ label }: { label: any }) => label)
              .find((item: string, index: number, arr: string[]) => item === value && arr.indexOf(item) !== index);

            return !isDuplication;
          },
        )
        .test(
          'description-max-length',
          (
            <Trans
              i18nKey='Common.Validations.StringMaxLength'
              values={{
                amount: validations.shortStringLength,
              }}
            />
          ) as unknown as string,
          (value) => {
            const isInvalidPresent = value && value.length > validations.shortStringLength;

            return !isInvalidPresent;
          },
        )
        .matches(/^[a-zA-Z0-9_ ]*$/, {
          message: (<Trans i18nKey='Common.Validations.SpecialChar' />) as unknown as string,
        }),
      value: string().required(),
    }),
  );

const tagsMasstransit = (): yup.AnySchema =>
  yup
    .array()
    .of(
      yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .test(
      'masstransit',
      `Some masstransit is too long, competitor length should be less then ${validations.shortStringLength}`,
      (value?: { label: string }[]) => {
        if (!value) return true;
        return value.every((competitor) => competitor.label.length <= validations.shortStringLength);
      },
    )
    .test(
      'masstransit',
      'Masstransit name allows only letters, numbers, underscores, and spaces',
      (value?: { label: string }[]) => {
        if (!value) return true;
        return value.every((competitor) => {
          const regex = /^[a-zA-Z0-9_ ]*$/;
          return regex.test(competitor.label);
        });
      },
    )
    .test('masstransit', 'Some masstransits is duplicated', (value?: { label: string }[]) => {
      if (!value) return true;
      const labels = value.map((i) => i.label);
      return !(new Set(labels).size !== labels.length);
    });

const tagsCompetitors = (): yup.AnySchema =>
  yup
    .array()
    .of(
      yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .test(
      'buildingCompetitors',
      `Some competitor is too long, competitor length should be less then ${validations.middleStringLength}`,
      (value?: { label: string }[]) => {
        if (!value) return true;
        return value.every((competitor) => competitor.label.length <= validations.middleStringLength);
      },
    )
    .test(
      'buildingCompetitors',
      'Competitor name allows only letters, numbers, underscores, and spaces',
      (value?: { label: string }[]) => {
        if (!value) return true;
        return value.every((competitor) => {
          const regex = /^[a-zA-Z0-9_ ]*$/;
          return regex.test(competitor.label);
        });
      },
    )
    .test('buildingCompetitors', 'Some competitors is duplicated', (value?: { label: string }[]) => {
      if (!value) return true;
      const labels = value.map((i) => i.label);
      return !(new Set(labels).size !== labels.length);
    });

// @ts-ignore
const tagsSimple = (): yup.ArraySchema<yup.StringSchema> => yup.array().nullable().of(yup.string());

const file = (): yup.AnySchema =>
  yup.mixed().test('invalid file', (value) => value && (value instanceof File || 'src' in value));

const clusters = (): yup.AnySchema =>
  yup.array().test('clusters', 'Field is required', (value) => {
    if (value?.length === 0) {
      return false;
    }
    return true;
  });

const images = (): yup.AnySchema =>
  yup
    .array()
    .transform((value) => {
      const resolvedImages = value?.filter((item: ImageSaved) => !item?.isOld);

      return resolvedImages?.length ? resolvedImages : undefined;
    })
    .of(file());

const passwordLogin = (): yup.StringSchema => string();

const password = (): yup.StringSchema =>
  string()
    .min(8)
    .max(64)
    .matches(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, {
      message: (<Trans i18nKey='Common.Validations.PasswordSpecialCharacter' />) as unknown as string,
    })
    .matches(/[A-Z]/, {
      message: (<Trans i18nKey='Common.Validations.PasswordUppercaseCharacter' />) as unknown as string,
    })
    .matches(/[a-z]/, {
      message: (<Trans i18nKey='Common.Validations.PasswordLowercaseCharacter' />) as unknown as string,
    })
    .matches(/[0-9]/, {
      message: (<Trans i18nKey='Common.Validations.PasswordDigit' />) as unknown as string,
    });

const passwordConfirmation = (
  { passwordField }: { passwordField: string } = { passwordField: 'password' },
): yup.StringSchema =>
  string().test(
    'passwords-match',
    (<Trans i18nKey='Common.Validations.ConfirmPassword' />) as unknown as string,
    function passwordConfirmation(value) {
      return this.parent[passwordField] === value;
    },
  );

const accept = (): yup.BooleanSchema =>
  // @ts-ignore
  yup
    .boolean()
    .oneOf([true], (<Trans i18nKey='Common.Validations.Accept' />) as unknown as string)
    .default(false);

const isValidKeyword = (value: string): boolean => {
  const exactMatchRegex = /^\[[a-zA-Z0-9_ ]+]$/;
  const phraseMatchRegex = /^"[a-zA-Z0-9_ ]+"$/;
  const typeMatchRegex = /^[a-zA-Z0-9_ ]+$/;

  return Boolean(value && (exactMatchRegex.test(value) || phraseMatchRegex.test(value) || typeMatchRegex.test(value)));
};

let val1 = true;
let val2 = true;
let val3 = true;
let val4 = true;
let val5 = true;
let val6 = true;

const keywordGroups = (
  // eslint-disable-next-line
  keywords: any[],
): yup.AnySchema =>
  yup.array().of(
    yup.object({
      keywords: yup
        .array()
        .test(
          'keyword-label',
          (<Trans i18nKey='Common.Validations.InvalidKeywordFormat' />) as unknown as string,
          (value) => !value || value.every(({ label }) => Boolean(label && isValidKeyword(label))),
        )
        .test(
          'keywords-length',
          (<Trans i18nKey='Common.Validations.KeywordMinLength' />) as unknown as string,
          (value) => Boolean(value?.length),
        )
        .test(
          'keywords-duplicate',
          (<Trans i18nKey='Common.Validations.KeywordsDuplicationMin' />) as unknown as string,
          () => {
            keywords.forEach((ad: any, index: number) => {
              const keyword = ad.keywords.map(({ label }: any) => label);
              const duplicates = keyword.filter((item: any, i: number) => keyword.indexOf(item) !== i);
              const menuItem = document.getElementById(`keywords[${index}].group`);
              const error = document.getElementById('main-validation-message');
              if (duplicates.length > 0) {
                if (menuItem) {
                  val4 = true;
                  menuItem.style.borderLeft = `${2}px solid red`;
                  menuItem.style.borderRadius = `${20 / 2}px`;
                  if (error) {
                    error.style.display = 'flex';
                  }
                }
              } else if (duplicates.length === 0 && !val2 && !val3 && !val1 && !val5 && !val6) {
                if (menuItem) {
                  val4 = false;
                  menuItem.style.borderLeft = '2 px solid white';
                  if (error) {
                    error.style.display = 'none';
                  }
                }
              }
            });

            const hasDuplicateTitles = keywords.some((obj: any) => {
              const labels = obj.keywords?.map(({ label }: any) => label);
              const duplicates = labels?.filter((item: any, index: number) => labels.indexOf(item) !== index);

              return duplicates.length > 0;
            });

            return !hasDuplicateTitles;
          },
        )
        .test(
          'keyword-max-length',
          (
            <Trans
              i18nKey='Common.Validations.StringMaxLength'
              values={{
                amount: validations.maxKeywordLength,
              }}
            />
          ) as unknown as string,
          (value) => {
            const isInvalidPresent = value?.find(({ label }) => label.length > validations.maxKeywordLength);

            return Boolean(!isInvalidPresent);
          },
        ),
      titles: yup.array().of(
        yup.object({
          label: yup
            .string()
            .test(
              'title-duplicate',
              (<Trans i18nKey='Common.Validations.TitleDuplicationMin' />) as unknown as string,
              (value) => {
                keywords.forEach((ad: any, index: number) => {
                  const titles = ad.titles.map(({ label }: any) => label);
                  const duplicates = titles.filter((item: any, i: number) => titles.indexOf(item) !== i);
                  const menuItem = document.getElementById(`keywords[${index}].group`);
                  const error = document.getElementById('main-validation-message');
                  if (duplicates.length > 0) {
                    if (menuItem) {
                      val1 = true;
                      menuItem.style.borderLeft = `${2}px solid red`;
                      menuItem.style.borderRadius = `${20 / 2}px`;
                      if (error) {
                        error.style.display = 'flex';
                      }
                    }
                  } else if (duplicates.length === 0 && !val2 && !val3 && !val4 && !val5 && !val6) {
                    if (menuItem) {
                      val1 = false;
                      menuItem.style.borderLeft = '2px solid white';
                      if (error) {
                        error.style.display = 'none';
                      }
                    }
                  }
                });

                const sliceSpaces = (string: string) => string.replace(/^\s+|\s+$/g, '');

                const hasDuplicateTitles = keywords.some((obj: any) => {
                  const labels = obj.titles?.map(({ label }: any) => sliceSpaces(label));
                  const duplicates = labels?.filter((item: any, index: number) => labels.indexOf(item) !== index);
                  const slicedValue = value && sliceSpaces(value);
                  return duplicates?.includes(slicedValue);
                });

                return !hasDuplicateTitles;
              },
            )
            .test('not-exclamation-mark', 'Exclamation marks are not allowed!', (value) => {
              const isMark = value && value.includes('!');
              keywords.forEach((ad: any, index: number) => {
                ad.titles?.forEach((title: any) => {
                  const menuItem = document.getElementById(`keywords[${index}].group`);
                  const error = document.getElementById('main-validation-message');
                  if (title.label.includes('!')) {
                    if (menuItem) {
                      val6 = true;
                      menuItem.style.borderLeft = `${2}px solid red`;
                      menuItem.style.borderRadius = `${20 / 2}px`;
                      if (error) {
                        error.style.display = 'flex';
                      }
                    }
                  } else if (!title.label.includes('!') && !val1 && !val3 && !val4 && !val2 && !val5) {
                    if (menuItem) {
                      val6 = false;
                      menuItem.style.borderLeft = '2 px solid white';
                      if (error) {
                        error.style.display = 'none';
                      }
                    }
                  }
                });
              });

              return !isMark;
            })
            .test(
              'title-max-length',
              (
                <Trans
                  i18nKey='Common.Validations.StringMaxLength'
                  values={{
                    amount: validations.maxTitleLength,
                  }}
                />
              ) as unknown as string,
              (value) => {
                keywords.forEach((ad: any, index: number) => {
                  ad.titles?.forEach((title: any) => {
                    const menuItem = document.getElementById(`keywords[${index}].group`);
                    const error = document.getElementById('main-validation-message');
                    if (title.label.length > 30) {
                      if (menuItem) {
                        val5 = true;
                        menuItem.style.borderLeft = `${2}px solid red`;
                        menuItem.style.borderRadius = `${20 / 2}px`;
                        if (error) {
                          error.style.display = 'flex';
                        }
                      }
                    } else if (title.label.length < 30 && !val1 && !val3 && !val4 && !val2 && !val6) {
                      if (menuItem) {
                        val5 = false;
                        menuItem.style.borderLeft = '2 px solid white';
                        if (error) {
                          error.style.display = 'none';
                        }
                      }
                    }
                  });
                });

                const isInvalidPresent = value && value.length > 30;
                return !isInvalidPresent;
              },
            )
            .test('title-required', (<Trans i18nKey='Common.Validations.Required' />) as unknown as string, (value) => {
              const isInvalidPresent = value === undefined;
              return !isInvalidPresent;
            }),
        }),
      ),
      descriptions: yup.array().of(
        yup.object({
          label: yup
            .string()
            .test(
              'description-max-length',
              (
                <Trans
                  i18nKey='Common.Validations.StringMaxLength'
                  values={{
                    amount: validations.maxDescriptionLength,
                  }}
                />
              ) as unknown as string,
              (value) => {
                keywords.forEach((ad: any, index: number) => {
                  ad.descriptions?.forEach((description: any) => {
                    const menuItem = document.getElementById(`keywords[${index}].group`);
                    const error = document.getElementById('main-validation-message');
                    if (description.label.length > 90) {
                      if (menuItem) {
                        val2 = true;
                        menuItem.style.borderLeft = `${2}px solid red`;
                        menuItem.style.borderRadius = `${20 / 2}px`;
                        if (error) {
                          error.style.display = 'flex';
                        }
                      }
                    } else if (description.label.length < 90 && !val1 && !val3 && !val4 && !val5 && !val6) {
                      if (menuItem) {
                        val2 = false;
                        menuItem.style.borderLeft = '2 px solid white';
                        if (error) {
                          error.style.display = 'none';
                        }
                      }
                    }
                  });
                });
                const isInvalidPresent = value && value.length > validations.maxDescriptionLength;
                return !isInvalidPresent;
              },
            )
            .test(
              'description-required',
              (<Trans i18nKey='Common.Validations.Required' />) as unknown as string,
              (value) => {
                const isInvalidPresent = value === undefined;
                return !isInvalidPresent;
              },
            )
            .test(
              'description-duplicate',
              (<Trans i18nKey='Common.Validations.DescriptionDuplicationMin' />) as unknown as string,
              (value) => {
                keywords.forEach((ad: any, index: number) => {
                  const descriptions = ad.descriptions.map(({ label }: any) => label);
                  const duplicates = descriptions.filter((item: any, i: number) => descriptions.indexOf(item) !== i);
                  const menuItem = document.getElementById(`keywords[${index}].group`);
                  const error = document.getElementById('main-validation-message');
                  if (duplicates.length > 0) {
                    if (menuItem) {
                      val3 = true;
                      menuItem.style.borderLeft = `${2}px solid red`;
                      menuItem.style.borderRadius = `${20 / 2}px`;
                      if (error) {
                        error.style.display = 'flex';
                      }
                    }
                  } else if (duplicates.length === 0 && !val1 && !val2 && !val4 && !val5 && !val6) {
                    if (menuItem) {
                      val3 = false;
                      menuItem.style.borderLeft = '2 px solid white';
                      if (error) {
                        error.style.display = 'none';
                      }
                    }
                  }
                });

                const sliceSpaces = (string: string) => string.replace(/^\s+|\s+$/g, '');
                const hasDuplicateDescriptions = keywords.some((obj: any) => {
                  const labels = obj.descriptions?.map(({ label }: any) => sliceSpaces(label));
                  const duplicates = labels?.filter((item: any, index: number) => labels.indexOf(item) !== index);
                  const slicedValue = value && sliceSpaces(value);
                  return duplicates?.includes(slicedValue);
                });
                return !hasDuplicateDescriptions;
              },
            ),
        }),
      ),
      group: yup.object().test(
        'group-max-length',
        (
          <Trans
            i18nKey='Common.Validations.StringMaxLength'
            values={{
              amount: validations.maxGroupLength,
            }}
          />
        ) as unknown as string,
        (value: any) => {
          const isInvalidPresent = value && value.label.length > validations.maxGroupLength;

          return !isInvalidPresent;
        },
      ),
    }),
  );

const titleGoogleOptions = ({ titles }: { titles?: OptionFromValue[] }): yup.AnySchema =>
  yup.array().of(
    yup.object({
      label: yup
        .string()
        .test(
          'title-max-length',
          (
            <Trans
              i18nKey='Common.Validations.StringMaxLength'
              values={{
                amount: validations.maxTitleLength,
              }}
            />
          ) as unknown as string,
          (value) => {
            const isInvalidPresent = value && value.length > validations.maxTitleLength;

            return Boolean(!isInvalidPresent);
          },
        )
        .test(
          'title-duplicate',
          (<Trans i18nKey='Common.Validations.TitleDuplication' />) as unknown as string,
          (value) => {
            const sliceSpaces = (string: string) => string.replace(/^\s+|\s+$/g, '');
            const slicedValue = value && sliceSpaces(value);
            const isDuplication = titles
              ?.map(({ label }: { label: any }) => sliceSpaces(label))
              .find(
                (item: string, index: number, arr: string[]) =>
                  sliceSpaces(item) === slicedValue && arr.indexOf(item) !== index,
              );
            return !isDuplication;
          },
        )
        .test(
          'not-exclamation-mark',
          (<Trans i18nKey='Common.Validations.noExclamationMark' />) as unknown as string,
          (value) => {
            const isMark = value && value.includes('!');

            return !isMark;
          },
        )
        .test('not-exclamation-mark', 'Exclamation marks are not allowed!', (value) => {
          const isMark = value && value.includes('!');

          return !isMark;
        })
        .test(
          'not-question-mark',
          (<Trans i18nKey='Common.Validations.noQuestionErrorMark' />) as unknown as string,
          (value) => {
            const isQuestion = value && value.indexOf('?') !== value.lastIndexOf('?');

            return !isQuestion;
          },
        )
        .test(
          'not-special-symbol',
          (<Trans i18nKey='Common.Validations.SpecialSymbolsError' />) as unknown as string,
          (value) => {
            const exactMatchRegex = /([\\*@\u2022\u2026]|[\\.]{3,})+/;

            const isSpecialSymbol = value && exactMatchRegex.test(value);

            return !isSpecialSymbol;
          },
        )
        .required(),
    }),
  );

const titleFbOptions = (): yup.AnySchema =>
  yup.array().of(
    yup.object({
      label: yup
        .string()
        .required()
        .test(
          'title-max-length',
          (
            <Trans
              i18nKey='Common.Validations.StringMaxLength'
              values={{
                amount: validations.shortStringLength,
              }}
            />
          ) as unknown as string,
          (value) => {
            const isInvalidPresent = value && value.length > validations.shortStringLength;

            return Boolean(!isInvalidPresent);
          },
        ),
    }),
  );

const descriptionGoogleOptions = ({ descriptions }: { descriptions?: OptionFromValue[] }): yup.AnySchema =>
  yup.array().of(
    yup.object({
      label: yup
        .string()
        .test(
          'description-max-length',
          (
            <Trans
              i18nKey='Common.Validations.StringMaxLength'
              values={{
                amount: validations.maxDescriptionLength,
              }}
            />
          ) as unknown as string,
          (value) => {
            const isInvalidPresent = value && value.length > validations.maxDescriptionLength;

            return !isInvalidPresent;
          },
        )
        .test(
          'description-duplicate',
          (<Trans i18nKey='Common.Validations.DescriptionDuplication' />) as unknown as string,
          (value) => {
            const sliceSpaces = (string: string) => string.replace(/^\s+|\s+$/g, '');
            const slicedValue = value && sliceSpaces(value);
            const isDuplication = descriptions
              ?.map(({ label }: { label: any }) => sliceSpaces(label))
              .find(
                (item: string, index: number, arr: string[]) =>
                  sliceSpaces(item) === slicedValue && arr.indexOf(item) !== index,
              );

            return !isDuplication;
          },
        )
        .test(
          'only-one-exclamation-mark',
          (<Trans i18nKey='Common.Validations.OnlyOneExclamationMark' />) as unknown as string,
          (value) => {
            const isExclamationMark = value && value.indexOf('!') !== value.lastIndexOf('!');

            return !isExclamationMark;
          },
        )
        .test(
          'not-question-mark',
          (<Trans i18nKey='Common.Validations.noQuestionErrorMark' />) as unknown as string,
          (value) => {
            const isQuestion = value && value.indexOf('?') !== value.lastIndexOf('?');

            return !isQuestion;
          },
        )
        .test(
          'not-special-symbol',
          (<Trans i18nKey='Common.Validations.SpecialSymbolsError' />) as unknown as string,
          (value) => {
            const exactMatchRegex = /([\\*@\u2022\u2026]|[\\.]{3,})+/;

            const isSpecialSymbol = value && exactMatchRegex.test(value);

            return !isSpecialSymbol;
          },
        )
        .required(),
    }),
  );

const descriptionFbOptions = (): yup.AnySchema =>
  yup.array().of(
    yup.object({
      label: yup
        .string()
        .required()
        .test(
          'description-max-length',
          (
            <Trans
              i18nKey='Common.Validations.StringMaxLength'
              values={{
                amount: validations.maxDescriptionFbLength,
              }}
            />
          ) as unknown as string,
          (value) => {
            const isInvalidPresent = value && value.length > validations.maxDescriptionFbLength;

            return !isInvalidPresent;
          },
        ),
    }),
  );

// @ts-ignore
const facebookAd = (): yup.ArraySchema<yup.AnySchema> =>
  yup.object({
    descriptions: yup.array().of(
      yup.object({
        label: yup.string().required(),
      }),
    ),
    titles: yup.array().of(
      yup.object({
        label: yup.string().required(),
      }),
    ),
  }) as any;

// @ts-ignore
const googleAdWordsAd = (): yup.ArraySchema<yup.AnySchema> =>
  yup.object({
    keywords: yup.array().min(1).required(),
    descriptions: yup.array().of(
      yup.object({
        label: yup.string(),
      }),
    ),
    titles: yup.array().of(
      yup.object({
        label: yup.string(),
      }),
    ),
  }) as any;

const googleAdsAd = ({
  descriptions,
  titles,
}: {
  descriptions?: OptionFromValue[];
  titles?: OptionFromValue[];
  // @ts-ignore
} = {}): yup.ArraySchema<yup.AnySchema> =>
  yup.object({
    descriptions: yup.array().of(
      yup.object({
        label: yup
          .string()
          .test('description-max-length', (value) => {
            const isInvalidPresent = value && value.length > validations.maxDescriptionLength;

            return !isInvalidPresent;
          })
          .test('description-not-empty', () => {
            const empty = descriptions?.find((description: any) => isEmptyObject(description));

            return !empty;
          })
          .test('descriptions-not-duplicated', () => {
            const description = descriptions
              ?.map(({ label }: { label: any }) => label)
              .find((item: string, index: number, arr: string[]) => arr.indexOf(item) !== index);

            return !description;
          }),
      }),
    ),
    titles: yup.array().of(
      yup.object({
        label: yup.string().test('titles-not-duplicated', () => {
          const title = titles
            ?.map(({ label }: { label: any }) => label)
            .find((item: string, index: number, arr: string[]) => arr.indexOf(item) !== index);

          return !title;
        }),
      }),
    ),
  }) as any;

const adSocialNetworks = (): yup.AnySchema =>
  yup
    .mixed()
    .test(
      'ad-social-networks',
      (<Trans i18nKey='Common.Validations.AdSocials' />) as unknown as string,
      function adSocialNetworks() {
        const parentValue = (this as any).parent;

        return [
          FACEBOOK_IS_ACTIVE_FIELD,
          INSTAGRAM_IS_ACTIVE_FIELD,
          GOOGLE_ADS_IS_ACTIVE_FIELD,
          GOOGLE_ADWORDS_IS_ACTIVE_FIELD,
        ].some((field) => get(parentValue, field));
      },
    )
    // it's useless default value. just hack to add ability to use 'required' rule
    .default('');

export {
  string,
  shortString,
  longString,
  bio,
  url,
  phoneNumber,
  positiveNumber,
  facebookPrice,
  adsPrice,
  adWordsPrice,
  positiveInteger,
  runDates,
  runDate,
  geoTargeting,
  email,
  zip,
  address,
  description,
  apartmentParameters,
  apartmentPrices,
  tags,
  tagsMasstransit,
  tagsCompetitors,
  tagsSimple,
  images,
  clusters,
  password,
  passwordLogin,
  passwordConfirmation,
  accept,
  isValidKeyword,
  keywordGroups,
  adSocialNetworks,
  googleAdWordsAd,
  titleGoogleOptions,
  descriptionGoogleOptions,
  titleFbOptions,
  descriptionFbOptions,
  googleAdsAd,
  facebookAd,
  neighborhood,
};
