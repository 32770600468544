import React, { memo } from 'react';
import { Radio as AntdRadio } from 'antd';
import classnames from 'classnames';
import { RadioProps } from './types';
import styles from './styles.module.scss';

export const Radio = memo<RadioProps>(({ className: classNameFromProps, ...props }) => {
  const className = classnames(styles.root, classNameFromProps);

  return <AntdRadio className={className} {...props} />;
});
