import React from 'react';
import { Input } from 'antd';
import { Icon } from 'components/common/icon';
import { withFormItem } from 'hocs/with-form-item';
import { useInputProps } from './hooks';
import { PasswordFieldProps } from './types';

const iconRender: NonNullable<PasswordFieldProps['iconRender']> = (visible) => (
  <Icon name={visible ? 'eye' : 'closed-eye'} size={24} color='black3' />
);

export const PasswordField = withFormItem<PasswordFieldProps>()((props) => {
  const inputProps = useInputProps(props);

  return <Input.Password iconRender={iconRender} {...inputProps} />;
});
