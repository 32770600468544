import { CampaignsFilter, CampaignApi, PostCampaignApi, PatchCampaignApi } from 'types';
import { serializeFiltrationParamsWithoutOrdering } from 'utils/serializers';
import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import {
  GetCampaignsResponse,
  GetCampaignResponse,
  PostCampaignResponse,
  PatchCampaignResponse,
} from '../response-types';
import { createFormData } from './helpers';

export const createCampaignsApi = (http: BaseHttpClient) => ({
  getCampaigns(options?: CampaignsFilter) {
    return http.get<GetCampaignsResponse>(urls.campaigns.getAll.url, serializeFiltrationParamsWithoutOrdering(options));
  },

  getCampaign({ id }: Pick<CampaignApi, 'id'>) {
    return http.get<GetCampaignResponse>(urls.campaigns.details.url.replace('{id}', String(id)));
  },

  postCampaign(campaign: Partial<PostCampaignApi>) {
    return http.post<PostCampaignResponse>(urls.campaigns.post.url, createFormData(campaign));
  },

  patchCampaign({ id, data }: Pick<CampaignApi, 'id'> & { data: Partial<PatchCampaignApi> }) {
    return http.patch<PatchCampaignResponse>(
      urls.campaigns.patch.url.replace('{id}', String(id)),
      createFormData(data),
    );
  },

  deleteCampaign({ id }: Pick<CampaignApi, 'id'>) {
    return http.delete(urls.campaigns.remove.url.replace('{id}', String(id)));
  },
});
