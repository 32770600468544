import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';

export const FormStatesStore = BaseModel.named('FormStatesStore')
  .props({
    forms: types.optional(types.frozen(), {}),
  })
  .actions((self) => ({
    setFormSubmitting({ formName, isSubmitting }: { formName: string; isSubmitting: boolean }): void {
      self.forms = {
        ...self.forms,
        [formName]: {
          ...self.forms[formName],
          isSubmitting,
        },
      };
    },

    resetFormState({ formName }: { formName: string }): void {
      const { [formName]: removedFormErrors, ...forms } = self.forms;

      self.forms = forms;
    },
  }));

export interface FormStatesStoreInstance extends Instance<typeof FormStatesStore> {}
export interface FormStatesStoreSnapshot extends SnapshotOut<typeof FormStatesStore> {}
