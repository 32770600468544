import { useForm, useStores } from 'hooks';
import { OptionDetailed } from 'types';
import { useMemo } from 'react';
import { useCampaignFormContext } from './use-campaign-form-context';

export const useFacebookAdPreviewHeader = (): {
  title: Nullable<OptionDetailed['label']>;
  image: Nullable<OptionDetailed['picture']>;
} => {
  const {
    campaignManager: { socialNetworks },
  } = useCampaignFormContext();
  const { facebookPageOptions } = socialNetworks;
  const { values } = useForm();
  const selectedFbPageId = (values as any).fbPageId;
  const selectedInstagramAccountId = (values as any).instagramAccountId;

  const { abTestingCampaign } = useStores();

  const { setFBValue, setInstValue, FBValue } = abTestingCampaign;

  if (selectedFbPageId !== undefined) {
    setFBValue(selectedFbPageId);
  }

  if (selectedInstagramAccountId !== undefined) {
    setInstValue(selectedInstagramAccountId);
  }

  const selectedFbPage = useMemo(
    () => facebookPageOptions.find(({ value }) => value === FBValue),
    [facebookPageOptions, FBValue],
  );

  const title = selectedFbPage?.label;
  const image = selectedFbPage?.picture;

  return { title, image };
};
