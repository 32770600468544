import MockAdapter from 'axios-mock-adapter';
import { datatype } from 'faker';
import database from 'database';
import { startOfDay, endOfDay, differenceInDays, addDays } from 'utils/dates';
import { LineChartItemApi } from 'types';
import { urls } from '../api-urls';
import { getUrlParamsByRegexp } from '../helper';

const generateAnalytics = ({
  from,
  to,
  categories,
}: {
  from: Date;
  to: Date;
  categories: string[];
}): LineChartItemApi[] => {
  const startDate = startOfDay(from);
  const days = Math.abs(differenceInDays(startDate, endOfDay(to))) + 1;
  const dates = Array.from({ length: days }, (_, index) => addDays(startDate, index));

  return dates
    .map((date) =>
      categories.map((category) => ({
        category,
        label: date.toJSON(),
        value: datatype.number({ min: 0, max: 1000 }),
      })),
    )
    .flat();
};

export function createReportsMock(mock: MockAdapter): void {
  const {
    reports: { getCampaignReports, getReportsOverview },
  } = urls;

  mock.onGet(getCampaignReports.regex).reply(async (config) => {
    const [id] = getUrlParamsByRegexp({
      url: config.url,
      regexp: getCampaignReports.regex,
    });
    const query = { campaign_id: id };

    const { from = addDays(new Date(), -10), to = new Date() } = config.params;

    const age = await database.ageReports.findAll(query);
    const region = await database.regionReports.findAll(query);
    const totalImpressions = await database.totalImpressionReports.findAll(query);
    const totalClicks = await database.totalClickReports.findAll(query);

    return [
      200,
      {
        summary: {
          spend: 33750,
          cost_per_lead: 6750,
          leads: 5,
          impressions: 41478,
          clicks: 2565,
          clicks_through_rates: 0.0849,
          cost_per_click: 18,
        },
        age,
        region,
        total_impressions: totalImpressions,
        total_clicks: totalClicks,
        analytics: generateAnalytics({
          from,
          to,
          categories: [
            'Spend',
            'Cost per lead',
            'Leads',
            'Impressions',
            'Clicks',
            'Clicks through rates',
            'Cost per unique click',
          ],
        }),
      },
    ];
  });

  mock.onGet(getReportsOverview.url).reply(async (config) => [
    200,
    {
      impressions: 13059,
      impressions_per_day: 1187,
      clicks: 768,
      clicks_per_day: 70,
      clicks_through_rate: 0.12,
      cost_per_click: 8.54,
      leads: 5,
    },
  ]);
}
