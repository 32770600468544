import { AD_TITLE_FIELD, AD_DESCRIPTION_FIELD } from 'components/campaigns/campaign-form/constants';
import { GOOGLE_ADS_FIELD, GOOGLE_ADWORDS_FIELD } from 'components/campaigns/campaign-form/ad-social-networks-selector';

const amount = {
  one: 1,
  treeTitles: 3,
  twoDescriptions: 2,
  tenTitles: 10,
  fifteenTitles: 15,
  fourDescriptions: 4,
  five: 5,
};

export const minIndex = (name: string, tagName: string): number => {
  if (tagName === GOOGLE_ADWORDS_FIELD) {
    if (name === AD_TITLE_FIELD) {
      return amount.treeTitles;
    }
    if (name === AD_DESCRIPTION_FIELD) {
      return amount.twoDescriptions;
    }
  }
  return amount.one;
};

export const maxIndex = (name: string, tagName: string): number => {
  if (tagName === GOOGLE_ADWORDS_FIELD) {
    if (name === AD_TITLE_FIELD) {
      return amount.fifteenTitles;
    }
    if (name === AD_DESCRIPTION_FIELD) {
      return amount.fourDescriptions;
    }
  } else if (tagName === GOOGLE_ADS_FIELD) {
    return amount.five;
  }
  return amount.one;
};
