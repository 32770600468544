import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Link, RouterLink } from 'components/common/link';
import { TextField, PasswordField, Checkbox, StateField, CityField, PhoneNumberField } from 'components/common/form';
import { AuthForm } from 'components/auth/auth-form';
import { Typography } from 'components/common/typography';
import { useTranslation, useStores, usePreloader, useParams } from 'hooks';
import locations from 'navigation/locations';
import { validationSchema } from 'pages/auth/sign-up/validation-schemas';
import { SignUpFormValues } from 'stores/session-store';

const alternativeActionComponents = [<RouterLink to={locations.signInWithPassword.toUrl()} />];

const acceptComponents = [
  <Link href={locations.termsOfService.toUrl()} />,
  <Link href={locations.privacyPolicy.toUrl()} />,
];

export const InvitedSignUp = observer(() => {
  const { uid, token } = useParams<{
    uid: string;
    token: string;
  }>();

  const [isTokenInvalid, setIsTokenInvalid] = useState(false);

  const { invitedSignUp } = useStores();

  const { initialValues, loadInvitedUser, setupInvitedUser, destroy, isTokenUsed } = invitedSignUp;

  usePreloader({ store: invitedSignUp });

  const { t } = useTranslation();

  useEffect(() => destroy, [destroy]);

  useEffect(() => {
    (async () => {
      if (!isTokenUsed) {
        try {
          const activationErrors = await loadInvitedUser({
            uid,
            token,
          });

          setIsTokenInvalid(Boolean(activationErrors?.isTokenInvalid));
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [isTokenUsed, loadInvitedUser, token, uid]);

  const onSubmit = useCallback(
    async (values: SignUpFormValues) => {
      await setupInvitedUser({ ...values, token });
    },
    [setupInvitedUser, token],
  );

  const expiredTokenInfo = useMemo(
    () =>
      isTokenInvalid
        ? {
            title: t('Auth.InvitedSignUp.InvalidTokenTitle'),
            titleIcon: {
              color: 'errorColor',
              name: 'close',
            },
            description: t('Auth.InvitedSignUp.InvalidTokenDescription'),
          }
        : undefined,
    [isTokenInvalid, t],
  );

  return (
    <AuthForm
      title={t('Auth.SignUp.Title')}
      alternativeAction={
        isTokenInvalid ? null : (
          <Trans i18nKey='Auth.SignUp.AlternativeAction' components={alternativeActionComponents} />
        )
      }
      name='invited-sign-up'
      initialValues={initialValues}
      submitText={isTokenInvalid ? null : t('Auth.SignUp.SubmitButton')}
      onSubmit={onSubmit}
      validation={validationSchema}
      {...expiredTokenInfo}
    >
      {expiredTokenInfo ? null : (
        <>
          <TextField
            name='name'
            label={t('Auth.SignUp.Labels.Name')}
            placeholder={t('Auth.SignUp.Placeholders.Name')}
          />
          <TextField
            name='lastName'
            label={t('Auth.SignUp.Labels.LastName')}
            placeholder={t('Auth.SignUp.Placeholders.LastName')}
          />
          <TextField
            name='email'
            disabled
            label={t('Auth.SignUp.Labels.Email')}
            placeholder={t('Auth.SignUp.Placeholders.Email')}
          />
          <StateField
            name='state'
            label={t('Auth.SignUp.Labels.State')}
            placeholder={t('Auth.SignUp.Placeholders.State')}
          />
          <CityField
            name='city'
            stateFormField='state'
            label={t('Auth.SignUp.Labels.City')}
            placeholder={t('Auth.SignUp.Placeholders.City')}
          />
          <PhoneNumberField
            name='contactPhone'
            label={t('Auth.SignUp.Labels.Phone')}
            placeholder={t('Auth.SignUp.Placeholders.Phone')}
          />
          <PasswordField
            name='password'
            label={t('Auth.SignUp.Labels.Password')}
            placeholder={t('Auth.SignUp.Placeholders.Password')}
          />
          <PasswordField
            name='confirmPassword'
            label={t('Auth.SignUp.Labels.ConfirmPassword')}
            placeholder={t('Auth.SignUp.Placeholders.ConfirmPassword')}
          />
          <Checkbox name='accept'>
            <Typography variant='body-7' color='grey5'>
              <Trans i18nKey='Auth.SignUp.Labels.Accept' components={acceptComponents} />
            </Typography>
          </Checkbox>
        </>
      )}
    </AuthForm>
  );
});
