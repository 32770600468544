import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';

export const NotFoundPageStore = BaseModel.named('NotFoundPageStore')
  .props({
    visible: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setVisible(state: boolean): void {
      self.visible = state;
    },

    destroy(): void {
      self.visible = false;
    },
  }));

export interface NotFoundPageStoreInstance extends Instance<typeof NotFoundPageStore> {}
export interface NotFoundPageStoreSnapshot extends SnapshotOut<typeof NotFoundPageStore> {}
