import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { GoogleAdWordsAdApi, GoogleAdWordsAdApiSerialized } from 'types';
import BaseModel from 'stores/models/base';
import KeywordGroup from 'stores/models/keyword-group';
import { MSTAddStaticMethods } from 'stores/utils';
import { nullable } from 'stores/mst-types';
import TitleOption from 'stores/models/ads/title-option';
import DescriptionOption from 'stores/models/ads/description-option';
import { serializeKeywordGroups } from 'utils/serializers';
import { excludeEmptyObjects } from 'utils/arrays';

const staticMethods = {
  fromResponseData: (data: GoogleAdWordsAdApi): GoogleAdWordsAdSnapshot => ({
    isActive: Boolean(data?.is_active),
    titles: data.titles && data.titles.map(TitleOption.fromResponseData),
    descriptions: data.descriptions && data.descriptions.map(DescriptionOption.fromResponseData),
    keywords: data.keywords && data.keywords.map(KeywordGroup.fromResponseData),
    name: 'googleAdWordsAd',
  }),

  toRequestData: (data: GoogleAdWordsAdSnapshot): GoogleAdWordsAdApiSerialized => ({
    titles: data.titles && excludeEmptyObjects(data.titles)?.length ? data.titles.map(TitleOption.toRequestData) : null,
    descriptions:
      data.descriptions && excludeEmptyObjects(data.descriptions)?.length
        ? data.descriptions?.map(DescriptionOption.toRequestData)
        : null,
    keywords: data.keywords && serializeKeywordGroups(data.keywords),
    is_active: Boolean(data.isActive),
  }),
};

const GoogleAdWordsAd = BaseModel.named('GoogleAdWordsAd').props({
  isActive: types.boolean,
  titles: nullable(types.array(TitleOption)),
  descriptions: nullable(types.array(DescriptionOption)),
  keywords: nullable(types.array(KeywordGroup)),
  name: types.string,
});

export type GoogleAdWordsAdInstanceType = Instance<typeof GoogleAdWordsAd>;
export type GoogleAdWordsAdSnapshot = SnapshotOut<typeof GoogleAdWordsAd>;

export default MSTAddStaticMethods(GoogleAdWordsAd, staticMethods);
