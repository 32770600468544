import React, { useMemo } from 'react';
import { Form } from 'components/common/form/form';
import { ImageUploader } from 'components/common/form/image-uploader';
import { withModal } from 'hocs/with-modal';
import { useModalProps, useTranslation } from 'hooks';
import { AdLogoPickerModalProps } from './types';

const imagePreviewStyle = {
  objectFit: 'contain' as const,
};

const placeholderProps = {
  height: 192,
  imageHeight: 145,
};

export const AdLogoPickerModal = withModal<AdLogoPickerModalProps>()((props) => {
  const { t } = useTranslation();

  useModalProps(
    useMemo(
      () => ({
        title: t('AdLogoPicker.Title'),
        titleProps: { textAlign: 'center' },
        width: 627,
      }),
      [t],
    ),
  );

  const footer = useMemo(
    () => ({
      cancelButtonAvailable: false,
      submitText: t('AdLogoPicker.SaveButton'),
    }),
    [t],
  );

  return (
    <Form footer={footer} {...props}>
      <ImageUploader
        name='logo'
        label={t('AdLogoPicker.Labels.Logo')}
        multiple={false}
        imagePreviewStyle={imagePreviewStyle}
        placeholderProps={placeholderProps}
      />
    </Form>
  );
});
