import { ImageSaved, KeywordsGroupFormValue, Option, OptionWithOldMarker } from 'types';

const getOptionLabelByValue = ({ options, value }: { options: Option[]; value: Option['value'] }): Option['label'] =>
  options.find(({ value: optionValue }) => optionValue === value)?.label as Option['label'];

const getOptionValueByLabel = ({ options, label }: { options: Option[]; label: Option['label'] }): Option['label'] =>
  options.find(({ label: optionLabel }) => optionLabel === label)?.value as Option['value'];

const isOldValue = ({ values, options }: { values?: any; options?: OptionWithOldMarker[] }): boolean =>
  Boolean(
    options
      ?.filter(({ value }) => value)
      .filter(({ value }) => values && values.find(({ value: id }: { value: string }) => id === value))
      .find(({ isOld }) => isOld),
  );

const includesOldImages = ({ value, options }: { value?: ImageSaved[]; options?: ImageSaved[] }): boolean =>
  Boolean(options?.some(({ isOld, src: optionValue }) => value?.some(({ src }) => src === optionValue) && isOld));

const isOldKeywordValues = ({
  values,
  options,
}: {
  values?: KeywordsGroupFormValue[];
  options?: KeywordsGroupFormValue[];
}): boolean => {
  const allKeywords =
    options &&
    options
      ?.filter((option) => option.keywords)
      ?.map((option) => option.keywords)
      ?.reduce((a, b) => a.concat(b), []);
  const currentKeywords = values
    ?.filter((option) => option.keywords)
    ?.map((option) => option.keywords)
    ?.reduce((a, b) => a.concat(b), [])
    ?.map((keyword) => allKeywords?.find(({ value: id }) => id === keyword.value))
    ?.filter((keyword) => keyword);

  return Boolean(currentKeywords?.find((keyword) => keyword?.isOld));
};

export { isOldValue, includesOldImages, getOptionLabelByValue, getOptionValueByLabel, isOldKeywordValues };
