import React, { memo, MouseEventHandler, useCallback } from 'react';
import { useFormContext } from 'hooks';
import { Icon } from 'components/common/icon/icon';
import { InfoIconProps } from './types';

export const IconControl = memo<InfoIconProps>(({ cursor, color, onClick: onClickFromProps, ...props }) => {
  const { disabled } = useFormContext();

  const onClick = useCallback<MouseEventHandler>(
    (e) => {
      if (disabled) {
        e.stopPropagation();
        return;
      }
      if (onClickFromProps) {
        onClickFromProps(e);
      }
    },
    [disabled, onClickFromProps],
  );

  return (
    <Icon
      color={disabled ? 'disabledBorderColor' : color}
      cursor={disabled ? 'not-allowed' : cursor}
      onClick={onClick}
      {...props}
    />
  );
});
