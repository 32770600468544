import { ContextType, createContext, useContext } from 'react';

import { useServices } from 'hooks/use-services';
import { RootStoreInstance } from './root-store';

const RootStoreContext = createContext<RootStoreInstance>({} as RootStoreInstance);

export const RootStoreProvider = (props: Record<string, any>): JSX.Element => {
  const container = useServices();

  const rootStore = container.get<RootStoreInstance>('rootStore');

  return <RootStoreContext.Provider value={rootStore} {...props} />;
};

export const useStores = (): ContextType<typeof RootStoreContext> => useContext(RootStoreContext);
