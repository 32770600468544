export const policyLink = {
  facebookFanPage:
    'https://business.facebook.com/latest/self_view?business_id=216812739296142&asset_id=487290048686058&request_product_tour=false&mio=false',
  facebookMS: 'https://www.facebook.com/Minerva-Solutions-487290048686058/',
  facebookTemps: 'https://www.facebook.com/terms.php',
  facebookPolicy: 'https://www.facebook.com/policy.php',
  facebookExplanation: 'https://www.facebook.com/privacy/explanation',
  privacy: 'https://policies.google.com/privacy',
  privacyFull: 'https://www.paypal.com/webapps/mpp/ua/privacy-full',
  networkAdvertising: 'http://www.networkadvertising.org/choices/',
  yourOnlineChoices: 'http://www.youronlinechoices.com/',
  optOut: 'http://optout.aboutads.info/?c=2&lang=EN',
};
