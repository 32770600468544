import { types } from 'mobx-state-tree';
import { apiFlow } from 'stores/mst-types';
import Base from 'stores/models/base';
import { CampaignInstanceType, CampaignFormValues } from 'stores/models/campaigns/campaign';
import { CampaignManagerStore } from 'stores/campaigns/campaign-manager-store';
import { toDateRangeFromValue } from 'utils/forms';
import { locations } from 'navigation';
import { History } from 'history';
import config from 'config';

const { campaignSocialNetworksAvailability } = config.options;

const { types: campaignTypes } = config.api.constants.campaigns;

export const OpenHouseCampaignStore = Base.named('OpenHouseCampaignStore')
  .props({
    campaignManager: types.optional(CampaignManagerStore, {}),
  })
  .views((self) => ({
    get formInitializationValues() {
      const { campaignValues, campaign } = self.campaignManager;

      return {
        targetKind: campaignTypes.building.id,
        ...campaignValues,
        openHouseRunDates: campaign?.openHouseRunDates && toDateRangeFromValue(campaign?.openHouseRunDates),
        ...self.campaignManager.targetInitializationValues,
      };
    },
  }))
  .actions((self) => ({
    newGoLive: apiFlow(function* newGoLive(data: Partial<CampaignFormValues>) {
      yield self.campaignManager.newGoLive({
        data,
        onSuccess: ({ id, showWarn }) => {
          const history = self.services.get<History>('history');
          history.push(
            locations.campaigns.openHouseCampaign.toUrl({
              id,
              showWarn,
            }),
          );
        },
      });
    }),

    initCampaign: apiFlow(function* initCampaign({ id }: Partial<Pick<CampaignInstanceType, 'id'>>) {
      yield self.campaignManager.init({
        id,
        socialNetworksAvailability: campaignSocialNetworksAvailability.openHouse,
        campaignKindId: campaignTypes.openHouse.id,
        notFoundUpdateFallbackUrl: locations.campaigns.newOpenHouseCampaign.toUrl(),
      });

      const targetId = self.campaignManager.campaign?.targetId;
      const targetKindId = self.campaignManager.campaign?.targetKind?.id;

      if (targetId && targetKindId) {
        yield self.campaignManager.loadTarget({
          id: targetId,
          targetKindId,
        });
      }
    }),
  }))
  .actions((self) => ({
    init: apiFlow(function* init({ id }: Pick<CampaignInstanceType, 'id'>) {
      yield Promise.all([
        self.initCampaign({ id }),
        self.campaignManager.loadBuildingOptions(),
        self.campaignManager.loadListingOptions(),
      ]);

      self.campaignManager.setInitialization(false);
    }),

    destroy: () => {
      self.campaignManager.destroy();
    },
  }));
