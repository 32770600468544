import { centerCrop, makeAspectCrop } from 'react-image-crop';
import { CropUnitProps } from 'components/common/crop';
import { ImageUploaderValueItem } from 'components/common/form';
import { base64ToFile } from 'utils/files';
import { constants } from './constants';

const getCroppedImage = (image: HTMLImageElement, crop: CropUnitProps, scale = 1): string => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const canvasFinal = document.createElement('canvas');
  const cftx = canvasFinal.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(Math.trunc(crop.width) * scaleX * pixelRatio);
  canvas.height = Math.floor(Math.trunc(crop.height) * scaleY * pixelRatio);

  canvasFinal.width = Math.trunc(crop.width);
  canvasFinal.height = Math.trunc(crop.height);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);

  ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

  cftx?.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, crop.width, crop.height);

  ctx.restore();

  return canvasFinal.toDataURL();
};

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number): CropUnitProps =>
  centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        height: (mediaHeight / 100) * 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );

const getImageName = (originalValue: File | ImageUploaderValueItem): string => {
  if (originalValue instanceof File) {
    return originalValue.name.split(/\.(?=[^.]+$)/)[0];
  }
  if (originalValue?.src) {
    return originalValue?.src.split('/').pop()?.split('.')[0] || '';
  }
  return '';
};

const imageToFile = ({
  imageEl,
  crop,
  scale,
  name,
}: {
  imageEl?: Nullable<HTMLImageElement>;
  crop?: CropUnitProps;
  scale: number;
  name: string;
}): Nullable<File> => {
  if (!imageEl || !crop) {
    return null;
  }

  const croppedImage: string = getCroppedImage(imageEl, crop, scale / 100);

  return base64ToFile(croppedImage, name);
};

const getAspect = (variant: string, name?: string): number => {
  if (variant === constants.variants.square) {
    return 1;
  }
  if (variant === constants.variants.rectangle && name === constants.logo) {
    return 4;
  }
  if (variant === constants.variants.rectangle) {
    return 1.91;
  }
  return 0;
};

export { getAspect, getCroppedImage, centerAspectCrop, getImageName, imageToFile };
