import { ImageFormValue } from '../types';

const getImagePreview = (value: Nullable<ImageFormValue>): Nullable<{ src: string }> =>
  value instanceof File
    ? {
        src: window.URL.createObjectURL(value),
      }
    : value;

export { getImagePreview };
