import { types } from 'mobx-state-tree';
import { apiFlow } from 'stores/mst-types';
import Base from 'stores/models/base';
import Buildings from 'stores/models/buildings/buildings';

export const BuildingsStore = Base.named('BuildingsStore')
  .props({
    buildings: types.optional(Buildings, {}),
  })
  .views((self) => ({
    get buildingsList() {
      return self.buildings.buildingsList;
    },

    get count() {
      return self.buildings.count;
    },

    get loader() {
      return self.buildings.loader;
    },
  }))
  .actions((self) => ({
    init: apiFlow(function* init() {
      yield self.buildings.fetchBuildingPropertyTypeOptions();
    }),

    fetch: self.buildings.fetch,

    destroy: () => {
      self.buildings.destroy();
    },
  }));
