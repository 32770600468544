import queryString from 'qs';

const parse = (str: string, options?: queryString.IParseOptions): ReturnType<typeof queryString.parse> =>
  queryString.parse(str, { ignoreQueryPrefix: true, ...options });

const stringify = (
  obj: Record<string, unknown>,
  options?: queryString.IStringifyOptions,
): ReturnType<typeof queryString.stringify> => queryString.stringify(obj, options);

const combineSearchParams = (currentParams: string, incomingParams: string): ReturnType<typeof stringify> =>
  stringify({
    ...parse(currentParams),
    ...parse(incomingParams),
  });

export { parse, stringify, combineSearchParams };
