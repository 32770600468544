import React, { memo, useMemo, useEffect, useRef } from 'react';
import { Alert } from 'components/common/alert';
import { Box } from 'components/common/box';
import { useFormContext, useTranslation } from 'hooks';
import { FormErrorProps } from './types';

export const FormError = memo<FormErrorProps>(({ messages = [], ...props }) => {
  const { t } = useTranslation();

  const { formServerErrors } = useFormContext();

  const alertRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (formServerErrors && alertRef.current) {
      alertRef.current.scrollIntoView();
    }
  }, [formServerErrors]);

  const errorMessages = useMemo(() => {
    if (!formServerErrors) {
      return null;
    }

    const { generalErrors, fieldErrors, isSomethingWentWrong } = formServerErrors;

    const fieldErrorMessages = fieldErrors.map(({ errors }: any) => errors.map((error: string) => error)).flat();

    const errors = isSomethingWentWrong ? [t('Common.SomethingWentWrong')] : [...generalErrors, ...fieldErrorMessages];

    return errors.map((error, i) => <Box key={i}>{error}</Box>);
  }, [t, formServerErrors]);

  const errors = [errorMessages, ...messages].filter(Boolean);

  if (!errors.length) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' gridRowGap={20} {...props}>
      {errors.map((error, index) => (
        <Alert key={index} {...(index === 0 ? { ref: alertRef } : {})} type='error' message={error} />
      ))}
    </Box>
  );
});
