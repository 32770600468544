import { cast, getRoot, Instance, SnapshotOut, types } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';
import Option from 'stores/models/option';
import OptionDetailed from 'stores/models/option-detailed';
import FacebookCustomAudienceOption from 'stores/models/facebook-custom-audience-option';
import AdSocialNetworksSettings from 'stores/models/ads/ad-social-networks-settings';
import { apiFlow } from 'stores/mst-types';

const CampaignSocialNetworks = BaseModel.named('CampaignSocialNetworks')
  .props({
    settings: types.optional(AdSocialNetworksSettings, {}),
    rawFacebookPageOptions: types.optional(types.array(OptionDetailed), []),
    rawInstagramAccountOptions: types.optional(types.array(OptionDetailed), []),
    rawFacebookPixelOptions: types.optional(types.array(Option), []),
    rawFacebookCustomAudienceOptions: types.optional(types.array(FacebookCustomAudienceOption), []),
    rawGoogleAccountOptions: types.optional(types.array(Option), []),
    isGoogleAccountAvailable: types.optional(types.boolean, true),
  })
  .views((self) => ({
    get facebookPageOptions() {
      return [...self.rawFacebookPageOptions];
    },

    get instagramAccountOptions() {
      return [...self.rawInstagramAccountOptions];
    },

    get facebookPixelOptions() {
      return [...self.rawFacebookPixelOptions];
    },

    get facebookCustomAudienceOptions() {
      return [...self.rawFacebookCustomAudienceOptions];
    },

    get googleAccountOptions() {
      return [...self.rawGoogleAccountOptions];
    },

    get isFacebookConnected() {
      const { session } = getRoot<any>(self);
      const { user, isSelectedUser, selectedUser } = session;

      if (isSelectedUser) {
        return selectedUser.facebook.isConnected;
      }
      return user.facebook.isConnected;
    },

    get isGoogleConnected() {
      const { session } = getRoot<any>(self);
      const { user, isSelectedUser, selectedUser } = session;

      if (isSelectedUser) {
        return selectedUser.google.isConnected;
      }
      return user.google.isConnected;
    },
  }))
  .actions((self) => ({
    loadFacebookPageOptions: apiFlow(function* loadFacebookPageOptions() {
      if (!self.isFacebookConnected) {
        return;
      }

      const { getFacebookPageOptions } = getRoot<any>(self).common;

      self.rawFacebookPageOptions = yield getFacebookPageOptions();
    }),

    loadInstagramAccountOptions: apiFlow(function* loadInstagramAccountOptions() {
      if (!self.isFacebookConnected) {
        return;
      }

      const { getInstagramAccountOptions } = getRoot<any>(self).common;

      self.rawInstagramAccountOptions = yield getInstagramAccountOptions();
    }),

    loadFacebookPixelOptions: apiFlow(function* loadFacebookPixelOptions() {
      if (!self.isFacebookConnected) {
        return;
      }

      const { getFacebookPixelOptions } = getRoot<any>(self).common;

      self.rawFacebookPixelOptions = yield getFacebookPixelOptions();
    }),

    loadFacebookCustomAudienceOptions: apiFlow(function* loadFacebookCustomAudienceOptions() {
      if (!self.isFacebookConnected) {
        return;
      }

      const { getFacebookCustomAudienceOptions } = getRoot<any>(self).common;

      self.rawFacebookCustomAudienceOptions = yield getFacebookCustomAudienceOptions();
    }),

    loadGoogleAccountOptions: apiFlow(function* loadGoogleAccountOptions() {
      if (!self.isGoogleConnected) {
        return;
      }

      try {
        const { getGoogleAccountOptions } = getRoot<any>(self).common;

        self.rawGoogleAccountOptions = yield getGoogleAccountOptions();

        self.isGoogleAccountAvailable = true;
      } catch (e) {
        self.isGoogleAccountAvailable = false;
      }
    }),
  }))
  .actions((self) => ({
    load: apiFlow(function* load() {
      const { isGoogleConnected } = self;

      yield Promise.all([
        self.settings.fetch(),
        self.loadFacebookPageOptions(),
        self.loadInstagramAccountOptions(),
        self.loadFacebookPixelOptions(),
        self.loadFacebookCustomAudienceOptions(),
        isGoogleConnected ? self.loadGoogleAccountOptions() : null,
      ]);
    }),

    destroy: () => {
      self.settings.destroy();
      self.rawFacebookPageOptions = cast([]);
      self.rawInstagramAccountOptions = cast([]);
      self.rawFacebookPixelOptions = cast([]);
      self.rawFacebookCustomAudienceOptions = cast([]);
      self.rawGoogleAccountOptions = cast([]);
    },
  }));

export type CampaignSocialNetworksInstanceType = Instance<typeof CampaignSocialNetworks>;
export type CampaignSocialNetworksSnapshot = SnapshotOut<typeof CampaignSocialNetworks>;

export default CampaignSocialNetworks;
