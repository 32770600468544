import { Instance, types, SnapshotOut } from 'mobx-state-tree';
import { BarChartItemApi } from 'types';
import Base from 'stores/models/base';
import { sortAlphabetically } from 'utils/sorting';
import { MSTAddStaticMethods } from 'stores/utils';
import BarItem, { BarItemSnapshot } from './bar-item';

const staticMethods = {
  fromResponseData: (data: BarChartItemApi[]): BarItemsSnapshot => {
    const items = data.reduce(
      (result, { label, items }) => [
        ...result,
        ...items.map(({ type, value }) => ({
          label,
          type,
          value: Number(value),
        })),
      ],
      [] as BarItemSnapshot[],
    );

    const entries = sortAlphabetically({
      items,
      field: 'label',
      comparer: (rawValueA, rawValueB) => {
        const [valueA, valueB] = [rawValueA, rawValueB].map((val) => String(val).toLowerCase());

        if (valueA > valueB) {
          return 1;
        }

        if (valueA < valueB) {
          return -1;
        }

        return 0;
      },
    });
    return { entries };
  },
};

const BarItems = Base.named('BarItems').props({
  entries: types.optional(types.array(BarItem), []),
});

export type BarItemsInstanceType = Instance<typeof BarItems>;
export type BarItemsSnapshot = SnapshotOut<typeof BarItems>;

export default MSTAddStaticMethods(BarItems, staticMethods);
