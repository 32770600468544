import { memo, useMemo, useRef, useCallback } from 'react';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { Box } from 'components/common/box';
import { AspectRatio } from 'components/common/aspect-ratio';
import { Image } from 'components/common/image';
import { Icon } from 'components/common/icon';
import themeColors from 'utils/theme-colors';
import { GalleryProps } from './types';

const iconHolderProps = {
  position: 'absolute',
  top: '50%',
  marginX: 25,
  transform: 'translateY(-50%)',
  zIndex: 10,
};

const iconProps = {
  cursor: 'pointer',
  size: 23,
  color: themeColors.white1,
};

const defaultItems = [] as NonNullable<GalleryProps['items']>;

export const Gallery = memo<GalleryProps>(({ items = defaultItems, wrapperProps, itemProps, ...props }) => {
  const carouselRef = useRef<CarouselRef | null>(null);

  const next = useCallback(() => {
    carouselRef?.current?.next();
  }, []);

  const previous = useCallback(() => {
    carouselRef?.current?.prev();
  }, []);

  const images = useMemo(
    () =>
      items?.map(({ src }, index) => (
        <AspectRatio key={index} borderRadius={0} overflow='hidden' {...itemProps}>
          <Image src={src} height='100%' />
        </AspectRatio>
      )),
    [items, itemProps],
  );

  const hasControls = images.length > 1;

  return images.length ? (
    <Box position='relative' {...wrapperProps}>
      {hasControls ? (
        <Box left={0} {...iconHolderProps}>
          <Icon name='arrow_left' onClick={previous} {...iconProps} />
        </Box>
      ) : null}
      {hasControls ? (
        <Box right={0} {...iconHolderProps}>
          <Icon name='arrow_right' onClick={next} {...iconProps} />
        </Box>
      ) : null}
      <Carousel ref={carouselRef} dots={false} {...props}>
        {images}
      </Carousel>
    </Box>
  ) : null;
});
