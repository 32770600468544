import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

export class LogMiddleware {
  static async onRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    console.log('REQUEST', config);

    if (config.data instanceof FormData) {
      const formDataEntries: any = {};
      config.data.forEach((value, key) => {
        formDataEntries[key] = value;
      });
      console.log('FORM DATA', formDataEntries);
    }

    if (typeof config.data === 'string') {
      try {
        const data = JSON.parse(config.data);
        console.log('JSON DATA', data);
      } catch (error) {
        console.log('ERROR', error);
      }
    }

    return config;
  }

  static async onResponse(response: AxiosResponse): Promise<AxiosResponse> {
    console.log('RESPONSE', response.request.fromCache ? '[FROM CACHE]' : '', response);
    return response;
  }

  static async onResponseError(error: AxiosError): Promise<never> {
    console.log('RESPONSE ERROR', error.response);
    throw error;
  }
}
