import { useForm } from 'hooks';
import get from 'lodash.get';
import { OptionWithOldMarker } from 'types';
import { AD_TITLE_FIELD, AD_DESCRIPTION_FIELD } from '../constants';

export const useSelectedTexts = ({
  textPickerFieldName,
  titleOptions,
  descriptionOptions,
}: {
  textPickerFieldName: string;
  titleOptions: OptionWithOldMarker[];
  descriptionOptions: OptionWithOldMarker[];
}): {
  title?: string;
  description?: string;
} => {
  const { values } = useForm();

  const texts = values ? get(values, textPickerFieldName) : null;
  return {
    title: titleOptions && titleOptions.length ? texts && get(texts, AD_TITLE_FIELD)[0]?.label : undefined,
    description:
      descriptionOptions && descriptionOptions.length ? texts && get(texts, AD_DESCRIPTION_FIELD)[0]?.label : undefined,
  };
};
