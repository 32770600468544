import { Instance, SnapshotOut } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { ListingOverviewApi } from 'types';
import BaseModel from 'stores/models/base';
import Listing from 'stores/models/listings/listing';
import { MSTAddStaticMethods } from 'stores/utils';

const staticMethods = {
  fromResponseData: (data: ListingOverviewApi): ListingOverviewSnapshot => ({
    ...pick(data, ['name', 'address', 'neighborhood', 'url']),
    id: String(data.id),
    propertyType: String(data.property_type),
    initializing_status: String(data.initializing_status),
  }),
};

const propsFromListing = pick(Listing.properties, [
  'id',
  'name',
  'address',
  'neighborhood',
  'propertyType',
  'url',
  'initializing_status',
]);

const ListingOverview = BaseModel.named('ListingOverview').props({
  ...propsFromListing,
});

export type ListingOverviewInstanceType = Instance<typeof ListingOverview>;
export type ListingOverviewSnapshot = SnapshotOut<typeof ListingOverview>;

export default MSTAddStaticMethods(ListingOverview, staticMethods);
