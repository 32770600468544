import { Instance, cast, types, SnapshotOut } from 'mobx-state-tree';
import { CampaignReportsApi } from 'types';
import BaseModel from 'stores/models/base';
import BarItems from 'stores/models/stats/bar-items';
import PieItems from 'stores/models/stats/pie-items';
import FunnelItem from 'stores/models/stats/funnel-item';
import LineItems from 'stores/models/stats/line-items';
import { MSTAddStaticMethods } from 'stores/utils';
import { nullable } from 'stores/mst-types';
import { dollarsToCents } from 'utils/converters';

const staticMethods = {
  fromResponseData: (data: CampaignReportsApi): CampaignReportsSnapshot => {
    if (!data) {
      return {} as any;
    }

    const { summary, leads_and_applications } = data;

    const safeLeadsAndApplications = leads_and_applications
      ? {
          costPerLead: leads_and_applications.cost_per_lead ?? null,
          costPerApplication: leads_and_applications.cost_per_application ?? null,
          costPerTour: leads_and_applications.cost_per_tour ?? null,
          tours: leads_and_applications.tours ?? null,
          leads: leads_and_applications.leads ?? null,
          applications: leads_and_applications.applications ?? null,
        }
      : null;

    return {
      leadsAndApplications: safeLeadsAndApplications,
      summary: {
        spend: dollarsToCents(Number(summary.spend)),
        leads: Number(summary.leads),
        impressions: Number(summary.impressions),
        clicks: Number(summary.clicks),
        costPerLead: dollarsToCents(Number(summary.cost_per_lead)),
        clicksThroughRates: Number(summary.clicks_through_rates),
        costPerUniqueClick: dollarsToCents(Number(summary.cost_per_click)),
      },
      analytics: LineItems.fromResponseData(data.analytics),
      ageBreakdown: BarItems.fromResponseData(data.age),
      regionBreakdown: BarItems.fromResponseData(data.region),
      totalImpressions: PieItems.fromResponseData(data.total_impressions),
      totalClicks: PieItems.fromResponseData(data.total_clicks),
    };
  },
};

const Summary = types.model({
  spend: types.number,
  costPerLead: types.number,
  leads: types.number,
  impressions: types.number,
  clicks: types.number,
  clicksThroughRates: types.number,
  costPerUniqueClick: types.number,
});

const LeadsAndApplicationsItem = types.model({
  value: types.maybeNull(types.number),
  label: types.maybeNull(types.string),
});

const LeadsAndApplications = types.model({
  costPerLead: types.maybeNull(LeadsAndApplicationsItem),
  costPerApplication: types.maybeNull(LeadsAndApplicationsItem),
  costPerTour: types.maybeNull(LeadsAndApplicationsItem),
  tours: types.maybeNull(LeadsAndApplicationsItem),
  leads: types.maybeNull(LeadsAndApplicationsItem),
  applications: types.maybeNull(LeadsAndApplicationsItem),
});

const CampaignReports = BaseModel.named('CampaignReports')
  .props({
    leadsAndApplications: types.maybeNull(LeadsAndApplications),
    summary: nullable(Summary),
    analytics: types.optional(LineItems, {}),
    ageBreakdown: types.optional(BarItems, {}),
    regionBreakdown: types.optional(BarItems, {}),
    totalImpressions: types.optional(PieItems, {}),
    totalClicks: types.optional(PieItems, {}),
  })
  .actions((self) => ({
    destroy() {
      self.ageBreakdown = cast({});
      self.regionBreakdown = cast({});
      self.totalImpressions = cast({});
      self.totalClicks = cast({});
    },
  }));

export type CampaignReportsInstanceType = Instance<typeof CampaignReports>;
export type CampaignReportsSnapshot = SnapshotOut<typeof CampaignReports>;

export default MSTAddStaticMethods(CampaignReports, staticMethods);
