import { types } from 'mobx-state-tree';
import { apiFlow } from 'stores/mst-types';
import Base from 'stores/models/base';
import { CampaignInstanceType, CampaignFormValues } from 'stores/models/campaigns/campaign';
import { CampaignManagerStore } from 'stores/campaigns/campaign-manager-store';
import { locations } from 'navigation';
import { History } from 'history';
import config from 'config';

const { types: campaignTypes } = config.api.constants.campaigns;
const { campaignSocialNetworksAvailability } = config.options;

export const ListingCampaignStore = Base.named('ListingCampaignStore')
  .props({
    campaignManager: types.optional(CampaignManagerStore, {}),
  })
  .views((self) => ({
    get formInitializationValues() {
      const { campaignValues } = self.campaignManager;

      return {
        ...campaignValues,
        ...this.targetInitializationValues,
      };
    },

    get targetLink() {
      const targetId = self.campaignManager.target?.id;

      return targetId
        ? locations.listings.listing.toUrl({
            id: targetId,
          })
        : null;
    },

    get targetInitializationValues() {
      return self.campaignManager.targetInitializationValues;
    },
  }))
  .actions((self) => ({
    newGoLive: apiFlow(function* newGoLive(data: Partial<CampaignFormValues>) {
      yield self.campaignManager.newGoLive({
        data,
        onSuccess: ({ id, showWarn }) => {
          const history = self.services.get<History>('history');
          history.push(
            locations.campaigns.listingCampaign.toUrl({
              id,
              showWarn,
            }),
          );
        },
      });
    }),

    initCampaign: apiFlow(function* initCampaign({ id }: Partial<Pick<CampaignInstanceType, 'id'>>) {
      yield self.campaignManager.init({
        id,
        socialNetworksAvailability: campaignSocialNetworksAvailability.listing,
        campaignKindId: campaignTypes.listing.id,
        notFoundUpdateFallbackUrl: locations.campaigns.newListingCampaign.toUrl(),
      });

      const targetId = self.campaignManager.campaign?.targetId;

      if (targetId) {
        yield self.campaignManager.loadTarget({ id: targetId });
      }
    }),
  }))
  .actions((self) => ({
    init: apiFlow(function* init({ id }: Pick<CampaignInstanceType, 'id'>) {
      yield Promise.all([self.initCampaign({ id }), self.campaignManager.loadListingOptions()]);

      self.campaignManager.setInitialization(false);
    }),

    destroy: () => {
      self.campaignManager.destroy();
    },
  }));
