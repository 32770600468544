import get from 'lodash.get';

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

const sortAlphabetically = <T>({
  items,
  field,
  comparer = collator.compare,
}: {
  items: T[];
  field: keyof T | (keyof T)[];
  comparer?: (valueA: any, valueB: any) => number;
}): T[] => {
  const sorter = (itemA: T, itemB: T) => {
    const [rawValueA, rawValueB] = [itemA, itemB].map((item) => get(item, field));
    const [valueA, valueB] = [rawValueA, rawValueB].map((rawValue) =>
      rawValue instanceof Date ? rawValue.toJSON() : String(rawValue),
    );

    return comparer(valueA, valueB);
  };

  return [...items].sort(sorter);
};

export { sortAlphabetically };
