import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';
import { RouteProps } from './types';
import { Route } from './route';

export const UnauthenticatedRoute = observer<RouteProps>((props) => {
  const { session } = useStores();
  const { isSignedIn } = session;

  return !isSignedIn ? <Route {...props} /> : null;
});
