import { types, cast, toGenerator } from 'mobx-state-tree';
import Base from 'stores/models/base';
import { apiFlow } from 'stores/mst-types';
import { fromListResponse } from 'services/api';
import { CampaignsFilter } from 'types';
import CampaignOverview from './campaign-overview';

const Campaigns = Base.named('Campaigns')
  .props({
    entries: types.optional(types.array(CampaignOverview), []),
    count: types.optional(types.number, 0),
  })
  .actions((self) => ({
    fetch: apiFlow(function* fetch(options?: CampaignsFilter) {
      const response = yield* toGenerator(self.api.getCampaigns(options));
      const { data, count } = fromListResponse({
        response: response.data,
      });

      self.entries = cast(data.map(CampaignOverview.fromResponseData));
      self.count = count;
    }),
    destroy() {
      self.entries = cast([]);
      self.count = 0;
    },
  }));

export default Campaigns;
