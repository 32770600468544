import React, { memo } from 'react';
import { Box } from 'components/common/box';
import { ErrorMessage as FormikErrorMessage } from 'formik';
import { ErrorMessageProps } from './types';

export const ErrorMessage = memo<ErrorMessageProps>(({ name, ...props }) => (
  <Box {...props}>
    <FormikErrorMessage name={name} />
  </Box>
));
