import {
  ChangePasswordApi,
  FacebookAdAccountApi,
  Filtration,
  GoogleManagerAccountApi,
  PatchSelectedUserApi,
  PatchUserProfileApi,
  SupportedUserOptionApi,
  UserApi,
} from 'types';
import { serializeFiltrationParams } from 'utils/serializers';
import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import {
  GetUserProfileResponse,
  GetUserResponse,
  GetInvitedUserResponse,
  PatchUserResponse,
  GetBrandingUserAdTitleSuggestionOptionsResponse,
  GetBrandingUserAdDescriptionSuggestionOptionsResponse,
  GetFacebookPageOptionsResponse,
  GetGoogleConnectResponse,
  GetInstagramAccountOptionsResponse,
  GetGoogleAccountOptionsResponse,
  GetFacebookPixelOptionsResponse,
  GetFacebookCustomAudienceOptionsResponse,
  GetFacebookConnectResponse,
  GetFacebookAdAccountResponse,
  GetOrganizationsResponse,
  GetUsersByOrganizationResponse,
  GetGoogleManagerAccountResponse,
} from '../response-types';
import { createFormData } from './helpers';

const {
  users: {
    getProfile,
    getUser,
    getInvitedUser,
    patchUser,
    changePassword,
    getAgentAdTitleSuggestionOptions,
    getAgentAdDescriptionSuggestionOptions,
    getFacebookAdAccounts,
    postFacebookAdAccount,
    getFacebookConnect,
    getFacebookDisconnect,
    getFacebookPageOptions,
    getFacebookPixelOptions,
    getFacebookCustomAudienceOptions,
    getGoogleConnect,
    getGoogleDisconnect,
    getInstagramAccountOptions,
    getGoogleAccountOptions,
    getOrganizations,
    getUsersByOrganization,
    getSelectedUser,
    getGoogleManagerAccounts,
    postGoogleManagerAccount,
  },
} = urls;

export const createUsersApi = (http: BaseHttpClient) => ({
  getUser() {
    return http.get<GetUserResponse>(getUser.url);
  },

  getInvitedUser({ uid, token }: { uid: string; token: string }) {
    return http.post<GetInvitedUserResponse>(getInvitedUser.url, {
      uid,
      token,
    });
  },

  getOrganizations() {
    return http.get<GetOrganizationsResponse>(getOrganizations.url);
  },

  getUsersByOrganization(id: SupportedUserOptionApi['id']) {
    return http.get<GetUsersByOrganizationResponse>(getUsersByOrganization.url, {
      organization__id: id,
    });
  },

  patchSelectedUser({ id, data }: { id: UserApi['id']; data: Partial<PatchSelectedUserApi> }) {
    return http.patch<PatchUserResponse>(patchUser.url.replace('{id}', id), createFormData(data));
  },

  patchUser({ id, data }: { id: UserApi['id']; data: Partial<PatchUserProfileApi> }) {
    return http.patch<PatchUserResponse>(patchUser.url.replace('{id}', id), createFormData(data));
  },

  getSelectedUser({ id }: { id: UserApi['id'] }) {
    return http.get<GetUserResponse>(getSelectedUser.url.replace('{id}', id));
  },

  changePassword(data: ChangePasswordApi) {
    return http.post(changePassword.url, data);
  },

  getUserProfile({ id }: Pick<UserApi, 'id'>) {
    return http.get<GetUserProfileResponse>(getProfile.url.replace('{id}', String(id)));
  },

  getBrandingUserAdTitleSuggestionOptions(options?: Filtration) {
    return http.get<GetBrandingUserAdTitleSuggestionOptionsResponse>(
      getAgentAdTitleSuggestionOptions.url,
      serializeFiltrationParams(options),
    );
  },

  getBrandingUserAdDescriptionSuggestionOptions(options?: Filtration) {
    return http.get<GetBrandingUserAdDescriptionSuggestionOptionsResponse>(
      getAgentAdDescriptionSuggestionOptions.url,
      serializeFiltrationParams(options),
    );
  },

  connectFacebook() {
    return http.get<GetFacebookConnectResponse>(getFacebookConnect.url);
  },

  getFacebookAdAccounts(options?: Filtration) {
    return http.get<GetFacebookAdAccountResponse>(getFacebookAdAccounts.url, serializeFiltrationParams(options));
  },

  facebookAdAccount(data: FacebookAdAccountApi) {
    return http.post(postFacebookAdAccount.url, data);
  },

  getFacebookDisconnect() {
    return http.get(getFacebookDisconnect.url);
  },

  getFacebookPageOptions() {
    return http.get<GetFacebookPageOptionsResponse>(getFacebookPageOptions.url);
  },

  connectGoogle() {
    return http.get<GetGoogleConnectResponse>(getGoogleConnect.url);
  },

  getGoogleManagerAccounts(options?: Filtration) {
    return http.get<GetGoogleManagerAccountResponse>(getGoogleManagerAccounts.url, serializeFiltrationParams(options));
  },

  googleManagerAccount(data: GoogleManagerAccountApi) {
    return http.post(postGoogleManagerAccount.url, data);
  },

  disconnectGoogle() {
    return http.get(getGoogleDisconnect.url);
  },

  getInstagramAccountOptions() {
    return http.get<GetInstagramAccountOptionsResponse>(getInstagramAccountOptions.url);
  },

  getFacebookPixelOptions() {
    return http.get<GetFacebookPixelOptionsResponse>(getFacebookPixelOptions.url);
  },

  getFacebookCustomAudienceOptions() {
    return http.get<GetFacebookCustomAudienceOptionsResponse>(getFacebookCustomAudienceOptions.url);
  },

  getGoogleAccountOptions() {
    return http.get<GetGoogleAccountOptionsResponse>(getGoogleAccountOptions.url);
  },
});
