import React from 'react';
import { CheckboxBase } from 'components/common/form/checkbox-base';
import { useField } from 'hooks';
import { withFormItem } from 'hocs/with-form-item';
import { CheckboxProps } from './types';

export const Checkbox = withFormItem<CheckboxProps>()((props) => {
  const { field, componentProps } = useField(props);

  return <CheckboxBase {...field} {...componentProps} />;
});
