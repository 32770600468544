import { getRoot, toGenerator, types, cast, getEnv } from 'mobx-state-tree';
import pick from 'lodash.pick';
import Base from 'stores/models/base';
import Option from 'stores/models/option';
import OrganizationUsers from 'stores/models/organizations/organization-users';
import { OrganizationUserOverviewInstanceType } from 'stores/models/organizations/organization-user-overview';
import OrganizationBuildings from 'stores/models/organizations/organization-buildings';
import { apiFlow, nullable } from 'stores/mst-types';
import Organization, { PatchOrganizationFormValues } from 'stores/models/organizations/organization';
import { isEmpty } from 'utils/is';
import { PatchOrganizationApi, PostOrganizationUserApi } from 'types';
import config from 'config';
import { fromItemResponse } from 'services/api';
import { getOptionValueByLabel } from 'utils/options';
import { History } from 'history';
import locations from 'navigation/locations';
import { warningMessage } from 'components/common/message';

const { inviteStatuses } = config.api.constants.organizations;

export interface CreateOrganizationUserFormValues {
  name: string;
  lastName: string;
  email: string;
}

const staticMethods = {
  toPostRequest: ({ name, lastName, email }: CreateOrganizationUserFormValues): PostOrganizationUserApi => ({
    first_name: name,
    last_name: lastName,
    email,
  }),
};

const UPDATING_LOGO_STATUS_NAME = 'updating-logo';

export const OrganizationDetailsStore = Base.named('OrganizationDetailsStore')
  .props({
    users: types.optional(OrganizationUsers, {}),
    organization: nullable(Organization),
    typesOfPropertiesOptions: types.optional(types.array(Option), []),
    organizationTypeOptions: types.optional(types.array(Option), []),
    buildings: types.optional(OrganizationBuildings, {}),
    organizationId: types.optional(types.string, ''),
  })
  .views((self) => ({
    get logoInitialValues() {
      const logo = self.organization?.logo;

      return {
        logo: logo && { ...logo },
      };
    },

    get infoInitialValues() {
      return self.organization
        ? pick(self.organization, [
            'name',
            'website',
            'state',
            'city',
            'description',
            ...(this.isMultifamilyOrganization ? ['typesOfProperties'] : []),
            'contactPhone',
          ])
        : {};
    },

    get organizationPhotosInitialValues() {
      return {
        photos: self.organization?.photos,
      };
    },

    get usersList() {
      return [...self.users.entries].map((user) => ({
        ...user,
        isApprovedAccount: inviteStatuses.accepted.id === user.inviteStatus,
        isSessionUser: user.id === this.sessionUser?.id,
      }));
    },

    get sessionUser() {
      return getRoot<any>(self).session.user;
    },

    get count() {
      return self.users.count;
    },

    get typesOfPropertiesList() {
      return [...self.typesOfPropertiesOptions];
    },

    get typesOfPropertiesPreviewList() {
      return self.organization?.typesOfProperties?.map((value) => {
        const option = this.typesOfPropertiesList.find(({ value: optionValue }) => optionValue === value);

        return option ? { label: option.label } : { label: value };
      });
    },

    get organizationTypeLabel() {
      const { organization } = self;

      if (!organization) {
        return null;
      }

      return self.organizationTypeOptions.find(({ value }) => String(value) === String(organization.type))?.label;
    },

    get isMultifamilyOrganization() {
      if (!self.organization?.type) {
        return false;
      }

      const multifamilyOrganizationKindId = getOptionValueByLabel({
        options: self.organizationTypeOptions,
        label: config.api.constants.organizations.kindOptionLabels.multifamily,
      });

      return self.organization?.type === multifamilyOrganizationKindId;
    },

    get logoPending() {
      return self.getIsPendingUpdateByResourceName(UPDATING_LOGO_STATUS_NAME);
    },

    get buildingsList() {
      return [...self.buildings.entries];
    },
  }))
  .actions((self) => ({
    loadOrganization: apiFlow(function* loadOrganization() {
      const sessionUser = getRoot<any>(self)?.session?.user;

      const organizationId = sessionUser?.organizationId;
      if (!organizationId) {
        return;
      }

      const response = yield* toGenerator(self.api.getOrganization({ id: organizationId }));

      const { data } = fromItemResponse({
        response: response.data,
      });

      self.organization = Organization.fromResponseData(data);
    }),

    loadTypesOfPropertiesOptions: apiFlow(function* loadTypesOfPropertiesOptions() {
      const { getTypesOfPropertiesOptions } = getRoot<any>(self).common;

      self.typesOfPropertiesOptions = yield getTypesOfPropertiesOptions();
    }),

    loadOrganizationTypeOptions: apiFlow(function* loadOrganizationTypeOptions() {
      const { getOrganizationKindOptions } = getRoot<any>(self).common;

      self.organizationTypeOptions = yield getOrganizationKindOptions();
    }),

    updateOrganization: apiFlow(
      function* updateOrganization(serializedData: Partial<PatchOrganizationApi>) {
        const id = self.organization?.id;

        if (isEmpty(id)) {
          return;
        }

        const response = yield self.api.patchOrganization({
          // @ts-ignore
          id,
          data: serializedData,
        });

        const { data } = fromItemResponse({
          response: response.data,
        });

        self.organization = Organization.fromResponseData(data);

        if (self.organization?.hasAffectedCampaigns) {
          const history = self.services.get<History>('history');
          const t = getEnv(self).get('i18n');

          history.push(locations.campaigns.list.toUrl());

          warningMessage(t('OrganizationDetailsPage.Alerts.AffectedCampaignsWarning'));
        }
      },
      { isUpdate: true },
    ),
  }))
  .actions((self) => ({
    fetchUsers: self.users.fetch,

    fetchBuildings: self.buildings.fetch,

    updateLogo: apiFlow(
      function* updateLogo(values: Pick<PatchOrganizationFormValues, 'logo'>) {
        yield self.updateOrganization(Organization.toPatchRequestLogoData(values));
      },
      {
        isUpdate: true,
        statusName: UPDATING_LOGO_STATUS_NAME,
        formName: 'organization-logo',
        successAlert: 'OrganizationDetailsPage.Alerts.LogoUpdated',
      },
    ),

    updateOrganizationInfo: apiFlow(
      function* updateOrganizationInfo(values: Omit<PatchOrganizationFormValues, 'logo' | 'photos'>) {
        yield self.updateOrganization(Organization.toPatchRequestInfoData(values, self.isMultifamilyOrganization));
      },
      {
        isUpdate: true,
        formName: 'organization-info',
        successAlert: 'OrganizationDetailsPage.Alerts.InfoUpdated',
      },
    ),

    updateOrganizationPhotos: apiFlow(
      function* updateOrganizationPhotos(values: Pick<PatchOrganizationFormValues, 'photos'>) {
        yield self.updateOrganization(Organization.toPatchRequestPhotosData(values));
      },
      {
        isUpdate: true,
        formName: 'organization-photos',
        successAlert: 'OrganizationDetailsPage.Alerts.PhotosUpdated',
      },
    ),

    createUser: apiFlow(
      function* createUser(data: CreateOrganizationUserFormValues) {
        const { user } = getRoot<any>(self).session;
        const organizationId = user?.organizationId;

        if (organizationId) {
          yield self.api.postOrganizationUser({
            organizationId,
            data: staticMethods.toPostRequest(data),
          });
        }
      },
      {
        isUpdate: true,
        formName: 'create-organization-user',
        successAlert: 'OrganizationDetailsPage.Users.Modal.Alerts.Created',
      },
    ),

    resendInvite: apiFlow(function* resendInvite({ email }: Pick<OrganizationUserOverviewInstanceType, 'email'>) {
      const { user } = getRoot<any>(self).session;
      const organizationId = user?.organizationId;

      if (organizationId) {
        yield self.api.postOrganizationUserInvite({
          organizationId,
          email,
        });
      }
    }),

    removeUser: apiFlow(function* removeUser({ id }: Pick<OrganizationUserOverviewInstanceType, 'id'>) {
      yield self.api.removeOrganizationUser({
        id,
      });
    }),

    init: apiFlow(function* init() {
      yield Promise.all([
        self.loadOrganization(),
        self.loadTypesOfPropertiesOptions(),
        self.loadOrganizationTypeOptions(),
      ]);
    }),

    destroy: () => {
      self.users.destroy();
      self.buildings.destroy();
      self.organization = null;
      self.typesOfPropertiesOptions = cast([]);
      self.organizationTypeOptions = cast([]);
    },
  }));
