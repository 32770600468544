import React, { memo, MouseEventHandler, useCallback } from 'react';
import { Box } from 'components/common/box';
import { Link } from 'components/common/link';
import { Gallery } from 'components/common/gallery';
import { useField, useFormContext, useTranslation } from 'hooks';
import { useModalPicker } from './hook';
import { ImagesPickerProps } from './types';

const defaultOptions = [] as NonNullable<ImagesPickerProps['options']>;
const defaultApprovedImageOptions = [] as NonNullable<ImagesPickerProps['approvedImageOptions']>;

export const ImagesPicker = memo<ImagesPickerProps>(
  ({
    approvedImageOptions = defaultApprovedImageOptions,
    options = defaultOptions,
    holderProps,
    opener,
    hideControl,
    galleryProps,
    max,
    ...props
  }) => {
    const { t } = useTranslation();
    const { field } = useField(props);
    const { name, value, onChange, onBlur } = field;

    const { toggleOpen, modal } = useModalPicker(name, onChange, onBlur, options, max, approvedImageOptions);

    const { disabled } = useFormContext();

    const onClick = useCallback<MouseEventHandler>(() => {
      if (disabled) {
        return;
      }
      if (toggleOpen) {
        toggleOpen();
      }
    }, [disabled, toggleOpen]);

    return options.length ? (
      <Box {...holderProps}>
        <Gallery items={value} itemProps={galleryProps} />
        {hideControl ? null : (
          <>
            <Box textAlign='center' marginTop={20}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link variant='headline-3' underline='none' onClick={onClick} disabled={disabled}>
                {opener ?? t('ImagesPicker.Opener')}
              </Link>
            </Box>
            {modal}
          </>
        )}
      </Box>
    ) : null;
  },
);
