import React, { memo } from 'react';
import classnames from 'classnames';
import { Box } from 'components/common/box';
import themeColors from 'utils/theme-colors';
import styles from './styles.module.scss';
import { SpinnerProps } from './types';

export const Spinner = memo<SpinnerProps>(
  ({ className: classNameFromProps = '', color = themeColors.primaryColor, size = 60, ...props }) => {
    const className = classnames(styles.root, classNameFromProps);

    return (
      <Box
        className={className}
        border={`4px solid ${themeColors.transparent}`}
        borderTopColor={color}
        width={size}
        height={size}
        {...props}
      />
    );
  },
);
