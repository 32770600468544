import { flatten, unflatten } from 'flat';
import deepmerge from 'deepmerge';
import { isPlainObject } from 'is-plain-object';
import lodashSet from 'lodash.set';
import { isEmpty } from './is';
import { OptionalNullable } from './types';

const excludeEmpty = <T extends Record<string, any>, K extends keyof T = keyof T>(
  values: T,
  omitKeys: K[] = [],
): OptionalNullable<T> =>
  Object.entries(values).reduce((result, [key, value]) => {
    if (value === '' || (isEmpty(value) && !omitKeys.includes(key as K))) {
      return result;
    }

    return { ...result, [key]: value };
  }, {}) as OptionalNullable<T>;

const flat = ({
  data,
  options = {},
}: {
  data: Record<string, any>;
  options?: { safe?: boolean };
}): Record<string, any> =>
  flatten(data, {
    safe: true,
    ...options,
  });

const resolveFlatKey = (key: string): string => key.replace(/\.(\d+)\.?/g, '[$1]');

const flatFormValues = (data: Record<string, any>): Record<string, any> => {
  const flatValues = flat({ data, options: { safe: false } });

  return Object.entries(flatValues).reduce(
    (result, [key, value]) => ({
      ...result,
      [resolveFlatKey(key)]: value,
    }),
    {},
  );
};

const unflat = ({ data, options = {} }: { data: Record<string, any>; options?: any }): Record<string, any> =>
  unflatten(data, options);

const findOptionById = <T extends { id: any }>({ id, settings }: { id: T['id']; settings: Record<string, T> }): T =>
  Object.values(settings).find(({ id: optionId }) => optionId === id) as T;

const getDiffFields = (
  obj1: Record<string, any>,
  obj2: Record<string, any>,
  isDifferent: (value1: any, value2: any) => boolean,
): string[] => {
  const [flatObj1, flatObj2] = [obj1, obj2].map((data) => flat({ data }));
  const [obj1Fields, obj2Fields] = [flatObj1, flatObj2].map((flatObj) => Object.keys(flatObj));
  const fields = [...obj1Fields, ...obj2Fields];

  return fields.reduce((result, field) => {
    const [obj1Value, obj2Value] = [flatObj1, flatObj2].map((flatObj) => flatObj[field]);

    return isDifferent(obj1Value, obj2Value) ? [...result, field] : result;
  }, [] as string[]);
};

const hasDifferences = (
  obj1: Record<string, any>,
  obj2: Record<string, any>,
  isDifferent: (value1: any, value2: any) => boolean,
): boolean => {
  const diffFields = getDiffFields(obj1, obj2, isDifferent);

  return Boolean(diffFields.length);
};

const mergeDeep = <T1 extends Record<string, any>, T2 extends Record<string, any>>(
  destination: Partial<T1>,
  source: Partial<T2>,
  options?: deepmerge.Options,
): T1 & T2 =>
  deepmerge(destination, source, {
    arrayMerge: (sourceArray) => sourceArray,
    isMergeableObject: (obj) => isPlainObject(obj),
    ...options,
  });

const set = <T extends Record<string, any>>(source: T, value: Record<EndPaths<T>, any>): T =>
  Object.entries(value).reduce((result, [key, value]) => lodashSet(result, key, value), source);

export { excludeEmpty, flat, flatFormValues, unflat, findOptionById, getDiffFields, hasDifferences, mergeDeep, set };
