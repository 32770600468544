import { Instance, SnapshotOut } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { BuildingOverviewAPI } from 'types';
import BaseModel from 'stores/models/base';
import Building from 'stores/models/buildings/building';
import { MSTAddStaticMethods } from 'stores/utils';

const staticMethods = {
  fromResponseData: (data: BuildingOverviewAPI): BuildingOverviewSnapshot => ({
    ...pick(data, ['name', 'address', 'neighborhood', 'url']),
    id: String(data.id),
    initializing_status: String(data.initializing_status),
    propertyType: String(data.property_type.label),
  }),
};

const propsFromBuilding = pick(Building.properties, [
  'id',
  'name',
  'address',
  'neighborhood',
  'propertyType',
  'url',
  'initializing_status',
]);

const BuildingOverview = BaseModel.named('BuildingOverview').props({
  ...propsFromBuilding,
});

export type BuildingOverviewInstanceType = Instance<typeof BuildingOverview>;
export type BuildingOverviewSnapshot = SnapshotOut<typeof BuildingOverview>;

export default MSTAddStaticMethods(BuildingOverview, staticMethods);
