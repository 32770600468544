import React, { memo, useMemo, useState } from 'react';
import { LayoutContext } from 'contexts';

export const LayoutWrapper = memo((props) => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  const contextValue = useMemo(() => ({ logoUrl, setLogoUrl }), [logoUrl, setLogoUrl]);

  return <LayoutContext.Provider value={contextValue} {...props} />;
});
