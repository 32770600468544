import startOfDay from 'date-fns/startOfDay';
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import isToday from 'date-fns/isToday';
import format from 'date-fns/format';

export { default as endOfDay } from 'date-fns/endOfDay';
export { default as differenceInDays } from 'date-fns/differenceInDays';

const startOfToday = (): Date => startOfDay(new Date());

const startOfTodayiInclusive = (): Date => startOfDay(addDays(new Date(), 1));

const getTimeFromMomentInstance = (momentInstance: { format: (value: string) => string }): string =>
  momentInstance.format('HH:mm:ss');

const getWeekBefore = (value: Date = new Date()): Date => startOfDay(addDays(value, -7));

const toReportDate = (value?: Date): string | undefined => value && format(value, 'yyyy-MM-dd');

export {
  startOfTodayiInclusive,
  startOfToday,
  getTimeFromMomentInstance,
  startOfDay,
  addDays,
  getWeekBefore,
  addYears,
  isToday,
  toReportDate,
};
