import { types } from 'mobx-state-tree';
import { apiFlow } from 'stores/mst-types';
import Base from 'stores/models/base';
import ReportsOverview from 'stores/models/reports/reports-overview';

export const ReportsStore = Base.named('ReportsStore')
  .props({
    reportsOverview: types.optional(ReportsOverview, {}),
    isInitialized: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    init: apiFlow(function* init() {
      yield self.reportsOverview.fetch();

      self.isInitialized = true;
    }),

    destroy: () => {
      self.reportsOverview.destroy();

      self.isInitialized = false;
    },
  }));
