import React, { memo, useCallback } from 'react';
import { Box } from 'components/common/box';
import { Button } from 'components/common/button';
import { ImageList } from 'components/common/image-list';
import themeColors from 'utils/theme-colors';
import { useTranslation } from 'hooks';
import { ImageSaved } from 'types';
import config from 'config';
import { ImagesPickerSelectProps } from './types';

const selectionMask = (
  <Box
    position='absolute'
    borderRadius={5}
    left={0}
    right={0}
    top={0}
    bottom={0}
    border={`3px solid ${themeColors.blue3}`}
  />
);

export const ImagesPickerSelect = memo<ImagesPickerSelectProps>(
  ({ value, options, onChange, min = config.options.imagesPicker.minSelectedItems, max = Infinity, ...props }) => {
    const { t } = useTranslation();

    const toggleSelect = useCallback(
      (option: NonNullable<ImagesPickerSelectProps['options']>[0]) => {
        const isSelected = value?.some(({ src }) => src === option.src);
        let nextValue: ImageSaved[] = [];

        switch (isSelected) {
          case true:
            nextValue =
              value?.filter(({ src }) => {
                const valueSrc: boolean = src.includes('/assets/approved/');
                const optionSrc: boolean = option.src.includes('/assets/approved/');
                if ((valueSrc && optionSrc) || (!valueSrc && !optionSrc)) {
                  return src !== option.src;
                }
                return valueSrc;
              }) || [];
            break;
          case false:
            nextValue =
              value
                ?.filter(({ src }) => {
                  const valueSrc: boolean = src.includes('/assets/approved/');
                  const optionSrc: boolean = option.src.includes('/assets/approved/');
                  if ((valueSrc && !optionSrc) || (!valueSrc && optionSrc)) {
                    return false;
                  }
                  return true;
                })
                .concat(option) || [];
            break;
          default:
            break;
        }
        onChange(nextValue);
      },
      [onChange, value],
    );

    const isSelectDisabled = value && value.length >= max;
    const isUndoDisabled = !value || value?.length <= min;

    const renderItem = useCallback<NonNullable<ImagesPickerSelectProps['renderItem']>>(
      ({ imageNode, item }) => {
        const isSelected = value?.some(({ src }) => src === item.src);

        const buttonText = isSelected ? t('ImagesPicker.UndoSelectButton') : t('ImagesPicker.SelectButton');

        const disabled = isSelected ? isUndoDisabled : isSelectDisabled;

        return (
          <Box position='relative'>
            <Box position='relative'>
              {imageNode}
              {isSelected ? selectionMask : null}
            </Box>
            <Box
              position='absolute'
              display='flex'
              justifyContent='center'
              left={0}
              right={0}
              bottom={0}
              marginBottom={10}
            >
              <Button size='tiny' disabled={disabled} onClick={() => toggleSelect(item)}>
                {buttonText}
              </Button>
            </Box>
          </Box>
        );
      },
      [value, t, isUndoDisabled, isSelectDisabled, toggleSelect],
    );

    return <ImageList renderItem={renderItem} items={options} {...props} />;
  },
);
