import React from 'react';
import { Typography } from 'components/common/typography';
import { withConfirmation } from 'hocs/with-confirmation';
import { useTranslation } from 'hooks';
import { RemoveButtonProps } from './types';

// @ts-ignore
export const RemoveButton = withConfirmation<RemoveButtonProps>()(({ children, ...props }) => {
  const { t } = useTranslation();

  return (
    <Typography variant='body-13' color='errorColor' cursor='pointer' {...props}>
      {children ?? t('LogoUploader.RemoveButton')}
    </Typography>
  );
});
