import { getEnv, IStateTreeNode } from 'mobx-state-tree';
import { observable, IObservableArray } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { successMessage, errorMessage } from 'components/common/message';

export type FlashAlert = FlashAlertData & {
  itemId: string;
};

type FlashAlertData = {
  message: string;
  type: 'error' | 'success';
  holderId?: string;
};

const createAlert = ({ holderId, ...data }: FlashAlertData): FlashAlert => ({
  holderId,
  itemId: uuidv4(),
  ...data,
});

export const withFlashAlerts = (self: IStateTreeNode) => {
  const alertStask: IObservableArray<FlashAlert> = observable.array();
  const modalAlertStask: IObservableArray<FlashAlert> = observable.array();

  const t = getEnv(self).get('i18n');

  return {
    views: {
      get flashAlerts() {
        return alertStask.slice();
      },
      get modalFlashAlerts() {
        return modalAlertStask.slice();
      },
      hasAlerts() {
        return alertStask.length > 0;
      },
      hasModalAlerts() {
        return modalAlertStask.length > 0;
      },
    },
    actions: {
      showError: (textKey?: string) => {
        errorMessage(t(textKey ?? 'Common.Alerts.Error'));
      },

      showModalError: (errorKey?: string, options?: Record<string, any>, holderId?: FlashAlertData['holderId']) => {
        const alert = createAlert(
          errorKey
            ? {
                holderId,
                message: t(errorKey, options || {}),
                type: 'error',
              }
            : {
                holderId,
                message: t('DefaultError'),
                type: 'error',
              },
        );

        modalAlertStask.unshift(alert);

        return () => {
          const index = modalAlertStask.indexOf(alert);
          modalAlertStask.splice(index, 1);
        };
      },
      showSuccess: (textKey?: string) => {
        successMessage(t(textKey ?? 'Common.Alerts.Success'));
      },
      showModalSuccess: (successKey: string, holderId?: FlashAlertData['holderId']) => {
        const alert = createAlert({
          holderId,
          message: t(successKey, 'DefaultSuccess'),
          type: 'success',
        });

        modalAlertStask.unshift(alert);

        return () => {
          const index = modalAlertStask.indexOf(alert);
          modalAlertStask.splice(index, 1);
        };
      },
      removeAlert: (itemIdToRemove: FlashAlert['itemId']) => {
        const itemToRemove = alertStask.find(({ itemId }) => itemIdToRemove === itemId);

        if (itemToRemove) {
          alertStask.remove(itemToRemove);
        }
      },
      removeAlertsByHolderId: (holderId: FlashAlert['holderId']) => {
        const itemsToRemove = alertStask.filter((alert) => alert.holderId === holderId);

        if (itemsToRemove?.length) {
          itemsToRemove.forEach((item) => {
            alertStask.remove(item);
          });
        }
      },
      removeModalAlertsByHolderId: (holderId: FlashAlert['holderId']) => {
        const itemsToRemove = modalAlertStask.filter((alert) => alert.holderId === holderId);

        if (itemsToRemove?.length) {
          itemsToRemove.forEach((item) => {
            modalAlertStask.remove(item);
          });
        }
      },
      removeAllModalsAlerts: () => {
        modalAlertStask.forEach((item) => {
          modalAlertStask.remove(item);
        });
      },
      removeAllAlerts: () => {
        alertStask.forEach((item) => {
          alertStask.remove(item);
        });
      },
    },
  };
};
