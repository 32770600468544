import { apiFlow } from 'stores/mst-types';
import { getEnv, getRoot, types, cast } from 'mobx-state-tree';
import pick from 'lodash.pick';
import Base from 'stores/models/base';
import config from 'config';
import { History } from 'history';
import { PostOrganizationApi } from 'types';
import locations from 'navigation/locations';
import Option from 'stores/models/option';
import { getOptionValueByLabel } from 'utils/options';

const { brandingCampaignTargetKinds } = config.api.constants;

const isAgent = brandingCampaignTargetKinds.agent.id;

interface PostOrganizationFormValues {
  name: string;
  kind: string;
  state: Nullable<string>;
  city: Nullable<string>;
  contactPhone: Nullable<string>;
}

const staticMethods = {
  toPostOrganizationRequest: (data: PostOrganizationFormValues): PostOrganizationApi => ({
    ...pick(data, ['name', 'kind']),
    state: data.kind !== isAgent && data.state ? Number(data.state) : undefined,
    city: data.kind !== isAgent && data.city ? Number(data.city) : undefined,
    contact_phone: data.kind !== isAgent && data.contactPhone ? data.contactPhone : undefined,
  }),
};

export const OrganizationSetupStore = Base.named('OrganizationSetupStore')
  .props({
    rawOrganizationKindOptions: types.optional(types.array(Option), []),
  })
  .views((self) => ({
    get initialValues() {
      return {
        kind: this.agentKindValue,
      };
    },

    get agentKindValue() {
      return getOptionValueByLabel({
        options: self.rawOrganizationKindOptions,
        label: config.api.constants.organizations.kindOptionLabels.agent,
      });
    },

    get brokerageKindValue() {
      return getOptionValueByLabel({
        options: self.rawOrganizationKindOptions,
        label: config.api.constants.organizations.kindOptionLabels.brokerage,
      });
    },

    get multifamilyKindValue() {
      return getOptionValueByLabel({
        options: self.rawOrganizationKindOptions,
        label: config.api.constants.organizations.kindOptionLabels.multifamily,
      });
    },

    get organizationKindOptions() {
      const t = getEnv(self).get('i18n');

      return [
        {
          label: t('Common.OrganizationKindOptions.Agent'),
          description: t('Common.OrganizationKindOptions.AgentDescription'),
          value: this.agentKindValue,
        },
        {
          label: t('Common.OrganizationKindOptions.Brokerage'),
          description: t('Common.OrganizationKindOptions.BrokerageDescription'),
          value: this.brokerageKindValue,
        },
        {
          label: t('Common.OrganizationKindOptions.Multifamily'),
          description: t('Common.OrganizationKindOptions.MultifamilyDescription'),
          value: this.multifamilyKindValue,
        },
      ].filter(({ value }) => value);
    },

    get destinationLink() {
      const { session } = getRoot<any>(self);

      return session.user.isOrganizationDetailsAvailable
        ? locations.organization.details.toUrl()
        : config.options.pages.defaultPage;
    },
  }))
  .actions((self) => ({
    init: apiFlow(function* init() {
      const { common } = getRoot<any>(self);

      self.rawOrganizationKindOptions = yield common.getOrganizationKindOptions();
    }),

    create: apiFlow(
      function* create(values: PostOrganizationFormValues) {
        yield self.api.postOrganization(staticMethods.toPostOrganizationRequest(values));

        const { session } = getRoot<any>(self);

        yield session.loadUser();

        setTimeout(() => {
          const history = self.services.get<History>('history');
          history.replace(self.destinationLink);
        });
      },
      {
        isUpdate: true,
        formName: 'organization-setup',
        successAlert: 'OrganizationSetup.Alerts.Created',
        errorAlert: 'Common.Alerts.Error',
      },
    ),

    destroy() {
      self.rawOrganizationKindOptions = cast([]);
    },
  }));
