import React, { useMemo, useCallback } from 'react';
import { useToggle } from 'react-use';
import isEqual from 'lodash.isequal';
import { observer } from 'mobx-react-lite';
import { useField, useTranslation } from 'hooks';
import { Box } from 'components/common/box';
import { Button } from 'components/common/button';
import { Icon } from 'components/common/icon';
import { withFormItem } from 'hocs/with-form-item';
import { useCampaignFormContext } from 'components/campaigns/campaign-form/hooks';
import { Alert } from 'components/common/alert';
import { Trans } from 'react-i18next';
import config from 'config';
import { findOptionById } from 'utils/objects';
import { GeoTargetingValue } from 'types';
import { GeoTargetingCreatorModal } from './geo-targeting-creator-modal';
import { GeoTargetingFieldValueItem } from './geo-targeting-field-value-item';
import { GeoTargetingFieldProps, GeoTargetingCreatorModalProps, GeoTargetingFieldValueItemProps } from './types';

const listProps = {
  display: 'flex',
  gap: 10,
  flexWrap: 'wrap',
};

const { types } = config.api.constants.campaigns;

export const GeoTargetingField = withFormItem<GeoTargetingFieldProps>()(
  observer(({ options, wrapperProps, ...props }) => {
    const { t } = useTranslation();
    const { field } = useField(props);
    const { name, value: rawValue, onChange, onBlur } = field;
    const value = rawValue as GeoTargetingFieldProps['value'];

    const [open, toggleOpen] = useToggle(false);

    const {
      campaignManager: { target, campaignKindId, campaign },
    } = useCampaignFormContext();

    const isChangedLocation = campaign?.isEntityLocationChanged;

    const location = target?.location;

    const typeLabel = useMemo(
      () =>
        campaignKindId &&
        findOptionById({
          id: campaignKindId,
          settings: types,
        })?.name,
      [campaignKindId],
    );

    const tooltip = useMemo(() => <Trans i18nKey='GeoTargetingField.Tooltip' values={{ typeLabel }} />, [typeLabel]);

    const add: GeoTargetingCreatorModalProps['add'] = useCallback(
      (addedValue: GeoTargetingValue) => {
        const isAlreadySelected = value?.some((valueItem) => isEqual(valueItem, addedValue));

        if (isAlreadySelected) {
          return;
        }

        const event = {
          target: { name, value: [...(value || []), addedValue] },
        };

        onChange(event);
        onBlur(event);
      },
      [name, onBlur, onChange, value],
    );

    const remove: GeoTargetingFieldValueItemProps['remove'] = useCallback(
      (removedValue) => {
        const nextValue = value?.filter((valueItem) => !isEqual(valueItem, removedValue));

        const event = {
          target: {
            name,
            value: nextValue?.length ? nextValue : undefined,
          },
        };

        onChange(event);
        onBlur(event);
      },
      [name, onBlur, onChange, value],
    );

    const choosenValue: any = value && value.length > 0 && `${value[0].city.label}, ${value[0].state.abbr}`;

    const items = useMemo(
      () =>
        value?.map((valueItem) => (
          <GeoTargetingFieldValueItem
            key={valueItem.city.value}
            label={`${valueItem.city.label}, ${valueItem.state.abbr}`}
            value={valueItem}
            remove={remove}
          />
        )),
      [value, remove],
    );

    const warning = useMemo(
      () =>
        isChangedLocation ? (
          <Alert
            type='warning'
            message={t('CampaignForm.Warnings.TextIsChangedLocation', {
              typeLabel,
            })}
            width='100%'
            marginBottom={25}
          />
        ) : null,
      [t, typeLabel, isChangedLocation],
    );

    return (
      <Box {...wrapperProps}>
        <Box marginBottom={40}>
          {warning}
          <Box {...listProps} marginBottom={10}>
            {location && location !== choosenValue ? (
              <GeoTargetingFieldValueItem label={location} tooltip={tooltip} />
            ) : null}
            {items?.length ? <Box {...listProps}>{items}</Box> : null}
          </Box>
        </Box>
        <Box display='flex' justifyContent='center'>
          <Button
            type='primary'
            size='small'
            icon={<Icon name='plus' size={16} marginRight={13} />}
            disabled={(props.disabled || campaign?.id) && campaign?.status.id !== '9'}
            onClick={toggleOpen}
          >
            {t('GeoTargetingField.Creator.CreateButton')}
          </Button>
          <GeoTargetingCreatorModal
            visible={open}
            tooltip={tooltip}
            location={location}
            onCancel={toggleOpen}
            add={add}
          />
        </Box>
      </Box>
    );
  }),
);
