import { BaseHttpClient } from './base-http-client';
import { urls } from './api-urls';

const {
  createCollectionType,
  getCollectionType,
  createCollection,
  getCollections,
  updateCollection,
  getCollection,
  deleteCollection,
} = urls.collections;

export const createCollectionTypeApi = (http: BaseHttpClient) => ({
  createCollectionType(name?: string) {
    return http.post<any>(createCollectionType.url, { name });
  },
  createCollection(data?: any) {
    return http.post<any>(createCollection.url, {
      name: data.name,
      building_ids: data.buildingsId,
      tags: data.collectionTypes,
    });
  },
  getCollectionType() {
    return http.get<any>(getCollectionType.url);
  },
  getCollections(data?: any) {
    return http.post<any>(getCollections.url, {
      name_suffix: data.name_suffix,
      building_ids: data.building_ids,
      tags: data.tags,
    });
  },
  getCollection(id?: number) {
    return http.get<any>(getCollection.url.replace('{id}', String(id)));
  },
  deleteCollection(id?: number) {
    return http.delete<any>(deleteCollection.url.replace('{id}', String(id)));
  },
  updateCollection(data?: any) {
    return http.patch<any>(updateCollection.url.replace('{id}', String(data?.id)), {
      name: data.name,
      building_ids: data.building_ids,
      tags: data.tags,
    });
  },
});
