import React, { useMemo } from 'react';
import { Radio as AntdRadio } from 'antd';
import classnames from 'classnames';
import { Radio } from 'components/common/form/radio';
import { Box } from 'components/common/box';
import { Typography } from 'components/common/typography';
import { withFormItem } from 'hocs/with-form-item';
import { useField } from 'hooks';
import { RadioGroupProps } from './types';
import styles from './styles.module.scss';

const { Group } = AntdRadio;

const defaultOptions = [] as NonNullable<RadioGroupProps['options']>;

export const RadioGroup = withFormItem<RadioGroupProps>()(
  ({ className: classNameFromProps, options = defaultOptions, rows, ...props }) => {
    const className = classnames(
      styles.root,
      {
        [styles.rows]: rows,
      },
      classNameFromProps,
    );

    const { field, componentProps } = useField(props);

    const items = useMemo(
      () =>
        options.map(({ label, value, description, disabled }) => (
          <Box key={value} display='flex' flexDirection='column' gridRowGap={23}>
            <Radio value={value} disabled={disabled}>
              {label}
            </Radio>
            {description ? (
              <Typography variant='body-1' color='grey5'>
                {description}
              </Typography>
            ) : null}
          </Box>
        )),
      [options],
    );

    return (
      <Group className={className} {...field} {...componentProps}>
        {items}
      </Group>
    );
  },
);
