import { useEffect, useContext } from 'react';
import { PreloaderContext } from 'contexts';
import { isEmpty } from 'utils/is';

export const usePreloader = ({
  store,
  pending: pendingFromProps,
  resourceName,
  ignoreResourceName,
}: {
  store?: {
    pendingLoad?: boolean;
    isInitialized?: boolean;
    getStatus: (resourceName?: string) => string;
    getIsPendingLoadByResourceName: (resourceName?: string) => boolean;
  };
  pending?: boolean;
  resourceName?: string;
  ignoreResourceName?: string | string[];
}): { initializing: boolean } => {
  const preloaderContext = useContext(PreloaderContext);

  const isStorePending = store && store.getIsPendingLoadByResourceName(resourceName);

  const initializing = isEmpty(store?.isInitialized) ? false : !store?.isInitialized;

  const pending = pendingFromProps ?? (resourceName ? isStorePending : isStorePending || initializing);

  const shouldIgnore =
    Boolean(ignoreResourceName) &&
    store &&
    ([] as string[])
      .concat(ignoreResourceName as string)
      .some((ignoreResourceNameItem) => store.getIsPendingLoadByResourceName(ignoreResourceNameItem));

  useEffect(() => {
    if (shouldIgnore || !preloaderContext) {
      return;
    }

    preloaderContext.setIsPreloaderActive(pending);
  }, [preloaderContext, pending, shouldIgnore]);

  return { initializing };
};
