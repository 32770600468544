import { SnapshotOut, Instance, types } from 'mobx-state-tree';
import pick from 'lodash.pick';
import BaseModel from 'stores/models/base';
import Image from 'stores/models/image';
import ImageWithOldMarker from 'stores/models/image-with-old-marker';
import { nullable } from 'stores/mst-types';
import { MSTAddStaticMethods } from 'stores/utils';
import { ImageFormValue, OrganizationApi, PatchOrganizationApi } from 'types';
import { serializeSimplifiedImage } from 'utils/serializers';
import { isEmpty } from 'utils/is';

export interface PatchOrganizationFormValues {
  name: string;
  website: string;
  description: string;
  logo: Nullable<ImageFormValue>;
  photos: Nullable<ImageFormValue[]>;
  typesOfProperties?: string[];
  state: string;
  city: string;
  contactPhone: Nullable<string>;
}

const staticMethods = {
  fromResponseData: (data: OrganizationApi): OrganizationInfoSnapshot => ({
    ...pick(data, ['name']),
    id: String(data.id),
    // @ts-ignore
    logo: isEmpty(data.logo) ? null : { src: data.logo },
    type: String(data.kind),
    website: data.website,
    description: data.description,
    photos: data.organization_photos?.map(ImageWithOldMarker.fromResponseData),
    typesOfProperties: data.property_types?.map(String) ?? [],
    hasAffectedCampaigns: Boolean(data.has_affected_campaigns?.length),
    state: data.state ? String(data.state) : undefined,
    city: data.state ? String(data.city) : undefined,
    contactPhone: data.contact_phone ? data.contact_phone : undefined,
  }),

  toPatchRequestInfoData: (
    values: Partial<PatchOrganizationFormValues>,
    isMultifamilyType: boolean,
  ): Partial<PatchOrganizationApi> => ({
    ...pick(values, ['name', 'website', 'description']),
    ...(isMultifamilyType
      ? {
          property_types: values.typesOfProperties?.length ? values.typesOfProperties : null,
        }
      : {}),
    state: Number(values.state),
    city: Number(values.city),
    contact_phone: values.contactPhone,
  }),

  toPatchRequestLogoData: (values: Pick<PatchOrganizationFormValues, 'logo'>): Pick<PatchOrganizationApi, 'logo'> => ({
    logo: values.logo ? serializeSimplifiedImage(values.logo) : null,
  }),

  toPatchRequestPhotosData: (
    values: Pick<PatchOrganizationFormValues, 'photos'>,
  ): Pick<PatchOrganizationApi, 'organization_photos'> => ({
    organization_photos: values.photos?.length ? values.photos : null,
  }),
};

const Organization = BaseModel.named('OrganizationInfo').props({
  id: nullable(types.string),
  name: nullable(types.string),
  logo: nullable(Image),
  type: nullable(types.string),
  website: nullable(types.string),
  typesOfProperties: types.optional(types.array(types.string), []),
  description: nullable(types.string),
  photos: nullable(types.array(ImageWithOldMarker)),
  hasAffectedCampaigns: nullable(types.boolean),
  state: nullable(types.string),
  city: nullable(types.string),
  contactPhone: nullable(types.string),
});

export type OrganizationInfoSnapshot = SnapshotOut<typeof Organization>;
export type OrganizationInfoInstanceType = Instance<typeof Organization>;

export default MSTAddStaticMethods(Organization, staticMethods);
