import React, { memo, useMemo, useCallback } from 'react';
import { useToggle } from 'react-use';
import { Box } from 'components/common/box';
import { useField } from 'hooks';
import { AdLogoPickerModal } from './ad-logo-picker-modal';
import { AdLogoPickerProps } from './types';

export const AdLogoPicker = memo<AdLogoPickerProps>(({ holderProps, children, ...props }) => {
  const { field } = useField(props);
  const { name, value, onChange, onBlur } = field;
  const [open, toggleOpen] = useToggle(false);

  const initialValues = useMemo(
    () => ({
      logo: value && [value],
    }),
    [value],
  );

  const handleChange = useCallback(
    ({ logo }: { logo: NonNullable<AdLogoPickerProps['value']>[] }) => {
      const event = { target: { name, value: logo[0] } };
      onChange(event);
      onBlur(event);
    },
    [name, onChange, onBlur],
  );

  return (
    <>
      <Box display='inline-flex' onClick={toggleOpen} {...holderProps}>
        {children}
      </Box>
      <AdLogoPickerModal visible={open} onCancel={toggleOpen} initialValues={initialValues} onSubmit={handleChange} />
    </>
  );
});
