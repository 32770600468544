import { types, SnapshotOut, Instance } from 'mobx-state-tree';

const Option = types.model('Option', {
  label: types.string,
  value: types.string,
});

export type OptionSnapshot = SnapshotOut<typeof Option>;
export type OptionInstanceType = Instance<typeof Option>;

export default Option;
