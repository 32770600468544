import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { MSTAddStaticMethods } from 'stores/utils';
import { GeoTargetingValueApi, GeoTargetingValue } from 'types';

const staticMethods = {
  fromResponseData: (data: GeoTargetingValueApi): GeoTargetingValue => data,

  toRequestData: (data: GeoTargetingValue): GeoTargetingValueApi => data,
};

const CampaignGeoTarget = types.model({
  state: types.model({
    value: types.string,
    label: types.string,
    abbr: types.string,
  }),
  city: types.model({
    value: types.string,
    label: types.string,
  }),
});

export type CampaignStatusInstanceType = Instance<typeof CampaignGeoTarget>;
export type CampaignStatusSnapshot = SnapshotOut<typeof CampaignGeoTarget>;

export default MSTAddStaticMethods(CampaignGeoTarget, staticMethods);
