import { BuildingAPI, Filtration, PatchBuildingApi, PostBuildingApi, RegeneratedCampaignTextAssetAPI } from 'types';
import { serializeFiltrationParams } from 'utils/serializers';
import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import {
  GetBuildingOptionsResponse,
  GetBuildingParametersResponse,
  GetBuildingResponse,
  GetBuildingsResponse,
  GetBuildingTextAssetsResponse,
  PatchBuildingResponse,
  PostBuildingResponse,
} from '../response-types';
import { createFormData } from './helpers';

const {
  getAll,
  details,
  activate,
  create,
  createImages,
  sync,
  update,
  getOptions,
  remove,
  getParameters,
  getYardiConnectionStatus,
  getTextAssets,
  validateSingleImage,
  regeneratedTextAsset,
} = urls.buildings;

export const createBuildingsApi = (http: BaseHttpClient) => ({
  getBuildings(options?: Filtration) {
    return http.get<GetBuildingsResponse>(getAll.url, serializeFiltrationParams(options));
  },

  getBuilding({ id }: Pick<BuildingAPI, 'id'>) {
    return http.get<GetBuildingResponse>(details.url.replace('{id}', String(id)));
  },

  activateBuilding(id: number) {
    return http.post<GetBuildingResponse>(activate.url.replace('{id}', String(id)));
  },

  syncWithYardi(id: number) {
    return http.get<any>(sync.url.replace('{id}', String(id)));
  },

  postBuilding(building: PostBuildingApi) {
    return http.post<PostBuildingResponse>(create.url, building);
  },

  postBuildingImages(id: any, images: any) {
    // TODO: any remove
    return http.put<any>(createImages.url.replace('{id}', String(id)), createFormData(images));
  },

  getBuildingParameters() {
    return http.get<GetBuildingParametersResponse>(getParameters.url);
  },

  patchBuilding({ id, data }: Pick<BuildingAPI, 'id'> & { data: Partial<PatchBuildingApi> }) {
    return http.put<PatchBuildingResponse>(update.url.replace('{id}', String(id)), data);
  },

  removeBuilding({ id }: Pick<BuildingAPI, 'id'>) {
    return http.delete(remove.url.replace('{id}', String(id)));
  },

  getBuildingOptions() {
    return http.get<GetBuildingOptionsResponse>(getOptions.url);
  },

  getYardiConnectionStatus() {
    return http.get<any>(getYardiConnectionStatus.url);
  },

  getBuildingTextAssets({ id }: Filtration & Pick<BuildingAPI, 'id'>) {
    return http.get<GetBuildingTextAssetsResponse>(getTextAssets.url.replace('{id}', String(id)));
  },

  validateBuildingSingleImage(image: any) {
    return http.put<any>(validateSingleImage.url, createFormData(image));
  },

  regenerateBuildingTextAsset({
    id,
    platform,
    text_type,
    // @ts-ignore
    selectedGroup,
    // @ts-ignore
    use_case_group,
    // @ts-ignore
    exclude_result,
    // @ts-ignore
    asset_number,
    ...options
  }: Filtration & Pick<BuildingAPI, 'id'> & { platform: string; text_type: string }) {
    let ad_channel: string;
    if (platform === 'GOOGLE') {
      ad_channel = use_case_group !== 'general_display_targeting' ? 'google_search' : 'google_display';
    } else {
      ad_channel = 'meta';
    }
    return http.post<RegeneratedCampaignTextAssetAPI>(regeneratedTextAsset.url.replace('{id}', String(id)), {
      ad_channel,
      text_type,
      use_case_group,
      asset_number,
      exclude_result,
    });
  },
});
