import React from 'react';
import DefaultErrorBoundary from './default-error-boundary';
import SentryErrorBoundary from './sentry-error-boundary';
import config from '../../../../config';

interface ErrorBoundaryProps {}

const ErrorBoundary: React.FunctionComponent<ErrorBoundaryProps> = (props) => {
  if (config.sentry.isEnabled) {
    return <SentryErrorBoundary {...props} />;
  }

  return <DefaultErrorBoundary {...props} />;
};

export default ErrorBoundary;
