import React, { useCallback, useMemo } from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxGroupProps as AntdCheckboxGroupProps } from 'antd/lib/checkbox/Group';
import { Box } from 'components/common/box';
import { CheckboxBase } from 'components/common/form/checkbox-base';
import { Typography } from 'components/common/typography';
import { useField } from 'hooks';
import { withFormItem } from 'hocs/with-form-item';
import classnames from 'classnames';
import { CheckboxGroupProps } from './types';
import styles from './styles.module.scss';

export const CheckboxGroup = withFormItem<CheckboxGroupProps>()(
  ({ className: classNameFromProps, options, ...props }) => {
    const className = classnames(styles.root, classNameFromProps);

    const { field, componentProps } = useField(props);
    const { name, onChange } = field;
    const items = useMemo(
      () =>
        options?.map(({ label, value }) => (
          <CheckboxBase key={String(value)} value={value} size={28}>
            <Typography variant='body-13' color='black3'>
              {label}
            </Typography>
          </CheckboxBase>
        )),
      [options],
    );

    const handleChange: AntdCheckboxGroupProps['onChange'] = useCallback(
      (value) => {
        onChange({ target: { name, value } });
      },
      [name, onChange],
    );

    return (
      <AntdCheckbox.Group className={className} {...field} onChange={handleChange} {...componentProps}>
        <Box display='flex' flexDirection='column' gridGap={16}>
          {items}
        </Box>
      </AntdCheckbox.Group>
    );
  },
);
