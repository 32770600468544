import React from 'react';
import { PageError, PageErrorProps } from 'components/common/page-error';
import { useTranslation } from 'hooks';

interface ErrorViewProps extends PageErrorProps {
  error?: Error | null;
  componentStack?: React.ErrorInfo | null;
}

const ErrorView: React.FunctionComponent<ErrorViewProps> = (props) => {
  const { t } = useTranslation();
  // @ts-ignore
  return <PageError title={t('Common.PageErrors.AppCrashed')} {...props} />;
};

ErrorView.defaultProps = {
  error: null,
  componentStack: null,
};

export default ErrorView;
