import React, { memo, useRef } from 'react';
import { Alert } from 'components/common/alert';
import { Box } from 'components/common/box';
import { FormWarningProps } from './types';

export const FormWarning = memo<FormWarningProps>(({ messages = [], ...props }) => {
  const alertRef = useRef<HTMLDivElement | null>(null);

  const errors = [...messages].filter(Boolean);

  if (!errors.length) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' gridRowGap={20} {...props}>
      {errors.map((error, index) => (
        <Alert key={index} {...(index === 0 ? { ref: alertRef } : {})} type='warning' message={error} />
      ))}
    </Box>
  );
});
