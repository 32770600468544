import React, { useEffect, useState, useCallback, memo } from 'react';
import { Prompt } from 'react-router-dom';
import { Location } from 'history';
import { useTranslation, useHistory } from 'hooks';
import { Confirmation } from 'components/common/confirmation';
import { RouteLeavingGuardProps } from './types';

export const RouteLeavingGuard = memo<RouteLeavingGuardProps>(({ when, message: messageFromProps }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const { t } = useTranslation();

  const history = useHistory();

  const navigate = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation: Location): boolean => {
      if (!confirmedNavigation && when) {
        setModalVisible(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, when],
  );

  const message = messageFromProps ?? t('RouteLeavingGuard.Message');

  const handleConfirmNavigationClick = useCallback(() => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent): any => {
      if (!when) {
        return undefined;
      }

      const e = event || window.event;
      // Cancel the event
      e.preventDefault();
      if (e) {
        e.returnValue = message; // Legacy method for cross browser support
      }
      return message; // Legacy method for cross browser support
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [message, when]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Confirmation
        visible={modalVisible}
        title={t('RouteLeavingGuard.Title')}
        description={message ?? t('RouteLeavingGuard.Message')}
        cancelText={t('RouteLeavingGuard.CancelButton')}
        confirmText={t('RouteLeavingGuard.ConfirmButton')}
        onConfirm={handleConfirmNavigationClick}
        onCancel={closeModal}
      />
    </>
  );
});
