export const constants = {
  marks: {
    20: '20%',
    50: '50%',
    100: '100%',
    200: '200%',
  },
  variants: {
    square: 'square',
    rectangle: 'rectangle',
  },
  scale: {
    widthSquare: 55,
    widthRectangle: 106,
    height: 55,
  },
  logo: 'logo',
};
