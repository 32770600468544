import React, { memo, forwardRef } from 'react';
import { Box } from 'components/common/box';
import { Typography } from 'components/common/typography';
import { Icon } from 'components/common/icon';
import themeColors from 'utils/theme-colors';
import { AlertProps } from './types';

const iconProps = {
  error: {
    iconName: 'delete',
    color: 'errorColor',
  },

  warning: {
    iconName: 'warning',
    color: 'yellow6',
  },
};

export const Alert = memo<AlertProps>(
  forwardRef<HTMLDivElement, AlertProps>(({ type = 'error' as const, variant = 'body-13', message, ...props }, ref) => {
    const { iconName, color } = iconProps[type];

    return (
      <Typography
        ref={ref}
        variant={variant}
        display='flex'
        paddingLeft={13}
        paddingRight={15}
        paddingY={14}
        borderRadius={5}
        border={`1px solid ${themeColors[color]}`}
        alignItems='flex-start'
        {...props}
      >
        <Icon name={iconName} color={color} size={25} marginRight={9} display='inline-flex' />
        <Typography variant='inherit'>
          <Box>{message}</Box>
        </Typography>
      </Typography>
    );
  }),
);
