import { useCallback, useRef } from 'react';
import { getImagePreview } from 'utils/images';

export const useFilePreview = (): {
  getFilePreview: (file: File) => string;
} => {
  const previewMapRef = useRef<Map<File, string>>(new Map());

  const getFilePreview = useCallback((file: File): string => {
    const cachedImageSrc = previewMapRef.current.get(file as File);

    if (cachedImageSrc) {
      return cachedImageSrc;
    }

    const imageSrc = getImagePreview(file)?.src ?? '';
    previewMapRef.current.set(file, imageSrc);

    return imageSrc;
  }, []);

  return { getFilePreview };
};
