import React, { memo, useCallback } from 'react';
import get from 'lodash.get';
import { useTranslation, useForm } from 'hooks';
import { Box } from 'components/common/box';
import { Icon } from 'components/common/icon';
import { Select } from 'components/common/form/select';
import { PriceField } from 'components/common/form/price-field';
import { FieldsLayout } from 'components/common/form/fields-layout';
import { TFunction } from 'types';
import { ApartmentParametersRowProps } from './types';

export const ApartmentParametersRow = memo<ApartmentParametersRowProps>(
  ({ options, rootFieldName, rowIndex, showRemoveButton, onRemove: onRemoveFromProps }) => {
    const { t } = useTranslation();

    const namePrefix = `${rootFieldName}[${rowIndex}]`;
    const selectFieldName = `${namePrefix}.type`;
    const minPriceFieldName = `${namePrefix}.minPrice`;
    const maxPriceFieldName = `${namePrefix}.maxPrice`;

    const { values } = useForm();

    const rowValue = get(values, namePrefix);
    const isPriceDisabled = !rowValue?.type;

    const isFirstRow = rowIndex === 0;

    const onRemove = useCallback(() => {
      onRemoveFromProps(rowIndex);
    }, [onRemoveFromProps, rowIndex]);

    const getLabel = useCallback(
      (...args: Parameters<TFunction>): ReturnType<TFunction> | undefined => (isFirstRow ? t(...args) : undefined),
      [isFirstRow, t],
    );

    return (
      <Box display='flex' alignItems='flex-end' position='relative'>
        {showRemoveButton ? (
          <Box display='flex' position='absolute' right='100%' bottom={0} marginRight={8} marginBottom={19}>
            <Icon name='minus' size={16} circle color='primaryColor' cursor='pointer' onClick={onRemove} />
          </Box>
        ) : null}
        <FieldsLayout columns={3} flex={1}>
          <Select
            name={selectFieldName}
            label={getLabel('ApartmentParametersField.Labels.ApartmentParameters')}
            placeholder={t('ApartmentParametersField.Placeholders.ApartmentParameters')}
            options={options}
          />
          <PriceField
            name={minPriceFieldName}
            label={getLabel('ApartmentParametersField.Labels.MinPrice')}
            placeholder={t('ApartmentParametersField.Placeholders.MinPrice')}
            disabled={isPriceDisabled}
          />
          <PriceField
            name={maxPriceFieldName}
            label={getLabel('ApartmentParametersField.Labels.MaxPrice')}
            placeholder={t('ApartmentParametersField.Placeholders.MaxPrice')}
            disabled={isPriceDisabled}
          />
        </FieldsLayout>
      </Box>
    );
  },
);
