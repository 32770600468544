import { types, cast, SnapshotOut, Instance, getRoot } from 'mobx-state-tree';
import Base from 'stores/models/base';
import Option from 'stores/models/option';
import { apiFlow } from 'stores/mst-types';

const ListingPropertyTypeOptions = Base.named('ListingPropertyTypeOptions')
  .props({
    entries: types.optional(types.array(Option), []),
  })
  .actions((self) => ({
    fetch: apiFlow(function* fetch() {
      const { common } = getRoot<any>(self);
      const options = yield common.getListingPropertyTypeOptions();

      self.entries = cast(options);
    }),
    destroy() {
      self.entries = cast([]);
    },
  }));

export type ListingPropertyTypeOptionsSnapshot = SnapshotOut<typeof ListingPropertyTypeOptions>;
export type ListingPropertyTypeOptionsInstanceType = Instance<typeof ListingPropertyTypeOptions>;

export default ListingPropertyTypeOptions;
