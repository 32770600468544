import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { ListingApi, ImageFormValue, PostListingApi, PatchListingApi, TagSelectOption } from 'types';
import BaseModel from 'stores/models/base';
import TagOption from 'stores/models/tag-option';
import CampaignTarget from 'stores/models/campaigns/campaign-target';
import { MSTAddStaticMethods } from 'stores/utils';
import { nullable } from 'stores/mst-types';
import { serializeTagSelectOptions } from 'utils/serializers';
import { excludeEmpty } from 'utils/objects';

export interface PostListingFormValues {
  name: string;
  agent: string;
  state: string;
  city: string;
  zip: string;
  neighborhood: string;
  address: string;
  url: string;
  propertyType: string;
  type: string;
  monthlyRent?: number;
  price?: number;
  listingAmenities: TagSelectOption[];
  bedrooms: number;
  bathrooms: number;
  acres?: number;
  squareFeet?: number;
  description: string;
  images: ImageFormValue[];
}

export interface PatchListingFormValues extends Partial<PostListingFormValues> {}

const staticMethods = {
  fromResponseData: (data: ListingApi): ListingSnapshot =>
    excludeEmpty<ListingSnapshot>({
      ...CampaignTarget.fromResponseData(data),
      ...pick(data, ['agent', 'neighborhood', 'address', 'bedrooms', 'bathrooms', 'description']),
      state: String(data.state),
      city: String(data.city),
      type: String(data.kind),
      zip: data.zip_code,
      price: data.price && Number(data.price),
      acres: data.acres && Number(data.acres),
      monthlyRent: data.monthly_rent && Number(data.monthly_rent),
      squareFeet: data.square_feet && Number(data.square_feet),
      propertyType: String(data.property_type),
      listingAmenities: data.listing_amenities?.map(TagOption.fromResponseData),
      initializing_status: data.initializing_status,
    }),

  toPostRequest: (values: PostListingFormValues): PostListingApi =>
    excludeEmpty({
      ...pick(values, [
        'name',
        'agent',
        'state',
        'city',
        'neighborhood',
        'address',
        'url',
        'price',
        'bedrooms',
        'bathrooms',
        'acres',
        'description',
        'images',
      ]),
      acres: values.acres,
      kind: values.type,
      zip_code: values.zip,
      property_type: values.propertyType,
      monthly_rent: values.monthlyRent,
      square_feet: values.squareFeet,
      listing_amenities: values.listingAmenities && serializeTagSelectOptions(values.listingAmenities),
    }),

  toPatchRequestData: (values: Partial<PatchListingFormValues>): Partial<PatchListingApi> => ({
    ...staticMethods.toPostRequest(
      // @ts-ignore
      values as unknown as PatchListingFormValues,
    ),
    acres: values.acres ? values.acres : '',
    square_feet: values.squareFeet ? values.squareFeet : '',
    listing_amenities: values.listingAmenities,
  }),
};

const Listing = BaseModel.named('Listing').props({
  ...CampaignTarget.properties,
  agent: types.string,
  state: types.string,
  city: types.string,
  zip: types.string,
  neighborhood: types.string,
  address: types.string,
  propertyType: types.string,
  type: types.string,
  monthlyRent: nullable(types.number),
  price: nullable(types.number),
  listingAmenities: nullable(types.array(TagOption)),
  bedrooms: types.number,
  bathrooms: types.number,
  acres: nullable(types.number),
  squareFeet: nullable(types.number),
  description: types.string,
  initializing_status: nullable(types.string),
});

export type ListingInstanceType = Instance<typeof Listing>;
export type ListingSnapshot = SnapshotOut<typeof Listing>;

export default MSTAddStaticMethods(Listing, staticMethods);
