import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import pick from 'lodash.pick';
import BaseModel from 'stores/models/base';
import { MSTAddStaticMethods } from 'stores/utils';

export interface FacebookAdAccountOverviewApi {
  logo: string;
  business_name: string;
  account_name: string;
  business_id: string;
  account_id: string;
}

const staticMethods = {
  fromResponseData: (data: FacebookAdAccountOverviewApi): FacebookAdAccountOverviewSnapshot => ({
    ...pick(data, ['logo']),
    businessName: data.business_name,
    accountName: data.account_name,
    businessId: data.business_id,
    accountId: data.account_id,
  }),
};

const FacebookAdAccountOverview = BaseModel.named('FacebookAdAccountOverview').props({
  logo: types.string,
  businessName: types.string,
  accountName: types.string,
  businessId: types.string,
  accountId: types.string,
});

export type FacebookAdAccountOverviewInstanceType = Instance<typeof FacebookAdAccountOverview>;
export type FacebookAdAccountOverviewSnapshot = SnapshotOut<typeof FacebookAdAccountOverview>;

export default MSTAddStaticMethods(FacebookAdAccountOverview, staticMethods);
