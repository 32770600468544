import React, { useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation, useModalProps, useStores } from 'hooks';
import { Form } from 'components/common/form/form';
import { FieldsLayout } from 'components/common/form/fields-layout';
import { StateField } from 'components/common/form/state-field';
import { CityField } from 'components/common/form/city-field';
import { withModal } from 'hocs/with-modal';
import { GeoTargetingCreatorModalProps } from './types';
import { validationSchema } from './validation-schemas';
import { GeoTargetingFieldValueItem } from './geo-targeting-field-value-item';

export const GeoTargetingCreatorModal = withModal<GeoTargetingCreatorModalProps>()(
  observer(({ add, tooltip, location, ...props }) => {
    const { t } = useTranslation();
    const { common } = useStores();

    const { getGeoTarget } = common;

    useModalProps(
      useMemo(
        () => ({
          title: t('GeoTargetingField.Creator.Modal.Title'),
        }),
        [t],
      ),
    );

    const items = useMemo(
      () => (
        <>
          <StateField
            name='stateId'
            label={t('GeoTargetingField.Creator.Modal.Labels.State')}
            placeholder={t('GeoTargetingField.Creator.Modal.Placeholders.State')}
          />
          <CityField
            name='cityId'
            stateFormField='stateId'
            label={t('GeoTargetingField.Creator.Modal.Labels.City')}
            placeholder={t('GeoTargetingField.Creator.Modal.Placeholders.City')}
          />
          {location ? <GeoTargetingFieldValueItem label={location} tooltip={tooltip} /> : null}
        </>
      ),
      [t, location, tooltip],
    );

    const footer = useMemo(
      () => ({
        cancelButtonAvailable: false,
        submitText: t('GeoTargetingField.Creator.Modal.SubmitButton'),
      }),
      [t],
    );

    const onSubmit = useCallback(
      async (values: { stateId: string; cityId: string }) => {
        const geoTarget = await getGeoTarget(values);

        add(geoTarget);
      },
      [add, getGeoTarget],
    );

    return (
      <Form validation={validationSchema} onSubmit={onSubmit} footer={footer}>
        <FieldsLayout columns={2} {...props}>
          {items}
        </FieldsLayout>
      </Form>
    );
  }),
);
