import { useMemo } from 'react';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import startOfToday from 'date-fns/startOfToday';
import config from 'config';
import { DateRangePickerProps } from 'components/common/form';
import { useCampaignFormContext } from './use-campaign-form-context';

const { statuses } = config.api.constants.campaigns;

export const useCampaignRunDatesRangePickerProps = (): Partial<DateRangePickerProps> => {
  const { campaignManager } = useCampaignFormContext();

  const isCampaignInLive = campaignManager.campaign?.status.id === statuses.live.id;

  const runDates = { ...campaignManager.campaign?.runDates };

  const isOriginalStartDateInPastOrNow = Boolean(runDates.from && new Date(runDates.from) < startOfTomorrow());

  const isOriginalEndDateLtTomorrow = Boolean(runDates.to && new Date(runDates.to) < startOfTomorrow());

  return useMemo(
    () =>
      isCampaignInLive
        ? {
            disabled: [isOriginalStartDateInPastOrNow, false] as [boolean, boolean],
            disabledDate: (current: Date) => {
              if (isOriginalEndDateLtTomorrow) {
                return current < startOfToday();
              }

              return current < startOfTomorrow();
            },
          }
        : {},
    [isCampaignInLive, isOriginalEndDateLtTomorrow, isOriginalStartDateInPastOrNow],
  );
};
