import { useRef, useEffect } from 'react';
import get from 'lodash.get';
import { useForm } from 'hooks/use-form';
import { stringify } from 'utils/json';

export const useFormFieldsBinding = ({ fields }: { fields: string[] }): void => {
  const { values, setFieldValue } = useForm();

  const prevValuesRef = useRef<Record<string, any>>(values as any);

  useEffect(() => {
    const prevValues = prevValuesRef.current;
    prevValuesRef.current = values as any;

    const updatedField = fields.find((field) => get(prevValues, field) !== get(values, field));

    if (!updatedField) {
      return;
    }

    const updatedValue = get(values, updatedField);

    fields.forEach((field) => {
      if (stringify(get(values, field)) === stringify(updatedValue)) {
        return;
      }

      setFieldValue(field, updatedValue);
    });

    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [values, setFieldValue]);
};
