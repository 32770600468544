import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import classnames from 'classnames';
import 'antd/es/date-picker/style/index';
import { IconControl } from 'components/common/icon';
import { useField } from 'hooks';
import { withFormItem } from 'hocs/with-form-item';
import { BasePicker } from './base-picker';
import { disabledPastDate, disabledFutureDate, disabledPastDateWithToday } from './helper';
import { DateRangePickerProps } from './types';
import styles from './styles.module.scss';

const { RangePicker: AntdRangePicker } = BasePicker;

export const DateRangePicker = withFormItem<DateRangePickerProps>()(
  ({ isFutureDisabled, isPastDisabled = !isFutureDisabled, disabled, ...props }) => {
    const { field, error, componentProps } = useField(props);
    const { name, onChange, onBlur, value } = field;

    const selectedValueRef = useRef(value);

    useEffect(() => {
      selectedValueRef.current = value;
    }, [value]);

    const defaultDisabledDate = useMemo(() => {
      if (isPastDisabled) {
        return disabledPastDateWithToday;
      }

      if (isFutureDisabled) {
        return disabledFutureDate;
      }

      return undefined;
    }, [isFutureDisabled, isPastDisabled]);

    const { variant } = componentProps;

    const className = classnames(styles.root, {
      [styles.error]: error,
      [styles[`${variant}Variant`]]: variant,
    });

    const dropdownClassName = classnames(styles.dropdown, {
      [styles[`${variant}DropdownVariant`]]: variant,
    });

    const handleChange: DateRangePickerProps['onChange'] = useCallback(
      (value) => {
        selectedValueRef.current = value;

        const isReset = !value;

        if (isReset) {
          onChange({ target: { name, value } });

          setTimeout(() => {
            onBlur({ target: { name } });
          });
        }
      },
      [name, onBlur, onChange],
    );

    const onOpenChange: DateRangePickerProps['onOpenChange'] = useCallback(
      (isChangedToOpen) => {
        if (isChangedToOpen) {
          return;
        }

        onChange({ target: { name, value: selectedValueRef.current } });
      },
      [name, onChange],
    );

    const handleBlur: DateRangePickerProps['onBlur'] = useCallback(() => {
      onBlur({ target: { name } });
    }, [name, onBlur]);

    return (
      <AntdRangePicker
        disabled={disabled}
        style={{ marginTop: 'auto', marginBottom: 'auto', maxWidth: '400px' }}
        className={className}
        dropdownClassName={dropdownClassName}
        {...field}
        format='MM-DD-YYYY'
        onChange={handleChange}
        onOpenChange={onOpenChange}
        onBlur={handleBlur}
        disabledDate={defaultDisabledDate}
        suffixIcon={<IconControl size={25} name='calendar' color='primaryColor' />}
        {...componentProps}
      />
    );
  },
);
