import React, { memo, useMemo, useState, useCallback } from 'react';
import { Form as AntdForm } from 'antd';
import classnames from 'classnames';
import { FormItemContext, FormItemContextValue } from 'contexts';
import { FormItemProps } from './types';
import styles from './styles.module.scss';

const { Item } = AntdForm;

export const FormItem = memo<FormItemProps>((originalProps) => {
  const { className: classNameFromProps = '', ...props } = originalProps;

  const [propsOverride, setPropsOverride] = useState<Partial<FormItemProps> | null>(null);
  const className = classnames(classNameFromProps, styles.root);

  const overrideProps = useCallback<FormItemContextValue['overrideProps']>(
    (propsOverride) => {
      setPropsOverride(propsOverride);
    },
    [setPropsOverride],
  );

  const contentValue = useMemo(() => ({ originalProps, overrideProps }), [originalProps, overrideProps]);

  return (
    <FormItemContext.Provider value={contentValue}>
      <Item className={className} {...props} {...propsOverride} />
    </FormItemContext.Provider>
  );
});
