import { flatFormValues } from 'utils/objects';

export const createFormData = (data: Record<string, any>): FormData => {
  const formData = new FormData();
  const flatData: Record<string, any> = flatFormValues(data);

  Object.keys(flatData).forEach((key) => {
    formData.append(key, flatData[key] ?? null);
  });

  return formData;
};
