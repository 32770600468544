import { useCallback } from 'react';
import { UploadProps } from 'antd';
import { useField, useForm, useTranslation } from 'hooks';
import { errorMessage } from 'components/common/message';
import { DropAreaProps, ImageUploaderValueItem } from 'components/common/form/image-uploader';
import config from 'config';
import { checkFileExtension } from 'utils/files';

const { options } = config;

export const useDropAreaProps = ({
  fileSizeLimit = options.logoUploader.fileSizeLimit,
  value: valueFromProps,
  onChange: onChangeFromProps,
  ...props
}: Omit<DropAreaProps, 'value'> & { value?: ImageUploaderValueItem }): Pick<
  ReturnType<typeof useField>,
  'componentProps'
> &
  Pick<UploadProps, 'beforeUpload'> & {
    value?: ImageUploaderValueItem;
    onRemove: () => void;
  } => {
  const { t } = useTranslation();

  const { setFieldTouched } = useForm();

  const { field, componentProps } = useField({
    value: valueFromProps,
    onChange: onChangeFromProps,
    ...props,
  });

  const { accept } = componentProps;

  const { name, onChange, onBlur, value } = field;

  const beforeUpload: UploadProps['beforeUpload'] = useCallback(
    (file) => {
      if (!checkFileExtension({ file, accept })) {
        errorMessage(
          t('LogoUploader.WrongExtensionError', {
            extensions: accept,
          }),
        );
        return false;
      }

      if (file.size > fileSizeLimit) {
        errorMessage(t('LogoUploader.FileSizeLimitExceededError'));
        return false;
      }

      const event = {
        target: { name, value: file },
      };

      setFieldTouched(name, true);
      onChange(event);

      setTimeout(() => {
        onBlur(event);
      });

      return false;
    },
    [accept, fileSizeLimit, name, setFieldTouched, onChange, t, onBlur],
  );

  const onRemove = useCallback(() => {
    const event = {
      target: {
        name,
        value: undefined,
      },
    };

    setFieldTouched(name, true);
    onChange(event);

    setTimeout(() => {
      onBlur(event);
    });
  }, [name, onChange, onBlur, setFieldTouched]);

  return {
    value,
    componentProps,
    beforeUpload,
    onRemove,
  };
};
