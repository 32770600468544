import React, { memo, useMemo } from 'react';
import { useToggle } from 'react-use';
import { Confirmation } from 'components/common/confirmation';
import { Icon } from './icon';
import { IconButtonProps } from './types';

export const IconButton = memo<IconButtonProps>(({ confirmation, onClick: onClickFromProps, ...props }) => {
  const [open, toggleOpen] = useToggle(false);

  const onClick = useMemo(
    () => (confirmation ? () => toggleOpen(true) : onClickFromProps),
    [toggleOpen, confirmation, onClickFromProps],
  );

  return (
    <>
      <Icon cursor='pointer' onClick={onClick} {...props} />
      {confirmation ? (
        <Confirmation visible={open} onConfirm={onClickFromProps as any} onCancel={toggleOpen} {...confirmation} />
      ) : null}
    </>
  );
});
