import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';
import { MSTAddStaticMethods } from 'stores/utils';
import descriptionOption from '../ads/description-option';
import titleOption from '../ads/title-option';
import keywordGroup from '../keyword-group';
import OptionWithOldMarker from '../option-with-old-marker';

const staticMethods = {};

const AdGroupOverview = BaseModel.named('AdGroupOverview').props({
  titles: types.optional(types.array(OptionWithOldMarker), []),
  descriptions: types.optional(types.array(OptionWithOldMarker), []),
  keywords: types.array(keywordGroup),
});

export type AdGroupOverviewOverviewInstanceType = Instance<typeof AdGroupOverview>;
export type AdGroupOverviewSnapshot = SnapshotOut<typeof AdGroupOverview>;

export default MSTAddStaticMethods(AdGroupOverview, staticMethods);
