import React, { memo, useMemo, useState } from 'react';
import classnames from 'classnames';
import { PreloaderContext } from 'contexts';
import { Box } from 'components/common/box';
import { Spinner } from 'components/common/spinner';
import { PreloaderProps } from './types';
import styles from './styles.module.scss';

export const Preloader = memo<PreloaderProps>(
  ({ className: classNameFromProps = '', children, pending, fixed, ...props }) => {
    const [isPreloaderActive, setIsPreloaderActive] = useState(pending ?? false);

    const className = classnames(
      styles.root,
      {
        [styles.pending]: pending ?? isPreloaderActive,
      },
      classNameFromProps,
    );

    const contextValue = useMemo(
      () => ({
        setIsPreloaderActive,
      }),
      [setIsPreloaderActive],
    );

    return (
      <PreloaderContext.Provider value={contextValue}>
        <Box className={className} {...props}>
          {children}
          <Box className={styles.overlay}>
            <Box className={styles.preloader} position={fixed ? 'fixed' : 'absolute'}>
              <Spinner size={92} />
            </Box>
          </Box>
        </Box>
      </PreloaderContext.Provider>
    );
  },
);
