const promisify = (fn: any): Promise<any> =>
  new Promise((resolve, reject) => {
    try {
      const value = fn.call(null);
      resolve(value);
    } catch (e) {
      reject(e);
    }
  });

const localStorageSupported = 'localStorage' in window;

export async function getItem(key: string): Promise<string | null> {
  return promisify(() => (localStorageSupported ? window.localStorage.getItem(key) : null));
}

export async function setItem(key: string, value: string): Promise<boolean> {
  try {
    if (!localStorageSupported) {
      return true;
    }

    await promisify(() => window.localStorage.setItem(key, value));
    return true;
  } catch {
    return false;
  }
}

export async function removeItem(key: string): Promise<void> {
  try {
    if (!localStorageSupported) {
      return;
    }

    await promisify(() => window.localStorage.removeItem(key));
  } catch (e) {
    console.log(e);
  }
}

export async function clear(): Promise<void> {
  try {
    if (!localStorageSupported) {
      return;
    }

    await promisify(() => window.localStorage.clear());
  } catch (e) {
    console.log(e);
  }
}

export async function load(key: string): Promise<any | null> {
  try {
    if (!localStorageSupported) {
      return null;
    }

    const value = await promisify(() => window.localStorage.getItem(key));
    return JSON.parse(value);
  } catch {
    return null;
  }
}

export async function save(key: string, value: unknown): Promise<boolean> {
  try {
    if (!localStorageSupported) {
      return true;
    }

    await promisify(() => window.localStorage.setItem(key, JSON.stringify(value)));
    return true;
  } catch {
    return false;
  }
}

const storage = {
  getItem,
  setItem,
  removeItem,
  clear,
  load,
  save,
};

export { storage };
