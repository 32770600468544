import React, { memo, useCallback } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link } from './link';
import { RouterLinkProps, LinkProps } from './types';

function isModifiedEvent(event: any) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export const InnerLink = memo<LinkProps & { navigate?: any }>(({ navigate, onClick: onClickFromProps, ...props }) => {
  const { target } = props;

  const onClick: LinkProps['onClick'] = useCallback(
    (event) => {
      try {
        if (onClickFromProps) {
          onClickFromProps(event);
        }
      } catch (ex) {
        event.preventDefault();
        throw ex;
      }

      if (
        !event.defaultPrevented && // onClick prevented default
        event.button === 0 && // ignore everything but left clicks
        (!target || target === '_self') && // let browser handle "target=_blank" etc.
        !isModifiedEvent(event) // ignore clicks with modifier keys
      ) {
        event.preventDefault();
        navigate();
      }
    },
    [navigate, onClickFromProps, target],
  );

  return <Link onClick={onClick} {...props} />;
});

export const RouterLink = memo<RouterLinkProps>((props) => (
  <ReactRouterLink component={InnerLink} target={undefined} {...props} />
));
