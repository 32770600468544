import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { CampaignOverviewApi } from 'types';
import BaseModel from 'stores/models/base';
import { MSTAddStaticMethods } from 'stores/utils';
import { nullable } from 'stores/mst-types';
import { deserializeDateRange, deserializeStatus, deserializeType } from 'utils/deserializers';
import Campaign from './campaign';

const staticMethods = {
  fromResponseData: (data: CampaignOverviewApi): CampaignOverviewSnapshot => ({
    ...pick(data, ['agent']),
    id: String(data.id),
    status: deserializeStatus(data.status),
    type: deserializeType(data.kind),
    name: data.name,
    propertyName: data.property_name,
    landingPageUrl: data.landing_page_url,
    runDates: deserializeDateRange(data.run_dates) as CampaignOverviewSnapshot['runDates'],
    isAffected: data.entity_updated,
    isEntityLocationChanged: data.is_entity_location_changed,
  }),
};
const propsFromCampaign = pick(Campaign.properties, ['id', 'name', 'status', 'type', 'runDates']);

const CampaignOverview = BaseModel.named('CampaignOverview').props({
  ...propsFromCampaign,
  agent: nullable(types.string),
  landingPageUrl: nullable(types.string),
  propertyName: nullable(types.string),
  isAffected: nullable(types.boolean),
  isEntityLocationChanged: nullable(types.boolean),
});

export type CampaignOverviewInstanceType = Instance<typeof CampaignOverview>;
export type CampaignOverviewSnapshot = SnapshotOut<typeof CampaignOverview>;

export default MSTAddStaticMethods(CampaignOverview, staticMethods);
