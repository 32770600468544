import { useForm } from 'hooks';
import get from 'lodash.get';
import { useCallback, useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { ImageSaved } from 'types';
import { ImagesPickerModal } from '../images-picker-modal';
import { ImagesPickerProps } from '../types';

interface ModalPicker {
  toggleOpen: () => void;
  modal?: React.ReactNode;
}

export const useModalPicker = (
  name: string,
  onChange: (e: any) => void,
  onBlur: (e: any) => void,
  imageOptions?: ImageSaved[],
  max?: ImagesPickerProps['max'],
  approvedImageOptions?: ImageSaved[],
): ModalPicker => {
  const [open, toggleOpen] = useToggle(false);

  const { values } = useForm();

  const value = get(values, name);

  const handleChange = useCallback(
    (value: ImagesPickerProps['value']) => {
      const event = { target: { name, value } };

      onChange(event);
      onBlur(event);
    },
    [name, onBlur, onChange],
  );
  const [filteredImageOptions, setFilteredImageOptions]: any = useState([]);

  useEffect(() => {
    const filterImages = async (imageOptions: any) => {
      const loadAndCheckImage = (imageSrc: string) =>
        new Promise((resolve) => {
          const image = new Image();
          image.src = imageSrc;
          image.onload = () => {
            const { height, width } = image;
            const ratioWidth = ((width / height) * 100) / 100;
            const ratioWidthResult = Number.isInteger(ratioWidth) ? ratioWidth : ratioWidth.toFixed(2);
            const ratioHeight = Math.ceil(((height / width) * 100) / 100);
            resolve(`${ratioWidthResult}:${ratioHeight}` === '1:1');
          };
          image.onerror = () => resolve(false);
        });

      const results = await Promise.all(
        imageOptions.map((option: any) =>
          loadAndCheckImage(option.src).then((isMatch: any) => (isMatch ? option : null)),
        ),
      );

      return results.filter((result: any) => result !== null);
    };

    const filterAndSetImages = async () => {
      const filtered: any = await filterImages(value);
      setFilteredImageOptions(filtered);
    };

    filterAndSetImages();
  }, [value]);

  const modal = imageOptions?.length ? (
    <ImagesPickerModal
      approvedImageOptions={approvedImageOptions}
      visible={open}
      onCancel={toggleOpen}
      value={name === 'facebookAd.images' || name === 'instagramAd.images' ? filteredImageOptions : value}
      options={imageOptions}
      onSubmit={handleChange}
      max={max}
    />
  ) : null;

  return { toggleOpen, modal };
};
