import get from 'lodash.get';
import { FormValidation } from 'types';
import { keywordGroups } from 'utils/validations';
import { KEYWORDS_FIELD } from './constants';

export const validationSchema: FormValidation = ({ values }) => {
  const optionKeywords = get(values, KEYWORDS_FIELD);
  return {
    [KEYWORDS_FIELD]: keywordGroups(optionKeywords),
  };
};
