import { datatype, random } from 'faker';
import { v4 as uuidv4 } from 'uuid';
import { BarChartItemApi, CampaignApi, PieChartItemApi, TagSelectOptionApi } from 'types';

const STORAGE_DATABASE_KEY = 'local-database';

export interface CampaignReport {
  id: string;
  campaign_id: CampaignApi['id'];
}

const createBarItems = ({
  campaignId,
  labels,
  types,
}: {
  campaignId: string;
  labels: string[];
  types: string[];
}): (BarChartItemApi & CampaignReport)[] =>
  labels.map((label) => ({
    id: uuidv4(),
    campaign_id: campaignId,
    label,
    items: types.map((type) => ({
      type,
      value: datatype.number({ min: 0, max: 1000 }),
    })),
  }));

const createPieItems = ({
  labels,
  campaignId,
}: {
  campaignId: string;
  labels: string[];
}): (PieChartItemApi & CampaignReport)[] =>
  labels.map((label) => ({
    id: uuidv4(),
    campaign_id: campaignId,
    label,
    value: datatype.number({ min: 0, max: 1000 }),
  }));

const createAmenityOptions = ({ length = 40 }: { length?: number } = {} as { length?: number }): TagSelectOptionApi[] =>
  Array.from({ length }, (_, index) => {
    const isCustom = Boolean(index && index % 11 === 0);
    const isPopular = Boolean(index && index % 5 === 0);

    const rawLabel = random.word();
    const label = [rawLabel, index + 1, isCustom ? 'custom' : isPopular ? 'popular' : ''].filter(Boolean).join(' ');

    return {
      id: uuidv4(),
      label,
      is_custom: isCustom,
      is_popular: isPopular,
      value: uuidv4(),
    };
  });

export { createBarItems, createPieItems, STORAGE_DATABASE_KEY, createAmenityOptions };
