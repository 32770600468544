import { Instance, types } from 'mobx-state-tree';

const CampaignType = types.model({
  id: types.string,
  name: types.string,
});

export type CampaignTypeInstanceType = Instance<typeof CampaignType>;

export default CampaignType;
