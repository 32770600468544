import { AxiosRequestConfig, AxiosError, AxiosInstance } from 'axios';
import { RootStoreInstance } from 'stores/root-store';
import { ContainerInterface } from 'services/types';
import { Deferred } from 'utils/deferred';
import { isThirdPartyRequestUrl, isUnaunthenticateUrl } from '../api-urls';

export class AuthMiddleware {
  container: ContainerInterface;

  parallelRefreshTokenRequestsResolver: Deferred | null = null;

  constructor(container: ContainerInterface) {
    this.container = container;
  }

  async onRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const url = config.url ?? '';

    // Ignore those non-secured urls
    if (isUnaunthenticateUrl(url)) {
      return config;
    }

    const headerObjects = { ...config.headers };

    const rootStore = this.container.get<RootStoreInstance>('rootStore');

    const accessToken = rootStore.session.tokens?.accessToken;

    if (accessToken) {
      headerObjects.Authorization = `Bearer ${accessToken}`;
    }

    return {
      ...config,
      headers: headerObjects,
    };
  }

  async onResponseError(error: AxiosError): Promise<any> {
    const status = error?.response?.status;
    const config = error?.config;
    const url = config?.url ?? '';

    const rootStore = this.container.get<RootStoreInstance>('rootStore');
    const api = this.container.get<AxiosInstance>('api');

    const { session } = rootStore;

    const refreshToken = session.tokens?.refreshToken;

    if (!refreshToken || status !== 401 || isThirdPartyRequestUrl(url) || isUnaunthenticateUrl(url)) {
      throw error;
    }

    if ((error.config as any)?.retry) {
      await session.signOut(true);
      throw error;
    }

    try {
      if (!this.parallelRefreshTokenRequestsResolver) {
        this.parallelRefreshTokenRequestsResolver = new Deferred();
        await session.refreshToken({ refreshToken });
        if (this.parallelRefreshTokenRequestsResolver) {
          this.parallelRefreshTokenRequestsResolver.resolve(true);
        }
      } else {
        const isTokenRefreshed = await this.parallelRefreshTokenRequestsResolver.promise;

        if (!isTokenRefreshed) {
          return undefined;
        }
      }
    } catch (err) {
      if (this.parallelRefreshTokenRequestsResolver) {
        this.parallelRefreshTokenRequestsResolver.resolve(false);
        await session.signOut(true);
      }
      return undefined;
    } finally {
      this.parallelRefreshTokenRequestsResolver = null;
    }

    const retryRequest = {
      ...error.config,
      retry: true,
    };

    return (api as any).client(retryRequest);
  }
}
