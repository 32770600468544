import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import { Box } from 'components/common/box';
import { Checkbox } from 'components/common/form/checkbox';
import { Typography } from 'components/common/typography';
import { withFormItem } from 'hocs/with-form-item';
import { useForm } from 'hooks';
import { Button } from 'antd';
import get from 'lodash.get';
import { CheckboxesProps } from './types';
import styles from './styles.module.scss';
import Sync from './sync.svg';

export const Checkboxes = withFormItem<CheckboxesProps>()(
  ({
    className: classNameFromProps,
    options,
    name,
    disabled,
    campaignStatus,
    campaignId,
    disabledSync,
    backSyncGoogleSearchAction,
    ...props
  }) => {
    const className = classnames(styles.root, classNameFromProps);

    const { setFieldTouched, values } = useForm();

    const activeGoogle: any = get(values, 'googleAdWordsAd');

    const onChange = useCallback(
      (e) => {
        if (!name) {
          return;
        }
        setTimeout(() => {
          setFieldTouched(name, true);
        });
      },
      [name, setFieldTouched],
    );

    const items = useMemo(
      () =>
        options?.map(({ label, disabled: itemDisabled, ...optionsItem }, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Checkbox
              key={index}
              size={28}
              onChange={onChange}
              composeOnChange
              disabled={disabled || itemDisabled}
              {...optionsItem}
            >
              <Typography variant='body-13' color='black3' style={{ marginTop: '3px' }}>
                {label}
              </Typography>
            </Checkbox>
            {label === 'Google search' && campaignStatus && campaignId && activeGoogle?.isActive ? (
              <Button
                disabled={disabledSync}
                onClick={backSyncGoogleSearchAction}
                style={{
                  color: '#0f0ff4',
                  borderColor: '#f4f4ff',
                  backgroundColor: '#f4f4ff',
                  borderRadius: '5px',
                  fontWeight: 'bold',
                }}
              >
                <img id='sync-img' alt='sync' style={{ marginRight: '10px' }} src={Sync} />
                Google Sync
              </Button>
            ) : null}
          </div>
        )),
      [
        disabled,
        onChange,
        options,
        backSyncGoogleSearchAction,
        campaignId,
        campaignStatus,
        disabledSync,
        activeGoogle?.isActive,
      ],
    );

    return (
      <Box className={className} display='flex' flexDirection='column' gridGap={16} {...props}>
        {items}
      </Box>
    );
  },
);
