import { cast, toGenerator, Instance, SnapshotOut, types } from 'mobx-state-tree';
import Base from 'stores/models/base';
import { apiFlow } from 'stores/mst-types';
import { fromItemResponse } from 'services/api';
import { MSTAddStaticMethods } from 'stores/utils';
import { AdSocialNetworksSettingsApi } from 'types';
import AdSocialNetworkSettings from './ad-social-network-settings';

const staticMethods = {
  fromResponseData: (data: AdSocialNetworksSettingsApi): AdSocialNetworksSettingsSnapshot => ({
    facebook: data.facebook,
    instagram: data.instagram,
    googleAdwords: data.google_adword,
    googleAds: data.google_ads,
  }),
};
const AdSocialNetworksSettings = Base.named('AdSocialNetworksSettings')
  .props({
    facebook: types.optional(AdSocialNetworkSettings, {}),
    instagram: types.optional(AdSocialNetworkSettings, {}),
    googleAdwords: types.optional(AdSocialNetworkSettings, {}),
    googleAds: types.optional(AdSocialNetworkSettings, {}),
  })
  .actions((self) => ({
    fetch: apiFlow(function* fetch() {
      const response = yield* toGenerator(self.api.getAdSocialNetworksSettings());
      const { data } = fromItemResponse({
        response: response.data,
      });

      const { facebook, instagram, googleAds, googleAdwords } = staticMethods.fromResponseData(data);

      self.facebook = cast(facebook);
      self.instagram = cast(instagram);
      self.googleAdwords = cast(googleAds);
      self.googleAds = cast(googleAdwords);
    }),

    destroy() {
      self.facebook = cast({});
      self.instagram = cast({});
      self.googleAdwords = cast({});
      self.googleAds = cast({});
    },
  }));

export type AdSocialNetworksSettingsInstanceType = Instance<typeof AdSocialNetworksSettings>;
export type AdSocialNetworksSettingsSnapshot = SnapshotOut<typeof AdSocialNetworksSettings>;

export default MSTAddStaticMethods(AdSocialNetworksSettings, staticMethods);
