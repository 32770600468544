import { CampaignReportsRequestOptionsApi } from 'types';
import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import { GetCampaignReportsResponse, GetReportsOverviewResponse } from '../response-types';

export const createReportsApi = (http: BaseHttpClient) => ({
  getCampaignReports({ campaign_id, filters }: CampaignReportsRequestOptionsApi) {
    return http.get<GetCampaignReportsResponse>(
      urls.reports.getCampaignReports.url.replace('{id}', campaign_id),
      filters,
    );
  },

  getReportsOverview() {
    return http.get<GetReportsOverviewResponse>(urls.reports.getReportsOverview.url);
  },
});
