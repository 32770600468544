import React, { memo } from 'react';
import { NumberInput } from 'components/common/form/number-input';
import { ZipFieldProps } from './types';

const numberFormatProps: ZipFieldProps['numberFormatProps'] = {
  thousandSeparator: false,
  decimalScale: 0,
  isNumericString: true,
  allowLeadingZeros: true,
  isAllowed: ({ value }: { value: string }) => (typeof value === 'string' ? value.length <= 5 : true),
};

export const ZipField = memo<ZipFieldProps>((props) => (
  <NumberInput valueAsNumber={false} numberFormatProps={numberFormatProps} {...props} />
));
