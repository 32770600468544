import { useCallback } from 'react';
import classnames from 'classnames';
import { useField } from 'hooks';
import styles from '../styles.module.scss';
import { InputProps } from '../types';

export const useInputProps = ({ className: classNameFromProps = '', ...props }: InputProps): InputProps => {
  const { field, error, isTouched, componentProps } = useField(props);
  const { onBlur: rawOnBlur } = field;

  const className = classnames(styles.root, {
    [styles.error]: error,
    [styles.touched]: isTouched,
  });

  const onBlur = useCallback(
    (event) => {
      setTimeout(() => {
        rawOnBlur(event);
      });
    },
    [rawOnBlur],
  );

  return {
    className,
    ...field,
    onBlur,
    ...componentProps,
  };
};
