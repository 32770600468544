import { types, cast, toGenerator } from 'mobx-state-tree';
import Base from 'stores/models/base';
import { apiFlow } from 'stores/mst-types';
import { fromListResponse } from 'services/api';
import { Pagination } from 'types';

const Collections = Base.named('Collections').actions((self) => ({
  fetch: apiFlow(function* fetch(name?: string) {
    const response = yield* toGenerator(self.api.createCollectionType(name));
    const { data } = fromListResponse({
      response: response.data,
    });
    return data;
  }),
}));

export default Collections;
