import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CampaignInstanceType } from 'stores/models/campaigns/campaign';
import { Checkboxes } from 'components/common/form/checkboxes';
import { useCampaignFormContext } from 'components/campaigns/campaign-form/hooks';
import { useStores, useTranslation } from 'hooks';
import { Alert } from 'antd';
import {
  FACEBOOK_IS_ACTIVE_FIELD,
  INSTAGRAM_IS_ACTIVE_FIELD,
  GOOGLE_ADS_IS_ACTIVE_FIELD,
  GOOGLE_ADWORDS_IS_ACTIVE_FIELD,
} from './constants';
import { useFacebookAndInstagramRelation } from './hooks';
import { AdSocialNetworksSelectorProps } from './types';
import './index.css';

export const AdSocialNetworksSelector = observer<AdSocialNetworksSelectorProps>((props) => {
  const { t } = useTranslation();

  const {
    campaignManager: { socialNetworks, socialNetworksAvailability, campaign, isGoogleAccountConnected },
  } = useCampaignFormContext();
  const { settings } = socialNetworks;

  const { isFacebookSelected } = useFacebookAndInstagramRelation();
  const [disabledSync, setDisabledSync] = useState(false);

  const backSyncGoogleSearchAction = async () => {
    setDisabledSync(true);
    const campaignId: any = campaignInfo?.id;
    const imgSync = document.getElementById('sync-img');
    imgSync?.classList.add('rotating');
    await backSyncGoogleSearch({ id: campaignId });
    await loadCampaign({ id: campaignId });
    imgSync?.classList.remove('rotating');
    const alert = document.getElementById('sync-alert');
    alert?.classList.add('show');
    setDisabledSync(false);
    setTimeout(() => {
      alert?.classList.remove('show');
    }, 4000);
  };

  const options = useMemo(
    () =>
      [
        {
          label: t('Common.AdSocialNetworkOptions.Facebook'),
          name: FACEBOOK_IS_ACTIVE_FIELD,
          disabled: false,
          available: socialNetworksAvailability.facebook,
        },
        {
          label: t('Common.AdSocialNetworkOptions.Instagram'),
          name: INSTAGRAM_IS_ACTIVE_FIELD,
          disabled: !isFacebookSelected,
          available: socialNetworksAvailability.instagram,
        },
        {
          label: t('Common.AdSocialNetworkOptions.GoogleAdWords'),
          name: GOOGLE_ADWORDS_IS_ACTIVE_FIELD,
          disabled: false,
          available: socialNetworksAvailability.googleAdwords,
        },
        {
          label: t('Common.AdSocialNetworkOptions.GoogleAds'),
          name: GOOGLE_ADS_IS_ACTIVE_FIELD,
          disabled: false,
          available: socialNetworksAvailability.googleAds,
        },
      ].filter(({ available }) => available),
    [
      t,
      socialNetworksAvailability.facebook,
      socialNetworksAvailability.instagram,
      socialNetworksAvailability.googleAdwords,
      socialNetworksAvailability.googleAds,
      isFacebookSelected,
    ],
  );

  const { buildingCampaign, googleSearchAd } = useStores();
  const { campaignManager } = buildingCampaign;

  const { backSyncGoogleSearch } = googleSearchAd;

  const { loadCampaign, campaign: campaignInfo } = campaignManager;

  const campaignId: any = campaignInfo?.id;
  const campaignStatus: any = campaignInfo?.status.id !== '9' && campaignInfo?.status.id !== '4';

  return options.length ? (
    <>
      <div style={{ display: 'flex', margin: 'auto', fontSize: '20px' }} id='sync-alert'>
        {' '}
        <Alert
          style={{
            position: 'fixed',
            top: '100px',
            zIndex: 2,
            borderRadius: '5px',
          }}
          message='Success'
          description='Google Search budget and Ad Groups are synchronized.'
          type='success'
          showIcon
        />
      </div>
      <Checkboxes
        disabledSync={disabledSync}
        campaignId={campaignId}
        campaignStatus={campaignStatus}
        backSyncGoogleSearchAction={backSyncGoogleSearchAction}
        label={t('CampaignForm.Info.Labels.SocialNetworks')}
        options={options}
        disabled={(campaign as CampaignInstanceType)?.isPosted}
        {...props}
      />
    </>
  ) : null;
});
