import React from 'react';
import { Input } from 'antd';
import { withFormItem } from 'hocs/with-form-item';
import { useInputProps } from './hooks';
import { TextFieldProps } from './types';

export const TextField = withFormItem<TextFieldProps>()((props) => {
  const inputProps = useInputProps(props);

  return <Input {...inputProps} />;
});
