import React, { memo, useMemo } from 'react';
import { FieldsLayout, Form } from 'components/common/form';
import { Typography } from 'components/common/typography';
import { Icon } from 'components/common/icon';
import { GridLayout } from 'components/common/grid-layout';
import { AuthFormProps } from './types';

export const AuthForm = memo<AuthFormProps>(
  ({ title, titleIcon, description, alternativeAction, submitText, children, ...props }) => {
    const footer = useMemo(
      () =>
        submitText
          ? {
              submitText,
              submitButtonProps: { block: true },
            }
          : undefined,
      [submitText],
    );

    return (
      <>
        <Form name='auth-form' footer={footer} {...props}>
          {title ? (
            <Typography variant='headline-6' display='flex' marginBottom={10}>
              {titleIcon ? <Icon size={24} marginRight={12} display='flex' alignSelf='center' {...titleIcon} /> : null}
              {title}
            </Typography>
          ) : null}
          <GridLayout columns={1} gridRowGap={30}>
            {description ? (
              <Typography variant='body-13' color='grey5'>
                {description}
              </Typography>
            ) : null}
            {children ? <FieldsLayout>{children}</FieldsLayout> : null}
          </GridLayout>
        </Form>
        {alternativeAction ? (
          <Typography variant='body-13' paddingTop={20} textAlign='center'>
            {alternativeAction}
          </Typography>
        ) : null}
      </>
    );
  },
);
