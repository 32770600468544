import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { PasswordField } from 'components/common/form';
import { AuthForm } from 'components/auth/auth-form';
import { useTranslation, useStores, usePreloader, useParams } from 'hooks';
import { RestorePasswordFormValues } from 'stores/session-store';
import { validationSchema } from './validation-schemas';

export const RestorePassword = observer(() => {
  const { t } = useTranslation();

  const { session } = useStores();
  const { restorePassword } = session;

  const { uid, token } = useParams<{ uid: string; token: string }>();

  usePreloader({ pending: false });

  const onSubmit = useCallback(
    async (values: Pick<RestorePasswordFormValues, 'password' | 'confirmPassword'>) => {
      await restorePassword({ uid, token, ...values });
    },
    [restorePassword, token, uid],
  );

  return (
    <AuthForm
      title={t('Auth.RestorePassword.Title')}
      description={t('Auth.RestorePassword.Description')}
      submitText={t('Auth.RestorePassword.SubmitButton')}
      onSubmit={onSubmit}
      validation={validationSchema}
      name='restore-password'
    >
      <PasswordField
        name='password'
        label={t('Auth.RestorePassword.Labels.Password')}
        placeholder={t('Auth.RestorePassword.Placeholders.Password')}
      />
      <PasswordField
        name='confirmPassword'
        label={t('Auth.RestorePassword.Labels.ConfirmPassword')}
        placeholder={t('Auth.RestorePassword.Placeholders.ConfirmPassword')}
      />
    </AuthForm>
  );
});
