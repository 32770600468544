import React, { memo, useMemo } from 'react';
import RcImage from 'rc-image';
import { Spinner } from 'components/common/spinner';
import { Box } from 'components/common/box';
import themeColors from 'utils/theme-colors';
import { excludeEmpty } from 'utils/objects';
import { ImageProps } from './types';

const defaultImageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
} as const;

const defaultWrapperStyle = {
  background: themeColors.transparent,
  width: '100%',
  height: '100%',
};

export const Image = memo<ImageProps>(
  ({
    style: styleFromProps,
    wrapperStyle: wrapperStyleFromProps,
    src,
    wrapperClassName,
    prefixCls,
    previewPrefixCls,
    placeholder = <Spinner />,
    fallback = '',
    preview = false,
    onPreviewClose,
    onClick,
    onError,
    alt,
    ...props
  }) => {
    const wrapperStyle = useMemo(
      () => ({
        ...defaultWrapperStyle,
        ...wrapperStyleFromProps,
      }),
      [wrapperStyleFromProps],
    );

    const style = useMemo(
      () => ({
        ...defaultImageStyle,
        ...styleFromProps,
      }),
      [styleFromProps],
    );

    const imageProps = useMemo(
      () =>
        excludeEmpty({
          src,
          wrapperClassName,
          prefixCls,
          previewPrefixCls,
          placeholder,
          fallback,
          preview,
          onPreviewClose,
          onClick,
          onError,
          alt,
        }),
      [
        src,
        wrapperClassName,
        prefixCls,
        previewPrefixCls,
        placeholder,
        fallback,
        preview,
        onPreviewClose,
        onClick,
        onError,
        alt,
      ],
    );

    return (
      <Box background={themeColors.white1} {...props}>
        <RcImage wrapperStyle={wrapperStyle} style={style} {...imageProps} />
      </Box>
    );
  },
);
