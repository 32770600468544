const toJSONDeep = <T>(model: T): T | null => {
  try {
    return JSON.parse(JSON.stringify(model));
  } catch (error) {
    console.log(error);
    return null;
  }
};

export { toJSONDeep };
