import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import compare from 'compare-versions';
import config from 'config';
import BaseModel from 'stores/models/base';
import { apiFlow } from 'stores/mst-types';

const {
  version: { packageVersion },
} = config;

export const VersionStore = BaseModel.named('VersionStore')
  .props({
    apiVersion: types.optional(types.string, ''),
    latestWebAppVersion: types.optional(types.string, ''),
    forceWebAppUpdate: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get needsRefresh() {
      if (!self.latestWebAppVersion || !self.forceWebAppUpdate || !packageVersion) {
        return false;
      }

      return Boolean(compare.compare(self.latestWebAppVersion, packageVersion, '>'));
    },
  }))
  .actions(() => ({
    getVersion: apiFlow(function* getVersion() {
      try {
        yield Promise.resolve('todo');
      } catch (e) {
        console.log('Most recent update unavailable');
        throw e;
      }
    }),
  }));

export interface VersionStoreInstance extends Instance<typeof VersionStore> {}
export interface VersionStoreSnapshot extends SnapshotOut<typeof VersionStore> {}
