import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';
import { nullable } from 'stores/mst-types';

const BarItem = BaseModel.named('BarItem').props({
  label: types.string,
  value: types.number,
  type: nullable(types.string),
});

export type BarItemInstanceType = Instance<typeof BarItem>;
export type BarItemSnapshot = SnapshotOut<typeof BarItem>;

export default BarItem;
