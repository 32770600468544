import React, { ChangeEvent, memo, useCallback } from 'react';
import get from 'lodash.get';
import { useTranslation, useForm, useStores } from 'hooks';
import { mergeDeep } from 'utils/objects';
import { Box } from 'components/common/box';
import { Icon } from 'components/common/icon';
import { Select } from 'components/common/form/select';
import { PriceField } from 'components/common/form/price-field';
import { FieldsLayout } from 'components/common/form/fields-layout';
import { TFunction } from 'types';
import { Typography } from 'components/common/typography';
import { Button } from 'components/common/button';
import { ApartmentPricesRowProps } from './types';

export const ApartmentPricesRow = memo<ApartmentPricesRowProps>(
  ({ options, rootFieldName, rowIndex, showRemoveButton, yardiStatus }) => {
    const { t } = useTranslation();

    const namePrefix = `${rootFieldName}[${0}]`;
    const selectFieldName = `${namePrefix}.type`;
    const minPriceFieldName = `${namePrefix}.apartmentPricesMin`;
    const maxPriceFieldName = `${namePrefix}.apartmentPricesMax`;

    const { values, setFieldValue } = useForm();

    const rowValue = get(values, namePrefix);
    const isPriceDisabled = !rowValue?.type;

    const isFirstRow = rowIndex === 0;

    const getLabel = useCallback(
      (...args: Parameters<TFunction>): ReturnType<TFunction> | undefined => (isFirstRow ? t(...args) : undefined),
      [isFirstRow, t],
    );

    const { building } = useStores();
    const { syncWithYardi } = building;

    const stateFromFormValues = get(values, 'yardiPropertyId');

    const onTargetKindChange = useCallback((): void => {
      (async () => {
        const result: any = await syncWithYardi(stateFromFormValues);

        setFieldValue(minPriceFieldName, result.data.min_price || null);
        setFieldValue(maxPriceFieldName, result.data.max_price || null);
      })();
    }, [stateFromFormValues, syncWithYardi, maxPriceFieldName, minPriceFieldName, setFieldValue]);

    return (
      <Box display='flex' alignItems='flex-end' position='relative'>
        <FieldsLayout columns={yardiStatus ? 3 : 2} flex={1}>
          {yardiStatus && (
            <Box display='flex' flexDirection='column' justifyContent='space-between'>
              <Typography variant='body-4' color='grey5' display='inline-block' cursor='pointer'>
                <Box is='span' verticalAlign='middle'>
                  {t('ApartmentParametersField.Labels.ApartmentPrices')}
                </Box>
              </Typography>
              <Button
                disabled={stateFromFormValues === undefined || stateFromFormValues === ''}
                style={{ height: '54px' }}
                type='primary'
                onClick={onTargetKindChange}
              >
                {t('BuildingForm.SyncWithYardi')}
              </Button>
            </Box>
          )}
          <PriceField
            name={minPriceFieldName}
            label={getLabel('ApartmentParametersField.Labels.MinPrice')}
            placeholder={t('ApartmentParametersField.Placeholders.MinPrice')}
          />
          <PriceField
            name={maxPriceFieldName}
            label={getLabel('ApartmentParametersField.Labels.MaxPrice')}
            placeholder={t('ApartmentParametersField.Placeholders.MaxPrice')}
          />
        </FieldsLayout>
      </Box>
    );
  },
);
