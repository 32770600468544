import { Instance, types, toGenerator, SnapshotOut } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { ReportsOverviewApi } from 'types';
import BaseModel from 'stores/models/base';
import { MSTAddStaticMethods } from 'stores/utils';
import { apiFlow } from 'stores/mst-types';
import { fromItemResponse } from 'services/api';

const staticMethods = {
  fromResponseData: (data: ReportsOverviewApi): ReportsOverviewSnapshot => ({
    ...pick(data, ['impressions', 'clicks', 'leads']),
    impressionsPerDay: data.impressions_per_day,
    clicksPerDay: data.clicks_per_day,
    clicksThroughRate: data.clicks_through_rate,
    costPerClick: data.cost_per_click,
  }),
};

const ReportsOverview = BaseModel.named('ReportsOverview')
  .props({
    impressions: types.optional(types.number, 0),
    impressionsPerDay: types.optional(types.number, 0),
    clicks: types.optional(types.number, 0),
    clicksPerDay: types.optional(types.number, 0),
    clicksThroughRate: types.optional(types.number, 0),
    costPerClick: types.optional(types.number, 0),
    leads: types.optional(types.number, 0),
  })
  .actions((self) => ({
    fetch: apiFlow(function* fetch() {
      const response = yield* toGenerator(self.api.getReportsOverview());

      const { data } = fromItemResponse({
        response: response.data,
      });

      const { impressions, impressionsPerDay, clicks, clicksPerDay, clicksThroughRate, costPerClick, leads } =
        staticMethods.fromResponseData(data);

      self.impressions = impressions;
      self.impressionsPerDay = impressionsPerDay;
      self.clicks = clicks;
      self.clicksPerDay = clicksPerDay;
      self.clicksThroughRate = clicksThroughRate;
      self.costPerClick = costPerClick;
      self.leads = leads;
    }),

    destroy() {
      self.impressions = 0;
      self.impressionsPerDay = 0;
      self.clicks = 0;
      self.clicksPerDay = 0;
      self.clicksThroughRate = 0;
      self.costPerClick = 0;
      self.leads = 0;
    },
  }));

export type ReportsOverviewInstanceType = Instance<typeof ReportsOverview>;
export type ReportsOverviewSnapshot = SnapshotOut<typeof ReportsOverview>;

export default MSTAddStaticMethods(ReportsOverview, staticMethods);
