import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import Option from 'stores/models/option';

const FacebookCustomAudienceOption = Option.named('FacebookCustomAudienceOption').props({
  pixelId: types.string,
});

export type FacebookCustomAudienceOptionSnapshot = SnapshotOut<typeof FacebookCustomAudienceOption>;
export type FacebookCustomAudienceOptionInstanceType = Instance<typeof FacebookCustomAudienceOption>;

export default FacebookCustomAudienceOption;
