import { createContext } from 'react';
import { ModalProps } from 'components/common/modal/types';

interface ModalContextValue {
  props: Partial<ModalProps>;
  overrideDefaultProps: (props: Partial<ModalProps>) => void;
  close?: () => void;
}

export const ModalContext = createContext<ModalContextValue | null>(null);
