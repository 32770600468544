import {
  Filtration,
  OrganizationApi,
  OrganizationUserOverviewApi,
  PatchOrganizationApi,
  PostOrganizationApi,
  PostOrganizationUserApi,
  UserApi,
} from 'types';
import config from 'config';
import { serializeFiltrationParams } from 'utils/serializers';
import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import {
  GetOrganizationResponse,
  PostOrganizationResponse,
  PatchOrganizationResponse,
  GetOrganizationKindOptionsResponse,
  GetOrganizationUsersResponse,
  PostOrganizationUserResponse,
  GetTypesOfPropertiesOptionsResponse,
  GetOrganizationBuildingsResponse,
  GetBrandingOrganizationAdTitleSuggestionOptionsResponse,
  GetBrandingOrganizationAdDescriptionSuggestionOptionsResponse,
} from '../response-types';
import { createFormData } from './helpers';

const {
  getOrganizationKindOptions,
  getTypesOfPropertiesOptions,
  getOrganization,
  postOrganization,
  patchOrganization,
  getOrganizationUsers,
  postOrganizationUser,
  postOrganizationUserInvite,
  removeOrganizationUser,
  getOrganizationBuildings,
  getOrganizationAdTitleSuggestionOptions,
  getOrganizationAdDescriptionSuggestionOptions,
  getAgentAdDescriptionRegenerateOptions,
  getAgentAdTitleRegenerateOptions,
  getOrganizationAdDescriptionRegenerateOptions,
  getOrganizationAdTitleRegenerateOptions,
} = urls.organizations;

const {
  brandingCampaignTargetKinds: { agent },
} = config.api.constants;

export const createOrganizationsApi = (http: BaseHttpClient) => ({
  getOrganization({ id }: { id: OrganizationApi['id'] }) {
    return http.get<GetOrganizationResponse>(getOrganization.url.replace('{id}', id));
  },

  postOrganization(data: PostOrganizationApi) {
    return http.post<PostOrganizationResponse>(postOrganization.url, data);
  },

  patchOrganization({ id, data }: { id: OrganizationApi['id']; data: Partial<PatchOrganizationApi> }) {
    return http.patch<PatchOrganizationResponse>(patchOrganization.url.replace('{id}', id), createFormData(data));
  },

  postOrganizationUser({
    organizationId,
    data,
  }: {
    organizationId: NonNullable<UserApi['organization_id']>;
    data: PostOrganizationUserApi;
  }) {
    return http.post<PostOrganizationUserResponse>(postOrganizationUser.url.replace('{id}', organizationId), data);
  },

  postOrganizationUserInvite({
    organizationId,
    email,
  }: Pick<OrganizationUserOverviewApi, 'email'> & {
    organizationId: string;
  }) {
    return http.post(postOrganizationUserInvite.url.replace('{id}', organizationId), {
      email,
    });
  },

  removeOrganizationUser({ id }: Pick<OrganizationUserOverviewApi, 'id'>) {
    return http.delete(removeOrganizationUser.url.replace('{id}', id));
  },

  getOrganizationKindOptions() {
    return http.get<GetOrganizationKindOptionsResponse>(getOrganizationKindOptions.url);
  },

  getOrganizationUsers(options?: Filtration) {
    return http.get<GetOrganizationUsersResponse>(getOrganizationUsers.url, serializeFiltrationParams(options));
  },

  getTypesOfPropertiesOptions() {
    return http.get<GetTypesOfPropertiesOptionsResponse>(getTypesOfPropertiesOptions.url);
  },

  getOrganizationBuildings(options?: Filtration) {
    return http.get<GetOrganizationBuildingsResponse>(getOrganizationBuildings.url, serializeFiltrationParams(options));
  },

  getBrandingOrganizationAdTitleSuggestionOptions(options?: Filtration) {
    return http.get<GetBrandingOrganizationAdTitleSuggestionOptionsResponse>(
      getOrganizationAdTitleSuggestionOptions.url,
      serializeFiltrationParams(options),
    );
  },

  getBrandingOrganizationAdDescriptionSuggestionOptions(options?: Filtration) {
    return http.get<GetBrandingOrganizationAdDescriptionSuggestionOptionsResponse>(
      getOrganizationAdDescriptionSuggestionOptions.url,
      serializeFiltrationParams(options),
    );
  },

  getAdBrandingDescriptionRegenerateOptions({
    typeId,
    platform,
    ...options
  }: Filtration & { typeId: string; platform: string }) {
    const url =
      typeId === agent.id
        ? getAgentAdDescriptionRegenerateOptions.url
        : getOrganizationAdDescriptionRegenerateOptions.url;

    return http.get<GetBrandingOrganizationAdDescriptionSuggestionOptionsResponse>(url, {
      ...serializeFiltrationParams(options),
      platform,
    });
  },

  getAdBrandingTitleRegenerateOptions({
    typeId,
    platform,
    ...options
  }: Filtration & { typeId: string; platform: string }) {
    const url =
      typeId === agent.id ? getAgentAdTitleRegenerateOptions.url : getOrganizationAdTitleRegenerateOptions.url;

    return http.get<GetBrandingOrganizationAdDescriptionSuggestionOptionsResponse>(url, {
      ...serializeFiltrationParams(options),
      platform,
    });
  },
});
