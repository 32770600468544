const truncate = (value: string, limit = 15): string =>
  value && value.length > limit ? `${value.slice(0, limit)}...` : value;

const getInitials = (value?: string): string | undefined =>
  value
    ?.split(' ')
    .map((item) => item[0])
    .join('')
    .toUpperCase();

export { truncate, getInitials };
