import React, { memo } from 'react';
import { useAsync } from 'react-use';
import { RadioGroup } from 'components/common/form/radio-group';
import { RadioGroupRemoteProps } from './types';

const defaultFetch = () => Promise.resolve(undefined);

export const RadioGroupRemote = memo<RadioGroupRemoteProps>(({ fetch = defaultFetch, ...props }) => {
  const { value: options, loading } = useAsync(fetch);

  return loading ? null : <RadioGroup options={options} {...props} />;
});
