import React, { forwardRef, ElementType, useState, useLayoutEffect } from 'react';
import { useEnsuredForwardedRef } from 'react-use';
import UiBox from 'ui-box';
import { useCssVariables } from 'hooks';
import { BoxProps } from './types';

const defaultCssVariables = {};

export const Box = forwardRef(
  ({ cssVariables = defaultCssVariables, titleAsContentText = false, ...props }: BoxProps<ElementType>, ref: any) => {
    const ensuredForwardRef = useEnsuredForwardedRef<typeof ref>(ref);

    const { children } = props;

    const [title, setTitle] = useState();

    useLayoutEffect(() => {
      if (titleAsContentText) {
        setTitle(ensuredForwardRef.current?.textContent);
      }
    }, [ensuredForwardRef, titleAsContentText, children]);

    useCssVariables({
      getElement: () => ensuredForwardRef.current,
      cssVariables,
    });

    return <UiBox ref={ensuredForwardRef} title={title} {...props} />;
  },
);
