// @ts-ignore
import { Service } from 'axios-middleware';
import appConfig from 'config';
import { ContainerInterface, ErrorReportClientInterface, ApiConfig } from '../types';
import { configureErrorHandler } from './errors-handler';
import { ApiClient, createClient } from './api-client';
import { ErrorHandlerMiddleware, AuthMiddleware, LogMiddleware } from './middlewares';
import { enableMock } from './api-mock/index';

export function createApiClient(container: ContainerInterface): ApiClient {
  const config = container.get<typeof appConfig>('config');
  const errorReportClient = container.get<ErrorReportClientInterface>('error-report');

  const errorHandler = configureErrorHandler(errorReportClient, container);

  const apiSettings = config.api as ApiConfig;

  const api = createClient(apiSettings);

  // Config mock if need it
  if (apiSettings.useMock) {
    api.client.interceptors.request.use(
      (config) => {
        // @ts-ignore
        config.__originalData = config.data;
        return config;
      },
      (error) => Promise.reject(error),
    );
    enableMock(api.client, apiSettings.mockResponseDelay);
  }

  // Configure middlewares
  new Service(api.client).register([
    ...(apiSettings.logEnabled ? [new LogMiddleware()] : []),
    new AuthMiddleware(container),
    new ErrorHandlerMiddleware(errorHandler),
  ]);

  return api;
}
