import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { FacebookAdApi, FacebookAdApiSerialized } from 'types';
import BaseModel from 'stores/models/base';
import Image from 'stores/models/image';
import TitleOption from 'stores/models/ads/title-option';
import DescriptionOption from 'stores/models/ads/description-option';
import { MSTAddStaticMethods } from 'stores/utils';
import { excludeEmptyObjects } from 'utils/arrays';
import { nullable } from 'stores/mst-types';

const staticMethods = {
  fromResponseData: (data: FacebookAdApi): FacebookAdSnapshot => ({
    isActive: Boolean(data?.is_active),
    images: data?.images?.map(Image.fromResponseData) || [],
    titles: data.titles && data.titles.map(TitleOption.fromResponseData),
    descriptions: data.descriptions && data.descriptions.map(DescriptionOption.fromResponseData),
    name: 'facebookAd',
  }),

  toRequestData: (data: FacebookAdSnapshot): FacebookAdApiSerialized => ({
    ...pick(data, ['images']),
    titles: data.titles && excludeEmptyObjects(data.titles)?.length ? data.titles.map(TitleOption.toRequestData) : null,
    descriptions:
      data.descriptions && excludeEmptyObjects(data.descriptions)?.length
        ? data.descriptions?.map(DescriptionOption.toRequestData)
        : null,
    is_active: Boolean(data.isActive),
  }),
};

const FacebookAd = BaseModel.named('FacebookAd').props({
  isActive: types.boolean,
  titles: nullable(types.array(TitleOption)),
  descriptions: nullable(types.array(DescriptionOption)),
  images: types.array(Image),
  name: types.string,
});

export type FacebookAdInstanceType = Instance<typeof FacebookAd>;
export type FacebookAdSnapshot = SnapshotOut<typeof FacebookAd>;

export default MSTAddStaticMethods(FacebookAd, staticMethods);
