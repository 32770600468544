import React, { useMemo, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash.isequal';
import { useAsync } from 'react-use';
import { observer } from 'mobx-react-lite';
import { useStores, useField, useTranslation } from 'hooks';
import { Box } from 'components/common/box';
import { GridLayout } from 'components/common/grid-layout';
import { withFormItem } from 'hocs/with-form-item';
import { ApartmentPricesFieldFormValue } from 'types';
import { ApartmentPricesFieldProps } from './types';
import { ApartmentPricesRow } from './apartment-prices-row';

const emptyRowValue = {};
const defaultValue = [emptyRowValue] as NonNullable<ApartmentPricesFieldProps['value']>;

export const ApartmentPricesField = withFormItem<ApartmentPricesFieldProps>()(
  observer((props) => {
    const { t } = useTranslation();
    const { field } = useField<ApartmentPricesFieldProps['value']>(props);
    const { name, value = defaultValue, onChange } = field;
    const { common } = useStores();
    const { getApartmentParameterTypeOptions } = common;

    const { value: options } = useAsync(getApartmentParameterTypeOptions);

    const indexedValueItems = useMemo<(ApartmentPricesFieldFormValue & { id: string })[]>(
      () =>
        (value as NonNullable<ApartmentPricesFieldProps['value']>).map((item) => ({
          id: uuidv4(),
          ...item,
        })),
      // eslint-disable-next-line  react-hooks/exhaustive-deps
      [value.length],
    );

    const onRemove = useCallback(
      (rowIndex: number) => {
        const nextRawValue = value && value.filter((_: any, index: number) => index !== rowIndex);
        const nextValue = nextRawValue?.some((valueItem: any) => !isEqual(valueItem, emptyRowValue))
          ? nextRawValue
          : undefined;

        onChange({ target: { name, value: nextValue } });
      },
      [name, onChange, value],
    );

    const showRemoveButton = true;

    const items = useMemo(
      () =>
        indexedValueItems.map(({ id }, index) => (
          <ApartmentPricesRow
            yardiStatus={props.yardiStatus}
            key={id}
            rootFieldName={name}
            rowIndex={index}
            options={options}
            onRemove={onRemove}
            showRemoveButton={showRemoveButton}
          />
        )),
      [indexedValueItems, name, onRemove, options, showRemoveButton, props.yardiStatus],
    );

    return (
      <Box>
        <GridLayout columns={1} gridRowGap={20} marginBottom={20}>
          {items}
        </GridLayout>
      </Box>
    );
  }),
);
