import { getRoot, Instance, SnapshotOut, toGenerator, types } from 'mobx-state-tree';
import { withFlashAlerts } from 'stores/extensions';
import pick from 'lodash.pick';
import InvitedUser from 'stores/models/invited-user';
import BaseModel from 'stores/models/base';
import { apiFlow, nullable } from 'stores/mst-types';
import { staticMethods as SessionStoreStaticMethods, SignUpFormValues } from 'stores/session-store';
import { handleActivationTokenError } from 'services/api/errors';
import { deserializeAuthTokenPairs } from 'utils/deserializers';
import config from 'config';
import { PostSignUpInvitedUserApi } from 'types';

export interface SignUpInvitedUserFormValues extends SignUpFormValues {
  token: string;
}

const staticMethods = {
  toLoadUserRequest: ({ uid, token }: { uid: string; token: string }): { uid: string; token: string } => ({
    uid,
    token,
  }),

  toSignUpInvitedUserPostRequest: ({ token, ...values }: SignUpInvitedUserFormValues): PostSignUpInvitedUserApi => ({
    ...SessionStoreStaticMethods.toSignUpPostRequest(values),
    token,
  }),
};

export const InvitedSignUpStore = BaseModel.named('InvitedSignUpStore')
  .props({
    user: nullable(InvitedUser),
    isTokenUsed: types.optional(types.boolean, false),
  })
  .extend(withFlashAlerts)
  .views((self) => ({
    get initialValues() {
      return {
        ...pick(self.user, ['lastName', 'email']),
        name: self.user?.firstName,
      };
    },
  }))
  .actions((self) => ({
    loadInvitedUser: apiFlow<{
      isTokenInvalid: boolean;
    } | null>(
      function* loadInvitedUser({ uid, token }: { uid: string; token: string }) {
        try {
          const response = yield* toGenerator(self.api.getInvitedUser(staticMethods.toLoadUserRequest({ uid, token })));

          const user = InvitedUser.fromResponseData(response.data);

          self.user = InvitedUser.create(user);

          return null;
        } catch (error) {
          return handleActivationTokenError(error);
        }
      },
      { formName: 'invited-sign-up' },
    ),

    setupInvitedUser: apiFlow(
      function* setupInvitedUser(values: SignUpInvitedUserFormValues) {
        self.isTokenUsed = true;
        const response = yield* toGenerator(
          self.api.signUpInvitedUser(staticMethods.toSignUpInvitedUserPostRequest(values)),
        );

        const { session } = getRoot<any>(self);
        const { signInByTokens } = session;

        yield signInByTokens({
          tokens: deserializeAuthTokenPairs(response.data),
          getRedirectLink: () => session.getLoggedInUserLocation(config.options.pages.defaultPage),
        });
      },
      { formName: 'invited-sign-up' },
    ),

    destroy() {
      self.user = null;
    },
  }));

export interface InvitedSignUpStoreInstance extends Instance<typeof InvitedSignUpStore> {}
export interface InvitedSignUpStoreSnapshot extends SnapshotOut<typeof InvitedSignUpStore> {}
