import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { SearchField } from 'components/common/form/search-field';
import { useStores } from 'hooks';
import { StateFieldProps } from './types';

export const StateField = observer<StateFieldProps>((props) => {
  const { common } = useStores();

  const { getStateOptions, getStateInitialOptions } = common;

  const fetch: StateFieldProps['fetch'] = useCallback(async (search) => getStateOptions({ search }), [getStateOptions]);

  return <SearchField fetch={fetch} fetchInitialOptions={getStateInitialOptions} {...props} />;
});
