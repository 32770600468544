import React, { ComponentType, memo, ReactNode } from 'react';
import { Tooltip, TooltipProps } from 'components/common/tooltip';

export interface WithTooltipProps {
  tooltip?: ReactNode;
  tooltipProps?: TooltipProps;
}

export const withTooltip =
  <P extends Record<string, any>>() =>
  (WrappedComponent: ComponentType<P>): ComponentType<WithTooltipProps & P> =>
    memo<WithTooltipProps & P>(({ tooltip, ...componentProps }) => {
      const content = <WrappedComponent cursor={tooltip ? 'pointer' : 'default'} {...(componentProps as P)} />;

      return tooltip ? <Tooltip title={tooltip}>{content}</Tooltip> : content;
    });
