import React, { Suspense, memo } from 'react';
import { Route as ReactRouterRoute, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { SuspenseFallback } from 'components/common/suspense-fallback';
import { RouteProps } from './types';

export const Route = memo<RouteProps>((props) => {
  const { layout: Layout = React.Fragment, component: Component, render, children, ...rest } = props;

  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  if (render) {
    return (
      <ReactRouterRoute
        {...rest}
        render={(props) => (
          <Layout>
            <Suspense fallback={<SuspenseFallback />}>{render(props)}</Suspense>
          </Layout>
        )}
      />
    );
  }

  const pageContent = Component ? <Component location={location} match={match} history={history} /> : children;

  return (
    <ReactRouterRoute {...rest}>
      <Layout>
        <Suspense fallback={<SuspenseFallback />}>{pageContent}</Suspense>
      </Layout>
    </ReactRouterRoute>
  );
});
