import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldsLayout } from 'components/common/form/fields-layout';
import { KeywordsPicker } from 'components/common/form/keywords-picker';
import { withModal } from 'hocs/with-modal';
import { useModalProps, useStores, useTranslation } from 'hooks';
import { Box } from 'components/common/box';
import { Icon } from 'components/common/icon';
import { v4 as uuidv4 } from 'uuid';
import { Button, Tooltip } from 'antd';
import { KEYWORDS_FIELD } from './constants';
import { validationSchema } from './validation-schemas';
import { AdKeywordsPickerModalProps } from './types';
import './index.css';
import { CustomSelect } from '../custom-select';
import { FormAdGroup } from '../form-ad-group';

export const AdSettingsModal = withModal<AdKeywordsPickerModalProps>()(({ options, initialValues, ...props }) => {
  const { t } = useTranslation();

  useModalProps(
    useMemo(
      () => ({
        title: t('AdKeywordsPicker.Title'),
      }),
      [t],
    ),
  );

  const footer = useMemo(
    () => ({
      cancelButtonAvailable: false,
      submitText: t('AdKeywordsPicker.SaveButton'),
    }),
    [t],
  );

  const { googleSearchAd } = useStores();
  const { adGroups, buldingGroups, generatedTitles, generatedDescriptions, removeOlddAdGroups } = googleSearchAd;
  const slicedArr = adGroups.map((e) => ({
    keywords: e.keywords,
    id: e.id,
    group: e.group,
    titles: e.titles && e.titles.slice(0, 15),
    descriptions: e.descriptions && e.descriptions.slice(0, 4),
    type: e.type,
  }));

  const [fieldValue, setfieldValue]: any = useState({ keywords: adGroups });
  const [activeItem, setActiveItem]: any = useState();
  const [activeIndex, setActiveIndex]: any = useState(0);
  const [menuItems, setMenuItems]: any = useState(adGroups);
  const [selectItem, setSelectItem]: any = useState(adGroups[0]);
  const [groups, setGroups] = useState(adGroups);
  const [customValue, setCustomValue] = useState('');
  const [optionTitles, setOptionTitles] = useState(adGroups[0].titles);
  const [optionDescriptions, setOptionDescriptions] = useState(adGroups[0].descriptions);
  const [keywordsAffected, setKeywordsAffected] = useState(adGroups[0].group?.is_keywords_affected);
  const [titlesAffected, setTitlesAffected] = useState(adGroups[0].group?.is_titles_affected);
  const [descriptionsAffected, setDescriptionsAffected] = useState(adGroups[0].group?.is_descriptions_affected);
  const [disableDuplicateGroupName, setDisableDuplicateGroupName] = useState(false);
  const [updatedEntity, setUpdatedEntity] = useState(false);

  useEffect(() => {
    const findGroup = adGroups.find((group: any) => group.group.value === groups?.[activeIndex]?.group?.value);
    if (updatedEntity && findGroup) {
      setOptionTitles(adGroups[activeIndex].titles);
      setOptionDescriptions(adGroups[activeIndex].descriptions);
      setUpdatedEntity(false);
    }
    if (updatedEntity && !findGroup) {
      setOptionTitles(generatedTitles);
      setOptionDescriptions(generatedDescriptions);
      setUpdatedEntity(false);
    }
  }, [
    adGroups,
    generatedDescriptions,
    generatedTitles,
    activeIndex,
    setUpdatedEntity,
    updatedEntity,
    groups,
    activeItem,
  ]);

  useEffect(() => {
    setDisableDuplicateGroupName(false);
    const labels = menuItems.map((element: any) => element.group.label);
    const labelSet = new Set(labels);
    if (labels.length !== labelSet.size) {
      setDisableDuplicateGroupName(true);
    } else {
      setDisableDuplicateGroupName(false);
    }
  }, [menuItems]);

  useEffect(() => {
    if (groups.length > 0 && groups[0].group) {
      setKeywordsAffected(groups[0].group.is_keywords_affected);
      setTitlesAffected(groups[0].group.is_titles_affected);
      setDescriptionsAffected(groups[0].group.is_descriptions_affected);
      const index = groups[0].group.value;
      setActiveItem(index);
      setActiveIndex(0);
      const firstFocus = [groups[0]];
      setSelectItem(firstFocus);
      setOptionTitles(groups[0].titles);
      setOptionDescriptions(groups[0].descriptions);
      const sliceGroups = groups.map((group: any) => ({
        descriptions: group.descriptions.slice(0, 4),
        group: group.group,
        keywords: group.keywords,
        titles: group.titles.slice(0, 15),
        type: group.type,
        id: group.id,
      }));
      const filt = {
        name: 'adGroups',
        keywords: sliceGroups,
      };
      setfieldValue(filt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFocusMenuItem = (e: any, index: any) => {
    if (groups.length > 0 && groups[index].group) {
      setKeywordsAffected(groups[index].group?.is_keywords_affected);
      setTitlesAffected(groups[index].group?.is_titles_affected);
      setDescriptionsAffected(groups[index].group?.is_descriptions_affected);
    }
    setActiveItem(e);
    setActiveIndex(index);
    const filteredOptions: any = groups?.filter((item) => item?.group?.value === e);
    setSelectItem(filteredOptions);
    const findGroup = adGroups.find((group: any) => group.group.value === groups?.[index]?.group?.value);
    if (findGroup) {
      setOptionTitles(adGroups[index].titles);
      setOptionDescriptions(adGroups[index].descriptions);
    } else {
      setOptionTitles(generatedTitles);
      setOptionDescriptions(generatedDescriptions);
    }
    const sliceGroups = groups.map((group: any) => ({
      descriptions: group.descriptions.slice(0, 4),
      group: group.group,
      keywords: group.keywords,
      titles: group.titles.slice(0, 15),
      type: group.type,
      id: group.id,
    }));
    const filt = {
      groupValue: e,
      name: 'adGroups',
      keywords: sliceGroups,
    };
    setfieldValue(filt);
  };

  const removeAdGroup = (e: any) => {
    const removedGroup = menuItems.filter((item: any) => item?.group?.value !== e);

    const removedValue = groups.filter((item: any) => item?.group?.value !== e);
    const filt = {
      groupValue: e,
      name: 'adGroups',
      keywords: removedValue,
    };
    setfieldValue(filt);
    setMenuItems(removedGroup);
  };

  const addCustomGroup = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setKeywordsAffected(false);
    setTitlesAffected(false);
    setDescriptionsAffected(false);
    setActiveIndex(menuItems.length);
    const uuid = uuidv4();
    const addOptions: any = {
      descriptions: generatedDescriptions.slice(0, 4),
      titles: generatedTitles.slice(0, 15),
      group: {
        value: uuid,
        label: customValue,
        template: '',
        is_old: false,
        is_new: false,
        is_keywords_affected: false,
        is_descriptions_affected: false,
        is_titles_affected: false,
      },
      keywords: [],
      type: 'nonbranded',
      id: null,
    };
    const newItem = [...groups, addOptions];
    setOptionTitles(generatedTitles);
    setOptionDescriptions(generatedDescriptions);
    const filteredOptions: any = groups?.filter((item) => item?.group?.value === uuid);
    setSelectItem(filteredOptions);
    setMenuItems(newItem);
    const filt = {
      groupValue: uuid,
      name: 'adGroups',
      keywords: newItem,
    };

    setfieldValue(filt);
    setActiveItem(uuid);
    setCustomValue('');
  };
  const groupNameOptions = useCallback(
    () =>
      buldingGroups?.map(({ group }) => ({
        ...group,
        disabled: menuItems?.some(({ group: item }: any) => item?.label === group?.label),
      })),
    [menuItems, buldingGroups],
  );

  const onGroupChange = (group: any) => {
    setActiveIndex(menuItems.length);
    const selectedGroup = group.target.value.value;
    const newItem: any = buldingGroups?.filter((e: any) => e.group.value === selectedGroup);
    const slicedObject: any = {
      descriptions: newItem[0].descriptions.slice(0, 4),
      titles: newItem[0].titles.slice(0, 15),
      group: newItem[0].group,
      keywords: newItem[0].keywords,
      type: newItem[0].type,
      id: newItem[0].id,
    };
    const newItems = [...groups, slicedObject];
    setMenuItems(newItems);
    const filt = {
      groupValue: selectedGroup,
      name: 'adGroups',
      keywords: newItems,
    };

    setSelectItem(newItem);
    setOptionTitles(group.titles);
    setOptionDescriptions(group.descriptions);
    setfieldValue(filt);
    setActiveItem(selectedGroup);
  };

  const removeOldGroups = () => {
    setKeywordsAffected(false);
    setTitlesAffected(false);
    setDescriptionsAffected(false);
    const groupWithouOld = groups.filter(({ group }: any) => !group?.is_old);
    const firstFocus = [groups[0]];
    setSelectItem(firstFocus);
    setOptionTitles(generatedTitles);
    setOptionDescriptions(groups[0].descriptions);
    setMenuItems(groupWithouOld);
    const index = groups[0] && groups[0].group?.value;
    setActiveItem(index);
    setActiveIndex(0);
    const filt = {
      name: 'adGroups',
      keywords: groupWithouOld,
    };
    setfieldValue(filt);
  };

  const isOldExist = groups.some((group: any) => group?.group?.is_old);
  return (
    <FormAdGroup
      validation={validationSchema}
      nameForm='AdGroup'
      style={{ marginLeft: '-40px' }}
      disableDuplicateGroupName={disableDuplicateGroupName}
      footer={footer}
      menuItems={menuItems}
      removeOlddAdGroups={removeOlddAdGroups}
      initialValues={fieldValue}
      {...props}
    >
      <div className='group-container'>
        <div className='side-menu'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div className='group-title'>AdGroups: </div>{' '}
            {isOldExist && (
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  marginTop: '-5px',
                  marginRight: '30px',
                  borderColor: '#637381',
                  color: '#637381',
                  borderRadius: '6px',
                }}
                onClick={removeOldGroups}
              >
                Remove old AdGroups
                <Box
                  style={{
                    display: 'flex',
                    jusifyContent: 'center',
                    marginLeft: '10px',
                  }}
                  key='close'
                >
                  <Icon style={{ color: 'red' }} name='close' size={10} />
                </Box>
              </Button>
            )}
          </div>
          <Box
            width='100%'
            gridColumnGap={0}
            style={{
              marginBottom: '30px',
              paddingRight: '50px',
              marginLeft: '30px',
            }}
          >
            <CustomSelect
              name='group-picker'
              placeholder={t('KeywordsPicker.Labels.AdGroupName')}
              showArrow={false}
              showSearch={false}
              menuItems={menuItems}
              customValue={customValue}
              setCustomValue={setCustomValue}
              options={groupNameOptions()}
              labelInValue
              customAdd={addCustomGroup}
              onChange={onGroupChange}
            />
          </Box>
          {menuItems?.map((e: any, index: any) => (
            <div
              className={activeItem === e.group?.value ? 'option-item-active' : 'option-item'}
              id={`keywords[${index}].group`}
            >
              <Box display='flex' marginLeft={35} marginRight={5}>
                {menuItems.length > 1 && (
                  <Icon
                    className='icon-remove'
                    name='minus'
                    size={20}
                    circle
                    cursor='pointer'
                    onClick={() => removeAdGroup(e.group?.value)}
                  />
                )}
              </Box>
              {!e.group.is_old && !e.group.is_new && <Icon size={15} />}
              {e.group.is_old && (
                <Box
                  style={{
                    display: 'flex',
                    jusifyContent: 'center',
                    marginLeft: '10px',
                  }}
                  key='close'
                >
                  <Icon style={{ color: 'red' }} name='close' size={10} />
                </Box>
              )}
              {e.group.is_new && (
                <Box
                  style={{
                    display: 'flex',
                    jusifyContent: 'center',
                    marginLeft: '10px',
                  }}
                  key='check'
                >
                  <Icon style={{ color: 'green' }} name='check' size={10} />
                </Box>
              )}
              <Tooltip placement='top' color='greekblue' title={e.group?.label}>
                <button
                  className='group-text'
                  type='button'
                  onClick={() => onFocusMenuItem(e.group?.value, index)}
                  style={{
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                  }}
                >
                  {e.group?.label}
                </button>
              </Tooltip>
            </div>
          ))}
          {disableDuplicateGroupName && (
            <div
              style={{
                color: 'rgb(217, 39, 39)',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <p>Ad Group names must be unique, please remove duplicates.</p>
            </div>
          )}
        </div>
        <FieldsLayout style={{ marginLeft: '50px' }} minHeight={583} width='100%'>
          <KeywordsPicker
            setUpdatedEntity={setUpdatedEntity}
            groups={groups}
            adGroups={adGroups}
            keywordsAffected={keywordsAffected}
            titlesAffected={titlesAffected}
            descriptionsAffected={descriptionsAffected}
            setGroups={setGroups}
            name={KEYWORDS_FIELD}
            titleOptions={optionTitles}
            descriptionOptions={optionDescriptions}
            activeIndex={activeIndex}
            activeItem={activeItem}
            setOptionTitles={setOptionTitles}
            setOptionDescriptions={setOptionDescriptions}
            selectItem={selectItem}
            options={menuItems}
          />
        </FieldsLayout>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          className='main-validation-message'
          id='main-validation-message'
          style={{
            color: 'red',
            position: 'absolute',
            bottom: '60px',
            display: 'none',
            border: '1px solid red',
            borderRadius: '5px',
            padding: '5px',
            alignItems: 'center',
          }}
        >
          <Icon style={{ color: 'red', marginRight: '10px' }} name='warning' size={20} />
          At least one ad group contains incorrect data. Check the titles, keywords, and descriptions in the ad groups
          highlighted in red.
        </div>
      </div>
    </FormAdGroup>
  );
});
