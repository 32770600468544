const getLast = <T>(array: T[]): T => array[array.length - 1];

const getUnique = <T>(items: T[]): T[] => [...new Set(items)];

const getDiffByField = <T, K extends keyof T>({ target, array, field }: { target: T[]; array: T[]; field: K }): T[] => {
  const [targetValues, arrayValues] = [target, array].map((list) => list.map(({ [field]: value }) => value));
  const targetValuesDiff = targetValues.filter((value) => !arrayValues.includes(value));
  const uniqueTargetValuesDiff = getUnique(targetValuesDiff);

  return target.filter(({ [field]: value }) => uniqueTargetValuesDiff.includes(value));
};

const excludeEmptyObjects = <T extends { [key: string]: any }>(array: T[]): T[] =>
  array.filter((element: T) => Object.keys(element).length !== 0);

export { getLast, getUnique, getDiffByField, excludeEmptyObjects };
