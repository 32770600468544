import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import { withStatus } from 'stores/extensions/with-status';
import { withServices } from 'stores/extensions/with-services';
import { withRootStore } from 'stores/extensions/with-root-store';

const Base = types.model('Base', {}).extend(withStatus).extend(withServices).extend(withRootStore);

export type BaseRawType = SnapshotOut<typeof Base>;
export type BaseInstanceType = Instance<typeof Base>;

export default Base;
