import React, { memo, useCallback } from 'react';
import { useToggle } from 'react-use';
import { Box } from 'components/common/box';
import { useField } from 'hooks';
import { AdTextPickerModal } from './ad-text-picker-modal';
import { AdTextPickerProps } from './types';

const defaultTitleOptions = [] as NonNullable<AdTextPickerProps['titleOptions']>;
const defaultDescriptionOptions = [] as NonNullable<AdTextPickerProps['descriptionOptions']>;

export const AdTextPicker = memo<AdTextPickerProps>(
  ({
    titleOptions = defaultTitleOptions,
    descriptionOptions = defaultDescriptionOptions,
    holderProps,
    children,
    ...props
  }) => {
    const { field } = useField(props);
    const { name, value, onChange, onBlur } = field;
    const [open, toggleOpen] = useToggle(false);

    const handleChange = useCallback(
      (value: NonNullable<AdTextPickerProps['value']>) => {
        const event = { target: { name, value } };
        onChange(event);
        onBlur(event);
      },
      [name, onChange, onBlur],
    );

    return (
      <>
        <Box display='inline-flex' onClick={toggleOpen} {...holderProps}>
          {children}
        </Box>
        <AdTextPickerModal
          visible={open}
          onCancel={toggleOpen}
          initialValues={value}
          titleOptions={titleOptions}
          descriptionOptions={descriptionOptions}
          onSubmit={handleChange}
          {...props}
        />
      </>
    );
  },
);
