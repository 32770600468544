const fileToImageUrl = (file: File): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(file);
  });

const base64ToFile = (value: string, fileName = 'image'): File => {
  const arr: any = value?.split(',');

  const mime = arr[0].match(/:(.*?);/)[1];
  const type = mime?.split('/')[1];

  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  /* eslint-disable-next-line no-plusplus */
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], `${fileName}.${type}`, { type: mime });
};

const getExtensionsFromAccept = ({ acceptList }: { acceptList: string[] }): string[] =>
  acceptList.map((item) => {
    const [, ...extChars] = item;

    return extChars.join('');
  });

const checkFileExtension = ({ file, accept }: { file: File; accept?: string }): boolean => {
  const acceptList = accept?.split(', ');

  const fileExtension = file.type.split('/')[1];
  const acceptExtensions = acceptList && getExtensionsFromAccept({ acceptList });

  return !acceptExtensions || acceptExtensions.some((acceptExtension) => acceptExtension === fileExtension);
};

export { fileToImageUrl, base64ToFile, checkFileExtension };
