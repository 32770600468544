import axios, { AxiosResponse, AxiosError } from 'axios';
import { isEmpty } from 'utils/is';
import * as objects from 'utils/objects';
import { HttpErrorTypes } from './types';

const getHttpErrorName = (error?: AxiosError): string | null => {
  const status = error?.response?.status;

  if (isEmpty(status)) {
    return null;
  }

  return `HTTP Error ${status}`;
};

const getHttpErrorMessage = (error?: AxiosError): string | null => {
  const method = error?.config?.method;
  const url = error?.config?.url;

  if ([method, url].some(isEmpty)) {
    return null;
  }

  return `${method?.toUpperCase()} ${url}`;
};

export class BaseHttpError extends Error {
  type?: HttpErrorTypes | null;

  response?: AxiosResponse<any>;

  originalError?: AxiosError;

  remoteMessage?: string;

  constructor({
    name,
    type,
    message,
    remoteMessage,
  }: {
    type: HttpErrorTypes;
    name?: Nullable<string>;
    message?: string;
    remoteMessage?: string;
  }) {
    super(message);
    if (name) {
      this.name = name;
    }

    this.remoteMessage = remoteMessage;
    this.type = type;
  }

  static fromError(error: AxiosError): BaseHttpError {
    const httpError = fromError(error);
    httpError.response = error?.response;
    return httpError;
  }
}

export class HttpNetworkError extends BaseHttpError {}

export class HttpServerError extends BaseHttpError {
  constructor({ originalError, remoteMessage }: { originalError: AxiosError; remoteMessage?: string }) {
    super({
      name: getHttpErrorName(originalError),
      type: 'server',
      message: getHttpErrorMessage(originalError) || 'Server Error',
      remoteMessage,
    });
  }
}

export class HttpUnknownError extends BaseHttpError {
  constructor(originalError?: Error) {
    super({ type: 'unknown', message: originalError?.message });
    this.originalError = originalError as AxiosError;
    this.stack = originalError?.stack;
  }
}

export class HttpClientError extends BaseHttpError {
  constructor({
    type,
    originalError,
    message,
  }: {
    type: HttpErrorTypes;
    originalError?: AxiosError;
    statusCode?: number;
    message?: string;
    error?: AxiosError;
  }) {
    super({
      name: getHttpErrorName(originalError),
      type,
      message: getHttpErrorMessage(originalError) || 'Client Error',
      remoteMessage: message,
    });
  }
}

function extractErrorMessageFromResponse(response: AxiosResponse<any>): {
  message: string;
} {
  const message =
    response.data?.error?.message ??
    response.data?.message ??
    ((typeof response.data === 'object' && JSON.stringify(response.data)) || 'Oops! Something went wrong!');

  return { message };
}

const is400 = (status: number) => status >= 400 && status <= 499;
const is500 = (status: number) => status >= 500 && status <= 599;

const exchangeStatusCodeToType: {
  [key: number]: HttpErrorTypes;
} = {
  401: 'unauthorized',
  403: 'forbidden',
  404: 'not-found',
  400: 'bad-request',
  422: 'unprocessable-entity',
  429: 'too-many-requests',
  0: 'rejected',
};

export const fromError = (error: AxiosError): BaseHttpError => {
  if (error.message.includes('Network Error')) {
    return new HttpNetworkError({
      type: 'connection',
      name: 'HTTP Error Unable to connect',
      message: getHttpErrorMessage(error) || '',
      remoteMessage: error.message,
    });
  }

  if (error.message.startsWith('timeout')) {
    return new HttpNetworkError({
      type: 'timeout',
      name: 'HTTP Error Timeout',
      message: getHttpErrorMessage(error) || '',
      remoteMessage: error.message,
    });
  }

  if (axios.isCancel(error)) {
    return new HttpClientError({ type: 'canceled', message: 'Canceled' });
  }

  if (error.code?.includes('ECONNABORTED')) {
    return new HttpNetworkError({ type: 'timeout', message: 'Time Out' });
  }

  if (error.response?.status) {
    const statusCode = error.response.status;

    if (is400(statusCode)) {
      const { message } = extractErrorMessageFromResponse(error.response);
      return new HttpClientError({
        originalError: error,
        type: exchangeStatusCodeToType[statusCode || 0],
        message,
      });
    }
    if (is500(statusCode)) {
      const { message } = extractErrorMessageFromResponse(error.response);
      return new HttpServerError({
        originalError: error,
        remoteMessage: message,
      });
    }
  } else if (error instanceof BaseHttpError) {
    return error;
  }

  return new HttpUnknownError(error);
};

export const isUnauthorizedError = (error: Error): boolean =>
  error instanceof HttpClientError && error.type === 'unauthorized';

export const isTooManyRequestsError = (error: Error): boolean =>
  error instanceof HttpClientError && error.type === 'too-many-requests';

export const isBadRequestError = (error: Error): error is HttpClientError & { type: 'bad-request'; response: any } =>
  error instanceof HttpClientError && error.type === 'bad-request' && Boolean(error.response);

export const isNotFoundRequestError = (error: Error): error is HttpClientError & { type: 'not-found'; response: any } =>
  error instanceof HttpClientError && error.type === 'not-found' && Boolean(error.response);

export const isExpiredTokenError = (error: Error, tokenField = 'token'): boolean => {
  if (!isBadRequestError(error)) {
    return false;
  }

  const errors = objects.flat({ data: error.response.data });

  return tokenField in errors;
};

export const isServerError = (error: Error): boolean => error instanceof HttpServerError;

export const handleActivationTokenError = (
  error: unknown,
):
  | {
      isTokenInvalid: boolean;
    }
  | never => {
  const castError = error as any;
  const tokenError = castError?.response?.data?.token_error as Nullable<string[]>;

  if (!isBadRequestError(castError) || !tokenError) {
    throw error;
  }

  return {
    isTokenInvalid: true,
  };
};
