import { types, cast, toGenerator, getEnv } from 'mobx-state-tree';
import Base from 'stores/models/base';
import { errorMessage } from 'components/common/message';
import { apiFlow } from 'stores/mst-types';
import { fromListResponse } from 'services/api';
import { Filtration } from 'types';
import FacebookAdAccountOverview from './facebook-ad-account-overview';

const FacebookAdAccounts = Base.named('FacebookAdAccounts')
  .props({
    entries: types.optional(types.array(FacebookAdAccountOverview), []),
    count: types.optional(types.number, 0),
    isOpened: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    fetch: apiFlow(function* fetch(options?: Filtration) {
      const t = getEnv(self).get('i18n');

      try {
        self.isOpened = true;
        const response = yield* toGenerator(self.api.getFacebookAdAccounts(options));
        const { data, count } = fromListResponse({
          response: response.data,
        });

        self.entries = cast(data.map(FacebookAdAccountOverview.fromResponseData));
        self.count = count;
      } catch (err: any) {
        self.isOpened = false;

        const errorPermissions = err.response.data.missing_permissions;
        const errorAdAccounts = err.response.data.ad_accounts_error;

        if (errorPermissions) {
          errorMessage(
            t('ProfilePage.Platforms.Modal.Facebook.FacebookAdAccounts.Errors.ErrorPermissions', {
              errors: errorPermissions,
            }),
          );
        } else if (errorAdAccounts) {
          errorMessage(t('ProfilePage.Platforms.Modal.Facebook.FacebookAdAccounts.Errors.ErrorAdAccounts'));
        }
      }
    }),
    destroy() {
      self.entries = cast([]);
      self.count = 0;
      self.isOpened = true;
    },
  }));

export default FacebookAdAccounts;
