import React, { memo, useMemo } from 'react';
import { useTranslation, useModalContext } from 'hooks';
import { Box } from 'components/common/box';
import { Button } from 'components/common/button/button';
import { VariantContext } from 'contexts/variant-context';
import { FormFooterProps } from './types';

export const FormFooter = memo<FormFooterProps>((props) => {
  const { t } = useTranslation();
  const modalContext = useModalContext();

  const {
    disabled = false,
    onSubmit,
    submitText = t('FormFooter.SaveButton'),
    submitButtonProps,
    loading,
    onCancel = modalContext?.close,
    cancelText = t('FormFooter.CancelButton'),
    cancelButtonProps,
    cancelButtonAvailable = true,
    children,
    disableDuplicateGroupName = false,
    nameForm,
    ...rest
  } = props;

  const isModalForm = Boolean(modalContext);

  const buttonSize = isModalForm ? ('middle' as const) : ('middle' as const);
  const topPadding = isModalForm ? 50 : 35;

  const contextValue = useMemo(
    () => ({
      buttonProps: {
        size: buttonSize,
      },
    }),
    [buttonSize],
  );

  const defaultButtons = (
    <>
      {Boolean(onCancel) && cancelButtonAvailable && (
        <Button htmlType='button' onClick={onCancel} propsFromContext {...cancelButtonProps}>
          {cancelText}
        </Button>
      )}
      <Button
        htmlType='submit'
        type='primary'
        propsFromContext
        onClick={onSubmit}
        disabled={disabled || disableDuplicateGroupName}
        {...submitButtonProps}
      >
        {submitText}
      </Button>
    </>
  );

  return (
    <VariantContext.Provider value={contextValue}>
      <Box display='flex' flexDirection='row' justifyContent='center' paddingTop={topPadding} gridGap={20} {...rest}>
        {children ?? defaultButtons}
      </Box>
    </VariantContext.Provider>
  );
});
