import React, { memo } from 'react';
import themeColors from 'utils/theme-colors';
import { Box } from 'components/common/box';
import { ScaleProps } from './types';

export const Scale = memo<ScaleProps>(({ ...props }) => (
  <Box
    display='flex'
    alignItems='center'
    justifyContent='center'
    borderRadius={4}
    border={`solid 1px ${themeColors.inputBorderColor}`}
    {...props}
  />
));
