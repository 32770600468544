import { v4 as uuidv4 } from 'uuid';
import isMatch from 'lodash.ismatch';
import { storage } from 'utils/storage';
import config from 'config';
import { STORAGE_DATABASE_KEY } from './helper';

const { api } = config;

export class Collection<T extends { id: string } & Record<string, any>, NewItem = Omit<T, 'id'>> {
  items: T[] = [] as T[];

  name = '';

  async init({ name }: { name: string }): Promise<void> {
    this.name = name;

    await this.overrideLocal();
  }

  private async overrideLocal() {
    if (!api.useMock) {
      return;
    }

    const database = await storage.load(STORAGE_DATABASE_KEY);

    const defaultItems = [] as T[];
    this.items = (database && database[this.name]) || defaultItems;
  }

  private async overrideRemote() {
    if (!api.useMock) {
      return;
    }

    const database = (await storage.load(STORAGE_DATABASE_KEY)) || {};

    database[this.name] = this.items;

    await storage.save(STORAGE_DATABASE_KEY, database);
  }

  async add(item: NewItem): Promise<T> {
    const newItem = {
      id: uuidv4(),
      [config.api.sorting.createdAt]: new Date().toJSON(),
      ...item,
    } as unknown as T;
    this.items = [...this.items, newItem];

    await this.overrideRemote();

    return newItem;
  }

  async batchAdd(items: NewItem[]): Promise<T[]> {
    this.items = await Promise.all(items.map((item) => this.add(item)));

    await this.overrideRemote();

    return this.items;
  }

  async find(query: Partial<T>): Promise<Nullable<T>> {
    return this.items.find((item) => isMatch(item, query));
  }

  async findAll(query: Partial<T> = {}): Promise<T[]> {
    return this.items.filter((item) => isMatch(item, query));
  }

  async update(query: Partial<T>, data: Partial<T>): Promise<Nullable<T>> {
    this.items = this.items.map((item) => {
      if (isMatch(item, query)) {
        return {
          ...item,
          ...data,
        };
      }

      return item;
    });

    const updatedItem = await this.find(query);

    await this.overrideRemote();

    return updatedItem;
  }

  async remove(query: Partial<T>): Promise<void> {
    this.items = this.items.filter((item) => !isMatch(item, query));

    await this.overrideRemote();
  }

  async seed(items: NewItem | NewItem[]): Promise<void> {
    if (this.items.length) {
      return;
    }

    this.items = ([] as NewItem[]).concat(items).map(
      (item) =>
        ({
          id: uuidv4(),
          [config.api.sorting.createdAt]: new Date().toJSON(),
          ...item,
        } as unknown as T),
    );

    await this.overrideRemote();
  }
}
