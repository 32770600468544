import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import { nullable, stringDate } from 'stores/mst-types';

const DateRange = types.model('DateRange', {
  from: nullable(stringDate),
  to: nullable(stringDate),
});

export const DateStart = types.model('DateStart', {
  from: nullable(stringDate) || undefined,
});

export type DateRangeSnapshot = SnapshotOut<typeof DateRange>;
export type DateStartSnapshot = SnapshotOut<typeof DateStart>;
export type DateRangeInstanceType = Instance<typeof DateRange>;

export default DateRange;
