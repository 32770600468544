import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import { GetVersionResponse } from '../response-types';

const {
  version: { getVersion },
} = urls;

export const createVersionApi = (http: BaseHttpClient) => ({
  getVersion() {
    return http.get<GetVersionResponse>(getVersion.url);
  },
});
