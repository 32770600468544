import React, { memo, useCallback } from 'react';
import { useFormContext } from 'hooks';
import { Box } from 'components/common/box';
import { Typography } from 'components/common/typography';
import { IconControl, InfoIcon } from 'components/common/icon';
import { useCampaignFormContext } from 'components/campaigns/campaign-form/hooks';
import themeColors from 'utils/theme-colors';
import { GeoTargetingFieldValueItemProps } from './types';

export const GeoTargetingFieldValueItem = memo<GeoTargetingFieldValueItemProps>(
  ({ label, value, remove, tooltip, ...props }) => {
    const { disabled } = useFormContext();

    const {
      campaignManager: { campaign },
    } = useCampaignFormContext();

    const onClick = useCallback(() => {
      if (!disabled && value) {
        if (remove) {
          remove(value);
        }
      }
    }, [disabled, remove, value]);

    return (
      <Box
        background={disabled ? themeColors.disabledBackgroundColor : themeColors.purple6}
        border={`1px solid ${campaign?.isEntityLocationChanged && tooltip ? themeColors.yellow6 : themeColors.purple6}`}
        position='relative'
        display='flex'
        borderRadius={5}
        paddingY={9}
        paddingLeft={15}
        paddingRight={13}
        onClick={onClick}
        {...props}
      >
        <Typography
          is='span'
          variant='body-1'
          color={disabled ? 'disabledTextColor' : 'black-1'}
          verticalAlign='middle'
          marginRight={14}
        >
          {label}
        </Typography>
        {remove ? (
          <IconControl name='close' color='primaryColor' size={12} verticalAlign='middle' cursor='pointer' />
        ) : null}
        {tooltip ? (
          <Box position='absolute' top={0} right={0} margin='-5px'>
            <InfoIcon tooltip={tooltip} />
          </Box>
        ) : null}
      </Box>
    );
  },
);
