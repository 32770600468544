import { Instance, types, SnapshotOut, cast } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';
import { MSTAddStaticMethods } from 'stores/utils';
import { ABTestingResultApi } from 'types/ab-testing';

const staticMethods = {
  fromResponseData: (data: ABTestingResultApi): ABTestingResultSnapshot => ({
    resultVariantA: data.variant_a,
    resultVariantB: data.variant_b,
  }),
};

const ABTestingResult = types.model('ABTestingResult', {
  magik_kpi: types.optional(types.string, ''),
  impressions: types.optional(types.string, ''),
  clicks: types.optional(types.string, ''),
  is_winner: types.optional(types.boolean, false),
});

const ABTestingResults = BaseModel.named('ABTestingResults')
  .props({
    resultVariantA: types.optional(ABTestingResult, {}),
    resultVariantB: types.optional(ABTestingResult, {}),
  })
  .actions((self) => ({
    destroy() {
      self.resultVariantA = cast({});
      self.resultVariantB = cast({});
    },
  }));

export type ABTestingOverviewInstanceType = Instance<typeof ABTestingResults>;
export type ABTestingResultSnapshot = SnapshotOut<typeof ABTestingResults>;

export default MSTAddStaticMethods(ABTestingResults, staticMethods);
