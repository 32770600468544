import { types, cast, toGenerator } from 'mobx-state-tree';
import Base from 'stores/models/base';
import { apiFlow } from 'stores/mst-types';
import { fromListResponse } from 'services/api';
import { Pagination } from 'types';
import ListingOverview from './listing-overview';
import ListingPropertyTypeOptions from './listing-property-type-options';

const Listings = Base.named('Listings')
  .props({
    entries: types.optional(types.array(ListingOverview), []),
    count: types.optional(types.number, 0),
    listingsPropertyTypeOptions: types.optional(ListingPropertyTypeOptions, {}),
  })
  .views((self) => ({
    get listingsList() {
      return [...self.entries].map(({ propertyType, ...listings }) => ({
        ...listings,
        propertyType: this.getListingPropertyTypePresentation(propertyType),
      }));
    },

    getListingPropertyTypePresentation(propertyTypeValue: string): Nullable<string> {
      const option = self.listingsPropertyTypeOptions.entries.find(({ value }) => value === propertyTypeValue);

      return option?.label;
    },
  }))
  .actions((self) => ({
    fetch: apiFlow(function* fetch(options?: Pagination) {
      const response = yield* toGenerator(self.api.getListings(options));
      const { data, count } = fromListResponse({
        response: response.data,
      });

      self.entries = cast(data.map(ListingOverview.fromResponseData));
      self.count = count;
    }),

    fetchListingsPropertyTypeOptions: self.listingsPropertyTypeOptions.fetch,

    destroy() {
      self.entries = cast([]);
      self.count = 0;
      self.listingsPropertyTypeOptions.destroy();
    },
  }));

export default Listings;
