import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TagSelectOption } from 'types';
import { TagSelectProps } from './types';

const TAG_SELECT_NEW_CUSTOM_OPTION_PREFIX = '__NEW_CUSTOM_OPTION__';

const filterOption: TagSelectProps['filterOption'] = (search, option) => {
  if (!option) {
    return false;
  }

  const { label, options } = option;

  return !options && String(label).toLowerCase().includes(search.toLowerCase());
};

type CleanedOption = Omit<TagSelectOption, 'isPopular' | 'isCustom'>;
type GroupedOptions = {
  popular: CleanedOption[];
  custom: CleanedOption[];
  all: CleanedOption[];
};
const groupOptions = ({
  options,
  newCustomOptions,
}: {
  options: TagSelectOption[];
  newCustomOptions: CleanedOption[];
}): GroupedOptions =>
  options.reduce(
    (result, option: TagSelectOption) => {
      const { isCustom, isPopular } = option;

      if (isPopular) {
        return {
          ...result,
          popular: [...result.popular, option],
        };
      }

      if (isCustom || isNewTagSelectCustomOption(option)) {
        return {
          ...result,
          custom: [...result.custom, option],
        };
      }

      return {
        ...result,
        all: [...result.all, option],
      };
    },
    { popular: [], custom: newCustomOptions, all: [] } as GroupedOptions,
  );

const filterEmpty = <
  T extends {
    label: string;
    options: any[];
  },
>(
  items: T[],
): T[] => items.filter(({ options }) => options.length);

const createTagSelectCustomOption = (label: ReactNode): Pick<TagSelectOption, 'label' | 'value'> => ({
  label: String(label),
  value: `${TAG_SELECT_NEW_CUSTOM_OPTION_PREFIX}${uuidv4()}`,
});

const isNewTagSelectCustomOption = ({ value }: Pick<TagSelectOption, 'value'>): boolean =>
  String(value).startsWith(TAG_SELECT_NEW_CUSTOM_OPTION_PREFIX);

export { groupOptions, filterEmpty, filterOption, createTagSelectCustomOption, isNewTagSelectCustomOption };
