import { getEnv, getRoot, toGenerator } from 'mobx-state-tree';
import { History } from 'history';
import { apiFlow } from 'stores/mst-types';
import pick from 'lodash.pick';
import Base from 'stores/models/base';
import User, {
  PatchProfileFormValues,
  ChangePasswordFormValues,
  FacebookAdAccountFormValues,
  GoogleManagerAccountFormValues,
} from 'stores/models/user';
import { isEmpty } from 'utils/is';
import { PatchUserProfileApi } from 'types';
import { toJSONDeep } from 'utils/stores';
import locations from 'navigation/locations';
import { warningMessage } from 'components/common/message';
import { fromItemResponse } from 'services/api';

const UPDATING_AVATAR_STATUS_NAME = 'updating-avatar';

export const ProfileStore = Base.named('ProfileStore')
  .views((self) => ({
    get avatarInitialValues() {
      return toJSONDeep({
        avatar: this.profile?.avatar,
      });
    },

    get contactInfoInitialValues() {
      return toJSONDeep(
        this.profile
          ? pick(this.profile, ['firstName', 'lastName', 'email', 'contactPhone', 'website', 'bio', 'state', 'city'])
          : {},
      );
    },

    get featuredPhotosInitialValues() {
      return toJSONDeep({
        featuredPhotos: this.profile?.featuredPhotos,
      });
    },

    get profile() {
      const isSelectedUser = getRoot<any>(self)?.session?.isSelectedUser;

      if (isSelectedUser) {
        return getRoot<any>(self)?.session?.selectedUser;
      }
      return getRoot<any>(self)?.session?.user;
    },

    get avatarPending() {
      return self.getIsPendingUpdateByResourceName(UPDATING_AVATAR_STATUS_NAME);
    },

    get platformIsConnected() {
      const facebook = this.profile?.facebook;
      const google = this.profile?.google;

      return {
        facebookIsConnected: facebook.isConnected,
        googleIsConnected: google.isConnected,
      };
    },
  }))
  .actions((self) => ({
    update: apiFlow(
      function* update(serializedData: Partial<PatchUserProfileApi>) {
        const id = self.profile?.id;

        if (isEmpty(id)) {
          return;
        }

        yield self.api.patchUser({
          id,
          data: serializedData,
        });

        const { session } = getRoot<any>(self);

        yield session.refreshUser();

        if (session.user?.hasAffectedCampaigns) {
          const history = self.services.get<History>('history');
          const t = getEnv(self).get('i18n');

          history.push(locations.campaigns.list.toUrl());

          warningMessage(t('ProfilePage.Alerts.AffectedCampaignsWarning'));
        }
      },
      {
        isUpdate: true,
      },
    ),
  }))
  .actions((self) => ({
    updateAvatar: apiFlow(
      function* updateAvatar(values: Pick<PatchProfileFormValues, 'avatar'>) {
        yield self.update(User.toPatchRequestAvatarData(values));
      },
      {
        isUpdate: true,
        statusName: UPDATING_AVATAR_STATUS_NAME,
        formName: 'profile-avatar',
        successAlert: 'ProfilePage.Alerts.AvatarUpdated',
      },
    ),

    updateContactInfo: apiFlow(
      function* updateContactInfo(values: Omit<PatchProfileFormValues, 'avatar'>) {
        yield self.update(User.toPatchRequestContactInfoData(values));
      },
      {
        isUpdate: true,
        formName: 'profile-contact-info',
        successAlert: 'ProfilePage.Alerts.ContactInfoUpdated',
      },
    ),

    updateFeaturedPhotos: apiFlow(
      function* updateFeaturedPhotos(values: Pick<PatchProfileFormValues, 'featuredPhotos'>) {
        yield self.update(User.toPatchRequestFeaturedPhotosData(values));
      },
      {
        isUpdate: true,
        formName: 'profile-featured-photos',
        successAlert: 'ProfilePage.Alerts.FeaturedPhotosUpdated',
      },
    ),

    changePassword: apiFlow(
      function* changePassword(values: ChangePasswordFormValues) {
        yield self.api.changePassword(User.toChangePasswordRequestData(values));
      },
      {
        isUpdate: true,
        formName: 'change-password',
        successAlert: 'ProfilePage.Alerts.PasswordUpdated',
      },
    ),

    connectFacebook: apiFlow(function* connectFacebook() {
      const response = yield* toGenerator(self.api.connectFacebook());

      const { data } = fromItemResponse({ response: response.data });

      window.location.href = data.redirect_url;
    }),

    chooseFacebookAdAccount: apiFlow(
      function* chooseFacebookAdAccount(values: FacebookAdAccountFormValues) {
        const { session } = getRoot<any>(self);

        yield self.api.facebookAdAccount(User.toFacebookAdAccountRequestData(values));
        yield session.refreshUser();
      },
      {
        isUpdate: true,
      },
    ),

    facebookDisconnect: apiFlow(function* facebookDisconnect() {
      yield self.api.getFacebookDisconnect();

      const { session } = getRoot<any>(self);

      yield session.refreshUser();
    }),

    connectGoogle: apiFlow(function* connectGoogle() {
      const response = yield* toGenerator(self.api.connectGoogle());

      const { data } = fromItemResponse({ response: response.data });

      window.location.href = data.redirect_url;
    }),

    chooseGoogleManagerAccount: apiFlow(
      function* chooseGoogleManagerAccount(values: GoogleManagerAccountFormValues) {
        const { session } = getRoot<any>(self);

        yield self.api.googleManagerAccount(User.toGoogleManagerAccountRequestData(values));
        yield session.refreshUser();
      },
      {
        isUpdate: true,
      },
    ),

    disconnectGoogle: apiFlow(function* disconnectGoogle() {
      yield self.api.disconnectGoogle();

      const { session } = getRoot<any>(self);

      yield session.refreshUser();
    }),
  }));
