import { Instance, types } from 'mobx-state-tree';

const CampaignStatus = types.model({
  id: types.string,
  name: types.string,
});

export type CampaignStatusInstanceType = Instance<typeof CampaignStatus>;

export default CampaignStatus;
