import React, { memo, useMemo } from 'react';
import { useTranslation } from 'hooks';
import { Box } from 'components/common/box';
import { Avatar } from 'components/common/avatar';
import { Typography } from 'components/common/typography';
import { Icon } from 'components/common/icon';
import { ImageUploaderDropArea } from 'components/common/form/image-uploader';
import { getImagePreview } from 'utils/images';
import config from 'config';
import themeColors from 'utils/theme-colors';
import { useDropAreaProps } from './hooks';
import { RemoveButton } from './remove-button';
import { LogoUploaderProps } from './types';

const { options } = config;

const controlSize = 44;

const dropAreaStyle = {
  position: 'absolute' as const,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  opacity: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden' as const,
};

export const LogoUploader = memo<LogoUploaderProps>(
  ({
    userName,
    size = 288,
    value: valueFromProps,
    accept = options.logoUploader.accept,
    previewMode,
    holderProps,
    removeConfirmation,
    removeButtonProps,
    ...props
  }) => {
    const { t } = useTranslation();

    const { value, componentProps, beforeUpload, onRemove } = useDropAreaProps({
      multiple: false,
      value: valueFromProps,
      accept,
      ...props,
    });

    const preview = useMemo(() => value && getImagePreview(value), [value]);

    const confirmation = useMemo(
      () => ({
        title: t('LogoUploader.DeleteConfirmation.Title'),
        description: t('LogoUploader.DeleteConfirmation.Description'),
        confirmText: t('LogoUploader.DeleteConfirmation.ConfirmButton'),
        ...removeConfirmation,
      }),
      [removeConfirmation, t],
    );

    return (
      <Box
        position='relative'
        display='flex'
        flex={1}
        flexDirection='column'
        alignItems='center'
        width={size}
        {...holderProps}
      >
        <Box width={size} height={size} position='relative' marginBottom={30}>
          <Box width='100%' height='100%' borderRadius='50%' overflow='hidden'>
            <Avatar name={userName ?? ''} size={size} src={preview?.src} />
          </Box>
          {previewMode ? null : (
            <Box position='absolute' left='75%' bottom={0} transform='translateX(-50%)'>
              <Box
                width={controlSize}
                height={controlSize}
                borderRadius='50%'
                display='flex'
                alignItems='center'
                justifyContent='center'
                border={`2px solid ${themeColors.white1}`}
                background={themeColors.lightGrey}
              >
                <Icon name='camera-to-take-photos' size={17} color='grey5' />
              </Box>
              <ImageUploaderDropArea
                beforeUpload={beforeUpload}
                multiple={false}
                {...componentProps}
                style={dropAreaStyle}
              />
            </Box>
          )}
        </Box>
        {previewMode ? null : (
          <Typography variant='body-7' color='grey5' textAlign='center' paddingX={45} marginBottom={20}>
            {t('LogoUploader.FileRequirements')}
          </Typography>
        )}
        <Box flex={1} />
        {previewMode ? null : (
          <RemoveButton disabled={!value} confirmation={confirmation} onClick={onRemove} {...removeButtonProps} />
        )}
      </Box>
    );
  },
);
