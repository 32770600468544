import React, { useCallback, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Box } from 'components/common/box';
import { RouterLink } from 'components/common/link';
import { FieldsLayout, TextField, PasswordField, Checkbox } from 'components/common/form';
import { AuthForm } from 'components/auth/auth-form';
import { useTranslation, useStores, usePreloader } from 'hooks';
import { SignInFormValues } from 'stores/session-store';
import locations from 'navigation/locations';
import { validationSchema } from './validation-schemas';

const alternativeActionComponents = [<RouterLink to={locations.signUp.toUrl()} />];

export const SignInWithPassword = observer(() => {
  usePreloader({ pending: false });

  const { t } = useTranslation();
  const { session } = useStores();
  const { isSignedIn, moveLoggedInUserToInitialPage } = session;

  useEffect(() => {
    if (isSignedIn) {
      moveLoggedInUserToInitialPage();
    }
  }, [isSignedIn, moveLoggedInUserToInitialPage]);

  const onSubmit = useCallback(
    async (values: SignInFormValues) => {
      await session.signIn(values);
    },
    [session],
  );

  return (
    <AuthForm
      title={t('Auth.SignIn.Title')}
      alternativeAction={<Trans i18nKey='Auth.SignIn.AlternativeAction' components={alternativeActionComponents} />}
      submitText={t('Auth.SignIn.SubmitButton')}
      onSubmit={onSubmit}
      validation={validationSchema}
      name='sign-in-with-password'
    >
      <Box>
        <FieldsLayout marginBottom={25}>
          <TextField
            name='username'
            label={t('Auth.SignIn.Labels.Email')}
            placeholder={t('Auth.SignIn.Placeholders.Email')}
          />
          <PasswordField
            name='password'
            label={t('Auth.SignIn.Labels.Password')}
            placeholder={t('Auth.SignIn.Labels.Password')}
          />
        </FieldsLayout>
        <Box display='flex' alignItems='center'>
          <Checkbox name='rememberMe'>{t('Auth.SignIn.Labels.RememberMe')}</Checkbox>
          <Box marginLeft='auto'>
            <RouterLink variant='body-13' to={locations.forgotPassword.toUrl()}>
              {t('Auth.SignIn.ForgotPasswordLink')}
            </RouterLink>
          </Box>
        </Box>
      </Box>
    </AuthForm>
  );
});
