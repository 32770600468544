import { types } from 'mobx-state-tree';
import { ValuesType } from 'utility-types';
import { apiFlow } from 'stores/mst-types';
import Base from 'stores/models/base';
import ComposedCampaignReports, {
  ComposedCampaignReportsSnapshot,
} from 'stores/models/campaigns/composed-campaign-reports';
import { CampaignReportsSnapshot } from 'stores/models/campaigns/campaign-reports';
import { CampaignManagerStore } from 'stores/campaigns/campaign-manager-store';
import { CampaignInstanceType } from 'stores/models/campaigns/campaign';
import { CampaignReportsRequestOptionsApi } from 'types';
import { sortAlphabetically } from 'utils/sorting';
import { chartDate } from 'utils/formatters';
import { getWeekBefore } from 'utils/dates';
import { toJSONDeep } from 'utils/stores';

export const CAMPAIGN_REPORT_TABS = {
  OVERALL: 'OVERALL',
  FACEBOOK_AND_INSTAGRAM: 'FACEBOOK_AND_INSTAGRAM',
  GOOGLE_ADS: 'GOOGLE_ADS',
  GOOGLE_ADWORDS: 'GOOGLE_ADWORDS',
} as const;

export type TabName = ValuesType<typeof CAMPAIGN_REPORT_TABS>;

const defaultReportTab = CAMPAIGN_REPORT_TABS.OVERALL;

export const CampaignReportsStore = Base.named('CampaignReportsStore')
  .props({
    campaignManager: types.optional(CampaignManagerStore, {}),
    selectedTab: types.optional(types.enumeration<TabName>(Object.values(CAMPAIGN_REPORT_TABS)), defaultReportTab),
    composedReports: types.optional(ComposedCampaignReports, {}),
    isInitialized: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get initialValues() {
      return { dateRange: [getWeekBefore(), new Date()] };
    },

    get campaignReportsJson(): ComposedCampaignReportsSnapshot {
      return toJSONDeep(self.composedReports) as ComposedCampaignReportsSnapshot;
    },

    get campaignReports(): CampaignReportsSnapshot {
      switch (self.selectedTab) {
        case CAMPAIGN_REPORT_TABS.OVERALL:
          return this.campaignReportsJson.overall;
        case CAMPAIGN_REPORT_TABS.FACEBOOK_AND_INSTAGRAM:
          return this.campaignReportsJson.facebookAndInstagram;
        case CAMPAIGN_REPORT_TABS.GOOGLE_ADS:
          return this.campaignReportsJson.googleAds;
        case CAMPAIGN_REPORT_TABS.GOOGLE_ADWORDS:
          return this.campaignReportsJson.googleAdWords;
        default:
          throw new Error(`Unhandled type for tab: ${self.selectedTab}`);
      }
    },

    get summary() {
      return { ...this.campaignReports.summary };
    },

    get leadsAndApplications() {
      return { ...this.campaignReports.leadsAndApplications };
    },

    get analytics() {
      return sortAlphabetically({
        items: [...this.campaignReports.analytics.entries],
        field: 'label',
      }).map(({ label, ...item }) => ({
        label: chartDate(label),
        ...item,
      }));
    },

    get ageBreakdown() {
      return [...this.campaignReports.ageBreakdown.entries];
    },

    get regionBreakdown() {
      return [...this.campaignReports.regionBreakdown.entries];
    },

    get totalImpressions() {
      return [...this.campaignReports.totalImpressions.entries];
    },

    get totalClicks() {
      return [...this.campaignReports.totalClicks.entries];
    },

    get campaign() {
      return self.campaignManager.campaign;
    },

    get target() {
      return self.campaignManager.target;
    },

    get isFacebookOrInstagramActive() {
      return this.campaign?.facebookAd?.isActive || this.campaign?.instagramAd?.isActive;
    },

    get isGoogleAdWordsActive() {
      return this.campaign?.googleAdWordsAd?.isActive;
    },

    get isGoogleAdsActive() {
      return this.campaign?.googleAdsAd?.isActive;
    },

    get facebookAds() {
      const facebookAd = this.campaign?.facebookAd;

      if (!facebookAd?.isActive) {
        return [];
      }
      const { titles, descriptions, images } = facebookAd;

      return (
        images?.map(({ src }) => ({
          title: titles?.length ? titles[0].label : '',
          description: descriptions?.length ? descriptions[0].label : '',
          images: [{ src }],
        })) || []
      );
    },

    get instagramAds() {
      const instagramAd = this.campaign?.instagramAd;

      if (!instagramAd?.isActive) {
        return [];
      }
      const { titles, descriptions, images } = instagramAd;

      return (
        images?.map(({ src }) => ({
          title: titles?.length ? titles[0].label : '',
          description: descriptions?.length ? descriptions[0].label : '',
          images: [{ src }],
        })) || []
      );
    },

    get googleAdWordsAd() {
      const googleAdWordsAd = this.campaign?.googleAdWordsAd;

      if (!googleAdWordsAd) {
        return null;
      }

      const { titles, descriptions } = googleAdWordsAd;
      const url = this.target?.url;

      return {
        title: titles?.length ? titles[0].label : '',
        description: descriptions?.length ? descriptions[0].label : '',
        url,
      };
    },

    get googleAd() {
      const googleAdsAd = this.campaign?.googleAdsAd;

      if (!googleAdsAd) {
        return null;
      }

      const { titles, descriptions, images, logo } = googleAdsAd;
      const url = this.target?.url;

      return {
        title: titles?.length ? titles[0].label : '',
        description: descriptions?.length ? descriptions[0].label : '',
        url,
        images,
        logo,
      };
    },

    get facebookPage() {
      const fbPageId = self.campaignManager.campaign?.fbPageId;
      return (
        fbPageId && self.campaignManager.socialNetworks.facebookPageOptions.find(({ value }) => value === fbPageId)
      );
    },

    get instagramAccount() {
      const instagramAccountId = self.campaignManager.campaign?.instagramAccountId;
      return (
        instagramAccountId &&
        self.campaignManager.socialNetworks.instagramAccountOptions.find(({ value }) => value === instagramAccountId)
      );
    },

    get isFbAndInstagramRequestsLimitExceeded() {
      return self.composedReports.isFbAndInstagramRequestsLimitExceeded;
    },
  }))
  .actions((self) => ({
    selectTab(tabName: TabName) {
      self.selectedTab = tabName;
    },
  }))
  .actions((self) => ({
    init: apiFlow(function* init({ id }: Pick<CampaignInstanceType, 'id'>) {
      yield Promise.all([
        self.campaignManager.loadCampaign({ id }),
        self.campaignManager.socialNetworks.loadFacebookPageOptions(),
        self.campaignManager.socialNetworks.loadInstagramAccountOptions(),
      ]);

      if (self.campaign) {
        const { targetId, targetKind } = self.campaign;

        yield self.campaignManager.loadTarget({
          id: targetId as string,
          targetKindId: targetKind?.id as string,
        });
      }

      self.isInitialized = true;
    }),

    loadReports: apiFlow(function* loadReports(options: CampaignReportsRequestOptionsApi) {
      yield self.composedReports.fetch(options);

      const isFacebookAndInstagramTabSelected = self.selectedTab === CAMPAIGN_REPORT_TABS.FACEBOOK_AND_INSTAGRAM;

      if (self.isFbAndInstagramRequestsLimitExceeded && isFacebookAndInstagramTabSelected) {
        self.selectTab(defaultReportTab);
      }
    }),

    destroy: () => {
      self.campaignManager.destroy();
      self.composedReports.destroy();
      self.selectedTab = defaultReportTab;
      self.isInitialized = false;
    },
  }));
