import React, { useMemo, useState, memo, useCallback, useEffect } from 'react';
import { Tabs as AntdTabs } from 'antd';
import classnames from 'classnames';
import { Box } from 'components/common/box';
import { Typography } from 'components/common/typography';
import { Icon } from 'components/common/icon';
import { ContentList } from 'components/common/content-list';
import { TabsContext } from './context';
import { TabsProps } from './types';
import styles from './styles.module.scss';

const { TabPane } = AntdTabs;

export const Tabs = memo<TabsProps>(
  ({
    className: classNameFromProps = '',
    items: itemsFromProps,
    switcherProps,
    contentProps,
    tabWrapperProps,
    initialTab = 0,
    onSelect,
    showSelect,
    ...props
  }) => {
    const [selectedTabKey, setSelectedTabKey] = useState(String(initialTab));

    const className = classnames(
      styles.root,
      itemsFromProps[Number(selectedTabKey)] && itemsFromProps[Number(selectedTabKey)].isErrorPresent
        ? 'has-error'
        : classNameFromProps,
    );

    useEffect(() => {
      setSelectedTabKey(String(initialTab));
    }, [initialTab, itemsFromProps.length]);

    const items = useMemo(
      () =>
        itemsFromProps.map(
          (
            {
              label,
              activeLabel,
              disabled,
              content,
              icon: standardIcon,
              activeIcon,
              errorIcon,
              labelColor,
              isErrorPresent,
              visible = true,
            },
            index,
          ) => {
            const isActive = String(index) === String(selectedTabKey);

            const icon = isActive && activeIcon ? activeIcon : standardIcon;

            const iconTab = isErrorPresent ? errorIcon : icon;

            return visible ? (
              <TabPane
                tab={
                  <Typography
                    variant='body-3'
                    display='flex'
                    alignItems='center'
                    {...tabWrapperProps}
                    {...switcherProps}
                  >
                    {iconTab}
                    <Typography variant='body-3' color={labelColor} display='flex' alignItems='center'>
                      {isActive && activeLabel ? activeLabel : label}
                    </Typography>
                    {isErrorPresent ? <Icon name='warning' color='errorColor' size={18} marginLeft={7} /> : null}
                  </Typography>
                }
                key={index}
                disabled={disabled}
              >
                {content ? (
                  Array.isArray(content) && content.length ? (
                    <ContentList content={content} contentProps={contentProps} showSelect={showSelect} />
                  ) : (
                    <Box paddingX={50} paddingY={50} {...contentProps}>
                      {content}
                    </Box>
                  )
                ) : null}
              </TabPane>
            ) : null;
          },
        ),
      [itemsFromProps, selectedTabKey, tabWrapperProps, switcherProps, contentProps, showSelect],
    );

    const contextValue = useMemo(() => ({}), []);

    const onChange = useCallback<NonNullable<TabsProps['onChange']>>(
      (key) => {
        setSelectedTabKey(key);

        if (onSelect) {
          onSelect({
            key,
            value: itemsFromProps[Number(key)]?.value,
          });
        }
      },
      [itemsFromProps, onSelect],
    );

    return (
      <TabsContext.Provider value={contextValue}>
        <AntdTabs className={className} onChange={onChange} activeKey={selectedTabKey} {...props}>
          {items}
        </AntdTabs>
      </TabsContext.Provider>
    );
  },
);
