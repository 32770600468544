import { types } from 'mobx-state-tree';
import Base from 'stores/models/base';
import { apiFlow } from 'stores/mst-types';
import Campaigns from 'stores/models/campaigns/campaigns';
import { CampaignsFilter } from 'types';
import config from 'config';

const { sorting } = config.api;

export const CAMPAIGNS_SORTING = [
  `-${sorting.updatedCampaign}`,
  `-${sorting.isEntityLocationChanged}`,
  `-${sorting.createdAt}`,
];

export const CampaignsStore = Base.named('CampaignsStore')
  .props({
    campaigns: types.optional(Campaigns, {}),
  })
  .views((self) => ({
    get campaignsList() {
      return [...self.campaigns.entries];
    },

    get count() {
      return self.campaigns.count;
    },
  }))
  .actions((self) => ({
    fetch: apiFlow(function* fetch(options?: CampaignsFilter) {
      yield self.campaigns.fetch({
        sorting: CAMPAIGNS_SORTING,
        ...options,
      });
    }),

    destroy: () => {
      self.campaigns.destroy();
    },
  }));
