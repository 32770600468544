import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';

const PieItem = BaseModel.named('PieItem').props({
  type: types.string,
  value: types.number,
});

export type PieItemInstanceType = Instance<typeof PieItem>;
export type PieItemSnapshot = SnapshotOut<typeof PieItem>;

export default PieItem;
