import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { Box } from 'components/common/box';
import { withThemeColor } from 'hocs/with-theme-color';
import styles from './styles.module.scss';
import { TypographyProps } from './types';

export const Typography = withThemeColor<TypographyProps>()(
  forwardRef<any, TypographyProps>(({ className: classNameFromProps = '', variant = 'body-1', ...props }, ref) => {
    const className = classnames(styles[variant], classNameFromProps);

    return <Box ref={ref} className={className} {...props} />;
  }),
);
