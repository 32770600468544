import React, { memo, useCallback } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { TextField } from 'components/common/form/text-field';
import { useField } from 'hooks';
import { isEmpty } from 'utils/is';
import { NumberInputProps } from './types';

export const NumberInput = memo<NumberInputProps>(({ valueAsNumber = true, numberFormatProps, ...props }) => {
  const {
    field: { onChange, ...field },
    componentProps,
  } = useField(props);
  const { name } = field;

  const onChangeHandler = useCallback(
    ({ value }: any) => {
      const processedValue = valueAsNumber && !isEmpty(value) && value !== '' ? Number(value) : value;

      onChange({
        target: {
          name,
          value: processedValue,
        },
      });
    },
    [onChange, name, valueAsNumber],
  );

  return (
    <NumberFormat
      onValueChange={onChangeHandler}
      thousandSeparator
      isNumericString
      decimalScale={2}
      // @ts-ignore
      customInput={TextField}
      allowNegative={false}
      {...numberFormatProps}
      {...(componentProps as Partial<NumberFormatProps>)}
      {...field}
      value={field?.value ?? ''}
    />
  );
});
