import React, { ReactNode } from 'react';
import ErrorView from './error-view';

interface DefaultErrorBoundaryProps {}

interface DefaultErrorBoundaryState {
  error: Error | null;
}

export default class DefaultErrorBoundary extends React.Component<
  DefaultErrorBoundaryProps,
  DefaultErrorBoundaryState
> {
  constructor(props: DefaultErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error });
    console.log('ERROR BOUNDARY', error, errorInfo);
  }

  render(): ReactNode {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return <ErrorView error={error} />;
    }

    return children;
  }
}
