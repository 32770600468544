import React, { memo } from 'react';
import themeColors from 'utils/theme-colors';
import { Box } from 'components/common/box';
import { VerticalBarProps } from './types';

export const VerticalBar = memo<VerticalBarProps>((props) => (
  <Box
    width={0}
    height='calc(100% - 35px)'
    position='absolute'
    left='50%'
    border={`solid 0.1px ${themeColors.grey7}`}
    {...props}
  />
));
