import { DateRangeFormValue, DateRangeFormStartValue } from 'types';
import { DateRangeSnapshot, DateStartSnapshot } from 'stores/models/date-range';
import { isNonValue } from 'utils/is';
import { stringify } from 'utils/json';
import { hasDifferences } from 'utils/objects';

const parseBooleanString = (value?: unknown): boolean => value === 'true';

const toDateRangeFromValue = (value?: Partial<DateRangeSnapshot>): Nullable<OptionalItems<DateRangeFormValue>> => {
  if (!value) {
    return undefined;
  }

  const { from, to } = value;

  return [from ? new Date(from) : undefined, to ? new Date(to) : undefined];
};

const toDateStartFromValue = (value?: Partial<DateStartSnapshot>): Nullable<DateRangeFormStartValue> => {
  if (!value) {
    return undefined;
  }

  const { from } = value;

  return from ? new Date(from) : undefined;
};

const hasDirtyValues = ({
  initialValues,
  values,
}: {
  initialValues: Record<string, any>;
  values: Record<string, any>;
}): boolean => {
  initialValues.fbMonthlyBudget = values.fbDailyBudget * 30;
  values.fbMonthlyBudget = initialValues.fbMonthlyBudget;
  initialValues.adsMonthlyBudget = values.adsDailyBudget * 30;
  initialValues.adwordsMonthlyBudget = values.adwordsDailyBudget * 30;
  values.adwordsMonthlyBudget = initialValues.adwordsMonthlyBudget;
  values.adsMonthlyBudget = initialValues.adsMonthlyBudget;
  initialValues.variantAbTesting = values.variantAbTesting;
  initialValues.yardiAutoUpdate = values.yardiAutoUpdate;

  return hasDifferences(initialValues, values, (initialValue, value) => {
    const isBothNonValue = isNonValue(initialValue) && isNonValue(value);

    return !isBothNonValue && stringify(initialValue) !== stringify(value);
  });
};

export { parseBooleanString, toDateRangeFromValue, hasDirtyValues, toDateStartFromValue };
