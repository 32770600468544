import { useEffect } from 'react';
import { isEmpty } from 'utils/is';

export const useCssVariables = ({
  getElement,
  cssVariables,
}: {
  getElement: () => any;
  cssVariables?: Record<string, any>;
}): void => {
  useEffect(() => {
    const element = getElement();

    if (!element || !cssVariables) {
      return;
    }

    Object.entries(cssVariables).forEach(([variable, value]) => {
      if (isEmpty(value)) {
        return;
      }

      element.style.setProperty(variable, value);
    });
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [cssVariables]);
};
