import React, { memo, useState, useEffect, useCallback } from 'react';
import { AspectRatio } from 'components/common/aspect-ratio';
import { Image as ImageAntd } from 'components/common/image';
import themeColors from 'utils/theme-colors';
import { Typography } from 'components/common/typography';
import { Box } from 'components/common/box';
import { ImageItemProps } from './types';

const oldStyle = {
  border: `3px solid ${themeColors.yellow6}`,
};

export const ImageItem = memo<ImageItemProps>(({ isOld, src, ...props }) => {
  const [aspect, setAspect] = useState('');

  const getCaption = useCallback(
    (src: string): void => {
      const image = new Image();
      image.src = src;

      image.onload = () => {
        const { height, width } = image;

        const ratioWidth = ((width / height) * 100) / 100;
        const ratioWidthResult = Number.isInteger(ratioWidth) ? ratioWidth : ratioWidth.toFixed(2);
        const ratioHeight = Math.ceil(((height / width) * 100) / 100);

        setAspect(`${ratioWidthResult}:${ratioHeight}`);
      };
    },
    [setAspect],
  );

  useEffect(() => {
    getCaption(src);
  }, [getCaption, src]);

  return (
    <Box position='relative'>
      <AspectRatio borderRadius={5} overflow='hidden' {...(isOld ? oldStyle : {})} {...props}>
        <ImageAntd src={src} height='100%' />
      </AspectRatio>
      <Typography variant='body-7' color='grey5' textAlign='center' position='absolute' left={55} marginTop={10}>
        {aspect}
      </Typography>
    </Box>
  );
});
