import { SnapshotOut, Instance, types } from 'mobx-state-tree';
import pick from 'lodash.pick';
import BaseModel from 'stores/models/base';
import { MSTAddStaticMethods } from 'stores/utils';
import { InvitedUserApi } from 'types';

const staticMethods = {
  fromResponseData: (data: InvitedUserApi): InvitedUserSnapshot => ({
    ...pick(data, ['email']),
    firstName: data.first_name,
    lastName: data.last_name,
  }),
};

const InvitedUser = BaseModel.named('InvitedUser').props({
  firstName: types.string,
  lastName: types.string,
  email: types.string,
});

export type InvitedUserSnapshot = SnapshotOut<typeof InvitedUser>;
export type InvitedUserInstanceType = Instance<typeof InvitedUser>;

export default MSTAddStaticMethods(InvitedUser, staticMethods);
