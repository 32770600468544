import { ContainerInterface } from 'services';
import { createMigrate, persistStore } from 'utils/persist-store';
import config from 'config';
import { RootStore, RootStoreInstance } from './root-store';
import { migrations } from './migrations';

export async function configureStore(container: ContainerInterface): Promise<RootStoreInstance> {
  const rootStore = await persistStore({
    key: 'root',
    version: 3,
    storeModel: RootStore,
    storeEnv: container,
    migrate: createMigrate(migrations, {
      debug: config.stores.migrations.debug,
    }),
    whitelist: ['session'],
  });

  return rootStore;
}
