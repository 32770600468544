import { Instance, types, SnapshotOut } from 'mobx-state-tree';
import { LineChartItemApi } from 'types';
import Base from 'stores/models/base';
import { MSTAddStaticMethods } from 'stores/utils';
import LineItem from './line-item';

const staticMethods = {
  fromResponseData: (data: LineChartItemApi[]): LineItemsSnapshot => {
    const entries = data.map(({ label, value, category }) => ({
      label,
      value: Number(value),
      category,
    }));

    return { entries };
  },
};

const LineItems = Base.named('LineItems').props({
  entries: types.optional(types.array(LineItem), []),
});

export type LineItemsInstanceType = Instance<typeof LineItems>;
export type LineItemsSnapshot = SnapshotOut<typeof LineItems>;

export default MSTAddStaticMethods(LineItems, staticMethods);
