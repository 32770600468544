import { Instance, cast, types, toGenerator, SnapshotOut } from 'mobx-state-tree';
import { CampaignReportsRequestOptionsApi, ComposedCampaignReportsApi } from 'types';
import BaseModel from 'stores/models/base';
import { MSTAddStaticMethods } from 'stores/utils';
import { apiFlow } from 'stores/mst-types';
import { fromItemResponse } from 'services/api';
import CampaignReports from './campaign-reports';

const staticMethods = {
  fromResponseData: (data: ComposedCampaignReportsApi): ComposedCampaignReportsSnapshot => ({
    isFbAndInstagramRequestsLimitExceeded: Boolean(data.is_fb_and_insta_requests_limit_exceeded),
    overall: CampaignReports.fromResponseData(data.overall),
    facebookAndInstagram: CampaignReports.fromResponseData(data.facebook_and_instagram),
    googleAds: CampaignReports.fromResponseData(data.google_ads),
    googleAdWords: CampaignReports.fromResponseData(data.google_adwords),
  }),
};

const ComposedCampaignReports = BaseModel.named('ComposedCampaignReports')
  .props({
    isFbAndInstagramRequestsLimitExceeded: types.optional(types.boolean, false),
    overall: types.optional(CampaignReports, {}),
    facebookAndInstagram: types.optional(CampaignReports, {}),
    googleAds: types.optional(CampaignReports, {}),
    googleAdWords: types.optional(CampaignReports, {}),
  })
  .actions((self) => ({
    fetch: apiFlow(function* fetch(filter: CampaignReportsRequestOptionsApi) {
      if (filter?.filters?.start_date !== undefined && filter?.filters?.end_date !== undefined) {
        const response = yield* toGenerator(self.api.getCampaignReports(filter));

        const { data } = fromItemResponse({
          response: response.data,
        });
        const { isFbAndInstagramRequestsLimitExceeded, overall, facebookAndInstagram, googleAds, googleAdWords } =
          staticMethods.fromResponseData(data);

        self.isFbAndInstagramRequestsLimitExceeded = isFbAndInstagramRequestsLimitExceeded;
        self.overall = cast(overall);
        self.facebookAndInstagram = cast(facebookAndInstagram);
        self.googleAds = cast(googleAds);
        self.googleAdWords = cast(googleAdWords);
      }
    }),

    destroy() {
      self.overall = cast({});
      self.facebookAndInstagram = cast({});
      self.googleAds = cast({});
      self.googleAdWords = cast({});
    },
  }));

export type ComposedCampaignReportsInstanceType = Instance<typeof ComposedCampaignReports>;
export type ComposedCampaignReportsSnapshot = SnapshotOut<typeof ComposedCampaignReports>;

export default MSTAddStaticMethods(ComposedCampaignReports, staticMethods);
