import { memo, useContext, useEffect } from 'react';
import { PreloaderContext } from 'contexts';

export const SuspenseFallback = memo(() => {
  const preloaderContext = useContext(PreloaderContext);

  useEffect(() => {
    if (!preloaderContext) {
      return;
    }

    preloaderContext.setIsPreloaderActive(true);
  }, [preloaderContext]);

  return null;
});
