import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';
import { ServerValidationErrors } from 'utils/types';
import * as objects from 'utils/objects';
import { HttpClientError } from 'services/api/errors';

const getBadRequestErrors = (errors: unknown): string[] =>
  ([] as (string | Record<string, string[]>)[]).concat(errors as (string | Record<string, string[]>)[]).map((item) => {
    if (typeof item === 'object') {
      return Object.values(item).flat().join('\n');
    }

    return item;
  });

export const FormErrorsStore = BaseModel.named('FormErrorsStore')
  .props({
    formServerErrors: types.optional(types.frozen(), {}),
  })
  .actions((self) => ({
    setFormServerErrors<T extends { non_field_errors?: string[]; __all__?: string[] }>({
      formName,
      errors = {},
      isRateLimitExceeded = false,
      isSomethingWentWrong = false,
      responseError,
    }: {
      formName: string;
      errors?: ServerValidationErrors<T>;
      isRateLimitExceeded?: boolean;
      isSomethingWentWrong?: boolean;
      responseError: any;
    }): void {
      const { non_field_errors: generalErrors, __all__: multipleFieldErrors, ...rawFieldErrors } = errors;

      const fieldErrors = Object.entries(rawFieldErrors).reduce(
        (result, [field, errors]) => [
          ...result,
          {
            field: field as string,
            errors: getBadRequestErrors(errors),
          },
        ],
        [] as { field: string; errors: string[] }[],
      );

      self.formServerErrors = {
        ...self.formServerErrors,
        [formName]: {
          isRateLimitExceeded,
          isSomethingWentWrong,
          generalErrors: ([] as (string | undefined)[])
            .concat(generalErrors, multipleFieldErrors)
            .filter(Boolean)
            .map((message) => message || ''),
          fieldErrors,
          responseError,
        },
      };
    },

    resetFormServerErrors({ formName }: { formName: string }): void {
      const { [formName]: removedFormErrors, ...formsErrors } = self.formServerErrors;

      self.formServerErrors = formsErrors;
    },
  }))
  .actions((self) => ({
    setFormBadRequestError({ formName, error }: { formName: string; error: HttpClientError }): void {
      self.setFormServerErrors({
        formName,
        errors: objects.flat({ data: error?.response?.data }),
        responseError: error,
      });
    },
  }));

export interface FormErrorsStoreInstance extends Instance<typeof FormErrorsStore> {}
export interface FormErrorsStoreSnapshot extends SnapshotOut<typeof FormErrorsStore> {}
