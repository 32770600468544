import React, { memo } from 'react';
import { Box } from 'components/common/box';
import { Icon } from 'components/common/icon';
import { Typography } from 'components/common/typography';
import { RowsFieldAddButtonProps } from './types';

export const RowsFieldAddButton = memo<RowsFieldAddButtonProps>(({ children, onAdd, ...props }) => (
  <Typography variant='body-4' color='grey5' display='inline-block' {...props}>
    <Icon
      name='plus'
      size={20}
      circle
      color='primaryColor'
      marginRight={25}
      verticalAlign='middle'
      cursor='pointer'
      onClick={onAdd}
    />
    <Box is='span' verticalAlign='middle'>
      {children}
    </Box>
  </Typography>
));
