import React, { memo, MouseEventHandler, useMemo } from 'react';
import classnames from 'classnames';
import { Box } from 'components/common/box';
import { Icon } from 'components/common/icon';
import themeColors from 'utils/theme-colors';
import { TagProps } from './types';
import styles from './styles.module.scss';

const onTagMouseDown: MouseEventHandler = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

export const Tag = memo<TagProps>(
  ({ className: classNameFromProps = '', children, onClose, closable, isOld, isInvalid, ...props }) => {
    const className = classnames(styles.root, classNameFromProps);

    const backgroundColor = useMemo(() => {
      if (isInvalid) {
        return themeColors.errorBackground;
      }

      if (isOld) {
        return themeColors.yellow1;
      }

      return themeColors.grey12;
    }, [isInvalid, isOld]);

    const iconColor = useMemo(() => {
      if (isInvalid) {
        return 'errorColor';
      }

      if (isOld) {
        return 'yellow4';
      }

      return 'primaryColor';
    }, [isInvalid, isOld]);

    return (
      <Box className={className} onMouseDown={onTagMouseDown} title={children} background={backgroundColor} {...props}>
        <Box className={styles.content}>{children}</Box>
        {closable ? <Icon name='close' size={12} color={iconColor} onClick={onClose} /> : null}
      </Box>
    );
  },
);
