import { Options, StoreState } from './types';
import { PERSIST_KEY } from './constants';

type StrToBoolMap = { [key: string]: boolean };

const arrToDict = (arr?: string[]): StrToBoolMap =>
  !arr
    ? {}
    : arr.reduce((dict, elem) => {
        dict[elem] = true;
        return dict;
      }, {} as StrToBoolMap);

const isString = (value: any): value is string => typeof value === 'string';

const processListedFields = ({
  state,
  blacklist,
  whitelist,
}: { state: Record<string, any> } & Pick<Options, 'whitelist' | 'blacklist'>): Record<string, any> => {
  const whitelistDict = arrToDict(whitelist);
  const blacklistDict = arrToDict(blacklist);

  return Object.entries(state).reduce((result, [key, value]) => {
    if ((whitelist && !whitelistDict[key]) || (blacklist && blacklistDict[key])) {
      return result;
    }

    return {
      ...result,
      [key]: value,
    };
  }, {});
};

const prepareStorageState = ({
  storeState = {},
  version,
  whitelist,
  blacklist,
}: { storeState?: StoreState } & Pick<Options, 'version' | 'whitelist' | 'blacklist'>): string =>
  JSON.stringify({
    ...processListedFields({ state: storeState, whitelist, blacklist }),
    [PERSIST_KEY]: { version },
  });

const defaultMigrate = (v: any) => v;

const prepareStoreState = async ({
  storageState,
  whitelist,
  blacklist,
  migrate = defaultMigrate,
  version,
}: {
  storageState: Nullable<string>;
  version: NonNullable<Options['version']>;
} & Pick<Options, 'whitelist' | 'blacklist' | 'migrate'>): Promise<StoreState> => {
  try {
    const parsedStorageState = isString(storageState) ? JSON.parse(storageState) : storageState;

    if (!parsedStorageState) {
      return {};
    }

    const state = await migrate(parsedStorageState, version);

    return processListedFields({
      state: state || {},
      whitelist,
      blacklist,
    });
  } catch (error) {
    console.log(error);
    return {};
  }
};

export { prepareStoreState, prepareStorageState };
