import { types, cast, toGenerator, getEnv } from 'mobx-state-tree';
import Base from 'stores/models/base';
import { errorMessage } from 'components/common/message';
import { apiFlow } from 'stores/mst-types';
import { fromListResponse } from 'services/api';
import { Filtration } from 'types';
import GoogleManagerAccountOverview from './google-manager-account-overview';

const GoogleManagerAccounts = Base.named('GoogleManagerAccounts')
  .props({
    entries: types.optional(types.array(GoogleManagerAccountOverview), []),
    count: types.optional(types.number, 0),
    isOpened: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    fetch: apiFlow(function* fetch(options?: Filtration) {
      const t = getEnv(self).get('i18n');

      try {
        self.isOpened = true;
        const response = yield* toGenerator(self.api.getGoogleManagerAccounts(options));
        const { data, count } = fromListResponse({
          response: response.data,
        });

        self.entries = cast(data.map(GoogleManagerAccountOverview.fromResponseData));
        self.count = count;
      } catch (err: any) {
        self.isOpened = false;

        const errorManageAccounts = err.response.data.manager_accounts_error;

        if (errorManageAccounts) {
          errorMessage(t('ProfilePage.Platforms.Modal.Google.GoogleAdAccounts.Errors.ErrorManageAccount'));
        }
      }
    }),
    destroy() {
      self.entries = cast([]);
      self.count = 0;
      self.isOpened = true;
    },
  }));

export default GoogleManagerAccounts;
