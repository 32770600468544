import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { GoogleAdsAdApi, GoogleAdsAdApiSerialized } from 'types';
import BaseModel from 'stores/models/base';
import Image from 'stores/models/image';
import TitleOption from 'stores/models/ads/title-option';
import DescriptionOption from 'stores/models/ads/description-option';
import { MSTAddStaticMethods } from 'stores/utils';
import { nullable } from 'stores/mst-types';
import { excludeEmptyObjects } from 'utils/arrays';

const staticMethods = {
  fromResponseData: (data: GoogleAdsAdApi): GoogleAdsAdSnapshot => ({
    isActive: Boolean(data?.is_active),
    titles: data.titles && data.titles.map(TitleOption.fromResponseData),
    descriptions: data.descriptions && data.descriptions.map(DescriptionOption.fromResponseData),
    images: data.images.map(Image.fromResponseData),
    logo: { src: data.logo as string },
    name: 'googleAdWordsAd',
  }),

  toRequestData: (data: GoogleAdsAdSnapshot): GoogleAdsAdApiSerialized => ({
    ...pick(data, ['images', 'logo']),
    titles: data.titles && excludeEmptyObjects(data.titles)?.length ? data.titles.map(TitleOption.toRequestData) : null,
    descriptions:
      data.descriptions && excludeEmptyObjects(data.descriptions)?.length
        ? data.descriptions?.map(DescriptionOption.toRequestData)
        : null,
    is_active: Boolean(data.isActive),
  }),
};

const GoogleAdsAd = BaseModel.named('GoogleAdsAd').props({
  isActive: types.boolean,
  titles: nullable(types.array(TitleOption)),
  descriptions: nullable(types.array(DescriptionOption)),
  images: types.array(Image),
  logo: Image,
  name: types.string,
});

export type GoogleAdsAdInstanceType = Instance<typeof GoogleAdsAd>;
export type GoogleAdsAdSnapshot = SnapshotOut<typeof GoogleAdsAd>;

export default MSTAddStaticMethods(GoogleAdsAd, staticMethods);
