import { env } from 'utils/env';
import { api } from './api';
import { features } from './features';
import { options } from './options';
import { sentry } from './sentry';
import { urls } from './urls';
import { accessRoles } from './access-roles';
import { stores } from './stores';
import { validations } from './validations';
import { sections } from './sections';
import { platforms } from './platforms';
import { policyLink } from './policy-link';
import { statuses } from './statuses';

const config = {
  applicationName: env.REACT_APP_MAIN_DOCUMENT_TITLE || 'Minerva',
  marketingDescriptionUrl: env.MARKETING_DESCRIPTION_URL,
  parentSite: env.REACT_APP_PARENT_SITE,
  parentSiteName: env.REACT_APP_PARENT_SITE_NAME,
  supportEmail: env.REACT_APP_SUPPORT_EMAIL,
  version: {
    enabled: env.REACT_APP_VERSION_SHOW_VERSION,
    targetEnvironment: env.REACT_APP_VERSION_TARGET_ENVIRONMENT,
    packageName: env.REACT_APP_VERSION_PACKAGE_NAME,
    packageVersion: env.REACT_APP_VERSION_PACKAGE_VERSION,
  },
  api,
  features,
  options,
  sentry,
  urls,
  accessRoles,
  stores,
  validations,
  sections,
  platforms,
  policyLink,
  statuses,
};

export default config;
