import React, { useCallback, useState, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Link, RouterLink } from 'components/common/link';
import { Typography } from 'components/common/typography';
import { TextField, PasswordField, Checkbox, StateField, CityField, PhoneNumberField } from 'components/common/form';
import { AuthForm } from 'components/auth/auth-form';
import { useTranslation, useStores, usePreloader } from 'hooks';
import locations from 'navigation/locations';
import { SignUpFormValues } from 'stores/session-store';
import { validationSchema } from './validation-schemas';

const alternativeActionComponents = [<RouterLink to={locations.signInWithPassword.toUrl()} />];

const acceptComponents = [
  <Link href={locations.termsOfService.toUrl()} />,
  <Link href={locations.privacyPolicy.toUrl()} />,
];

export const SignUp = observer(() => {
  const [signedUpEmail, setSignedUpEmail] = useState<string | null>(null);

  usePreloader({ pending: false });

  const { t } = useTranslation();
  const { session } = useStores();

  const { signUp } = session;

  const onSubmit = useCallback(
    async (values: SignUpFormValues) => {
      await signUp(values);

      setSignedUpEmail(values.email);
    },
    [signUp],
  );

  const success = useMemo(
    () =>
      signedUpEmail ? (
        <AuthForm
          title={t('Auth.SignUp.SuccessTitle')}
          description={
            <Trans
              i18nKey='Auth.SignUp.SuccessDescription'
              components={[<Typography variant='inherit' color='primaryColor' is='span' />]}
              values={{ email: signedUpEmail }}
            />
          }
        />
      ) : null,
    [signedUpEmail, t],
  );

  return (
    success ?? (
      <AuthForm
        title={t('Auth.SignUp.Title')}
        alternativeAction={<Trans i18nKey='Auth.SignUp.AlternativeAction' components={alternativeActionComponents} />}
        submitText={t('Auth.SignUp.SubmitButton')}
        onSubmit={onSubmit}
        validation={validationSchema}
        name='sign-up'
      >
        <TextField name='name' label={t('Auth.SignUp.Labels.Name')} placeholder={t('Auth.SignUp.Placeholders.Name')} />
        <TextField
          name='lastName'
          label={t('Auth.SignUp.Labels.LastName')}
          placeholder={t('Auth.SignUp.Placeholders.LastName')}
        />
        <TextField
          name='email'
          label={t('Auth.SignUp.Labels.Email')}
          placeholder={t('Auth.SignUp.Placeholders.Email')}
        />
        <StateField
          name='state'
          label={t('Auth.SignUp.Labels.State')}
          placeholder={t('Auth.SignUp.Placeholders.State')}
        />
        <CityField
          name='city'
          stateFormField='state'
          label={t('Auth.SignUp.Labels.City')}
          placeholder={t('Auth.SignUp.Placeholders.City')}
        />
        <PhoneNumberField
          name='contactPhone'
          label={t('Auth.SignUp.Labels.Phone')}
          placeholder={t('Auth.SignUp.Placeholders.Phone')}
        />
        <PasswordField
          name='password'
          label={t('Auth.SignUp.Labels.Password')}
          placeholder={t('Auth.SignUp.Placeholders.Password')}
        />
        <PasswordField
          name='confirmPassword'
          label={t('Auth.SignUp.Labels.ConfirmPassword')}
          placeholder={t('Auth.SignUp.Placeholders.ConfirmPassword')}
        />
        <TextField
          name='promoCode'
          label={t('Auth.SignUp.Labels.PromoCode')}
          placeholder={t('Auth.SignUp.Placeholders.PromoCode')}
        />
        <Checkbox name='accept'>
          <Typography variant='body-7' color='grey5'>
            <Trans i18nKey='Auth.SignUp.Labels.Accept' components={acceptComponents} />
          </Typography>
        </Checkbox>
      </AuthForm>
    )
  );
});
