import React, { memo } from 'react';
import { RouterLink } from 'components/common/link/router-link';
import { Icon } from 'components/common/icon';
import { useTranslation } from 'hooks';
import { DetailsLinkProps } from './types';

export const DetailsLink = memo<DetailsLinkProps>(({ iconProps, children, ...props }) => {
  const { t } = useTranslation();

  return (
    <RouterLink underline='none' {...props}>
      {children ?? t('Common.DetailsLink')}
      <Icon name='arrow_right' size={10} color='primaryColor' marginLeft={15} {...iconProps} />
    </RouterLink>
  );
});
