import { useStores } from 'hooks';
import { OptionDetailed } from 'types';
import { useMemo } from 'react';
import { useCampaignFormContext } from './use-campaign-form-context';

export const useInstagramAdPreviewHeader = (): {
  title: Nullable<OptionDetailed['label']>;
  image: Nullable<OptionDetailed['picture']>;
} => {
  const {
    campaignManager: { socialNetworks },
  } = useCampaignFormContext();
  const { instagramAccountOptions } = socialNetworks;

  const { abTestingCampaign } = useStores();
  const { InstValue } = abTestingCampaign;

  const selectedInstagamAccount = useMemo(
    () => instagramAccountOptions.find(({ value }) => value === InstValue),
    [instagramAccountOptions, InstValue],
  );

  const title = selectedInstagamAccount?.label;
  const image = selectedInstagamAccount?.picture;

  return { title, image };
};
