import { ContainerInterface } from './types';

export default class Container implements ContainerInterface {
  dependencies: { [key: string]: any };

  factories: { [key: string]: any };

  constructor() {
    this.dependencies = {};
    this.factories = {};
  }

  register<T>(name: string, dependency: T): void {
    this.dependencies[name] = dependency;
  }

  factory<T>(name: string, factory: (container: ContainerInterface) => T): void {
    this.factories[name] = factory;
  }

  get<T>(name: string): T {
    if (!this.dependencies[name]) {
      const factory = this.factories[name];
      if (factory) {
        this.dependencies[name] = this.resolve<T>(factory);
      } else {
        throw new Error(`No module found for: ${name}`);
      }
    }
    return this.dependencies[name] as T;
  }

  replace<T>(name: string, dependency: T): void {
    if (this.dependencies[name]) {
      this.dependencies[name] = dependency;
    }
  }

  resolve<T>(factory: (container: ContainerInterface) => T): T {
    return factory.call(null, this);
  }
}
