import React, { useMemo } from 'react';
import { Form } from 'components/common/form/form';
import { FieldsLayout } from 'components/common/form/fields-layout';
import { TextPicker } from 'components/common/form/text-picker';
import { withModal } from 'hocs/with-modal';
import { useModalProps, useTranslation } from 'hooks';
import { AD_DESCRIPTION_FIELD, AD_TITLE_FIELD } from 'components/campaigns/campaign-form/constants';
import { useCampaignFormContext } from 'components/campaigns/campaign-form/hooks';
import { Loading } from 'components/common/loading';
import { validationSchema } from './validation-schemas';
import { maxIndex } from './utils';
import { AdTextPickerModalProps } from './types';

export const AdTextPickerModal = withModal<AdTextPickerModalProps>()(
  ({ titleOptions, descriptionOptions, name, ...props }) => {
    const { t } = useTranslation();
    const {
      campaignManager: { pending },
    } = useCampaignFormContext();

    useModalProps(
      useMemo(
        () => ({
          title: t('AdTextPicker.Title'),
          titleProps: { textAlign: 'center' },
          width: 'auto',
        }),
        [t],
      ),
    );

    const footer = useMemo(
      () => ({
        cancelButtonAvailable: false,
        submitText: t('AdTextPicker.SaveButton'),
      }),
      [t],
    );

    return (
      <Form validation={validationSchema} footer={footer} footerProps={{ paddingTop: 0 }} {...props}>
        <Loading pending={pending}>
          <FieldsLayout gridRowGap={20}>
            <div
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '-20px',
              }}
            >
              Titles:{' '}
            </div>
            <TextPicker
              labelText={
                name && maxIndex(AD_TITLE_FIELD, name) > 1
                  ? 'AdTextPicker.Labels.TitleOrdinal'
                  : 'AdTextPicker.Labels.Title'
              }
              name='titles'
              options={titleOptions}
              placeholder={
                name && maxIndex(AD_TITLE_FIELD, name) > 1
                  ? 'AdTextPicker.Placeholder.TitleOrdinal'
                  : 'AdTextPicker.Placeholder.Title'
              }
              tabName={name}
            />
            <div
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '-20px',
              }}
            >
              Descriptions:{' '}
            </div>
            <TextPicker
              labelText={
                name && maxIndex(AD_DESCRIPTION_FIELD, name) > 1
                  ? 'AdTextPicker.Labels.DescriptionOrdinal'
                  : 'AdTextPicker.Labels.Description'
              }
              name='descriptions'
              placeholder={
                name && maxIndex(AD_DESCRIPTION_FIELD, name) > 1
                  ? 'AdTextPicker.Placeholder.DescriptionOrdinal'
                  : 'AdTextPicker.Placeholder.Description'
              }
              options={descriptionOptions}
              tabName={name}
            />
          </FieldsLayout>
        </Loading>
      </Form>
    );
  },
);
