import { AxiosError } from 'axios';
import { BaseHttpError } from '../errors';

export class ErrorHandlerMiddleware {
  errorHandler?: any;

  constructor(errorHandler?: (error: BaseHttpError) => void) {
    this.errorHandler = errorHandler;
  }

  onResponseError(error: AxiosError): never {
    const httpError = BaseHttpError.fromError(error);
    this.errorHandler(httpError);
    throw httpError;
  }
}
