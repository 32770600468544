import { longString, email, password, passwordConfirmation, accept, string, phoneNumber } from 'utils/validations';
import { FormValidation } from 'types';
import { excludeEmpty } from 'utils/objects';
import { ObjectShape } from 'yup';

export const validationSchema: FormValidation = () => {
  const isInvitedSignup = window.location.pathname.includes('/invited-sign-up/');

  return {
    name: longString().required(),
    lastName: longString().required(),
    email: email().required(),
    state: string().required(),
    city: string().required(),
    password: password().required(),
    confirmPassword: passwordConfirmation().required(),
    contactPhone: phoneNumber().required(),
    ...(excludeEmpty({
      promoCode: isInvitedSignup ? null : string().required(),
    }) as ObjectShape),
    accept: accept(),
  };
};
