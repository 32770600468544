import { CampaignApi } from 'types';
import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import { GetCampaignKeywordOptionsResponse } from '../response-types';

const { getCampaignKeywordOptions } = urls.keywords;

export const createKeywordsApi = (http: BaseHttpClient) => ({
  getCampaignKeywordOptions({
    campaign_id,
    campaign_kind,
    target_kind,
    target_id,
  }: {
    campaign_id?: CampaignApi['id'];
    campaign_kind: CampaignApi['kind'];
    target_kind?: CampaignApi['target_kind'];
    target_id: CampaignApi['target'];
  }) {
    return http.get<GetCampaignKeywordOptionsResponse>(getCampaignKeywordOptions.url, {
      campaign_id,
      campaign_kind,
      target_kind,
      target_id,
    });
  },
});
