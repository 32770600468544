import { useEffect } from 'react';
import { usePrevious } from 'react-use';
import isEqual from 'lodash.isequal';
import { ModalProps } from 'components/common/modal';
import { useModalContext } from './use-modal-context';

export const useModalProps = (props: Partial<ModalProps> = {} as Partial<ModalProps>): Nullable<ModalProps> => {
  const modalContext = useModalContext();

  const overrideDefaultProps = modalContext?.overrideDefaultProps;
  const prevProps = usePrevious(props);
  const isPropsUpdated = !isEqual(props, prevProps);

  useEffect(() => {
    if (!isPropsUpdated || !overrideDefaultProps) {
      return;
    }

    overrideDefaultProps(props);
  }, [overrideDefaultProps, isPropsUpdated, props]);

  return modalContext?.props;
};
