import React, { memo, useMemo } from 'react';
import { Upload } from 'antd';
import classnames from 'classnames';
import placeholderImage from 'assets/images/img-illust-upload-photo.png';
import { useTranslation } from 'hooks';
import { Box } from 'components/common/box';
import { Typography } from 'components/common/typography';
import { ImageUploaderDropAreaProps } from './types';
import styles from './styles.module.scss';

const defaultPlaceholderProps = {
  height: 100,
  imageHeight: 'auto',
};

export const ImageUploaderDropArea = memo<ImageUploaderDropAreaProps>(
  ({ className: classNameFromProps, placeholderProps: placeholderPropsFromProps, ...props }) => {
    const className = classnames(styles.root, classNameFromProps);

    const { t } = useTranslation();

    const placeholderProps = useMemo(
      () => ({
        ...defaultPlaceholderProps,
        ...placeholderPropsFromProps,
      }),
      [placeholderPropsFromProps],
    );

    const defaultPlaceholder = useMemo(
      () => (
        <Box display='flex' alignItems='center' position='relative' height={placeholderProps.height}>
          <Box display='flex' alignItems='center' paddingLeft={59}>
            <img src={placeholderImage} height={80} alt='placeholder' />
          </Box>
          <Typography
            flex={1}
            variant='body-15'
            color='black3'
            position='relative'
            textAlign='center'
            display='flex'
            flexDirection='column'
            fontSize='14px !important'
          >
            <div>{t('ImageUploader.Placeholder')}</div>
            {props.name === 'images' && (
              <div style={{ fontWeight: 'normal' }}>{t('ImageUploader.RequirementsSquare')}</div>
            )}
            {props.name === 'images' && (
              <div style={{ fontWeight: 'normal' }}>{t('ImageUploader.RequirementsLandscape')}</div>
            )}
          </Typography>
        </Box>
      ),
      [placeholderProps.height, props.name, t],
    );

    const { placeholder = defaultPlaceholder, ...rest } = props;

    return (
      <Upload.Dragger className={className} showUploadList={false} {...rest}>
        {placeholder}
      </Upload.Dragger>
    );
  },
);
