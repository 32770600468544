import MockAdapter from 'axios-mock-adapter';
import { AxiosInstance } from 'axios';
import { mockServerErrors } from '../helper';
import { createUsersMock } from './users-mock';
import { createReportsMock } from './campaign-reports-mock';
import { createVersionMock } from './version-mock';

export function enableMock(axios: AxiosInstance, delayResponse = 1000): void {
  const mock = new MockAdapter(axios, {
    onNoMatch: 'passthrough',
    delayResponse,
  });

  mockServerErrors(mock);
  createUsersMock(mock);
  createReportsMock(mock);
  createVersionMock(mock);
}
