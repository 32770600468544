import { useTranslation as useTranslationOriginal, UseTranslationOptions } from 'react-i18next';
import { i18n } from 'i18next';
import { TFunction, I18nPaths } from 'types';

type UseTranslationResponse = [TFunction, i18n, boolean] & {
  t: TFunction;
  i18n: i18n;
  ready: boolean;
};

export const useTranslation = <N extends I18nPaths = I18nPaths>(
  ns?: N | Readonly<N>,
  options?: UseTranslationOptions,
): UseTranslationResponse => useTranslationOriginal(ns, options);
