import React, { memo, useMemo } from 'react';
import { useTranslation } from 'hooks';
import { Box } from 'components/common/box';
import { Slider } from 'components/common/slider';
import { Typography } from 'components/common/typography';
import { GridLayout } from 'components/common/grid-layout';
import { Scale } from 'components/common/scale';
import themeColors from 'utils/theme-colors';
import { constants } from './constants';
import { RadioGroup } from './radio-group';
import { ImageEditProps } from './types';

const typographyProps = {
  variant: 'body-3',
  color: 'secondaryFontColor',
  marginBottom: 40,
};

export const ImageEdit = memo<ImageEditProps>(({ scale, setScale, handleToggleAspectClick, name, ...props }) => {
  const { t } = useTranslation();

  const variants = useMemo(() => {
    switch (name) {
      case constants.logo:
        return [
          {
            value: constants.variants.square,
            description: '1:1',
            information: t('ImageEdit.InformationForSquareLogo'),
            scaleProps: {
              width: constants.scale.widthSquare,
              height: constants.scale.height,
            },
          },
          {
            value: constants.variants.rectangle,
            description: '4:1',
            information: t('ImageEdit.InformationForRectangleLogo'),
            scaleProps: {
              width: constants.scale.widthRectangle,
              height: constants.scale.height,
            },
          },
        ];
      default:
        return [
          {
            value: constants.variants.square,
            description: '1:1',
            information: t('ImageEdit.InformationForSquare'),
            scaleProps: {
              width: constants.scale.widthSquare,
              height: constants.scale.height,
            },
          },
          {
            value: constants.variants.rectangle,
            description: '1.91:1',
            information: t('ImageEdit.InformationForRectangle'),
            scaleProps: {
              width: constants.scale.widthRectangle,
              height: constants.scale.height,
            },
          },
        ];
    }
  }, [name, t]);

  return (
    <Box display='flex' flexDirection='column' {...props}>
      <Typography {...typographyProps}>{t('ImageEdit.Scale')}</Typography>
      <GridLayout gridTemplateColumns='3fr 1fr' column={2} gridColumnGap={60} marginBottom={60}>
        <Slider
          min={20}
          max={200}
          marks={constants.marks}
          defaultValue={100}
          trackStyle={{
            backgroundColor: themeColors.primaryColor,
          }}
          handleStyle={{
            borderColor: themeColors.primaryColor,
          }}
          onChange={setScale}
        />
        <Scale width={84}>{`${scale}%`}</Scale>
      </GridLayout>
      <Typography {...typographyProps}>{t('ImageEdit.AspectRatio')}</Typography>
      <RadioGroup
        name='radio'
        variants={variants}
        defaultValue={constants.variants.square}
        onChange={handleToggleAspectClick}
      />
    </Box>
  );
});
