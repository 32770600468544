import { types } from 'mobx-state-tree';
import { apiFlow } from 'stores/mst-types';
import Base from 'stores/models/base';
import Listings from 'stores/models/listings/listings';

export const ListingsStore = Base.named('ListingsStore')
  .props({
    listings: types.optional(Listings, {}),
  })
  .views((self) => ({
    get listingsList() {
      return self.listings.listingsList;
    },

    get count() {
      return self.listings.count;
    },
  }))
  .actions((self) => ({
    init: apiFlow(function* init() {
      yield self.listings.fetchListingsPropertyTypeOptions();
    }),

    fetch: self.listings.fetch,

    destroy: () => {
      self.listings.destroy();
    },
  }));
