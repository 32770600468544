import React from 'react';
import { Typography } from 'components/common/typography';
import { Box } from 'components/common/box';
import { FormFooter } from 'components/common/form/form-footer';
import { withModal } from 'hocs/with-modal';
import { ConfirmationProps } from './types';

export const Confirmation = withModal<ConfirmationProps>({ width: 577 })(
  ({ description, onConfirm, confirmText, cancelText, submitButtonProps, ...props }) => (
    <Box {...props}>
      <Typography variant='body-2' textAlign='center'>
        {description}
      </Typography>
      <FormFooter
        submitText={confirmText}
        onSubmit={onConfirm}
        cancelText={cancelText}
        submitButtonProps={submitButtonProps}
      />
    </Box>
  ),
);
