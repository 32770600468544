import React, { memo, useCallback } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { TextField } from 'components/common/form/text-field';
import { useField, useForm } from 'hooks';
import { isEmpty } from 'utils/is';
import { NumberInputProps } from './types';

export const NumberInputBudget = memo<NumberInputProps>(({ valueAsNumber = true, numberFormatProps, ...props }) => {
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const {
    field: { onBlur, onChange, ...field },
    componentProps,
  } = useField(props);
  const { name } = field;
  const { secondName, isDaily } = props;
  const { setFieldValue } = useForm();

  const onChangeHandler = useCallback(
    ({ value }: any) => {
      if (focused) {
        if (isDaily) {
          setFieldValue(secondName || '', value * 30);
        } else {
          setFieldValue(secondName || '', value / 30);
        }
      }
      const processedValue = valueAsNumber && !isEmpty(value) && value !== '' ? Number(value) : value;
      onChange({
        target: {
          name,
          value: processedValue,
        },
      });
    },
    [onChange, name, valueAsNumber, focused, isDaily, secondName, setFieldValue],
  );

  const onBlurRow = useCallback(
    (event) => {
      setTimeout(() => {
        onBlur(event);
      });
      setFocused(false);
    },
    [onBlur],
  );

  return (
    <NumberFormat
      onValueChange={onChangeHandler}
      onFocus={onFocus}
      onBlur={onBlurRow}
      thousandSeparator
      isNumericString
      decimalScale={2}
      // @ts-ignore
      customInput={TextField}
      allowNegative={false}
      {...numberFormatProps}
      {...(componentProps as Partial<NumberFormatProps>)}
      {...field}
      value={field?.value ? field?.value : ''}
    />
  );
});
