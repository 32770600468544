import { createContext } from 'react';
import { FormikHandlers } from 'formik';

type FormContextValue = {
  id: string;
  name?: string;
  formServerErrors: any;
  requiredFields: string[];
  formFields: string[];
  addFormField: (field: string) => void;
  removeFormField: (field: string) => void;
  setCustomValidationKey: (key: string | null) => void;
  setCustomSubmitKey: (key: string | null) => void;
  disabled?: boolean;
} & Pick<FormikHandlers, 'handleSubmit'>;

export const FormContext = createContext<FormContextValue>({} as any);
