import { DEFAULT_VERSION } from './constants';

import type { PersistedState, MigrationManifest } from './types';

export const createMigrate = (migrations: MigrationManifest, { debug }: { debug: boolean } = { debug: false }) =>
  function createMigrate(state: PersistedState, currentVersion: number): Promise<PersistedState> {
    if (!state) {
      if (debug) {
        console.log('store persist: no inbound state, skipping migration');
      }

      return Promise.resolve(undefined);
    }

    const inboundVersion: number =
      state._persist && state._persist.version !== undefined ? state._persist.version : DEFAULT_VERSION;
    if (inboundVersion === currentVersion) {
      if (debug) {
        console.log('store persist: versions match, noop migration');
      }

      return Promise.resolve(state);
    }
    if (inboundVersion > currentVersion) {
      if (debug) {
        console.error('store persist: downgrading version is not supported');
      }
      return Promise.resolve(state);
    }

    const migrationKeys = Object.keys(migrations)
      .map((ver) => parseInt(ver, 10))
      .filter((key) => currentVersion >= key && key > inboundVersion)
      .sort((a, b) => a - b);

    if (debug) {
      console.log('store persist: migrationKeys', migrationKeys);
    }

    try {
      const migratedState = migrationKeys.reduce((state, versionKey) => {
        if (debug) {
          console.log('store persist: running migration for versionKey', versionKey);
        }

        return migrations[versionKey](state);
      }, state as PersistedState);

      return Promise.resolve(migratedState);
    } catch (err) {
      return Promise.reject(err);
    }
  };
