import { onSnapshot, applySnapshot } from 'mobx-state-tree';
import { storage as defaultStorage } from 'utils/storage';
import { RootStoreInstance } from 'stores';
import { Options } from './types';
import { DEFAULT_VERSION } from './constants';
import { prepareStoreState, prepareStorageState } from './helper';

export const persistStore = async ({
  key,
  storeModel,
  storeEnv,
  version = DEFAULT_VERSION,
  storage = defaultStorage,
  migrate,
  whitelist,
  blacklist,
}: Options): Promise<RootStoreInstance> => {
  const getLocalStorageState = async () => {
    const storageState = await storage.getItem(key);

    return prepareStoreState({
      storageState,
      migrate,
      whitelist,
      blacklist,
      version,
    });
  };

  const store = storeModel.create(await getLocalStorageState(), storeEnv);

  document.addEventListener(
    'visibilitychange',
    async ({ currentTarget }) => {
      if (currentTarget !== document || document.hidden) {
        return;
      }

      applySnapshot(store, {
        ...store,
        ...(await getLocalStorageState()),
      });
    },
    false,
  );

  onSnapshot(store, (_snapshot) => {
    try {
      storage.setItem(
        key,
        prepareStorageState({
          storeState: typeof _snapshot === 'object' ? { ..._snapshot } : {},
          whitelist,
          blacklist,
          version,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  });

  return store;
};
