import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { MSTAddStaticMethods } from 'stores/utils';
import { Variant, VariantRequestApi } from 'types/ab-testing';
import Image from 'stores/models/image';
import TitleOption from 'stores/models/ads/title-option';
import DescriptionOption from 'stores/models/ads/description-option';
import { nullable } from 'stores/mst-types';

const staticMethods = {
  fromResponseData: (data: Variant): VariantABTestingSnapshot => ({
    images: data.images && data.images.map(Image.fromResponseData),
    titles: data.titles && data.titles.map(TitleOption.fromResponseData),
    descriptions: data.descriptions && data.descriptions.map(DescriptionOption.fromResponseData),
  }),

  toRequestData: (data: VariantABTestingSnapshot): VariantRequestApi => ({
    ...pick(data, ['images']),
    titles: data.titles && data.titles.map(TitleOption.toRequestData),
    descriptions: data.descriptions && data.descriptions?.map(DescriptionOption.toRequestData),
  }),
};

const VariantABTesting = types.model('VariantABTesting', {
  titles: nullable(types.array(TitleOption)),
  descriptions: nullable(types.array(DescriptionOption)),
  images: types.array(Image),
});

export type VariantABTestingSnapshot = SnapshotOut<typeof VariantABTesting>;
export type VariantABTestingInstanceType = Instance<typeof VariantABTesting>;

export default MSTAddStaticMethods(VariantABTesting, staticMethods);
