import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import pick from 'lodash.pick';
import Option from 'stores/models/option';
import { nullable } from 'stores/mst-types';

const OptionWithOldMarker = types.model('OptionWithOldMarker', {
  ...pick(Option.properties, ['value', 'label']),
  platform: nullable(types.string),
  asset_number: nullable(types.number),
  use_case_group: nullable(types.string),
  isOld: nullable(types.boolean),
  isActive: nullable(types.boolean),
  isTemporary: nullable(types.boolean),
});

export type OptionWithOldMarkerSnapshot = SnapshotOut<typeof OptionWithOldMarker>;
export type OptionWithOldMarkerInstanceType = Instance<typeof OptionWithOldMarker>;

export default OptionWithOldMarker;
