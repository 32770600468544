import { memo, useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';
import isEqual from 'lodash.isequal';
import { useForm } from 'hooks';
import { ValuesChangeSubscriberProps } from './types';

export const ValuesChangeSubscriber = memo<ValuesChangeSubscriberProps>(({ onChange }) => {
  const { values, touched } = useForm();
  const prevValues = usePrevious(values);

  const isInitialization = useMemo(() => !Object.values(touched).length, [touched]);

  useEffect(() => {
    if (isEqual(prevValues, values)) {
      return;
    }
    if (onChange) {
      onChange(values as unknown as Record<string, any>, {
        isInitialization,
      });
    }
  }, [isInitialization, onChange, values, prevValues]);

  return null;
});
