import { toGenerator, types } from 'mobx-state-tree';
import { apiFlow } from 'stores/mst-types';
import Base from 'stores/models/base';
import Collections from 'stores/models/collections/collections';
import { fromListResponse } from 'services/api';

export const CollectionsStore = Base.named('CollectionsStore')
  .props({
    collections: types.optional(Collections, {}),
  })
  .actions((self) => ({
    createCollectionType: apiFlow(function* createCollectionType(name?: string) {
      const response = yield* toGenerator(self.api.createCollectionType(name));
      const { data } = fromListResponse({
        response: response.data,
      });
      return data;
    }),
    getCollectionTypes: apiFlow(function* getCollectionType() {
      const response = yield* toGenerator(self.api.getCollectionType());
      return response?.data;
    }),
    getCollections: apiFlow(function* getCollections(data?: any) {
      const response = yield* toGenerator(self.api.getCollections(data));
      return response?.data;
    }),
    getCollection: apiFlow(function* getCollection(id?: number) {
      const response = yield* toGenerator(self.api.getCollection(id));
      return response?.data;
    }),
    createCollection: apiFlow(function* createCollection(data?: any) {
      const response = yield* toGenerator(self.api.createCollection(data));
      return response?.data;
    }),
    updateCollection: apiFlow(function* updateCollection(data?: any) {
      const response = yield* toGenerator(self.api.updateCollection(data));
      return response?.data;
    }),
    deleteCollection: apiFlow(function* deleteCollection(id?: number) {
      const response = yield* toGenerator(self.api.deleteCollection(id));
      return response?.data;
    }),
  }));
