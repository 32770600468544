import React, { memo } from 'react';
import classnames from 'classnames';
import { Typography } from 'components/common/typography';
import { LinkProps } from './types';
import styles from './styles.module.scss';

export const Link = memo<LinkProps>(({ underline = 'default', className: classNameFromProps, ...props }) => {
  const className = classnames(
    styles.root,
    {
      [styles[`${underline}UnderlineVariant`]]: Boolean(underline),
    },
    classNameFromProps,
  );
  return <Typography is='a' target='_blank' className={className} variant='inherit' color='primaryColor' {...props} />;
});
