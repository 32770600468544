import React, { forwardRef, memo, useMemo } from 'react';
import classnames from 'classnames';
import { Box } from 'components/common/box';
import { useEnsuredForwardedRef } from 'react-use';
import { ScrollbarProps } from './types';
import styles from './styles.module.scss';

const defaultPaddingX = 15;

const defaultScrollableXProps = {
  paddingX: defaultPaddingX,
  marginX: -defaultPaddingX,
};

export const Scrollbar = memo(
  forwardRef<HTMLDivElement, ScrollbarProps>(
    ({ variant = 'light', className: classNameFromProps = '', color, hoverColor, ...props }, ref) => {
      const ensuredForwardRef = useEnsuredForwardedRef<typeof ref>(ref as any);

      const className = classnames(
        styles.root,
        {
          [styles[`${variant}Variant`]]: Boolean(variant),
        },
        classNameFromProps,
      );

      const cssVariables = useMemo(
        () => ({
          '--color': color,
          '--hover-color': hoverColor,
        }),
        [color, hoverColor],
      );

      return (
        <Box
          ref={ensuredForwardRef}
          className={className}
          cssVariables={cssVariables}
          {...defaultScrollableXProps}
          {...props}
        />
      );
    },
  ),
);
