import React from 'react';
import * as Sentry from '@sentry/react';
import config from 'config';
import ErrorView from './error-view';

interface SentryErrorBoundaryProps {}

const SentryErrorBoundary: React.FunctionComponent<SentryErrorBoundaryProps> = (props) => {
  const { children } = props;

  return (
    <Sentry.ErrorBoundary fallback={<ErrorView />} showDialog={config.sentry.showCrashDialog}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
