import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';
import { MSTAddStaticMethods } from 'stores/utils';

export interface GoogleManagerAccountOverviewApi {
  manager_id: number;
  manager_name: string;
}

const staticMethods = {
  fromResponseData: (data: GoogleManagerAccountOverviewApi): GoogleManagerAccountOverviewSnapshot => ({
    managerName: data.manager_name,
    managerId: String(data.manager_id),
  }),
};

const GoogleManagerAccountOverview = BaseModel.named('GoogleManagerAccountOverview').props({
  managerName: types.string,
  managerId: types.string,
});

export type GoogleManagerAccountOverviewInstanceType = Instance<typeof GoogleManagerAccountOverview>;
export type GoogleManagerAccountOverviewSnapshot = SnapshotOut<typeof GoogleManagerAccountOverview>;

export default MSTAddStaticMethods(GoogleManagerAccountOverview, staticMethods);
