import {
  PostSignUpApi,
  AuthTokenPairsApi,
  PostSignInApi,
  PostRefreshTokenApi,
  PostForgotPasswordApi,
  PostRestorePasswordApi,
  PostLogoutApi,
  PostSignUpInvitedUserApi,
} from 'types';
import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import {
  SignInResponse,
  PostRefreshTokenResponse,
  PostRestorePasswordResponse,
  SignUpInvitedUserResponse,
} from '../response-types';

const { signIn, signUp, signUpInvitedUser, refreshToken, activateAccount, forgotPassword, restorePassword, logout } =
  urls.auth;

export const createAuthApi = (http: BaseHttpClient) => ({
  signUp(data: PostSignUpApi) {
    return http.post(signUp.url, data);
  },

  activateAccount({ uid, token }: { uid: string; token: string }) {
    return http.post<AuthTokenPairsApi>(activateAccount.url, {
      uid,
      token,
    });
  },

  signIn(data: PostSignInApi) {
    return http.post<SignInResponse>(signIn.url, data);
  },

  signUpInvitedUser(data: PostSignUpInvitedUserApi) {
    return http.post<SignUpInvitedUserResponse>(signUpInvitedUser.url, data);
  },

  refreshToken(data: PostRefreshTokenApi) {
    return http.post<PostRefreshTokenResponse>(refreshToken.url, data);
  },

  forgotPassword(data: PostForgotPasswordApi) {
    return http.post<PostRefreshTokenResponse>(forgotPassword.url, data);
  },

  restorePassword(data: PostRestorePasswordApi) {
    return http.post<PostRestorePasswordResponse>(restorePassword.url, data);
  },

  logout(data: PostLogoutApi) {
    return http.post(logout.url, data);
  },
});
