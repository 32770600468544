import MockAdapter from 'axios-mock-adapter';
import { AxiosRequestConfig } from 'axios';
import * as objects from 'utils/objects';
import { sortAlphabetically } from 'utils/sorting';
import database from 'database';
import { PaginationParamsApi, FiltrationParamsApi } from 'types';
import { ItemsResponse, ItemResponse } from './response-types';

const getUserByRequest = (config: AxiosRequestConfig): Promise<Nullable<typeof database.users.items[0]>> => {
  const { headers } = config;
  const { Authorization } = headers;

  const [, requestToken] = Authorization.split(' ');

  return database.users.find({ jwt: requestToken });
};

const getIdFromUrl = (url?: string): number | null => {
  if (!url) {
    return null;
  }
  const idStr: string = url.substr(url.lastIndexOf('/') + 1);
  return parseInt(idStr, 10);
};

const getUrlParamsByRegexp = ({ url, regexp }: { url?: string; regexp?: RegExp }): string[] => {
  if (!regexp || !url) {
    return [];
  }

  // @ts-ignore
  const [, ...params] = url.match(regexp);

  return params;
};

const formDataToObject = (formData: FormData) => {
  const result: { [key: string]: unknown } = {};

  formData.forEach((value, key) => {
    result[key] = value;
  });

  return result;
};

const badRequestErrorEntry = '400error';
const requestsLimitExceededErrorEntry = '429error';
const somethingWentWrongErrorEntry = '500error';

const createErrorMessage = () => ['is invalid'];

const checkSubstring = (value: unknown, substring: string): boolean =>
  typeof value === 'string' && value.includes(substring);

const defaultErrorsSet = {
  400: {},
  isSomethingWentWrong: false,
  isRateLimitExceeded: false,
};

const findServerErrors = (data: Record<string, any>): typeof defaultErrorsSet => {
  const processedData = data instanceof FormData ? formDataToObject(data) : objects.flat({ data });

  const isSomethingWentWrong = Object.values(processedData).some((value) =>
    checkSubstring(value, somethingWentWrongErrorEntry),
  );

  if (isSomethingWentWrong) {
    return { ...defaultErrorsSet, isSomethingWentWrong };
  }

  const isRateLimitExceeded = Object.values(processedData).some((value) =>
    checkSubstring(value, requestsLimitExceededErrorEntry),
  );

  if (isRateLimitExceeded) {
    return { ...defaultErrorsSet, isRateLimitExceeded };
  }

  return Object.entries(processedData || {}).reduce((result, [field, value]) => {
    if (!checkSubstring(value, badRequestErrorEntry)) {
      return result;
    }

    const badRequestErrors = {
      ...result['400'],
      [field]: createErrorMessage(),
    };

    return {
      ...result,
      400: objects.unflat({ data: badRequestErrors }),
    };
  }, defaultErrorsSet as any);
};

const hasServerErrors = (data: Record<string, any>): boolean => {
  const { 400: errors400, isSomethingWentWrong, isRateLimitExceeded } = findServerErrors(data);

  return isSomethingWentWrong || Boolean(Object.keys(errors400).length) || isRateLimitExceeded;
};

const serverErrorMatch = [
  /[\s\S]*/g,
  {
    asymmetricMatch: hasServerErrors,
  },
];

const rateLimitExceededErrorMessage = 'Rate limit exceeded';

const serverErrorReply = (config: any) => {
  console.log('Error form serverErrorReply');

  const data = typeof config.data === 'string' ? JSON.parse(config.data) : config.data;

  const { 400: errors400, isSomethingWentWrong, isRateLimitExceeded } = findServerErrors(data);

  if (isSomethingWentWrong) {
    return [500];
  }

  if (Object.keys(errors400).length) {
    return [400, errors400];
  }

  if (isRateLimitExceeded) {
    return [429, { message: rateLimitExceededErrorMessage }];
  }

  return [500];
};

const mockServerErrors = (mock: MockAdapter): void => {
  mock.onPost(...(serverErrorMatch as any[])).reply(serverErrorReply);
  mock.onPatch(...(serverErrorMatch as any[])).reply(serverErrorReply);
};

const toListResult = <T extends any[]>({
  items,
  count = items.length,
}: {
  items: T;
  count?: number;
}): { count: number; results: T } => ({
  count,
  results: items,
});

const fromListResponse = <T extends any>({
  response,
}: {
  response: ItemsResponse<T>;
}): { data: T[]; count: number } => ({
  count: response.count,
  data: response.results,
});

const fromItemResponse = <T extends any>({ response }: { response: ItemResponse<T> }): { data: T } => ({
  data: response,
});

const paginate = <T>({
  items,
  params = {} as PaginationParamsApi,
}: {
  items: T[];
  params?: PaginationParamsApi;
}): T[] => {
  const { offset = 0, limit = Infinity } = params;

  return items.slice(offset, offset + limit);
};

const filter = <T>({
  items,
  params = {} as FiltrationParamsApi,
}: {
  items: T[];
  params?: FiltrationParamsApi;
}): T[] => {
  const { ordering: rawSorting } = params;

  // WE DON'T SUPPORT ARRAY SORTING FOR THE PROTOTYPE
  const sorting = typeof rawSorting === 'string' ? rawSorting : null;
  const isDescSorting = sorting && sorting.startsWith('-');
  const sortingField = sorting && isDescSorting ? sorting.slice(1) : sorting;

  const sortedItems = sorting && sortingField ? sortAlphabetically({ items, field: sortingField as any }) : items;

  return paginate({
    items: isDescSorting ? [...sortedItems].reverse() : sortedItems,
    params,
  });
};

export {
  getIdFromUrl,
  getUrlParamsByRegexp,
  getUserByRequest,
  mockServerErrors,
  toListResult,
  fromListResponse,
  fromItemResponse,
  paginate,
  filter,
};
