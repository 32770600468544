import config from 'config';
import { isAbsoluteUrl } from 'utils/is';
import { UrlDefinition } from './types';

export const urls = {
  auth: {
    signUp: {
      url: '/signup/',
    },

    signUpInvitedUser: {
      url: '/account/organization_signup/',
    },

    signIn: {
      url: '/login/',
    },

    refreshToken: {
      url: '/login/refresh/',
    },

    activateAccount: {
      url: '/account/activate/',
    },

    forgotPassword: {
      url: '/password/reset/',
    },

    restorePassword: {
      url: '/account/set_password/',
    },

    logout: {
      url: '/logout/',
    },
  },
  users: {
    getUser: {
      url: '/account/',
    },

    getInvitedUser: {
      url: '/account/invited_user/',
    },

    getSelectedUser: {
      url: '/account/{id}/',
    },

    patchUser: {
      url: '/account/{id}/',
      regex: /^\/account\/([^/]+)\/$/,
    },

    changePassword: {
      url: '/password/change/',
    },

    getProfile: {
      url: '/users/{id}/profile',
      regex: /^\/users\/([^/]+)\/profile$/,
    },

    getOrganizations: {
      url: '/account/get_organization/',
    },

    getUsersByOrganization: {
      url: '/account/get_users_by_organization/',
    },

    getAgentAdTitleSuggestionOptions: {
      url: '/ad_preview_title_branding_agent/',
    },

    getAgentAdDescriptionSuggestionOptions: {
      url: '/ad_preview_description_branding_agent/',
    },

    getFacebookConnect: {
      url: '/account/facebook/connect/',
    },

    getFacebookAdAccounts: {
      url: '/account/facebook/ad_accounts/',
    },

    postFacebookAdAccount: {
      url: '/account/facebook/ad_account/',
    },

    getFacebookDisconnect: {
      url: '/account/facebook/disconnect/',
    },

    getFacebookPageOptions: {
      url: '/account/facebook/pages/',
    },

    getFacebookPixelOptions: {
      url: '/account/facebook/pixel_accounts/',
    },

    getFacebookCustomAudienceOptions: {
      url: '/account/facebook/custom_audience/',
    },

    getGoogleConnect: {
      url: '/account/google/connect/',
    },

    getGoogleManagerAccounts: {
      url: '/account/google/manager_accounts/',
    },

    postGoogleManagerAccount: {
      url: '/account/google/manager_account/',
    },

    getGoogleDisconnect: {
      url: '/account/google/disconnect/',
    },

    getInstagramAccountOptions: {
      url: '/account/facebook/instagram_accounts/',
    },

    getGoogleAccountOptions: {
      url: '/account/google/client_accounts/',
    },
  },
  campaigns: {
    getAll: {
      url: '/campaign/',
    },

    details: {
      url: '/campaign/{id}/',
      regex: /^\/campaigns\/([^/]+)$/,
    },

    post: {
      url: '/campaign/',
    },

    patch: {
      url: '/campaign/{id}/',
      regex: /^\/campaigns\/([^/]+)$/,
    },

    remove: {
      url: '/campaign/{id}/',
      regex: /^\/campaigns\/([^/]+)$/,
    },
  },
  reports: {
    getCampaignReports: {
      url: '/campaign/{id}/campaign_report/',
      regex: /^\/campaign\/([^/]+)\/campaign_report$/,
    },
    getReportsOverview: {
      url: '/reports-overview',
    },
  },
  collections: {
    createCollectionType: {
      url: '/api-gateway/organizations/collection-tags',
    },
    getCollectionType: {
      url: '/api-gateway/organizations/collection-tags',
    },
    getCollections: {
      url: '/api-gateway/organizations/collections/filter',
    },
    getCollection: {
      url: '/api-gateway/organizations/collections/{id}',
    },
    createCollection: {
      url: '/api-gateway/organizations/collections',
    },
    updateCollection: {
      url: '/api-gateway/organizations/collections/{id}',
    },
    deleteCollection: {
      url: '/api-gateway/organizations/collections/{id}',
    },
  },
  buildings: {
    getAll: {
      url: '/api-gateway/organizations/buildings',
    },
    getYardiConnectionStatus: {
      url: '/api-gateway/yardi/user/connect',
    },
    sync: {
      url: '/api-gateway/yardi/property/{id}',
    },

    details: {
      url: '/api-gateway/organizations/buildings/{id}',
      regex: /^\/building\/([^/]+)$/,
    },

    create: {
      url: '/api-gateway/organizations/buildings',
    },
    createImages: {
      url: '/api-gateway/organizations/buildings/{id}/images',
    },

    getParameters: {
      url: '/api-gateway/organizations/buildings/parameters',
    },

    update: {
      url: '/api-gateway/organizations/buildings/{id}',
      regex: /^\/buildings\/([^/]+)$/,
    },
    activate: {
      url: '/api-gateway/organizations/buildings/{id}/activate',
      regex: /^\/buildings\/([^/]+)$/,
    },

    remove: {
      url: '/api-gateway/organizations/buildings/{id}',
      regex: /^\/building\/([^/]+)\/$/,
    },

    getOptions: {
      url: '/api-gateway/organizations/buildings/summary',
    },

    getTextAssets: {
      url: '/api-gateway/organizations/buildings/{id}/text-assets',
      regex: /^\/buildings\/([^/]+)\/$/,
    },

    validateSingleImage: {
      url: '/api-gateway/organizations/buildings/image/validate',
    },

    // TODO remove next two urls
    getAdTitleSuggestionOptions: {
      url: '/ad_preview_title_building/',
      regex: /^\/buildings\/([^/]+)\/title-suggestions$/,
    },

    getAdDescriptionSuggestionOptions: {
      url: '/ad_preview_description_building/',
      regex: /^\/buildings\/([^/]+)\/description-suggestions$/,
    },

    regeneratedTextAsset: {
      url: '/api-gateway/organizations/buildings/{id}/text-assets/regenerate',
    },
  },
  listings: {
    getAll: {
      url: '/listing/',
    },

    details: {
      url: '/listing/{id}/',
      regex: /^\/listing\/([^/]+)$/,
    },

    getOptions: {
      url: '/listing/options/',
    },

    getPropertyTypeOptions: {
      url: '/listing_property/',
    },

    getAmenityOptions: {
      url: '/listing_amenity/',
    },

    getMasstransitOptions: {
      url: '/mass_transit/',
    },

    getTypeOptions: {
      url: '/kind_listing/',
    },

    post: {
      url: '/listing/',
    },

    patch: {
      url: '/listing/{id}/',
      regex: /^\/listings\/([^/]+)$/,
    },

    remove: {
      url: '/listing/{id}/',
      regex: /^\/listings\/([^/]+)\/$/,
    },

    getAdTitleSuggestionOptions: {
      url: '/ad_preview_title_listing/',
      regex: /^\/listings\/([^/]+)\/title-suggestions$/,
    },

    getAdDescriptionSuggestionOptions: {
      url: '/ad_preview_description_listing/',
      regex: /^\/listings\/([^/]+)\/description-suggestions$/,
    },

    getAdDescriptionRegenerateOptions: {
      url: '/ad_preview_description_listing/regenerate/',
    },

    getAdTitleRegenerateOptions: {
      url: '/ad_preview_title_listing/regenerate/',
    },
  },
  openHouses: {
    getOptions: {
      url: '/open-house-options',
    },
  },
  geoLocations: {
    states: {
      getOptions: {
        url: '/state/',
      },

      details: {
        url: '/state/{id}/',
        regex: /^\/state\/([^/]+)\/$/,
      },
    },
    cities: {
      getOptions: {
        url: '/city/',
      },

      details: {
        url: '/city/{id}/',
        regex: /^\/city\/([^/]+)\/$/,
      },
    },
  },
  geoTargeting: {
    getOptions: {
      url: '/geotargets/',
    },

    post: {
      url: '/geotargets/',
    },
  },
  apartmentParameters: {
    getOptions: {
      url: '/kind_apartment/', // ??
    },
  },

  organizations: {
    getOrganizationUsers: {
      url: '/organizations/staff/',
    },

    postOrganizationUser: {
      url: '/organizations/{id}/invite_member/',
      regex: /^\/organizations\/([^/]+)\/invite_member\/$/,
    },

    removeOrganizationUser: {
      url: '/account/{id}/',
      regex: /^\/account\/([^/]+)\/$/,
    },

    postOrganizationUserInvite: {
      url: '/organizations/{id}/reinvite_member/',
      regex: /^\/organizations\/([^/]+)\/reinvite_member\/$/,
    },

    getOrganizationKindOptions: {
      url: '/kind_organization/',
    },

    getOrganization: {
      url: '/organizations/{id}/',
      regex: /^\/organizations\/([0-9]+)\/$/,
    },

    postOrganization: {
      url: '/organizations/',
    },

    patchOrganization: {
      url: '/organizations/{id}/',
      regex: /^\/organizations\/([0-9]+)\/$/,
    },

    getTypesOfPropertiesOptions: {
      url: '/organization_property/',
    },

    getOrganizationBuildings: {
      url: '/api-gateway/organizations/buildings',
    },

    getOrganizationAdTitleSuggestionOptions: {
      url: '/ad_preview_title_branding_organization/',
    },

    getOrganizationAdDescriptionSuggestionOptions: {
      url: '/ad_preview_description_branding_organization/',
    },

    getAgentAdDescriptionRegenerateOptions: {
      url: '/ad_preview_description_branding_agent/regenerate/',
    },
    getAgentAdTitleRegenerateOptions: {
      url: '/ad_preview_title_branding_agent/regenerate/',
    },

    getOrganizationAdDescriptionRegenerateOptions: {
      url: '/ad_preview_description_branding_organization/regenerate/',
    },
    getOrganizationAdTitleRegenerateOptions: {
      url: '/ad_preview_title_branding_organization/regenerate/',
    },
  },
  keywords: {
    getCampaignKeywordOptions: {
      url: '/keyword_groups/',
    },
  },

  backSyncGoogleSearch: {
    triggerBackSync: {
      url: '/v2/campaign/building/google/search/{id}/sync',
    },
  },

  googleAdwordsPlatform: {
    getAdGroups: {
      url: '/v2/campaign/new/building/google/search/{id}',
    },
    getExistingAdGroups: {
      url: '/v2/campaign/building/google/search/{id}',
    },
    postAdGroups: {
      url: '/v2/campaign/new/building/google/search/{id}',
    },
    putAdGroups: {
      url: '/v2/campaign/building/google/search/{id}',
    },
    getExistingAdGroupsBuildingUpdated: {
      url: 'v2/campaign/building/google/search/composite/{id}',
    },
    deleteAdGroups: {
      url: '/v2/campaign/building/google/search/{id}',
    },
    pauseAdGroups: {
      url: '/v2/campaign/building/google/search/{id}/pause',
    },
    unpauseAdGroups: {
      url: 'v2/campaign/building/google/search/{id}/unpause',
    },
  },

  adSocialNetworks: {
    getSettings: {
      url: '/account/social_networks/',
    },
  },

  abTesting: {
    getABTesting: {
      url: '/campaign/{id}/ab_testing_suggestions/',
    },
    patchABTesting: {
      url: '/v2/ab_testing/campaign/{id}/variant_b/',
    },
    skipABTesting: {
      url: '/campaign/{id}/skip_ab_testing_suggestions/',
    },
    getABTestingVariants: {
      url: '/v2/ab_testing/building/{id}/variants/',
    },
    getABTestingStatus: {
      url: '/v2/ab_testing/campaign/{id}/info/',
    },
    getABTestingResults: {
      url: '/v2/ab_testing/campaign/{id}/stats/',
    },
  },

  version: {
    getVersion: {
      url: '/version',
    },
  },
};

const unauthenticatedUrls: UrlDefinition[] = [urls.auth.signUp, urls.auth.signIn, urls.auth.refreshToken];

const checkUrlsCache: { [key: string]: boolean } = {};

export const isUnaunthenticateUrl = (path: string): boolean => {
  if (checkUrlsCache[path] === undefined) {
    // Save the result in cache to avoid computing multiple times, it should never change
    checkUrlsCache[path] = unauthenticatedUrls.some(
      (entry) => entry.url === path || (entry.regex && entry.regex.test(path)),
    );
  }

  return checkUrlsCache[path];
};

export const isThirdPartyRequestUrl = (url: string): boolean => {
  if (!isAbsoluteUrl(url)) {
    return false;
  }

  return !url.startsWith(config.api.url);
};
