import { Instance, types, SnapshotOut, cast } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';
import VariantABTesting from 'stores/models/variant-ab-testing';
import { MSTAddStaticMethods } from 'stores/utils';
import { ABTestingApi, ABTestingRequestApi } from 'types/ab-testing';

const staticMethods = {
  fromResponseData: (data: ABTestingApi): ABTestingOverviewSnapshot => ({
    variantA: VariantABTesting.fromResponseData(data.variant_a),
    variantB: VariantABTesting.fromResponseData(data.variant_b),
  }),

  toRequestData: (data: ABTestingOverviewSnapshot): ABTestingRequestApi => ({
    variant_b: VariantABTesting.toRequestData(data.variantB),
  }),
};

const ABTestingOverview = BaseModel.named('ABTestingOverview')
  .props({
    variantA: types.optional(VariantABTesting, {}),
    variantB: types.optional(VariantABTesting, {}),
  })
  .actions((self) => ({
    destroy() {
      self.variantA = cast({});
      self.variantB = cast({});
    },
  }));

export type ABTestingOverviewInstanceType = Instance<typeof ABTestingOverview>;
export type ABTestingOverviewSnapshot = SnapshotOut<typeof ABTestingOverview>;

export default MSTAddStaticMethods(ABTestingOverview, staticMethods);
