import React, { ComponentType, FC, Suspense } from 'react';
import { Preloader } from 'components/common/preloader';

interface HocProps {
  // eslint-disable-next-line react/require-default-props
  fallback?: boolean;
}

const defaultFallback = <Preloader pending />;

export const withSuspense = <P extends Record<string, any>>(WrappedComponent: ComponentType<P>): ComponentType<P> => {
  const WithSuspense: FC<P & HocProps> = ({ fallback = defaultFallback, ...props }) => (
    <Suspense fallback={fallback}>
      <WrappedComponent {...(props as P)} />
    </Suspense>
  );

  return WithSuspense;
};
