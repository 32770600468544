import React, { useCallback, useMemo, useEffect } from 'react';
import { usePrevious } from 'react-use';
import { observer } from 'mobx-react-lite';
import { useStores, useForm } from 'hooks';
import { SearchField } from 'components/common/form/search-field';
import { CityFieldProps } from './types';

export const CityField = observer<CityFieldProps>(({ stateFormField, requestParams, ...props }) => {
  const { common } = useStores();

  const { values, setFieldValue } = useForm<Record<string, any>>();

  const stateFromFormValues = stateFormField ? values[stateFormField] : undefined;

  const state = useMemo(
    () => (requestParams ? requestParams.state : stateFromFormValues),
    [requestParams, stateFromFormValues],
  );

  const prevState = usePrevious(state);

  const selectedCity = values[props.name];

  useEffect(() => {
    const isStateJustInitialized = !prevState && state;

    if (isStateJustInitialized) {
      return;
    }

    if (prevState !== state && selectedCity) {
      setFieldValue(props.name, undefined);
    }
  }, [state, prevState, selectedCity, setFieldValue, props.name]);

  const { getCityOptions, getCityInitialOptions } = common;

  const fetch: CityFieldProps['fetch'] = useCallback(
    async (search) => getCityOptions({ search, state }),
    [getCityOptions, state],
  );

  return (
    <SearchField fetch={fetch} disabled={!state} deps={state} fetchInitialOptions={getCityInitialOptions} {...props} />
  );
});
