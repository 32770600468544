import { ReactNode } from 'react';
import { message as antdMessage } from 'antd';
import { ArgsProps } from 'antd/lib/message';
import { Icon } from 'components/common/icon';
import { Typography } from 'components/common/typography';
import config from 'config';
import styles from './styles.module.scss';

const createMessage = ({
  message,
  color,
  icon,
  className,
}: {
  message: ReactNode;
  color: string;
  icon: string;
  className?: string;
}): Partial<ArgsProps> => ({
  icon: <></>,
  content: (
    <Typography display='flex' variant='body-2'>
      <Icon name={icon} verticalAlign='middle' color={color} marginRight={10} size={22} />
      <Typography variant='inherit'>{message}</Typography>
    </Typography>
  ),
  className,
  duration: config.options.messages.duration,
});

const successMessage = (message: ReactNode): void => {
  antdMessage.success(
    createMessage({
      icon: 'success',
      message,
      color: 'green4',
      className: styles.success,
    }),
  );
};

const errorMessage = (message: ReactNode): void => {
  antdMessage.error(
    createMessage({
      icon: 'delete',
      message,
      color: 'errorColor',
      className: styles.error,
    }),
  );
};

const warningMessage = (message: ReactNode): void => {
  antdMessage.success(
    createMessage({
      icon: 'warning',
      message,
      color: 'yellow6',
      className: styles.warning,
    }),
  );
};

export { successMessage, errorMessage, warningMessage };
