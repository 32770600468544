import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import { nullable } from 'stores/mst-types';
import { MSTAddStaticMethods } from 'stores/utils';
import { TagSelectOptionApi } from 'types';
import { deserializeTagSelectOption } from 'utils/deserializers';

const staticMethods = {
  fromResponseData: (data: TagSelectOptionApi): TagOptionSnapshot =>
    deserializeTagSelectOption(data) as TagOptionSnapshot,
};

const TagOption = types.model('TagOption', {
  label: types.string,
  value: types.string,
  isCustom: nullable(types.boolean),
  isPopular: nullable(types.boolean),
});

export type TagOptionSnapshot = SnapshotOut<typeof TagOption>;
export type TagOptionInstanceType = Instance<typeof TagOption>;

export default MSTAddStaticMethods(TagOption, staticMethods);
