import { useEffect } from 'react';
import get from 'lodash.get';
import { useForm } from 'hooks';
import { useCampaignFormContext } from 'components/campaigns/campaign-form/hooks';
import { FACEBOOK_IS_ACTIVE_FIELD, INSTAGRAM_IS_ACTIVE_FIELD } from '../constants';

export const useFacebookAndInstagramRelation = (): {
  isFacebookSelected: boolean;
} => {
  const { values, setFieldValue } = useForm();

  const isFacebookSelected = get(values, FACEBOOK_IS_ACTIVE_FIELD);
  const isInstagramSelected = get(values, INSTAGRAM_IS_ACTIVE_FIELD);

  const {
    campaignManager: { socialNetworks },
  } = useCampaignFormContext();
  const { settings } = socialNetworks;

  useEffect(() => {
    if (!isFacebookSelected && isInstagramSelected) {
      setFieldValue(INSTAGRAM_IS_ACTIVE_FIELD, false, true);
    }
  }, [settings.instagram.enabled, isFacebookSelected, isInstagramSelected, setFieldValue]);
  // @ts-ignore
  return { isFacebookSelected };
};
