import React, { memo } from 'react';
import { Box } from 'components/common/box';
import { Typography } from 'components/common/typography';
import { RowsFieldRemoveOldKeywordsButtonProps } from './types';

export const RowsFieldRemoveOldKeywordsButton = memo<RowsFieldRemoveOldKeywordsButtonProps>(
  ({ onRemoveOldKeywords, children, ...props }) => (
    <Typography variant='body-5' color='primaryColor' display='flex' justifyContent='end' {...props}>
      <Box cursor='pointer' onClick={onRemoveOldKeywords}>
        {children}
      </Box>
    </Typography>
  ),
);
