import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'components/common/box';
import { useLocation, useScrollToTop } from 'hooks';
import ErrorBoundary, { IntermediateErrorBoundary } from './error-boundary';

export interface AppContainerProps {}

export const AppContainer = observer<AppContainerProps>((props) => {
  const { children } = props;

  useScrollToTop();

  const { pathname } = useLocation();

  return (
    <ErrorBoundary>
      <IntermediateErrorBoundary pathname={pathname}>
        <Box display='flex' flexDirection='column'>
          {children}
        </Box>
      </IntermediateErrorBoundary>
    </ErrorBoundary>
  );
});
