import React, { ComponentType, memo } from 'react';
import { FormItem, FormItemProps } from 'components/common/form/form-item';
import { useFormContext } from 'hooks/use-form-context';
import { FormField } from 'types';
import { useField } from 'hooks';

interface Props {
  formItemProps?: Partial<FormItemProps>;
}

interface ComponentProps extends FormField {
  label?: any;
  disabled?: boolean | unknown;
}

export const withFormItem =
  <P extends ComponentProps>() =>
  (WrappedComponent: ComponentType<P>): ComponentType<P & Props> =>
    memo<P & Props>(({ label, formItemProps, disabled: componentDisabled, ...props }) => {
      const { field, error: rawError, isRequired } = useField(props);
      const { disabled } = useFormContext();

      const content = <WrappedComponent disabled={disabled || componentDisabled} {...(props as P)} />;

      const isErrorForNestedFields = Array.isArray(rawError);
      const error = isErrorForNestedFields ? undefined : rawError;

      return label || error ? (
        <FormItem
          htmlFor={field.id}
          label={label && `${label}${isRequired ? '*' : ''}`}
          validateStatus={error ? 'error' : ''}
          help={error}
          {...formItemProps}
        >
          {content}
        </FormItem>
      ) : (
        content
      );
    });
