import { initSentry, recordError as recordErrorWithSentry } from './sentry';

export function createErrorReport({ useSentry }: { useSentry: boolean }): {
  recordError: (error: Error) => void;
} {
  if (useSentry) {
    initSentry();

    return {
      recordError: (error: Error) => recordErrorWithSentry(error),
    };
  }

  return {
    recordError: (error: Error) => console.log(error),
  };
}
