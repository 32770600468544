import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores, useLocation, useHistory } from 'hooks';
import locations from 'navigation/locations';
import { createDestParamForLocation } from 'utils/locations';
import { RouteProps } from './types';
import { Route } from './route';

export const AuthenticatedRoute = observer<RouteProps & { shouldRedirect?: boolean }>(
  ({ shouldRedirect = true, ...props }) => {
    const { session } = useStores();
    const { isSignedIn, isSigningIn, isSigningOut } = session;

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
      if (!isSignedIn && !isSigningIn && !isSigningOut && shouldRedirect) {
        const dest = createDestParamForLocation(location);

        history.replace(locations.signInWithPassword.toUrl(dest ? { dest } : {}));
      }
    }, [history, isSignedIn, isSigningIn, isSigningOut, location, shouldRedirect]);

    return isSignedIn ? <Route {...props} /> : null;
  },
);
