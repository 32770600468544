import React, { memo, useCallback } from 'react';
import { useField, useTranslation } from 'hooks';
import { getDiffByField } from 'utils/arrays';
import { TagSelectProps } from 'components/common/form/tag-select';
import { filterEmpty, groupOptions } from 'components/common/form/tag-select/helper';
import { Select } from 'components/common/form/select';
import { Divider } from 'components/common/divider';
import { Box } from 'components/common/box';
import { TextField } from 'components/common/form/text-field';
import { Button } from 'components/common/button';
import { Icon } from 'components/common/icon';
import { CustomSelectProps } from './types';

export const CustomSelect = memo<CustomSelectProps>(
  ({ grouping = true, options, customAdd, customValue, menuItems, setCustomValue, ...props }) => {
    const { t } = useTranslation();

    const { field, componentProps } = useField(props);
    const { onChange } = field;

    const onCustomGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCustomValue(event.target.value);
    };

    const optionsModifier: TagSelectProps['optionsModifier'] = useCallback(
      (options) => {
        if (!options || !grouping) {
          return options;
        }

        const { popular, custom, all } = groupOptions({
          options,
          newCustomOptions: getDiffByField({
            target: [],
            array: options,
            field: 'value',
          }),
        });

        return filterEmpty(
          [
            { label: 'Popular', options: popular },
            { label: 'Custom', options: custom },
            { label: 'All', options: all },
          ].map(({ options, ...item }) => ({
            ...item,
            options: options?.map((option) => ({
              ...option,
            })),
          })),
        );
      },
      [grouping],
    );

    const uniqueCustomItem = menuItems.filter(
      (obj: any) =>
        // eslint-disable-next-line
        obj.group.label == customValue,
    );

    return (
      <Select
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider marginY={8} />
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              paddingX={20}
              paddingY={20}
              width='100%'
            >
              <Box width='85%'>
                <TextField
                  name='customGroup'
                  placeholder={t('KeywordsPicker.Placeholders.CustomGroup')}
                  value={customValue}
                  onChange={onCustomGroupChange}
                />
              </Box>
              <Button
                type='primary'
                size='circle-small'
                icon={<Icon name='plus' size={17} cursor='pointer' />}
                onClick={customAdd}
                disabled={customValue.length === 0 || uniqueCustomItem.length > 0}
              />
            </Box>
            {uniqueCustomItem.length > 0 ? (
              <div
                style={{
                  color: '#d92727',
                  marginTop: '-15px',
                  marginBottom: '5px',
                  marginLeft: '25px',
                }}
              >
                Group with the same name already exists
              </div>
            ) : (
              <div style={{ height: '12px' }} />
            )}
          </>
        )}
        optionsModifier={optionsModifier}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        composeOnChange
        onChange={onChange}
        options={options}
        {...componentProps}
      />
    );
  },
);
