import { useEffect } from 'react';
import { useLayoutContext } from 'hooks/use-layout-context';

export const useLogo = ({ url }: { url: string | null }): void => {
  const layoutContext = useLayoutContext();
  const setLogoUrl = layoutContext?.setLogoUrl;

  useEffect(() => {
    if (setLogoUrl) {
      setLogoUrl(url);
    }
  }, [setLogoUrl, url]);
};
