import { types, SnapshotOut, Instance } from 'mobx-state-tree';

const GroupOption = types.model('GroupOption', {
  label: types.string,
  value: types.string,
  is_new: types.boolean,
  is_old: types.boolean,
  template: types.string,
  is_keywords_affected: types.boolean,
  is_titles_affected: types.boolean,
  is_descriptions_affected: types.boolean,
});

export type OptionSnapshot = SnapshotOut<typeof GroupOption>;
export type OptionInstanceType = Instance<typeof GroupOption>;

export default GroupOption;
