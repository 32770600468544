import { getEnv, IStateTreeNode } from 'mobx-state-tree';
import { ContainerInterface } from 'services';
import { ApiClient } from 'services/api';

export const withServices = (self: IStateTreeNode) => ({
  views: {
    get services(): ContainerInterface {
      return getEnv<ContainerInterface>(self);
    },

    get api(): ApiClient {
      return getEnv<ContainerInterface>(self).get<ApiClient>('api');
    },
  },
});
