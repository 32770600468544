import { getRoot, types } from 'mobx-state-tree';
import { apiFlow } from 'stores/mst-types';
import Base from 'stores/models/base';
import { CampaignInstanceType, CampaignFormValues } from 'stores/models/campaigns/campaign';
import { CampaignManagerStore } from 'stores/campaigns/campaign-manager-store';
import { locations } from 'navigation';
import { History } from 'history';
import config from 'config';
import { Option } from 'types';
import { toJSONDeep } from 'utils/stores';

const { types: campaignTypes } = config.api.constants.campaigns;
const { brandingCampaignTargetKinds, organizations } = config.api.constants;
const { campaignSocialNetworksAvailability } = config.options;

const agentOptions = [
  {
    label: brandingCampaignTargetKinds.agent.name,
    value: brandingCampaignTargetKinds.agent.id,
  },
  {
    label: brandingCampaignTargetKinds.brokerage.name,
    value: brandingCampaignTargetKinds.brokerage.id,
    disabled: true,
  },
];

const brokerageOptions = [
  {
    label: brandingCampaignTargetKinds.agent.name,
    value: brandingCampaignTargetKinds.agent.id,
  },
  {
    label: brandingCampaignTargetKinds.brokerage.name,
    value: brandingCampaignTargetKinds.brokerage.id,
  },
];

const multifamilyOptions = [
  {
    label: brandingCampaignTargetKinds.company.name,
    value: brandingCampaignTargetKinds.company.id,
  },
];

export const BrandingCampaignStore = Base.named('BrandingCampaignStore')
  .props({
    campaignManager: types.optional(CampaignManagerStore, {}),
  })
  .views((self) => ({
    get user() {
      const { user } = getRoot<any>(self).session;

      return user;
    },

    get userOrganizationKindName() {
      return this.user?.organizationKindName;
    },

    get targetInitializationValues() {
      return {
        ...self.campaignManager.targetInitializationValues,
        ...this.targetHiddenValues,
      };
    },

    get targetHiddenValues() {
      const { target } = self.campaignManager;

      return toJSONDeep({
        targetUrl: target?.url,
        targetId: target?.id,
        targetImages: target?.images,
      });
    },

    get formInitializationValues() {
      const { campaignValues, campaign } = self.campaignManager;

      return {
        targetKind: campaign?.targetKind?.id ?? this.initialTargetKind,
        ...campaignValues,
        ...this.targetInitializationValues,
      };
    },

    get initialTargetKind() {
      const { agent, multifamily } = organizations.kindNames;

      switch (this.userOrganizationKindName) {
        case agent:
          return brandingCampaignTargetKinds.agent.id;
        case multifamily:
          return brandingCampaignTargetKinds.company.id;
        default:
          return undefined;
      }
    },

    get targetKindOptions() {
      const { agent, brokerage, multifamily } = organizations.kindNames;

      switch (this.userOrganizationKindName) {
        case agent:
          return agentOptions;
        case brokerage:
          return brokerageOptions;
        case multifamily:
          return multifamilyOptions;
        default:
          return [] as Option[];
      }
    },
  }))
  .actions((self) => ({
    newGoLive: apiFlow(function* newGoLive(data: Partial<CampaignFormValues>) {
      yield self.campaignManager.newGoLive({
        data,
        onSuccess: ({ id, showWarn }) => {
          const history = self.services.get<History>('history');
          history.replace(
            locations.campaigns.brandingCampaign.toUrl({
              id,
              showWarn,
            }),
          );
        },
      });
    }),
    initCampaign: apiFlow(function* initCampaign({ id }: Partial<Pick<CampaignInstanceType, 'id'>>) {
      yield self.campaignManager.init({
        id,
        socialNetworksAvailability: campaignSocialNetworksAvailability.branding,
        campaignKindId: campaignTypes.branding.id,
        notFoundUpdateFallbackUrl: locations.campaigns.newBrandingCampaign.toUrl(),
      });

      const targetKindId = self.campaignManager.campaign
        ? self.campaignManager.campaign.targetKind?.id
        : self.initialTargetKind;

      if (targetKindId) {
        yield self.campaignManager.loadTarget({
          targetKindId,
        });
      }

      self.campaignManager.setInitialization(false);
    }),
  }))
  .actions((self) => ({
    init: apiFlow(function* init({ id }: Partial<Pick<CampaignInstanceType, 'id'>>) {
      yield Promise.all([self.initCampaign({ id })]);

      self.campaignManager.setInitialization(false);
    }),

    destroy: () => {
      self.campaignManager.destroy();
    },
  }));
