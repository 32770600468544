import { GOOGLE_ADS_FIELD, GOOGLE_ADWORDS_FIELD } from 'components/campaigns/campaign-form/ad-social-networks-selector';
import get from 'lodash.get';
import { FormValidation } from 'types';
import { excludeEmpty } from 'utils/objects';
import { titleGoogleOptions, descriptionGoogleOptions, descriptionFbOptions, titleFbOptions } from 'utils/validations';
import { ObjectShape } from 'yup';
import { TITLES_FIELD, DESCRIPTIONS_FIELD } from './constants';

export const validationSchema: FormValidation = ({ values }) => {
  const isGoogleSelected = get(values, 'name') === GOOGLE_ADS_FIELD || get(values, 'name') === GOOGLE_ADWORDS_FIELD;

  return {
    ...(excludeEmpty({
      [TITLES_FIELD]: isGoogleSelected ? titleGoogleOptions(values) : titleFbOptions(),
    }) as ObjectShape),
    ...(excludeEmpty({
      [DESCRIPTIONS_FIELD]: isGoogleSelected ? descriptionGoogleOptions(values) : descriptionFbOptions(),
    }) as ObjectShape),
  };
};
