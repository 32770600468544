import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import pick from 'lodash.pick';
import {
  ApartmentParametersFieldFormValue,
  ApartmentParametersFieldFormValueApi,
  BuildingApartmentParameter,
  BuildingAPI,
  ImageFormValue,
  PatchBuildingApi,
  PostBuildingApi,
  TagSelectOption,
} from 'types';
import BaseModel from 'stores/models/base';
import TagOption from 'stores/models/tag-option';
import { MSTAddStaticMethods } from 'stores/utils';
import { nullable } from 'stores/mst-types';
import { deserializeApartmentParameters } from 'utils/deserializers';
import { serializeApartmentParameters, serializeTagSelectOptions } from 'utils/serializers';

export interface PostBuildingFormValues {
  name: string;
  state: number;
  city: number;
  zip: string;
  neighborhood: string;
  address: string;
  url: string;
  propertyType: number;
  yardiPropertyId?: number;
  yardiAutoUpdate?: boolean;
  clusters: any;
  buildingAmenities: TagSelectOption[];
  buildingCompetitors?: TagSelectOption[];
  masstransit?: TagSelectOption[];
  apartmentParameters: ApartmentParametersFieldFormValue[];
  apartmentPrices?: [
    {
      apartmentPricesMin?: number;
      apartmentPricesMax?: number;
    },
  ];
  description: string;
  initializing_status?: string;
}

export interface PatchBuildingFormValues extends Partial<PostBuildingFormValues> {}

const staticMethods = {
  fromResponseData: (data: BuildingAPI): BuildingSnapshot => {
    const apartmentParameters: ApartmentParametersFieldFormValueApi[] = data?.apartment_parameters?.map(
      (parameter: BuildingApartmentParameter): ApartmentParametersFieldFormValueApi => ({
        // @ts-ingore
        kind_id: parameter.kind_id,
        min_price: Number(parameter.min_price),
        max_price: Number(parameter.max_price),
      }),
    );
    return {
      id: String(data.id),
      name: data.name,
      url: data.url,
      logo: null, // TODO is it valid value?
      // @ts-ignore
      images: data?.images?.map((imageData) => ({ src: imageData.path, is_old: false })),
      hasAffectedCampaigns: Boolean(data.has_affected_campaigns),
      initializing_status: data.initializing_status,
      ...pick(data, ['neighborhood', 'address', 'description']),
      state: String(data?.state?.id),
      city: String(data?.city?.id),
      zip: data.zip_code,
      propertyType: data.property_type?.value,
      apartmentParameters: data.apartment_parameters && deserializeApartmentParameters(apartmentParameters),
      apartmentPrices: [
        {
          apartmentPricesMin: data?.apartment_min_price ? +data?.apartment_min_price : null,
          apartmentPricesMax: data?.apartment_max_price ? +data?.apartment_max_price : null,
        },
      ],
      buildingCompetitors: data.competitors?.map(TagOption.fromResponseData),
      masstransit: data.mass_transit?.map(TagOption.fromResponseData),
      clusters: data.clusters,
    };
  },

  toPostRequest: (values: PostBuildingFormValues): PostBuildingApi => ({
    ...pick(values, ['name', 'neighborhood', 'address', 'url', 'description']),
    state: +values.state,
    city: +values.city,
    zip_code: values.zip,
    property_type: +values.propertyType,
    apartment_parameters: values.apartmentParameters && serializeApartmentParameters(values.apartmentParameters),
    apartment_min_price: values.apartmentPrices && values.apartmentPrices[0]?.apartmentPricesMin,
    apartment_max_price: values.apartmentPrices && values.apartmentPrices[0]?.apartmentPricesMax,
    building_amenities: values.buildingAmenities && serializeTagSelectOptions(values.buildingAmenities),
    competitors: values.buildingCompetitors && serializeTagSelectOptions(values.buildingCompetitors),
    mass_transit: values.masstransit && serializeTagSelectOptions(values.masstransit),
    clusters: values.clusters,
  }),

  toPatchRequestData: (values: Partial<PatchBuildingFormValues>): Partial<PatchBuildingApi> =>
    staticMethods.toPostRequest(
      // @ts-ignore
      values as unknown as PatchBuildingFormValues,
    ),
};

const Building = BaseModel.named('Building').props({
  id: types.string,
  name: types.string,
  url: nullable(types.string),
  logo: nullable(types.string),
  images: types.frozen(),
  clusters: types.frozen(),
  hasAffectedCampaigns: nullable(types.boolean),
  initializing_status: nullable(types.string),
  state: types.string,
  city: types.string,
  zip: types.string,
  neighborhood: types.string,
  address: types.string,
  propertyType: types.string,
  apartmentParameters: nullable(
    types.array(
      types.model({
        type: types.string,
        minPrice: types.number,
        maxPrice: types.number,
      }),
    ),
  ),
  apartmentPrices: nullable(
    types.array(
      types.model({
        apartmentPricesMin: nullable(types.number),
        apartmentPricesMax: nullable(types.number),
      }),
    ),
  ),
  buildingCompetitors: nullable(types.array(TagOption)),
  masstransit: nullable(types.array(TagOption)),
  description: nullable(types.string),
});

export type BuildingInstanceType = Instance<typeof Building>;
export type BuildingSnapshot = SnapshotOut<typeof Building>;

export default MSTAddStaticMethods(Building, staticMethods);
