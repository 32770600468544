import React from 'react';
import { Input } from 'antd';
import classnames from 'classnames';
import { useField } from 'hooks';
import { withFormItem } from 'hocs/with-form-item';
import config from 'config';
import { TextareaProps } from './types';
import styles from './styles.module.scss';

const { textarea } = config.options;

const { TextArea } = Input;

const autoSize = { minRows: textarea.rows, maxRows: textarea.rows };

export const Textarea = withFormItem<TextareaProps>()((props) => {
  const { field, error, componentProps } = useField(props);
  const className = classnames(styles.root, {
    [styles.error]: error,
  });

  return <TextArea className={className} rows={textarea.rows} autoSize={autoSize} {...field} {...componentProps} />;
});
