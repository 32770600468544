import React, { memo, useMemo } from 'react';
import { Avatar as AntdAvatar } from 'antd';
import { getInitials } from 'utils/strings';
import { stringToColor } from 'utils/colors';
import { AvatarProps } from './types';

export const Avatar = memo<AvatarProps>(({ name, style: styleFromProps, ...props }) => {
  const initials = getInitials(name);

  const style = useMemo(() => {
    const color = initials && stringToColor(initials);

    return {
      backgroundColor: color,
      verticalAlign: 'middle',
      ...styleFromProps,
    };
  }, [initials, styleFromProps]);

  return (
    <AntdAvatar style={style} {...props}>
      {initials}
    </AntdAvatar>
  );
});
