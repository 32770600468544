import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import { GetAdSocialNetworksSettingsResponse } from '../response-types';

const { getSettings } = urls.adSocialNetworks;

export const createAdSocialNetworksApi = (http: BaseHttpClient) => ({
  getAdSocialNetworksSettings() {
    return http.get<GetAdSocialNetworksSettingsResponse>(getSettings.url);
  },
});
