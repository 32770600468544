import React, { memo, useState, useEffect, useMemo } from 'react';
import { Box } from 'components/common/box';
import { Image } from 'components/common/image';
import config from 'config';
import image from 'assets/images/logo.png';
import { SplashScreenProps } from './types';

const {
  options: {
    splashScreen: { minimumTimeShow },
  },
} = config;

const splashScreenStub = (
  <Box display='flex' alignItems='center' justifyContent='center' height='100vh' overflow='hidden'>
    <Image src={image} width='38%' alt='Loading' placeholder={null} preview={false} />
  </Box>
);

export const SplashScreen = memo<SplashScreenProps>(({ loading, children }) => {
  const [minDelayCompleted, setMinDelayCompleted] = useState(false);

  useEffect(() => {
    let active = true;

    setTimeout(() => {
      if (!active) {
        return;
      }

      setMinDelayCompleted(true);
    }, minimumTimeShow);

    return () => {
      active = false;
    };
  }, []);

  const showContent = useMemo(() => !loading && minDelayCompleted, [loading, minDelayCompleted]);

  return showContent ? <>{children}</> : splashScreenStub;
});
