import { cast, toGenerator } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { apiFlow, nullable } from 'stores/mst-types';
import Base from 'stores/models/base';
import Building, {
  BuildingInstanceType,
  PatchBuildingFormValues,
  PostBuildingFormValues,
} from 'stores/models/buildings/building';
import { fromItemResponse } from 'services/api';
import { isEmpty } from 'utils/is';
import { toJSONDeep } from 'utils/stores';
import locations from 'navigation/locations';

interface DetailsFormValues extends PatchBuildingFormValues {}

export const BuildingStore = Base.named('BuildingStore')
  .props({
    building: nullable(Building),
  })
  .views((self) => ({
    get initialValues() {
      return self.building
        ? toJSONDeep({
            ...pick(self.building, [
              'name',
              'state',
              'city',
              'zip',
              'neighborhood',
              'address',
              'url',
              'buildingCompetitors',
              'masstransit',
              'description',
              'images',
              'initializing_status',
            ]),
            // @ts-ignore
            clusters: self.building.clusters && toJSONDeep(self.building.clusters),
            propertyType: self.building.propertyType && toJSONDeep(self.building.propertyType),
            apartmentParameters: self.building.apartmentParameters && toJSONDeep(self.building.apartmentParameters),
            apartmentPrices: self.building.apartmentPrices && self.building.apartmentPrices,
          })
        : { initializing_status: null };
    },
  }))
  .actions((self) => ({
    createBuilding: apiFlow(
      function* createBuilding(values: PostBuildingFormValues) {
        const result = yield self.api.postBuilding(Building.toPostRequest(values));
        return result;
      },
      {
        isUpdate: true,
        formName: 'building',
        successAlert: 'BuildingForm.Alerts.Created',
        errorAlert: 'Common.Alerts.Error',
      },
    ),

    activateBuilding: apiFlow(function* postImages(id: any) {
      const response = yield self.api.activateBuilding(id);
      return response;
    }),

    postImages: apiFlow(function* postImages(id: any, images: any) {
      const response = yield self.api.postBuildingImages(id, images);
      return response;
    }),

    validateSingleImage: apiFlow(function* validateSingleImage(image: any) {
      const response = yield self.api.validateBuildingSingleImage(image);
      return response;
    }),

    syncWithYardi: apiFlow(function* syncWithYardi(data: number) {
      const response = yield* toGenerator(self.api.syncWithYardi(data));
      return response;
    }),

    loadBuilding: apiFlow(
      function* loadBuilding({ id }: Pick<BuildingInstanceType, 'id'>) {
        const response = yield* toGenerator(self.api.getBuilding({ id }));
        const { data } = fromItemResponse({
          response: response.data,
        });
        data.images = data.images.filter((image) => image.deleted_at === null);
        self.building = cast(Building.fromResponseData(data));
      },
      { handleNotFound: true },
    ),

    updateBuilding: apiFlow(
      function* updateBuilding(values: DetailsFormValues) {
        const id = self.building?.id;
        if (isEmpty(id)) {
          return;
        }

        yield* toGenerator(
          self.api.patchBuilding({
            // @ts-ignore
            id,
            data: Building.toPatchRequestData(values),
          }),
        );
      },
      {
        isUpdate: true,
        formName: 'building',
        successAlert: self.building?.hasAffectedCampaigns
          ? 'BuildingForm.Alerts.AffectedCampaignsWarning'
          : 'BuildingForm.Alerts.Edited',
        notFoundUpdateFallbackUrl: locations.buildings.newBuilding.toUrl(),
      },
    ),

    remove: apiFlow(
      function* remove() {
        const id = self.building?.id;
        if (isEmpty(id)) {
          return;
        }
        // @ts-ignore
        yield self.api.removeBuilding({ id });
      },
      {
        isUpdate: true,
        formName: 'building',
        successAlert: 'BuildingForm.Alerts.Deleted',
        errorAlert: 'Common.Alerts.Error',
      },
    ),
  }))
  .actions((self) => ({
    init: apiFlow(function* init({ id }: Pick<BuildingInstanceType, 'id'>) {
      yield self.loadBuilding({ id });
    }),

    destroy: () => {
      self.building = null;
    },
  }));
