import React, { CSSProperties, memo } from 'react';
import { Modal as AntdModal } from 'antd';
import classnames from 'classnames';
import { Icon } from 'components/common/icon/icon';
import { InfoIcon } from 'components/common/icon/info-icon';
import { Scrollbar } from 'components/common/scrollbar';
import { Preloader } from 'components/common/preloader';
import { Typography } from 'components/common/typography';
import { Box } from 'components/common/box';
import { ModalProps } from './types';
import styles from './styles.module.scss';

const getDefaultWidth = (variant: ModalProps['variant']): CSSProperties['width'] => {
  switch (variant) {
    case 'form':
      return 700;
    case 'confirmation':
      return 600;
    case 'notification':
      return 500;
    case 'menu':
      return 1009;
    default:
      return 'auto';
  }
};

const scrollableXPadding = 40;

const scrollableYProps = {
  paddingX: scrollableXPadding,
  marginX: -scrollableXPadding,
};

export const Modal = memo<ModalProps>(
  ({
    className: classNameFromProps = '',
    variant = 'form',
    children,
    width = getDefaultWidth(variant),
    title: titleFromProps,
    tooltip,
    titleProps,
    scrollbar = true,
    contentProps,
    headerProps,
    ...props
  }) => {
    const className = classnames(styles.root, classNameFromProps);

    const title = titleFromProps && (
      <Box display='flex' alignItems='center' justifyContent='center' marginBottom={50} {...headerProps}>
        <Typography variant='headline-6' {...titleProps}>
          {titleFromProps}
        </Typography>
        {tooltip ? (
          <Box verticalAlign='middle' display='inline-flex' marginX={10}>
            <InfoIcon tooltip={tooltip} />
          </Box>
        ) : null}
      </Box>
    );

    const content = scrollbar ? (
      <Scrollbar flex={1} {...scrollableYProps} {...contentProps}>
        {children}
      </Scrollbar>
    ) : (
      children
    );

    return (
      <AntdModal
        wrapClassName={className}
        title={title}
        width={width}
        closeIcon={<Icon name='close' color='grey5' size={12} />}
        destroyOnClose
        centered
        focusTriggerAfterClose={false}
        transitionName=''
        maskTransitionName=''
        {...props}
      >
        <Preloader className={styles['preloader-height-auto']}>{content}</Preloader>
      </AntdModal>
    );
  },
);
