import React, { memo, useEffect, useState } from 'react';
import { Alert } from 'antd';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { ErrorMessageProps } from './types';
import styles from './styles.module.scss';

export const RequestErrorMessage = observer<ErrorMessageProps>(() => {
  const [show, setShow] = useState(false);
  const { session } = useStores();
  const { isError, setError } = session;

  useEffect(() => {
    if (isError) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
        setError(false);
      }, 6000);
    }
  }, [isError, setError]);

  return (
    <>
      {show && (
        <div className={styles['error-body-container']}>
          <Alert
            className={styles.alert}
            message='Error'
            description='An error has occurred on the server. Please try again later.'
            type='error'
            showIcon
          />
        </div>
      )}
    </>
  );
});
