const safeJsonParse = <T extends Record<string, any> = Record<string, any>>(json: string): T => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return {} as T;
  }
};

const stringify = (value: unknown): string => JSON.stringify(value);

export { safeJsonParse, stringify };
