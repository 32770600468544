import {
  OptionApi,
  Option,
  OptionEntity,
  SerializedDateRange,
  TagSelectOption,
  TagSelectOptionApi,
  ApartmentParametersFieldFormValueApi,
  ApartmentParametersFieldFormValue,
  AuthTokenPairs,
  AuthTokenPairsApi,
  OptionDetailsApi,
  CampaignTextAssetAPI,
  DeserializedDateRange,
  CampaignStatusApi,
  CampaignTypeApi,
  OptionWithOldMarker,
  PlatformOptionApi,
  PlatformOption,
  KeywordFormValue,
  KeywordFormValueApi,
  KeywordsGroupFormValue,
  KeywordsGroupFormValueApi,
  FacebookPlatformValuesApi,
  FacebookPlatformValues,
  OptionDetailed,
  OptionDetailedApi,
  FacebookCustomAudienceOption,
  FacebookCustomAudienceOptionApi,
  OptionWithPlatformMarker,
  SupportedUserOptionApi,
  SupportedUserOption,
  OrganizationOptionsApi,
  TitleApi,
  DescriptionApi,
  OptionFromValue,
  SerializedDateStart,
  OptionEntityApi,
  CampaignApprovedAssetImagesApi,
  CampaignApprovedAssetImages,
  CampaignTextOptionApi,
} from 'types';
import { v4 as uuidv4 } from 'uuid';
import { GoogleErrorsApi, GoogleErrorsValue } from 'types/errors';
import pick from 'lodash.pick';
import config from 'config';
import { findOptionById } from './objects';

const { statuses, types } = config.api.constants.campaigns;

const { statuses: statusesOption } = config;

const deserializeConnectPlatform = (value: PlatformOptionApi): PlatformOption => ({
  isConnected: value.is_connected,
});

const deserializeFacebookPlatform = (value: FacebookPlatformValuesApi): FacebookPlatformValues => ({
  isConnected: value.is_connected,
  accessTokenExpiredDate: value.facebook_access_token_expired_date,
});

const deserializeSupportedUser = (option: SupportedUserOptionApi): SupportedUserOption => ({
  ...pick(option, ['active']),
  value: String(option.id),
  label: `${option.first_name} ${option.last_name}`,
});

const deserializeSupportedOrganization = (option: OrganizationOptionsApi): Option => ({
  value: String(option.id),
  label: option.name,
});

const deserializeOrganizationOptions = (options: OrganizationOptionsApi[]): Option[] =>
  options.map(deserializeSupportedOrganization);

const deserializeUserOptions = (options: SupportedUserOptionApi[]): SupportedUserOption[] =>
  options.map(deserializeSupportedUser);

const deserializeStatus = (value: string): CampaignStatusApi =>
  findOptionById({
    id: String(value),
    settings: statuses,
  });

const deserializeType = (value: string): CampaignTypeApi =>
  findOptionById({
    id: String(value),
    settings: types,
  });

const deserializeCampaignTargetKind = ({
  value,
  campaignKind,
}: {
  value: string;
  campaignKind: string;
}): CampaignTypeApi =>
  findOptionById({
    id: String(value),
    settings: campaignKind === types.branding.id ? config.api.constants.brandingCampaignTargetKinds : types,
  });

const deserializeDateRange = <T extends Nullable<Partial<SerializedDateRange>>>(
  dateRange: T,
): InferNullableRecord<T, NullableFields<DeserializedDateRange>> =>
  ({
    from: dateRange?.start_date && dateRange.start_date,
    to: dateRange?.end_date && dateRange.end_date,
  } as InferNullableRecord<T, NullableFields<DeserializedDateRange>>);

const deserializeDateStart = <T extends Nullable<Partial<SerializedDateStart>>>(
  dateRange: T,
): InferNullableRecord<T, NullableFields<DeserializedDateRange>> =>
  ({
    from: dateRange?.start_date && dateRange.start_date,
  } as InferNullableRecord<T, NullableFields<DeserializedDateRange>>);

const deserializeOption = (option: OptionApi): Option => ({
  ...pick(option, ['label']),
  value: String(option.value),
});

const deserializeOptionEntity = (option: OptionEntityApi): OptionEntity => ({
  ...pick(option, ['label']),
  value: String(option.value),
  initializing_status: String(option.initializing_status),
});

const deserializeOptions = (options: OptionApi[]): Option[] => options.map(deserializeOption);
const deserializeOptionsEntity = (options: OptionEntityApi[]): OptionEntity[] => options.map(deserializeOptionEntity);

const deserializeOptionDetailed = (option: OptionDetailedApi): OptionDetailed => ({
  ...deserializeOption(option),
  picture: option.picture,
});

const deserializeOptionsDetailed = (options: OptionDetailedApi[]): OptionDetailed[] =>
  options.map(deserializeOptionDetailed);

const deserializeFacebookCustomAudienceOption = (
  option: FacebookCustomAudienceOptionApi,
): FacebookCustomAudienceOption => ({
  ...deserializeOption(option),
  pixelId: option.fb_pixel_id,
});

const deserializeFacebookCustomAudienceOptions = (
  options: FacebookCustomAudienceOptionApi[],
): FacebookCustomAudienceOption[] => options.map(deserializeFacebookCustomAudienceOption);

const deserializeOptionDetails = ({ id, name }: OptionDetailsApi): Option => ({
  label: name,
  value: String(id),
});

const deserializeTitleOption = (option: TitleApi): OptionFromValue => ({
  value: option.title ? option.title : uuidv4(),
  label: option.title_text ?? '',
});

const deserializeTitleOptions = (options: TitleApi[]): OptionFromValue[] => options.map(deserializeTitleOption);

const deserializeDescriptionOption = (option: DescriptionApi): OptionFromValue => ({
  value: option.description ? option.description : uuidv4(),
  label: option.description_text ?? '',
});

const deserializeDescriptionOptions = (options: DescriptionApi[]): OptionFromValue[] =>
  options.map(deserializeDescriptionOption);

const deserializeTagSelectOption = (option: TagSelectOptionApi): TagSelectOption => ({
  ...pick(option, ['label']),
  value: String(option.value),
  isCustom: Boolean(option.is_custom),
  isPopular: Boolean(option.is_popular),
  isOld: Boolean(option.is_old),
});

const deserializeTagSelectOptions = (options: TagSelectOptionApi[]): Required<TagSelectOption>[] =>
  options.map(deserializeTagSelectOption) as Required<TagSelectOption>[];

const deserializeCampaignTextOption = (option: CampaignTextOptionApi): OptionWithPlatformMarker => ({
  label: option.text,
  value: String(option.id),
  isOld: option.status === statusesOption.old,
  platform: option.platform,
  isActive: option.status === statusesOption.active,
  isTemporary: option.status === statusesOption.temporary,
  asset_number: option.asset_number,
  use_case_group: option.use_case_group,
});

const deserializeCampaignTextOptions = (options: CampaignTextOptionApi[]): Required<OptionWithOldMarker>[] =>
  options.map(deserializeCampaignTextOption) as Required<OptionWithPlatformMarker>[];

const deserializeBuildingCampaignTextOption = (option: CampaignTextAssetAPI): OptionWithOldMarker => ({
  label: option.value,
  value: option.id.toString(),
  isOld: false,
  asset_number: option.asset_number,
  use_case_group: option.use_case_group,
});

export const deserializeBuildingCampaignTextOptions = (options: CampaignTextAssetAPI[]): OptionWithOldMarker[] =>
  options.map(deserializeBuildingCampaignTextOption) as OptionWithPlatformMarker[];

const deserializeApartmentParameter = (
  option: ApartmentParametersFieldFormValueApi,
): ApartmentParametersFieldFormValue => ({
  type: String(option.kind_id),
  minPrice: Number(option.min_price),
  maxPrice: Number(option.max_price),
});

const deserializeApartmentParameters = (
  options: ApartmentParametersFieldFormValueApi[],
): ApartmentParametersFieldFormValue[] => options.map(deserializeApartmentParameter);

const deserializeAuthTokenPairs = ({ access, refresh }: AuthTokenPairsApi): AuthTokenPairs => ({
  accessToken: access,
  refreshToken: refresh,
});

const deserializeKeyword = ({
  label,
  value,
  is_old: isOld,
  is_custom: isCustom,
}: KeywordFormValueApi): Required<KeywordFormValue> => ({
  label,
  value: String(value),
  isOld: Boolean(isOld),
  isCustom: Boolean(isCustom),
});

const deserializeKeywordsGroup = ({ group, keywords }: KeywordsGroupFormValueApi): KeywordsGroupFormValue => ({
  group: deserializeOption(group),
  keywords: keywords?.map(deserializeKeyword),
});

const deserializeKeywordGroups = (groups: KeywordsGroupFormValueApi[]): KeywordsGroupFormValue[] =>
  groups.map(deserializeKeywordsGroup);

const deserializeGoogleRecentErrors = (value: GoogleErrorsApi): GoogleErrorsValue => ({
  callPhoneNumberNotSupportedForCountry: value.call_phone_number_not_supported_for_country,
});

const deserializeCampaignApprovedAssetImages = (
  value: Nullable<CampaignApprovedAssetImagesApi>,
): CampaignApprovedAssetImages => ({
  facebook: value?.facebook.map((imagePath) => ({ isOld: false, src: imagePath })) ?? [],
  googleDisplay: value?.google_display.map((imagePath) => ({ isOld: false, src: imagePath })) ?? [],
});

export {
  deserializeDateRange,
  deserializeDateStart,
  deserializeOptions,
  deserializeOptionsEntity,
  deserializeOptionDetails,
  deserializeTagSelectOption,
  deserializeTagSelectOptions,
  deserializeOptionsDetailed,
  deserializeApartmentParameters,
  deserializeAuthTokenPairs,
  deserializeCampaignTextOptions,
  deserializeStatus,
  deserializeType,
  deserializeCampaignTargetKind,
  deserializeConnectPlatform,
  deserializeKeyword,
  deserializeKeywordsGroup,
  deserializeKeywordGroups,
  deserializeFacebookPlatform,
  deserializeFacebookCustomAudienceOptions,
  deserializeSupportedUser,
  deserializeOrganizationOptions,
  deserializeUserOptions,
  deserializeSupportedOrganization,
  deserializeTitleOption,
  deserializeTitleOptions,
  deserializeDescriptionOptions,
  deserializeDescriptionOption,
  deserializeGoogleRecentErrors,
  deserializeCampaignApprovedAssetImages,
};
