import React, { memo, useCallback } from 'react';
import { Box } from 'components/common/box';
import { Icon } from 'components/common/icon';
import { RowsFieldRowProps } from './types';

export const RowsFieldRow = memo<RowsFieldRowProps>(
  ({ rowIndex, showRemoveButton, onRemove: onRemoveFromProps, children }) => {
    const onRemove = useCallback(() => {
      onRemoveFromProps(rowIndex);
    }, [onRemoveFromProps, rowIndex]);

    return (
      <Box display='flex' alignItems='flex-end' position='relative'>
        {showRemoveButton ? (
          <Box display='flex' position='absolute' right='100%' top={0} marginRight={20} marginTop={44}>
            <Icon name='minus' size={16} circle color='primaryColor' cursor='pointer' onClick={onRemove} />
          </Box>
        ) : null}
        <Box flex={1} minWidth={0}>
          {children}
        </Box>
      </Box>
    );
  },
);
