import React, { memo, useMemo } from 'react';
import { NumberInput } from 'components/common/form/number-input';
import { IntegerFieldProps } from './types';

export const IntegerField = memo<IntegerFieldProps>(({ numberFormatProps: numberFormatPropsFromProps, ...props }) => {
  const numberFormatProps = useMemo(
    () => ({
      decimalScale: 0,
      ...numberFormatPropsFromProps,
    }),
    [numberFormatPropsFromProps],
  );

  return <NumberInput numberFormatProps={numberFormatProps} {...props} />;
});
