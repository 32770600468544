import { toGenerator, getRoot, cast } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { apiFlow, nullable } from 'stores/mst-types';
import Base from 'stores/models/base';
import Listing, {
  PostListingFormValues,
  PatchListingFormValues,
  ListingInstanceType,
} from 'stores/models/listings/listing';
import config from 'config';
import { fromItemResponse } from 'services/api';
import { isEmpty } from 'utils/is';
import { toJSONDeep } from 'utils/stores';
import locations from 'navigation/locations';

const { types } = config.api.constants.listings;

export const ListingStore = Base.named('ListingStore')
  .props({
    listing: nullable(Listing),
  })
  .views((self) => ({
    get initialValues() {
      return self.listing
        ? toJSONDeep({
            ...pick(self.listing, [
              'name',
              'agent',
              'state',
              'city',
              'zip',
              'neighborhood',
              'address',
              'url',
              'propertyType',
              'type',
              'monthlyRent',
              'price',
              'listingAmenities',
              'bedrooms',
              'bathrooms',
              'acres',
              'squareFeet',
              'description',
              'images',
              'initializing_status',
            ]),
          })
        : this.defaultValues;
    },

    get defaultValues() {
      const { user } = getRoot<any>(self).session;

      return {
        agent: user.email,
        type: types.rent.id,
        initializing_status: null,
      };
    },
  }))
  .actions((self) => ({
    createListing: apiFlow(
      function* createListing(values: PostListingFormValues) {
        yield self.api.postListing(Listing.toPostRequest(values));
      },
      {
        isUpdate: true,
        formName: 'listing',
        successAlert: 'ListingForm.Alerts.Created',
      },
    ),

    loadListing: apiFlow(
      function* loadListing({ id }: Pick<ListingInstanceType, 'id'>) {
        const response = yield* toGenerator(self.api.getListing({ id }));
        const { data } = fromItemResponse({
          response: response.data,
        });

        self.listing = cast(Listing.fromResponseData(data));
      },
      { handleNotFound: true },
    ),

    updateListing: apiFlow(
      function* updateListing(values: PatchListingFormValues) {
        const id = self.listing?.id;

        if (isEmpty(id)) {
          return;
        }

        const response = yield* toGenerator(
          self.api.patchListing({
            // @ts-ignore
            id,
            data: Listing.toPatchRequestData(values),
          }),
        );

        const { data } = fromItemResponse({
          response: response.data,
        });

        self.listing = cast(Listing.fromResponseData(data));
      },
      {
        isUpdate: true,
        formName: 'listing',
        successAlert: 'ListingForm.Alerts.Edited',
        notFoundUpdateFallbackUrl: locations.listings.newListing.toUrl(),
      },
    ),

    remove: apiFlow(
      function* remove() {
        const id = self.listing?.id;

        if (isEmpty(id)) {
          return;
        }
        // @ts-ignore
        yield self.api.removeListing({ id });
      },
      {
        isUpdate: true,
        formName: 'listing',
        successAlert: 'ListingForm.Alerts.Deleted',
        errorAlert: 'Common.Alerts.Error',
      },
    ),
  }))
  .actions((self) => ({
    init: apiFlow(function* init({ id }: Pick<ListingInstanceType, 'id'>) {
      yield self.loadListing({ id });
    }),

    destroy: () => {
      self.listing = null;
    },
  }));
