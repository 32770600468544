import React, { useCallback, useState, useEffect, useRef, memo, useMemo } from 'react';
import { Button as AntdButton } from 'antd';
import classnames from 'classnames';
import { usePropsFromContext } from 'hooks/use-props-from-context';
import { useFormContext } from 'hooks/use-form-context';
import { ButtonProps } from './types';
import styles from './styles.module.scss';

export const Button = memo<ButtonProps>(
  ({ className, propsFromContext, onClick: onClickFromProps, formValidation, formSubmit, ...props }) => {
    const elRef = useRef<HTMLButtonElement | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const contextProps = usePropsFromContext('buttonProps', propsFromContext);

    const formContext = useFormContext();
    const setFormValidation = formContext?.setCustomValidationKey;
    const setFormSubmit = formContext?.setCustomSubmitKey;

    const { htmlType } = props;

    const hasCustomFormValidationOrSubmit = useMemo(() => {
      const isSubmitButton = htmlType === 'submit';

      return (isSubmitButton && formValidation) || formSubmit;
    }, [formSubmit, formValidation, htmlType]);

    const onClick: NonNullable<ButtonProps['onClick']> = useCallback(
      (event) => {
        if (hasCustomFormValidationOrSubmit && !isSubmitting) {
          event.preventDefault();

          if (formValidation && setFormValidation) {
            setFormValidation(formValidation);
          }

          if (formSubmit && setFormSubmit) {
            setFormSubmit(formSubmit);
          }

          setIsSubmitting(true);
          return;
        }

        setIsSubmitting(false);
        if (onClickFromProps) {
          onClickFromProps(event);
        }
      },
      [
        formSubmit,
        formValidation,
        hasCustomFormValidationOrSubmit,
        isSubmitting,
        onClickFromProps,
        setFormSubmit,
        setFormValidation,
      ],
    );

    useEffect(() => {
      if (isSubmitting && elRef.current) {
        elRef.current.click();
      }
    }, [isSubmitting]);

    return (
      <AntdButton
        ref={elRef}
        className={classnames(styles.root, styles[props.type as string], styles[props.size as string], className)}
        {...props}
        type={(props.type as any) ?? 'default'}
        size={props.size as any}
        onClick={onClick}
        {...contextProps}
      />
    );
  },
);
