import React, { memo, useEffect, useState, useRef } from 'react';
import { History } from 'history';
import { SplashScreen } from 'components/layout/splash-screen';
import { LayoutWrapper } from 'components/layout/layout-wrapper';
import { Version } from 'components/common/version';
import { StatusEnum } from 'stores/enums';
import { RootStoreInstance, RootStoreProvider } from 'stores';
import { AppNavigator, LocationProvider } from 'navigation';
import { ServicesProvider, ContainerInterface, configuredServices, ErrorReportClientInterface } from 'services';
import config from 'config';
import { RequestErrorMessage } from 'components/common/request-error-message';

const App = memo(() => {
  const [status, setStatus] = useState<StatusEnum>(StatusEnum.Pending);
  const servicesContainer = useRef<ContainerInterface | null>(null);

  useEffect(() => {
    (async () => {
      let container = null;

      try {
        document.title = config.applicationName || 'Minerva';

        container = await configuredServices;
        servicesContainer.current = container;

        // For deployment on AWS S3, we need to handle #! paths on redirects
        // to support client-side app navigation.
        const path = (/#!(.*)$/.exec(window.location.hash) || [])[1];

        if (path) {
          container.get<History>('history').replace(path);
        }

        // Get the store and bootstrap all internal stores
        const rootStore = container.get<RootStoreInstance>('rootStore');
        await rootStore.bootstrap();

        setStatus(StatusEnum.Done);
      } catch (error) {
        console.log(error);

        const errorReportClient = container?.get<ErrorReportClientInterface>('error-report');

        if (errorReportClient) {
          errorReportClient.recordError(error as Error);
        }

        setStatus(StatusEnum.Error);
      }
    })();
  }, []);

  if (status === StatusEnum.Error) {
    return <>Error</>;
  }

  return (
    <SplashScreen loading={status === StatusEnum.Pending}>
      <ServicesProvider value={servicesContainer.current as ContainerInterface}>
        <RootStoreProvider>
          <LocationProvider>
            {config.version.enabled ? <Version /> : null}
            <LayoutWrapper>
              <RequestErrorMessage />
              <AppNavigator />
            </LayoutWrapper>
          </LocationProvider>
        </RootStoreProvider>
      </ServicesProvider>
    </SplashScreen>
  );
});

export default App;
