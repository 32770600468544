import React, { useMemo, useRef, useEffect, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { observer } from 'mobx-react-lite';
import { Form as AntdForm } from 'antd';
import { useStores, useTranslation, useForm } from 'hooks';
import { FormContext } from 'contexts/form-context';
import { FormError } from 'components/common/form/form-error';
import { DirtyFormLeavingGuard } from 'components/common/form/dirty-form-leaving-guard';
import { ValuesChangeSubscriber } from 'components/common/form/values-change-subscriber';
import { createSchema, getRequiredFieldNames } from './helper';
import { FormWarning } from './form-warning';
import { InnerFormProps } from './types';

export const InnerForm = observer<InnerFormProps>(
  ({
    name,
    validation,
    showServerErrors,
    onChange,
    children,
    layout,
    formRef,
    style,
    allowDirtyLeave = true,
    setCustomValidationKey,
    setCustomSubmitKey,
    errorMessages: errorMessagesFromProps,
    getErrorMessages,
    warningMessages: warningMessagesFromProps,
    disabled,
  }) => {
    const idRef = useRef(uuidv4());
    const [formFields, setFormFields] = useState<string[]>([] as string[]);

    const addFormField = useCallback((field: string) => {
      setFormFields((prevState) => [...prevState.filter((prevField) => prevField !== field), field]);
    }, []);

    const removeFormField = useCallback((field: string) => {
      setFormFields((prevState) => prevState.filter((prevField) => prevField !== field));
    }, []);

    const { t } = useTranslation();
    const { formErrors: formErrorsStore, formStates: formStatesStore } = useStores();
    const { resetFormServerErrors } = formErrorsStore;
    const formServerErrors = name ? formErrorsStore.formServerErrors[name] : null;

    const { resetFormState } = formStatesStore;

    const form = useForm();
    const { values, handleReset, handleSubmit, isSubmitting, setTouched, initialValues } = form;

    useEffect(
      () => () => {
        if (name) {
          resetFormServerErrors({ formName: name });
          resetFormState({ formName: name });
        }
      },
      [resetFormServerErrors, resetFormState, name],
    );

    useEffect(() => {
      if (formRef) {
        formRef.current = form;
      }
    }, [form, formRef]);

    useEffect(() => {
      if (!isSubmitting) {
        return;
      }

      const touched = formFields.reduce(
        (result, key) => ({
          ...result,
          [key]: true,
        }),
        {},
      );
      setTouched(touched);
    }, [formFields, isSubmitting, setTouched]);

    useEffect(() => {
      if (!isSubmitting) {
        setCustomValidationKey(null);
        setCustomSubmitKey(null);
      }
    }, [isSubmitting, setCustomSubmitKey, setCustomValidationKey]);

    const requiredFields = useMemo(() => {
      const schema = createSchema({
        t,
        values: values as any,
        validation,
        initialValues: initialValues as any,
      });

      return getRequiredFieldNames(schema.describe());
    }, [t, values, validation, initialValues]);

    const contextValue = useMemo(
      () => ({
        id: idRef.current,
        name,
        formServerErrors,
        requiredFields,
        addFormField,
        removeFormField,
        setCustomValidationKey,
        setCustomSubmitKey,
        handleSubmit,
        formFields,
        disabled,
      }),
      [
        name,
        formServerErrors,
        requiredFields,
        addFormField,
        removeFormField,
        setCustomValidationKey,
        setCustomSubmitKey,
        handleSubmit,
        formFields,
        disabled,
      ],
    );

    const errorMessages = useMemo(
      () => [...(getErrorMessages ? getErrorMessages({ form }) : []), ...(errorMessagesFromProps || [])],
      [errorMessagesFromProps, form, getErrorMessages],
    );

    const warningMessages = useMemo(() => [...(warningMessagesFromProps || [])], [warningMessagesFromProps]);

    return (
      <FormContext.Provider value={contextValue}>
        <AntdForm layout='vertical' onFinish={handleSubmit} onReset={handleReset} style={style} {...layout}>
          {showServerErrors ? <FormError messages={errorMessages} marginBottom={25} /> : null}
          {showServerErrors ? <FormWarning messages={warningMessages} marginBottom={25} /> : null}
          {children}
          {onChange ? <ValuesChangeSubscriber onChange={onChange} /> : null}
          {allowDirtyLeave ? null : <DirtyFormLeavingGuard />}
        </AntdForm>
      </FormContext.Provider>
    );
  },
);
