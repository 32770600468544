import config from 'config';
import { History } from 'history';
import { configureStore, RootStoreInstance } from 'stores';
import { configureI18n } from 'i18n';
import { TFunction } from 'i18next';
import database from 'database';
import Container from './container';
import { ContainerInterface, ErrorReportClientInterface } from './types';
import { createApiClient, ApiClient } from './api';
import { configureHistory } from './browser-history';
import { createErrorReport } from './error-report';

export async function configureServices(): Promise<ContainerInterface> {
  if (config.api.useMock) {
    await database.init();
  }

  const container = new Container();

  const errorReport = createErrorReport({
    useSentry: config.sentry.isEnabled,
  });
  container.register<ErrorReportClientInterface>('error-report', errorReport);

  const i18n = await configureI18n();
  container.register<TFunction>('i18n', i18n);

  container.factory<History>('history', configureHistory);

  container.register<typeof config>('config', config);

  container.factory<ApiClient>('api', createApiClient);

  const rootStore = await configureStore(container);
  container.register<RootStoreInstance>('rootStore', rootStore);

  return container;
}

export const configuredServices = configureServices();
