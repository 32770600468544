import { env } from 'utils/env';
import packageVersion from 'utils/versions';

export const sentry = {
  isEnabled: env.REACT_APP_SENTRY_IS_ENABLED,
  dsn: env.REACT_APP_SENTRY_DSN,
  release: packageVersion,
  environment: env.REACT_APP_VERSION_TARGET_ENVIRONMENT,
  tracesSampleRate: env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  showCrashDialog: env.REACT_APP_SENTRY_SHOW_CRASH_DIALOG,
};
