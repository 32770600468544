import { OptionApi, SearchQuery } from 'types';
import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import {
  GetCityDetailsResponse,
  GetCityOptionsResponse,
  GetStateDetailsResponse,
  GetStateOptionsResponse,
} from '../response-types';

export const createGeoLocationsApi = (http: BaseHttpClient) => ({
  getStatesOptions({ search }: SearchQuery) {
    return http.get<GetStateOptionsResponse>(urls.geoLocations.states.getOptions.url, {
      search,
    });
  },

  getStateDetails({ id }: { id: OptionApi['value'] }) {
    return http.get<GetStateDetailsResponse>(urls.geoLocations.states.details.url.replace('{id}', String(id)));
  },

  getCityOptions({ search, state }: SearchQuery & { state: string }) {
    return http.get<GetCityOptionsResponse>(urls.geoLocations.cities.getOptions.url, {
      search,
      state__id: state,
    });
  },

  getCityDetails({ id }: { id: OptionApi['value'] }) {
    return http.get<GetCityDetailsResponse>(urls.geoLocations.cities.details.url.replace('{id}', String(id)));
  },
});
