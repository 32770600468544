import React, { memo } from 'react';
import { useTranslation } from 'hooks';
import { Box } from 'components/common/box';
import { Typography } from 'components/common/typography';
import { AD_TITLE_FIELD } from 'components/campaigns/campaign-form/constants';
import { Icon } from 'components/common/icon';
import { AddAdditionalButtonProps } from './types';

export const AddAdditionalButton = memo<AddAdditionalButtonProps>(({ name, onAdd, ...props }) => {
  const { t } = useTranslation();

  return (
    <Box display='flex' justifyContent='center' marginBottom={50} marginTop={30} {...props}>
      <Typography variant='body-4' color='secondaryFontColor' display='inline-block' cursor='pointer' onClick={onAdd}>
        <Icon
          name='plus'
          size={20}
          circle
          color='primaryColor'
          marginRight={25}
          verticalAlign='middle'
          cursor='pointer'
        />
        <Box style={{ color: '#0f0ff4', marginBottom: '-10px' }} is='span' verticalAlign='middle'>
          {name === AD_TITLE_FIELD ? t('TextPicker.AddAdditionalTitle') : t('TextPicker.AddAdditionalDescription')}
        </Box>
      </Typography>
    </Box>
  );
});
