import MockAdapter from 'axios-mock-adapter';
import * as storage from 'utils/storage';
import database from 'database';
import { urls } from '../api-urls';

export function createUsersMock(mock: MockAdapter): void {
  const {
    users: { getProfile },
  } = urls;

  mock.onGet(getProfile.regex).reply(async () => {
    const data = await storage.load('root');

    const user = await database.users.find({
      jwt: data.session?.token?.token,
    });

    const profile = {
      id: user?.id || '1',
      first_name: user?.first_name,
      last_name: user?.last_name,
      url: 'http://test-user.com',
      images: [
        {
          src: 'https://picsum.photos/id/1000/367/267',
        },
        {
          src: 'https://picsum.photos/id/1018/367/267',
        },
        {
          src: 'https://picsum.photos/id/1042/367/267',
        },
      ],
    };

    return [200, profile];
  });
}
