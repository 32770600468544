import React, { useCallback, memo, isValidElement, useState, useRef, useEffect } from 'react';
import { useMountedState } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { useModalContext, useTranslation } from 'hooks';
import { Preloader } from 'components/common/preloader';
import { noop } from 'utils/functions';
import { isNotFoundRequestError } from 'services/api/errors';
import { cloneDeep } from 'lodash';
import { createSchema } from './helper';
import { InnerForm } from './inner-form';
import { FormProps } from './types';
import { FormFooter } from './form-footer';

const defaultInitialValues = {};

const defaultOnSubmit = noop;

export const FormAdGroup = memo<FormProps>(
  ({
    name,
    validation: validationFromProps,
    initialValues = defaultInitialValues,
    onSubmit: onSubmitFromProps = defaultOnSubmit,
    showServerErrors = true,
    onChange,
    closeModalOnSuccess = true,
    onSuccess,
    footer = false,
    footerProps,
    children,
    layout,
    formRef,
    pending,
    resetOnFail,
    removeOlddAdGroups,
    menuItems,
    disableDuplicateGroupName,
    resetOnSuccessSubmit = true,
    nameForm,
    ...props
  }) => {
    const initializationRef = useRef(true);
    const [pendingSubmit, setPendingSubmit] = useState(false);
    // some form fields still has value from previous initialValues while we redirect user from for example /building/<id> to /building/new
    // it can happen if we will open two tabs with the same building then delete it on first tab and save changes on second tab
    // the system will redirect us to /building/new page but zip field still has the value from removed building
    // 'key' is needed to solve this issue
    const [key, setKey] = useState(uuidv4());
    const [customValidationKey, setCustomValidationKey] = useState<string | null>(null);
    const [customSubmitKey, setCustomSubmitKey] = useState<string | null>(null);

    useEffect(() => {
      if (initializationRef.current) {
        return;
      }

      setKey(uuidv4());
    }, [initialValues]);

    const isMounted = useMountedState();
    const { t } = useTranslation();

    const modalContext = useModalContext();

    const validationFn =
      validationFromProps && typeof validationFromProps === 'object'
        ? validationFromProps[customValidationKey ?? 'default']
        : validationFromProps;

    const validation = useCallback(
      (values) => {
        try {
          const schema = validationFn
            ? createSchema({
                t,
                values,
                initialValues,
                validation: validationFn,
              })
            : {};

          validateYupSchema(values, schema, true, values);
        } catch (err) {
          return yupToFormErrors(err); // for rendering validation errors
        }

        return {};
      },
      [t, validationFn, initialValues],
    );

    const close = modalContext?.close;

    const onSubmit: FormProps['onSubmit'] = useCallback(
      async (values, helpers) => {
        if (values?.name === 'adGroups') {
          removeOlddAdGroups(cloneDeep(values.keywords));
        }
        try {
          setPendingSubmit(true);

          const submit =
            customSubmitKey && typeof onSubmitFromProps === 'object' && customSubmitKey in onSubmitFromProps
              ? onSubmitFromProps[customSubmitKey]
              : onSubmitFromProps;

          if (typeof submit === 'function') {
            await submit(values, helpers);
          }
          if (onSuccess) {
            onSuccess();
          }

          if (closeModalOnSuccess && close) {
            close();
          }

          if (resetOnSuccessSubmit) {
            helpers.resetForm({ values });
          }

          return true;
        } catch (error) {
          console.log(error);

          // we reset form on 'not found' to prevent 'leave confirmation' before leave the page
          if (resetOnFail || isNotFoundRequestError(error as unknown as any)) {
            helpers.resetForm({});
          }

          return false;
        } finally {
          if (!isMounted()) {
            // eslint-disable-next-line no-unsafe-finally
            return undefined;
          }
          setPendingSubmit(false);
        }
      },
      [
        removeOlddAdGroups,
        customSubmitKey,
        onSubmitFromProps,
        onSuccess,
        closeModalOnSuccess,
        close,
        resetOnFail,
        resetOnSuccessSubmit,
        isMounted,
      ],
    );

    useEffect(() => {
      initializationRef.current = false;
    }, []);

    return (
      <Formik
        key={key}
        initialValues={initialValues}
        validate={validation}
        validateOnChange={false}
        validateOnBlur
        enableReinitialize
        onSubmit={onSubmit}
      >
        <InnerForm
          formRef={formRef}
          name={name}
          validation={validationFn}
          onChange={onChange}
          showServerErrors={showServerErrors}
          layout={layout}
          setCustomValidationKey={setCustomValidationKey}
          setCustomSubmitKey={setCustomSubmitKey}
          {...props}
        >
          <Preloader pending={pending ?? pendingSubmit}>
            {children}
            {footer ? (
              <FormFooter
                disableDuplicateGroupName={disableDuplicateGroupName}
                {...(isValidElement(footer) ? { children: footer } : typeof footer === 'object' ? footer : {})}
                {...footerProps}
              />
            ) : null}
          </Preloader>
        </InnerForm>
      </Formik>
    );
  },
);
