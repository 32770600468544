import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Option, OptionFromValue } from 'types';
import config from 'config';
import themeColors from 'utils/theme-colors';
import { Box } from 'components/common/box';
import { GridLayout } from 'components/common/grid-layout';
import { Link } from 'components/common/link';
import { withFormItem } from 'hocs/with-form-item';
import { useField, useForm } from 'hooks';
import { useCampaignFormContext } from 'components/campaigns/campaign-form/hooks';
import { AddAdditionalButton } from 'components/common/form/ad-text-picker/add-additional-button';
import { randomNumber } from 'utils/numbers';
import { Preloader } from 'components/common/preloader';
import { TextPickerContext } from './context';
import { TextPickerProps } from './types';
import left from './left.svg';
import right from './right.svg';
import shuffle from './shuffle.svg';
import './index.css';
import { GroupTextPickerRow } from '../ad-text-picker/group-text-picker-row';

const { googlePlatform } = config.platforms;

const defaultOptions = [] as NonNullable<TextPickerProps['options']>;

export const GroupTextPicker = withFormItem<TextPickerProps>()(
  ({
    options: optionsFromProps = defaultOptions,
    placeholder,
    labelText,
    tabName,
    activeGroup,
    selectedGroup,
    template,
    setOptionDescriptions,
    setOptionTitles,
    setUpdatedEntity,
    adGroups,
    ...props
  }) => {
    const { field, componentProps } = useField(props);
    const { name, value, onChange } = field;
    const [loading, setLoading] = useState(false);
    const [regenerateInputIndex, setRegenerateInputIndex] = useState(-1);
    const [regenerateInputTitleIndex, setRegenerateInputTitleIndex] = useState(-1);

    const form = useForm();
    const { setFieldTouched, setFieldValue, values } = useForm();
    const formRef = useRef(form);
    formRef.current = form;
    const diffValueField =
      tabName === 'titles' ? (value as OptionFromValue[])?.slice(0, 15) : (value as OptionFromValue[])?.slice(0, 4);
    const indexedValueItems = useMemo<(OptionFromValue & { id: string })[]>(
      () =>
        diffValueField.map((item: OptionFromValue) => ({
          id: uuidv4(),
          ...item,
        })),
      // eslint-disable-next-line  react-hooks/exhaustive-deps
      [value.length],
    );
    const updatedOptions = useMemo(
      () =>
        optionsFromProps.map((option) => {
          const valueItem = indexedValueItems?.find(({ value }) => option.value === value);
          return {
            ...option,
            label: valueItem && valueItem.label ? valueItem.label : option.label,
          };
        }),
      [indexedValueItems, optionsFromProps],
    );

    const [options, setOptions] = useState(updatedOptions);

    useEffect(() => {
      setOptions(updatedOptions);
    }, [updatedOptions]);

    const {
      campaignManager: { target, loadAdDescriptionRegenerateOptionsForGroup, loadAdTitleRegenerateOptionsForGroup },
    } = useCampaignFormContext();

    const selectedOption = useCallback(
      (index: number) => {
        if (value) {
          if (regenerateInputIndex >= 0) {
            form.setFieldValue(`descriptions[${regenerateInputIndex}].value`, options[0]?.value);
            form.setFieldValue(`descriptions[${regenerateInputIndex}].label`, options[0]?.label);
            setRegenerateInputIndex(-1);
          }

          if (Array.isArray(value)) {
            const res = options.find(({ value: optionLabel }) => optionLabel === value[index].value);

            return res;
          }

          return options.find(({ value: optionLabel }) => optionLabel === value.value);
        }
        return null;
      },
      [form, options, regenerateInputIndex, value],
    );

    const selectedTitleOption = useCallback(
      (index: number) => {
        if (value) {
          if (regenerateInputTitleIndex >= 0) {
            form.setFieldValue(`titles[${regenerateInputTitleIndex}].value`, options[0]?.value);
            form.setFieldValue(`titles[${regenerateInputTitleIndex}].label`, options[0]?.label);
            setRegenerateInputTitleIndex(-1);
          }

          if (Array.isArray(value)) {
            const res = options.find(({ value: optionLabel }) => optionLabel === value[index].value);

            return res;
          }

          return options.find(({ value: optionLabel }) => optionLabel === value.value);
        }
        return null;
      },
      [form, options, regenerateInputTitleIndex, value],
    );

    const visibleOptions = useCallback(
      (currentIndex: number) => {
        const otherInputValues = Array.isArray(value)
          ? value.filter((_, index) => index !== currentIndex).map((val) => val.value)
          : [];
        return options.filter(
          ({ isOld, value: optionLabel }) =>
            optionLabel === value[currentIndex].value || (!isOld && !otherInputValues.includes(optionLabel)),
        );
      },
      [options, value],
    );

    const selectedOptionIndex = useCallback(
      // @ts-ignore
      (index: number) => visibleOptions(index).indexOf(selectedOption(index) as Option),
      [visibleOptions, selectedOption],
    );

    const isSelectedOptionOld = useCallback((index) => selectedOption(index)?.isOld, [selectedOption]);

    const total = useCallback((index: number) => visibleOptions(index).length, [visibleOptions]);

    const selectOption = useCallback(
      (option: Nullable<Option>, index: number) => {
        if (option) {
          onChange({
            target: {
              name: `${name}[${index}]`,
              value: { value: option.value, label: option.label },
            },
          });
        }
      },
      [name, onChange],
    );

    const onPrevClick = useCallback(
      (index: number) => {
        const options = visibleOptions(index);
        const prevIndex = selectedOptionIndex(index) - 1;
        selectOption(options[prevIndex < 0 ? options.length - 1 : prevIndex], index);
      },
      [visibleOptions, selectOption, selectedOptionIndex],
    );

    const onNextClick = useCallback(
      (index: number) => {
        const options = visibleOptions(index);
        const nextIndex = selectedOptionIndex(index) + 1;
        selectOption(options[nextIndex >= options.length ? 0 : nextIndex], index);
      },
      [visibleOptions, selectOption, selectedOptionIndex],
    );

    const onShuffleClick = useCallback(
      (index: number) => {
        selectOption(
          visibleOptions(index)[
            randomNumber({
              min: 0,
              max: total(index),
              exclude: selectedOptionIndex(index),
            })
          ],
          index,
        );
      },
      [visibleOptions, selectOption, total, selectedOptionIndex],
    );

    const freeOptions = useCallback(
      (options: any) => {
        const filteredOptions = options.map((option: any) => ({
          value: option.value,
          label: option.label,
        }));
        const filteredValues = value.map((value: any) => ({
          label: value.label,
        }));

        return filteredOptions.filter((value: any) => filteredValues.every((item: any) => item.label !== value.label));
      },
      [value],
    );

    const onAdd = useCallback(() => {
      const freeOption = freeOptions(optionsFromProps);
      const emptyValue = freeOption.length !== 0 ? freeOption.splice(0, 1)[0] : { value: uuidv4(), label: '' };
      onChange({
        target: {
          name,
          value: [...value, emptyValue],
        },
      });
      form.validateForm();
    }, [name, onChange, value, freeOptions, optionsFromProps, form]);

    const onRemove = useCallback(
      (rowIndex: number) => {
        const nextRawValue = value && value.filter((_: any, index: number) => index !== rowIndex);

        onChange({ target: { name, value: nextRawValue } });
        setFieldTouched('titles', true);
        setFieldTouched('descriptions', true);
        form.validateForm();
      },
      [name, onChange, value, setFieldTouched, form],
    );

    const onRegenerateDescriptionsClick = useCallback(
      (index: number, label: string, use_case_group: string, asset_number: number) => {
        (async () => {
          try {
            setLoading(true);
            if (target) {
              const newDescription = await loadAdDescriptionRegenerateOptionsForGroup({
                id: target?.id,
                platform: googlePlatform,
                selectedGroup: activeGroup,
                // @ts-ignore
                use_case_group: optionsFromProps[index]?.use_case_group || template || 'custom_ad_group',
                // @ts-ignore
                asset_number: index,
                // @ts-ignore
                exclude_result: label,
              });
              setFieldValue(`keywords[${activeGroup}].descriptions[${index}]`, newDescription[0]);
              setOptions(updatedOptions);
              setRegenerateInputIndex(index);
              setUpdatedEntity(true);
            }
          } catch (error) {
            console.log(error);
          } finally {
            setUpdatedEntity(true);
            form.validateForm();
            setLoading(false);
          }
        })();
      },
      [
        setUpdatedEntity,
        loadAdDescriptionRegenerateOptionsForGroup,
        optionsFromProps,
        target,
        updatedOptions,
        activeGroup,
        setFieldValue,
        template,
        form,
      ],
    );

    const onRegenerateTitlesClick = useCallback(
      (index: number, label: string, use_case_group: string, asset_number: number) => {
        (async () => {
          try {
            setLoading(true);
            if (target) {
              const newTitle = await loadAdTitleRegenerateOptionsForGroup({
                id: target?.id,
                platform: googlePlatform,
                selectedGroup: activeGroup,
                // @ts-ignore
                use_case_group: optionsFromProps[index]?.use_case_group || template || 'custom_ad_group',
                // @ts-ignore
                asset_number: index,
                // @ts-ignore
                exclude_result: label,
              });
              setFieldValue(`keywords[${activeGroup}].titles[${index}]`, newTitle[0]);
              setUpdatedEntity(true);
              setOptions(updatedOptions);
              setRegenerateInputTitleIndex(index);
            }
          } catch (error) {
            console.log(error);
          } finally {
            setUpdatedEntity(true);
            form.validateForm();
            setLoading(false);
          }
        })();
      },
      [
        setUpdatedEntity,
        loadAdTitleRegenerateOptionsForGroup,
        optionsFromProps,
        target,
        updatedOptions,
        activeGroup,
        setFieldValue,
        template,
        form,
      ],
    );

    const isPrevDisabled = useCallback(
      (index) => optionsFromProps.length <= 2 || (total(0) <= 1 && total(2) <= 1),
      [total, optionsFromProps],
    );
    const isNextDisabled = useCallback(
      (index) => optionsFromProps.length <= 2 || (total(0) <= 1 && total(2) <= 1),
      [total, optionsFromProps],
    );
    const isShuffleDisabled = useCallback(
      (index) => optionsFromProps.length <= 2 || (total(0) <= 1 && total(2) <= 1),
      [total, optionsFromProps],
    );

    const controls = useCallback(
      (index: number) =>
        [
          {
            label: (
              <div
                style={{
                  width: '40px',
                  marginRight: '8px',
                  marginLeft: '24px',
                }}
                className='arrow-btn'
              >
                <img style={{ opacity: isPrevDisabled(index) ? '0.3' : '1' }} src={left} alt='' />
              </div>
            ),
            onClick: () => onPrevClick(index),
            disabled: isPrevDisabled(index),
          },
          {
            label: (
              <div style={{ width: '40px', marginRight: '24px' }} className='arrow-btn'>
                <img style={{ opacity: isNextDisabled(index) ? '0.3' : '1' }} src={right} alt='' />
              </div>
            ),
            onClick: () => onNextClick(index),
            disabled: isNextDisabled(index),
          },
          {
            label: (
              <div className='arrow-btn' style={{ width: '90px' }}>
                <img
                  src={shuffle}
                  alt=''
                  style={{
                    marginRight: '10px',
                    opacity: isShuffleDisabled(index) ? '0.3' : '1',
                  }}
                />{' '}
                Shuffle
              </div>
            ),
            onClick: () => onShuffleClick(index),
            disabled: isShuffleDisabled(index),
          },
        ].map(({ label, ...controlProps }, index) => (
          <Link key={index} variant='body-3' underline='none' {...controlProps}>
            {label}
          </Link>
        )),
      [onPrevClick, isPrevDisabled, onNextClick, isNextDisabled, onShuffleClick, isShuffleDisabled],
    );

    const borderColor = useCallback(
      (index: number) =>
        isSelectedOptionOld(index)
          ? themeColors.grey13 // themeColors.yellow4 if need back to warning color for changed entity
          : themeColors.grey13,
      [isSelectedOptionOld],
    );

    const contextValue = useMemo(
      () => ({
        tabName,
        placeholder,
        labelText,
      }),
      [labelText, placeholder, tabName],
    );
    const items = useMemo(
      () =>
        indexedValueItems.map(({ id }, index) => (
          <GroupTextPickerRow
            key={id}
            rootFieldName={name}
            rowIndex={index}
            activeGroup={activeGroup}
            selectedOptionIndex={selectedOptionIndex}
            tabName={tabName}
            total={total}
            controls={controls}
            isSelectedOptionOld={isSelectedOptionOld}
            borderColor={borderColor(index)}
            onRemove={onRemove}
            options={options}
            setOptions={setOptions}
            selectedOption={selectedOption}
            selectOption={selectOption}
            onRegenerateDescriptionsClick={onRegenerateDescriptionsClick}
            onRegenerateTitlesClick={onRegenerateTitlesClick}
          />
        )),
      [
        activeGroup,
        tabName,
        indexedValueItems,
        name,
        selectedOptionIndex,
        total,
        controls,
        isSelectedOptionOld,
        borderColor,
        onRemove,
        options,
        selectedOption,
        selectOption,
        onRegenerateDescriptionsClick,
        onRegenerateTitlesClick,
      ],
    );
    const columns = useMemo(() => (Array.isArray(items) && items.length > 1 ? 1 : 1), [items]);

    const hideAddAdditionalButton = tabName === 'titles' ? items.length === 15 : items.length === 4;
    return (
      <TextPickerContext.Provider value={contextValue}>
        <Box {...componentProps}>
          <Preloader pending={loading}>
            <GridLayout columns={columns} gridColumnGap={120} gridRowGap={30} marginBottom={30}>
              {items}
            </GridLayout>
            {hideAddAdditionalButton ? null : <AddAdditionalButton name={tabName} onAdd={onAdd} />}
          </Preloader>
        </Box>
      </TextPickerContext.Provider>
    );
  },
);
