import MockAdapter from 'axios-mock-adapter';
import { urls } from '../api-urls';

export function createVersionMock(mock: MockAdapter): void {
  const {
    version: { getVersion },
  } = urls;

  mock.onGet(getVersion.url).reply(200, {
    api_version: '322-dev',
  });
}
