import { env } from 'utils/env';
import bytes from 'bytes';
import locations from 'navigation/locations';

export const options = {
  pages: {
    defaultPage: locations.dashboard.toUrl(),
  },
  splashScreen: {
    minimumTimeShow: env.REACT_APP_SPLASH_SCREEN_MINIMUM_TIME_SHOW,
  },
  searchField: {
    debounceTimeout: 500,
  },
  textarea: {
    rows: 4,
    rowsMin: 1,
  },
  validations: {
    addressMinLength: 5,
    addressMaxLength: 200,
    zipMinLength: 5,
    zipMaxLength: 5,
    descriptionMaxLength: 5000,
    descriptionMinLength: 5,
    bioMaxLength: 2000,
    maxNameLength: 100,
    shortStringLength: 50,
    middleStringLength: 80,
    longStringLength: 100,
    priceMaximum: 10 ** 9 - 0.01,
    priceMinimumFb: 10,
    priceMinimumAds: 2,
    priceMinimumAdWords: 10,
    maxKeywordLength: 80,
    maxTitleLength: 30,
    maxDescriptionLength: 90,
    maxDescriptionFbLength: 350,
    maxLengthNeighborhood: 40,
    maxGroupLength: 255,
  },
  imageUploader: {
    accept: ['.jpg', '.jpeg', '.png'].join(', '),
    amountLimit: 20,
    fileSizeLimit: bytes('10MB'),
  },
  logoUploader: {
    accept: ['.jpeg', '.jpg', '.png', '.gif'].join(', '),
    fileSizeLimit: bytes('10MB'),
  },
  table: {
    defaultPageSize: 10,
    pageSizeFbAdAccounts: 5,
    pageSizeGoogleAdAccounts: 5,
  },
  suggestions: {
    adTitlesLimit: 10,
    adDescriptionsLimit: 21,
  },
  imagesPicker: {
    minSelectedItems: 1,
  },
  messages: {
    duration: 4,
  },
  itemsGallery: {
    autoplaySpeed: 10000,
  },
  auth: {
    initialRedirectAfterAccountActivationDelay: 3000,
  },
  campaignSocialNetworksAvailability: {
    building: {
      facebook: true,
      instagram: true,
      googleAds: true,
      googleAdwords: true,
    },
    listing: {
      facebook: true,
      instagram: true,
      googleAds: false,
      googleAdwords: false,
    },
    openHouse: {
      facebook: true,
      instagram: true,
      googleAds: true,
      googleAdwords: true,
    },
    branding: {
      facebook: true,
      instagram: true,
      googleAds: true,
      googleAdwords: true,
    },
  },
  enabledCampaigns: {
    building: true,
    listing: true,
    openHouse: false,
    branding: false,
  },
  campaignImages: {
    facebookAndInstagram: {
      max: 10,
    },
    googleAds: {
      max: 15,
    },
  },
};
