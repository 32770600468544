import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import pick from 'lodash.pick';
import Option from 'stores/models/option';
import { nullable } from 'stores/mst-types';

const OptionDetailed = types.model('OptionDetailed', {
  ...pick(Option.properties, ['label', 'value']),
  picture: nullable(types.string),
});

export type OptionDetailedSnapshot = SnapshotOut<typeof OptionDetailed>;
export type OptionDetailedInstanceType = Instance<typeof OptionDetailed>;

export default OptionDetailed;
