import { useCallback, useEffect, useMemo } from 'react';
import get from 'lodash.get';
import { useField as useFormikField, FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { useForm } from 'hooks/use-form';
import { useFormContext } from 'hooks/use-form-context';
import { FORM_ARRAY_FIELD_INDEX_NAME_SUBSTITUTION } from 'components/common/form/helper';
import { FormField } from 'types';

const formatError = (error: any): any =>
  Array.isArray(error)
    ? error.map((errorItem) => (typeof errorItem === 'object' ? Object.values(errorItem) : errorItem)).flat()[0]
    : error;

export const useField = <V, P = any>(
  props: FormField<V> & P,
): {
  field: FieldInputProps<any> & { id: string };
  meta: FieldMetaProps<any>;
  helper: FieldHelperProps<any>;
  error: boolean | any;
  isRequired: boolean;
  isTouched: boolean;
  componentProps: Omit<FormField<V> & P, 'resetValueOnDestroy' | 'value' | 'onChange' | 'onBlur'>;
} => {
  const { name, value: valueFromProps, onChange: onChangeFromProps, onBlur: onBlurFromProps } = props;

  const { value, onChange, onBlur, resetValueOnDestroy, composeOnChange, ...componentProps } = props;

  const [field, meta, helper] = useFormikField(name);

  const { errors, touched, setFieldValue } = useForm();
  const { id, requiredFields, addFormField, removeFormField, formFields } = useFormContext();

  const isTouched = meta.touched || get(touched, name);

  const childrenFields = formFields.filter((field) => field.includes(name) && field.length > name.length);
  const hasChildrenErrors = childrenFields.some((childrenField) => get(errors, childrenField));
  const rawError = isTouched && !hasChildrenErrors && (meta.error || get(errors, name));
  const error = formatError(rawError);

  // to remove value of conditionally removed field (for example wrapped wit <If> component)
  useEffect(
    () => () => {
      if (resetValueOnDestroy) {
        setFieldValue(name, undefined);
      }
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [resetValueOnDestroy],
  );

  // fixes this issue https://github.com/formium/formik/issues/445
  useEffect(() => {
    addFormField(name);

    return () => {
      removeFormField(name);
    };
  }, [addFormField, name, removeFormField]);

  const fieldNamePattern = useMemo(() => name.replace(/\[\d+\]/gi, FORM_ARRAY_FIELD_INDEX_NAME_SUBSTITUTION), [name]);

  const isRequired = requiredFields.includes(fieldNamePattern);

  const onChangeHandler = useCallback(
    (event: any) => {
      if (composeOnChange || !onChangeFromProps) {
        field.onChange(event);
      }
      if (onChangeFromProps) {
        onChangeFromProps(event);
      }
    },
    [composeOnChange, field, onChangeFromProps],
  );

  return {
    field: {
      ...field,
      id: `${id}-${name}`,
      value: valueFromProps !== undefined ? valueFromProps : field.value,
      onChange: onChangeHandler,
      onBlur: onBlurFromProps ?? field.onBlur,
    },
    error,
    meta,
    helper,
    isTouched,
    isRequired,
    componentProps: componentProps as any,
  };
};
