const mask = '+1 (999) 999-9999';

const maskSubstitutions = ['9'];
const isSubstitutionMaskChar = (char: string): boolean => maskSubstitutions.includes(char);
const isValueSubstitutionChar = (char: string): boolean => /[0-9+]/.test(char);

const resolveValue = ({ value }: { value: Nullable<string> }): Nullable<string> => {
  if (!value) {
    return value;
  }
  const valueChars = value.split('');

  return valueChars.reduce(
    (result, valueChar) => (isValueSubstitutionChar(valueChar) ? `${result}${valueChar}` : result),
    '',
  );
};

const hasTypedValue = (input: Nullable<string>): boolean => {
  if (!input) {
    return false;
  }
  const inputChars = input.split('');

  return inputChars.some((inputChar, index) => isSubstitutionMaskChar(mask[index]));
};

const formatValue = (value: Nullable<string>): Nullable<string> => {
  if (!value) {
    return value;
  }

  const maskChars = mask.split('');

  let valuePosition = 0;

  return maskChars.reduce((result, maskChar) => {
    if (isSubstitutionMaskChar(maskChar) || isValueSubstitutionChar(maskChar)) {
      const valueChar = value[valuePosition];

      valuePosition += 1;

      return `${result}${valueChar ?? ' '}`;
    }

    return `${result}${maskChar}`;
  }, '');
};

export { resolveValue, formatValue, hasTypedValue, mask };
