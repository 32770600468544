import React, { memo, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'hooks';
import { Box } from 'components/common/box';
import { Typography } from 'components/common/typography';
import { CampaignABTestingResultsProps } from './types';
import styles from './styles.module.scss';

export const CampaignABTestingResults = memo<CampaignABTestingResultsProps>(
  ({ className: classNameFromProps, ...props }) => {
    const { t } = useTranslation();

    const className = classnames(styles.root, classNameFromProps);

    const items = useMemo(
      () =>
        [
          {
            title: t('CampaignForm.CampaignABTesting.Preview.Table.Columns.MagicKPI'),
            data: `${81}%`,
          },
          {
            title: t('CampaignForm.CampaignABTesting.Preview.Table.Columns.Impressions'),
            data: 135,
          },
          {
            title: t('CampaignForm.CampaignABTesting.Preview.Table.Columns.Clicks'),
            data: 137,
          },
        ].map(({ title, data }) => (
          <Box display='flex' justifyContent='space-between' className={className}>
            <Typography variant='body-13' color='grey5'>
              {title}
            </Typography>
            <Typography variant='body-13' color='grey5'>
              {data}
            </Typography>
          </Box>
        )),
      [className, t],
    );

    return (
      <Box display='flex' flexDirection='column' width='100%' {...props}>
        {items}
      </Box>
    );
  },
);
