import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import config from 'config';

const ignoredErrors = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
];

const isIgnoredError = (sentryEvent: Sentry.Event): boolean =>
  Boolean(sentryEvent.exception?.values?.some(({ value }) => ignoredErrors.includes(value as string)));

export function initSentry(): void {
  Sentry.init({
    dsn: config.sentry.dsn,
    release: config.sentry.release,
    environment: config.sentry.environment,
    tracesSampleRate: config.sentry.tracesSampleRate,
    integrations: [new Integrations.BrowserTracing()],
    beforeSend(event: Sentry.Event) {
      if (isIgnoredError(event)) {
        return null;
      }

      return event;
    },
  });
}

export function setSentryUser(user: Record<string, any>): void {
  Sentry.setUser(user);
}

export function recordError(error: Error): void {
  Sentry.setExtra('error', { ...error });
  Sentry.captureException(error);
}
