import React, { ReactNode, Component, ErrorInfo } from 'react';
import ErrorView from './error-view';

interface IntermediateErrorBoundaryProps {
  pathname: string;
}

interface IntermediateErrorBoundaryState {
  hasError: Error | null;
}

export default class IntermediateErrorBoundary extends Component<
  IntermediateErrorBoundaryProps,
  IntermediateErrorBoundaryState
> {
  constructor(props: IntermediateErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    if (error.name === 'TypeError') {
      this.setState({ hasError: error });
    }
    if (error.name === 'ChunkLoadError') {
      document.location.reload();
      return;
    }

    throw error;
  }

  render(): ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorView error={hasError} />;
    }
    return children;
  }
}
