import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { Box } from 'components/common/box';
import { Image as ImageAntd } from 'components/common/image';
import { Icon } from 'components/common/icon';
import { ImageEditModal } from 'components/common/image-edit';
import { Typography } from 'components/common/typography';
import themeColors from 'utils/theme-colors';
import { ImageUploaderItemProps } from './types';

const imageStyle = {
  position: 'relative',
  objectFit: 'cover' as const,
};

const maskProps = {
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  borderRadius: 2,
  border: `1px solid ${themeColors.blackTransparent}`,
};

export const ImageUploaderItem = memo<ImageUploaderItemProps>(
  ({
    setImagesLength,
    src,
    index,
    originalValue,
    onRemove: onRemoveFromProps,
    onEdit,
    hasRemoveButton,
    hasEditButton,
    imagePreviewStyle: imagePreviewStyleFromProps,
    name,
    ...props
  }) => {
    const imagePreviewStyle = useMemo(
      () => ({
        imageStyle,
        ...imagePreviewStyleFromProps,
      }),
      [imagePreviewStyleFromProps],
    );

    const [open, toggleOpen] = useToggle(false);
    const [aspect, setAspect] = useState('');
    const [width, setWidth] = useState(0);

    const getCaption = useCallback((src: string): void => {
      const image = new Image();
      image.src = src;

      image.onload = () => {
        const { height, width } = image;

        const ratioWidth = ((width / height) * 100) / 100;
        const ratioWidthResult = Number.isInteger(ratioWidth) ? ratioWidth : ratioWidth.toFixed(2);
        const ratioHeight = Math.ceil(((height / width) * 100) / 100);

        setAspect(`${ratioWidthResult}:${ratioHeight}`);
      };
    }, []);

    useEffect(() => {
      getCaption(src);
    }, [getCaption, src]);

    useEffect(() => {
      setWidth(aspect === '1:1' ? 89 : 133);
    }, [aspect]);

    const onRemove = useCallback(() => {
      onRemoveFromProps(originalValue);
    }, [onRemoveFromProps, originalValue]);

    useEffect(() => {
      const item = Array.from(document.querySelectorAll('#image-item'));
      const width = item.map((e: any) => e.offsetWidth);
      const sum = width.reduce((partialSum, a) => partialSum + a, 0);
      setImagesLength(sum + width.length * 30);
    }, [aspect, setImagesLength, onRemove, originalValue]);

    return (
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box position='relative' {...props}>
          <Box {...maskProps} />
          {hasRemoveButton ? (
            <Box
              boxSizing='border-box'
              display='flex'
              borderRadius='50%'
              position='absolute'
              top={0}
              left='100%'
              transform='translate(-50%, -50%)'
              border={`1px solid ${themeColors.white1}`}
              cursor='pointer'
              onClick={onRemove}
            >
              <Icon cursor='pointer' name='close' size={22} circle color='primaryColor' />
            </Box>
          ) : null}
          {hasEditButton ? (
            <Box
              display='flex'
              borderRadius='50%'
              position='absolute'
              top={0}
              left='0'
              transform='translate(-50%, -50%)'
              border={`1px solid ${themeColors.white1}`}
              cursor='pointer'
            >
              <Icon cursor='pointer' name='edit' size={22} circle color='primaryColor' onClick={toggleOpen} />
            </Box>
          ) : null}
          <ImageAntd
            id='image-item'
            src={src}
            width={width}
            height={89}
            borderRadius={5}
            overflow='hidden'
            alt='uploaded image'
            style={imagePreviewStyle}
          />

          <ImageEditModal
            visible={open}
            index={index}
            onCancel={toggleOpen}
            onEdit={onEdit}
            image={src}
            isUpdate
            onRemove={onRemove}
            originalValue={originalValue}
            name={name}
          />
        </Box>
        <Typography variant='body-7' color='grey5'>
          {aspect}
        </Typography>
      </Box>
    );
  },
);
