import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'components/common/box';
import { Typography } from 'components/common/typography';
import { Button } from 'components/common/button';
import { usePreloader, useStores, useTranslation, useLocation } from 'hooks';
import themeColors from 'utils/theme-colors';
import decoration1 from 'assets/images/404-page/decoration-2.png';
import decoration2 from 'assets/images/404-page/big_bagel.png';
import decoration3 from 'assets/images/404-page/big_sphere.png';
import decoration4 from 'assets/images/404-page/decoration-1.png';
import decoration5 from 'assets/images/404-page/little_bagel.png';
import decoration6 from 'assets/images/404-page/little_sphere.png';

export const PageError = observer(() => {
  const { t } = useTranslation();
  const { notFoundPage } = useStores();

  const { destroy } = notFoundPage;

  const { pathname } = useLocation();

  usePreloader({ pending: false });

  useEffect(() => destroy, [destroy, pathname]);

  const decorations = useMemo(
    () =>
      [
        {
          src: decoration1,
          props: {
            left: 0,
            bottom: 0,
            marginBottom: 30,
            marginLeft: -160,
          },
        },
        {
          src: decoration2,
          props: {
            top: 0,
            right: '100%',
            marginTop: 120,
            marginRight: 183,
          },
        },
        {
          src: decoration3,
          props: {
            top: 0,
            right: 0,
            marginRight: 130,
            marginTop: 281,
          },
        },
        {
          src: decoration4,
          props: {
            top: 0,
            left: '100%',
            marginLeft: 120,
            marginTop: -150,
          },
        },
        {
          src: decoration5,
          props: {
            left: '100%',
            bottom: 0,
            marginLeft: 102,
            marginBottom: -14,
          },
        },
        {
          src: decoration6,
          props: {
            top: '100%',
            left: 0,
            marginLeft: 172,
            marginTop: 135,
          },
        },
      ].map(({ src, props }, index) => (
        <Box key={index} position='absolute' {...props}>
          <img src={src} alt='decoration' />
        </Box>
      )),
    [],
  );

  const refresh = () => window.location.reload();

  return (
    <Box height='100vh' position='relative' background={themeColors.grey12} overflow='hidden' top='20%'>
      <Box position='absolute' left='50%' top='50%' transform='translate(-50%, -50%)'>
        {decorations}
      </Box>
      <Box
        position='absolute'
        left='50%'
        bottom={300}
        transform='translateX(-50%)'
        display='flex'
        flexDirection='column'
        alignItems='center'
        textAlign='center'
      >
        <Typography variant='body-20' marginBottom={30}>
          {t('ErrorPage.SubTitle')}
        </Typography>
        <Typography variant='body-12' color='grey5' marginBottom={60}>
          {t('ErrorPage.Description')}
        </Typography>
        <Button type='primary' onClick={refresh}>
          {t('ErrorPage.RefreshButton')}
        </Button>
      </Box>
    </Box>
  );
});
