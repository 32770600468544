import React, { memo, useCallback } from 'react';
import { useForm } from 'hooks';
import { TextField } from 'components/common/form/text-field';
import { NeighborhoodFieldProps } from './types';

export const NeighborhoodField = memo<NeighborhoodFieldProps>(
  ({ cityFormField, disabled: disabledFromProps, ...props }) => {
    const { values } = useForm<Record<string, any>>();

    const selectedCity = cityFormField ? values[cityFormField] : undefined;

    const disabled = disabledFromProps ?? !selectedCity;

    const handleValueCheck = useCallback((event) => {
      if (!/^[a-zA-Z0-9_ ]*$/.test(event.key)) {
        event.preventDefault();
      }
    }, []);

    return <TextField onKeyPress={handleValueCheck} disabled={disabled} {...props} />;
  },
);
