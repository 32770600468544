import { flow, Instance, SnapshotOut, types } from 'mobx-state-tree';
import { SessionStore } from './session-store';
import { InvitedSignUpStore } from './auth/invited-sign-up-store';
import { FormErrorsStore } from './form-errors-store';
import { FormStatesStore } from './form-states-store';
import { NotFoundPageStore } from './not-found-page-store';
import { VersionStore } from './version-store';
import { ProfileStore } from './profile/profile-store';
import { CampaignsStore } from './campaigns/campaigns-store';
import { BuildingCampaignStore } from './campaigns/building-campaign-store';
import { ListingCampaignStore } from './campaigns/listing-campaign-store';
import { OpenHouseCampaignStore } from './campaigns/open-house-campaign-store';
import { BrandingCampaignStore } from './campaigns/branding-campaign-store';
import { CampaignReportsStore } from './campaigns/campaign-reports-store';
import { BuildingsStore } from './buildings/buildings-store';
import { BuildingStore } from './buildings/building-store';
import { DashboardStore } from './dashboard/dashboard-store';
import { ReportsStore } from './reports/reports-store';
import { CommonStore } from './common-store';
import { ListingsStore } from './listings/listings-store';
import { ListingStore } from './listings/listing-store';
import { CollectionsStore } from './collections/collections-store';
import { OrganizationSetupStore } from './organizations/organization-setup-store';
import { OrganizationDetailsStore } from './organizations/organization-details-store';
import { ABTestingStore } from './campaigns/ab-testing-store';
import { GoogleSearchStore } from './campaigns/google-search-store';

export const RootStore = types
  .model('RootStore', {
    common: types.optional(CommonStore, {}),
    session: types.optional(SessionStore, {}),
    notFoundPage: types.optional(NotFoundPageStore, {}),
    invitedSignUp: types.optional(InvitedSignUpStore, {}),
    profile: types.optional(ProfileStore, {}),
    organizationSetup: types.optional(OrganizationSetupStore, {}),
    organizationDetails: types.optional(OrganizationDetailsStore, {}),
    formErrors: types.optional(FormErrorsStore, {}),
    formStates: types.optional(FormStatesStore, {}),
    version: types.optional(VersionStore, {}),
    campaigns: types.optional(CampaignsStore, {}),
    buildingCampaign: types.optional(BuildingCampaignStore, {}),
    listingCampaign: types.optional(ListingCampaignStore, {}),
    openHouseCampaign: types.optional(OpenHouseCampaignStore, {}),
    brandingCampaign: types.optional(BrandingCampaignStore, {}),
    campaignReports: types.optional(CampaignReportsStore, {}),
    building: types.optional(BuildingStore, {}),
    buildings: types.optional(BuildingsStore, {}),
    dashboard: types.optional(DashboardStore, {}),
    reports: types.optional(ReportsStore, {}),
    listings: types.optional(ListingsStore, {}),
    listing: types.optional(ListingStore, {}),
    collections: types.optional(CollectionsStore, {}),
    abTestingCampaign: types.optional(ABTestingStore, {}),
    googleSearchAd: types.optional(GoogleSearchStore, {}),
  })
  .actions((self) => ({
    bootstrap: flow(function* bootstrap() {
      try {
        yield self.session.bootstrap();
      } catch (e) {
        console.log(e);
      }
    }),
  }));

export interface RootStoreInstance extends Instance<typeof RootStore> {}
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStore> {}
