import React, { useMemo, memo } from 'react';
import { Radio as AntdRadio } from 'antd';
import { Radio } from 'components/common/form/radio';
import { Typography } from 'components/common/typography';
import { GridLayout } from 'components/common/grid-layout';
import { Scale } from 'components/common/scale';
import { Box } from 'components/common/box';
import { RadioGroupProps } from './types';

const { Group } = AntdRadio;

export const RadioGroup = memo<RadioGroupProps>(({ variants, ...props }) => {
  const items = useMemo(
    () =>
      variants?.map(({ value, description, information, scaleProps }) => (
        <Box display='flex' flexDirection='column' marginBottom={47} key={value}>
          <GridLayout alignItems='center' gridGap={10} columns={3} gridTemplateColumns='1fr 2fr 4fr' marginBottom={10}>
            <Radio value={value} />
            <Typography variant='headline-3'>{description}</Typography>
            <Scale {...scaleProps} />
          </GridLayout>
          <Typography color='secondaryFontColor' variant='body-4'>
            {information}
          </Typography>
        </Box>
      )),
    [variants],
  );

  return <Group {...props}>{items}</Group>;
});
