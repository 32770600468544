import React, { memo, useMemo, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { GridLayout } from 'components/common/grid-layout';
import { ImageItem } from './image-item';
import { ImageListProps } from './types';

const defaultRenderItem: ImageListProps['renderItem'] = ({ imageNode }) => imageNode;

export const ImageList = memo<ImageListProps>(({ items: itemsFromProps, renderItem = defaultRenderItem, ...props }) => {
  const enhancedItemsFromProps = useMemo(
    () => itemsFromProps.map((item) => ({ ...item, id: uuidv4() })),
    [itemsFromProps],
  );

  const items = useMemo(
    () =>
      enhancedItemsFromProps.map(({ id, src, isOld }) => {
        const imageNode = <ImageItem src={src} isOld={isOld} />;

        return <Fragment key={id}>{renderItem({ imageNode, item: { src, isOld } })}</Fragment>;
      }),
    [enhancedItemsFromProps, renderItem],
  );

  return (
    <GridLayout columns={4} {...props}>
      {items}
    </GridLayout>
  );
});
