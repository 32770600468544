import React, { memo, useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';
import { Box } from 'components/common/box';
import { useField } from 'hooks';
import { KEYWORDS_FIELD } from './constants';
import { AdKeywordsPickerProps } from './types';
import { AdSettingsModal } from './ad-settings-modal';

const defaultOptions = [] as NonNullable<AdKeywordsPickerProps['options']>;

export const AdKeywordsPicker = memo<AdKeywordsPickerProps>(
  ({ options = defaultOptions, holderProps, children, ...props }) => {
    const { field } = useField(props);
    const { name, value, onChange, onBlur } = field;
    const [open, toggleOpen] = useToggle(false);
    const initialValues = useMemo(
      () => ({
        [KEYWORDS_FIELD]: value,
      }),
      [value],
    );

    const handleChange = useCallback(
      (value: { [KEYWORDS_FIELD]: AdKeywordsPickerProps['value'] }) => {
        const event = {
          target: { name, value: value[KEYWORDS_FIELD] },
        };
        onChange(event);
        onBlur(event);
      },
      [name, onChange, onBlur],
    );

    return (
      <div>
        <Box display='inline-flex' onClick={toggleOpen} {...holderProps}>
          {children}
        </Box>
        <AdSettingsModal
          width='85%'
          visible={open}
          onCancel={toggleOpen}
          initialValues={initialValues}
          options={options}
          onSubmit={handleChange}
        />
      </div>
    );
  },
);
