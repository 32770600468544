import React, { useMemo, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash.isequal';
import { observer } from 'mobx-react-lite';
import { useField, useForm } from 'hooks';
import { isOldKeywordValues } from 'utils/options';
import { Box } from 'components/common/box';
import { GridLayout } from 'components/common/grid-layout';
import { withFormItem } from 'hocs/with-form-item';
import { RowsFieldProps } from './types';
import { RowsFieldRow } from './rows-field-row';
import { RowsFieldAddButton } from './rows-field-add-button';
import { RowsFieldRemoveOldKeywordsButton } from './rows-field-remove-old-keywords-button';

const emptyRowValue = {};
const defaultValue = [emptyRowValue];

export const RowsField = withFormItem<RowsFieldProps>()(
  observer(({ renderRow, addButtonText, RemoveOldKeywordsText, options, addGrid, hideControl, ...props }) => {
    const { setErrors } = useForm();
    const { field } = useField(props);
    const { name, value = defaultValue, onChange } = field;

    const indexedValueItems = useMemo<{ id: string }[]>(
      () =>
        (value as any[]).map((item) => ({
          id: uuidv4(),
          ...item,
        })),
      // eslint-disable-next-line  react-hooks/exhaustive-deps
      [value.length],
    );

    const onAdd = useCallback(() => {
      onChange({
        target: { name, value: [...value, emptyRowValue] },
      });
    }, [name, onChange, value]);
    const onRemoveOldKeywords = useCallback(() => {
      const values = options?.map((option: any) => ({
        titles: option?.titles,
        descriptions: option?.descriptions,
        group: option.group?.filter((option: any) => !option.isOld),
        keywords: option.keywords,
      }));

      if (values) {
        onChange({
          target: { name, value: [...values] },
        });
      }
    }, [name, onChange, options]);

    const onRemove = useCallback(
      (rowIndex: number) => {
        const nextRawValue = value && value.filter((_: any, index: number) => index !== rowIndex);
        const nextValue = nextRawValue?.some((valueItem: any) => !isEqual(valueItem, emptyRowValue))
          ? nextRawValue
          : undefined;

        const event = { target: { name, value: nextValue } };
        onChange(event);
        setErrors({});
      },
      [name, onChange, setErrors, value],
    );

    const showRemoveButton = indexedValueItems.length > 1;

    const items = useMemo(
      () =>
        indexedValueItems.map(({ id }, index) => {
          const namePrefix = `${name}[${index}]`;

          return (
            <RowsFieldRow
              key={id}
              rowIndex={index}
              onRemove={onRemove}
              showRemoveButton={showRemoveButton && !hideControl}
            >
              {renderRow({
                namePrefix,
              })}
            </RowsFieldRow>
          );
        }),
      [indexedValueItems, name, onRemove, renderRow, showRemoveButton, hideControl],
    );

    const isButtonRemoved = isOldKeywordValues({
      values: value,
      options,
    });

    return (
      <Box display='flex' flexDirection='column' gridRowGap={addGrid ? 20 : 0}>
        {isButtonRemoved ? (
          <RowsFieldRemoveOldKeywordsButton
            style={{ position: 'absolute', top: '60px', zIndex: 1 }}
            onRemoveOldKeywords={onRemoveOldKeywords}
          >
            {RemoveOldKeywordsText}
          </RowsFieldRemoveOldKeywordsButton>
        ) : null}
        <GridLayout columns={1} gridRowGap={addGrid ? 20 : 0}>
          {items}
        </GridLayout>
        {!hideControl && <RowsFieldAddButton onAdd={onAdd}>{addButtonText}</RowsFieldAddButton>}
      </Box>
    );
  }),
);
