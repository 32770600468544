import React, { memo } from 'react';
import { NumberInput } from 'components/common/form/number-input';
import config from 'config';
import { PriceFieldProps } from './types';

const { validations } = config.options;

const numberFormatProps = {
  decimalScale: 0,
  isAllowed: ({ value }: { value: string }) => Number(value) <= validations.priceMaximum,
};

export const PriceField = memo<PriceFieldProps>((props) => (
  <NumberInput prefix='$' numberFormatProps={numberFormatProps} {...props} />
));
