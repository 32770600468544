import React, { memo } from 'react';
import { Link } from 'components/common/link/link';
import { Icon } from 'components/common/icon';
import { useTranslation } from 'hooks';
import { LinkButtonProps } from './types';

export const LinkButton = memo<LinkButtonProps>(({ iconProps, children, ...props }) => {
  const { t } = useTranslation();

  return (
    <Link underline='none' color='black3' {...props}>
      <Icon name='link' size={16} color='primaryColor' marginRight={6} {...iconProps} />
      {children ?? ''}
    </Link>
  );
});
