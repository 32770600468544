import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { GoogleSearchApi, GoogleSearchApiSerialized } from 'types';
import BaseModel from 'stores/models/base';
import { MSTAddStaticMethods } from 'stores/utils';
import { nullable } from 'stores/mst-types';
import AdGroupOption from 'stores/models/ads/ad-group';
import { excludeEmptyObjects } from 'utils/arrays';
import Option from '../option';
import campaignGeotarget from '../campaigns/campaign-geotarget';

const staticMethods = {
  fromResponseData: (data: GoogleSearchApi): GoogleSearchAdSnapshot => ({
    name: data.name,
    status: data.status,
    id: data.id,
    adGroups: data.ad_groups && data.ad_groups.map(AdGroupOption.fromResponseData),
  }),

  toRequestData: (data: GoogleSearchAdPostSnapshot): GoogleSearchApiSerialized => ({
    name: data.name,
    status: data.status,
    building: data.building,
    start_date: data.startDate,
    end_date: data.endDate,
    geo_target: data.geoTarget,
    ad_groups: data.adGroups && excludeEmptyObjects(data.adGroups)?.length ? data.adGroups : null,
  }),
};

const GoogleSearchAd = BaseModel.named('GoogleSearchAd').props({
  name: types.string,
  status: types.string,
  id: types.number,
  adGroups: nullable(types.array(AdGroupOption)),
});

const GoogleSearchAdRequest = BaseModel.named('GoogleSearchAdRequest').props({
  name: types.string,
  status: types.string,
  building: nullable(Option),
  startDate: types.string,
  endDate: types.string,
  geoTarget: nullable(campaignGeotarget),
  adGroups: nullable(types.array(AdGroupOption)),
});

export type GoogleSearchAdInstanceType = Instance<typeof GoogleSearchAd>;
export type GoogleSearchAdSnapshot = SnapshotOut<typeof GoogleSearchAd>;
export type GoogleSearchAdPostSnapshot = SnapshotOut<typeof GoogleSearchAdRequest>;

export default MSTAddStaticMethods(GoogleSearchAd, staticMethods);
