import React, { memo, useCallback } from 'react';
import { Box } from 'components/common/box';
import { Icon } from 'components/common/icon';
import { ImageList } from 'components/common/image-list';
import config from 'config';
import { ImageSaved } from 'types';
import { ImagesPickerPreviewProps, ImagesPickerSelectProps } from './types';

const defaultItems = [] as ImageSaved[];

export const ImagesPickerPreview = memo<ImagesPickerPreviewProps>(
  ({ value = defaultItems, onChange, min = config.options.imagesPicker.minSelectedItems, ...props }) => {
    const remove = useCallback(
      (option: NonNullable<ImagesPickerSelectProps['value']>[0]) => {
        const nextValue = value?.filter(({ src }) => src !== option.src) as ImageSaved[];

        onChange(nextValue);
      },
      [onChange, value],
    );

    const hasRemoveButton = value?.length > min;

    const renderItem = useCallback<NonNullable<ImagesPickerSelectProps['renderItem']>>(
      ({ imageNode, item }) => (
        <Box position='relative'>
          {imageNode}
          {hasRemoveButton ? (
            <Box position='absolute' display='flex' transform='translate(50%, -50%)' top={0} right={0}>
              <Icon name='close' circle size={28} color='primaryColor' cursor='pointer' onClick={() => remove(item)} />
            </Box>
          ) : null}
        </Box>
      ),
      [remove, hasRemoveButton],
    );

    return <ImageList renderItem={renderItem} items={value} {...props} />;
  },
);
