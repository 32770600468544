import { VariantContextValue } from 'contexts/variant-context';
import { useVariantContext } from 'hooks/use-variant-context';

export const usePropsFromContext = <K extends keyof VariantContextValue = keyof VariantContextValue>(
  propsField: K,
  enabled?: boolean,
): VariantContextValue[K] | undefined => {
  const variantContext = useVariantContext();

  return variantContext && enabled ? variantContext[propsField] : undefined;
};
