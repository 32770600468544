import { createContext } from 'react';
import { TextPickerProps } from 'components/common/form/text-picker';
import { ImagesPickerProps } from 'components/common/form/images-picker';
import { KeywordsGroupFormValue } from 'types';

export interface AdPreviewContextValue {
  textPickerFieldName?: string;
  imagesPickerFieldName?: string;
  titleOptions?: TextPickerProps['options'];
  descriptionOptions?: TextPickerProps['options'];
  imageOptions?: ImagesPickerProps['options'];
  approvedImageOptions?: ImagesPickerProps['options'];
  keywordsPickerFieldName?: string;
  keywordOptions?: KeywordsGroupFormValue[];
  setTabsErrors?: any;
}

export const AdPreviewContext = createContext<AdPreviewContextValue>({} as any);
