import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';

const LineItem = BaseModel.named('LineItem').props({
  label: types.string,
  value: types.number,
  category: types.string,
});

export type LineItemInstanceType = Instance<typeof LineItem>;
export type LineItemSnapshot = SnapshotOut<typeof LineItem>;

export default LineItem;
