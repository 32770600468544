import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm, useFormContext, useStores } from 'hooks';
import { RouteLeavingGuard } from 'components/common/route-leaving-guard';
import { hasDirtyValues } from 'utils/forms';
import { DirtyFormLeavingGuardProps } from './types';

const defaultSerializer = (value: any) => value;

export const DirtyFormLeavingGuard = observer<DirtyFormLeavingGuardProps>(
  ({ message, serializer = defaultSerializer }) => {
    const { values, initialValues = {} } = useForm();
    const { name } = useFormContext();
    const { formStates } = useStores();
    const isSubmitting = name && formStates.forms[name]?.isSubmitting;

    const isNavigationBlocked = useMemo(
      () =>
        !isSubmitting &&
        hasDirtyValues({
          initialValues: serializer(initialValues),
          values: serializer(values),
        }),
      [isSubmitting, serializer, initialValues, values],
    );
    return <RouteLeavingGuard when={isNavigationBlocked} message={message} />;
  },
);
