import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Typography } from 'components/common/typography';
import { Form } from 'components/common/form/form';
import { useModalProps, useTranslation } from 'hooks';
import { withModal } from 'hocs/with-modal';
import { ImagesPickerPreview } from './images-picker-preview';
import { ImagesPickerSelect } from './images-picker-select';
import { ImagesPickerModalProps } from './types';

const layoutProps = {
  columns: 5,
  gridColumnGap: 32,
  gridRowGap: 50,
};

const labelProps = {
  variant: 'body-3',
  marginBottom: 30,
  color: 'grey5',
};

const removeButtonCropFixSpace = 20;

export const ImagesPickerModal = withModal<ImagesPickerModalProps>()(
  ({ approvedImageOptions, value: initialValue, options, onSubmit: onSubmitFromProps, max, ...props }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<ImagesPickerModalProps['value']>(initialValue);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useModalProps(
      useMemo(
        () => ({
          title: t('ImagesPicker.Title'),
          titleProps: { textAlign: 'center' },
          width: 1010,
          contentProps: {
            paddingRight: removeButtonCropFixSpace,
            marginRight: -removeButtonCropFixSpace,
          },
        }),
        [t],
      ),
    );

    const footer = useMemo(
      () => ({
        cancelButtonAvailable: false,
        submitText: t('ImagesPicker.SaveButton'),
      }),
      [t],
    );

    const onSubmit = useCallback(() => {
      onSubmitFromProps(value);
    }, [onSubmitFromProps, value]);

    const valuePreview = useMemo(
      () => value?.map((valueItem) => options.find(({ src }) => valueItem.src === src) || valueItem),
      [options, value],
    );

    const visibleOptions = useMemo(() => options.filter(({ isOld }) => !isOld), [options]);

    return (
      <Form onSubmit={onSubmit} footer={footer} {...props}>
        <Typography {...labelProps}>{t('ImagesPicker.Labels.SelectedImages', { amount: max })}</Typography>
        <ImagesPickerPreview value={valuePreview} onChange={setValue} {...layoutProps} marginBottom={50} />
        {approvedImageOptions && Boolean(approvedImageOptions.length) && (
          <>
            <Typography {...labelProps}>{t('ImagesPicker.Labels.ApprovedImages')}</Typography>
            <ImagesPickerSelect
              options={approvedImageOptions}
              value={value}
              onChange={setValue}
              max={max}
              marginBottom={50}
              {...layoutProps}
            />
          </>
        )}
        <Typography {...labelProps}>{t('ImagesPicker.Labels.AllImages')}</Typography>
        <ImagesPickerSelect options={visibleOptions} value={value} onChange={setValue} max={max} {...layoutProps} />
      </Form>
    );
  },
);
