import React, { useCallback, memo, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import get from 'lodash.get';
import { useTranslation, useForm } from 'hooks';
import { Box } from 'components/common/box';
import { RowsField } from 'components/common/form/rows-field';
import { isNewTagSelectCustomOption, TagSelect, TagSelectProps } from 'components/common/form/tag-select';
import { isValidKeyword } from 'utils/validations';
import { KeywordFormValue, TagSelectOption } from 'types';
import { Tabs } from 'components/common/tabs';
import { KeywordsPickerProps } from './types';
import { GroupTextPicker } from '../text-picker/group-text-picker';

const isValidTag: TagSelectProps['isValidTag'] = ({ label }) => isValidKeyword(label);

export const KeywordsPicker = memo<KeywordsPickerProps>(
  observer(
    ({
      options,
      keywordsAffected,
      titlesAffected,
      descriptionsAffected,
      activeIndex,
      titleOptions,
      descriptionOptions,
      setGroups,
      selectItem,
      activeItem,
      groups,
      setOptionDescriptions,
      setOptionTitles,
      setUpdatedEntity,
      adGroups,
      ...props
    }) => {
      /* eslint-disable react-hooks/rules-of-hooks */
      const { t } = useTranslation();
      const { values } = useForm();

      useEffect(() => {
        const getValues = get(values, 'keywords');
        setGroups(getValues);
      }, [values, setGroups, activeIndex, groups, adGroups]);

      // each time we add a new row to KeywordsPicker - prev rows destroy and re-render
      // and we lost all new custom tags (because each row stores new tags locally)
      // that's why we move storing new keywords on top level
      const [customKeywordOptions, setCustomKeywordOptions] = useState<Record<string, KeywordFormValue[]>>({});

      const getKeywordOptions = useCallback(
        (groupFieldName: string): KeywordFormValue[] => {
          const groupValue = get(values, groupFieldName);

          if (!groupValue) {
            return [];
          }

          const group = options?.find(({ group }) => group.value === groupValue.value);

          const customOptions = (customKeywordOptions[groupValue.value] ?? []) as KeywordFormValue[];
          return [...(group?.keywords ?? []), ...customOptions];
        },
        [customKeywordOptions, values, options],
      );

      const renderRow = useCallback<NonNullable<KeywordsPickerProps['renderRow']>>(
        ({ namePrefix }) => {
          const groupFieldName = `${namePrefix}.group`;
          const keywordsFieldName = `${namePrefix}.keywords`;
          const keywordOptions = getKeywordOptions(groupFieldName);
          const selectedGroup = get(values, groupFieldName);

          const onKeywordsChange = ({ target: { value } }: any): void => {
            setCustomKeywordOptions({
              ...customKeywordOptions,
              [selectedGroup.value]: value?.filter(isNewTagSelectCustomOption),
            });
          };

          const labelText = 'googleAdWordsAd';

          return selectedGroup && selectedGroup.value === activeItem ? (
            <Box display='flex' style={{ marginLeft: '-35px' }} flexDirection='column' gridColumnGap={30}>
              <Tabs
                items={[
                  {
                    label: 'Keywords',
                    content: (
                      <>
                        <Box
                          flex={1}
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                          minWidth={0}
                        >
                          {keywordsAffected && (
                            <div style={{ color: 'red', marginBottom: '15px' }}>
                              Due to the Building update, the keywords were changed in the new Ad Group
                            </div>
                          )}
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}
                          >
                            <TagSelect
                              name={keywordsFieldName}
                              placeholder={t('KeywordsPicker.Labels.Keywords')}
                              options={keywordOptions as TagSelectOption[]}
                              disabled={!selectedGroup}
                              isValidTag={isValidTag}
                              composeOnChange
                              onChange={onKeywordsChange}
                            />
                          </div>
                        </Box>
                      </>
                    ),
                  },
                  {
                    label: 'Titles',
                    content: (
                      <Box
                        flex={1}
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                        minWidth={0}
                      >
                        {titlesAffected && (
                          <div style={{ color: 'red', marginBottom: '15px' }}>
                            Due to the Building update, the titles were changed in the new Ad Group
                          </div>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                          }}
                        >
                          <GroupTextPicker
                            labelText={labelText ? 'AdTextPicker.Labels.TitleOrdinal' : 'AdTextPicker.Labels.Title'}
                            name={`${namePrefix}.titles`}
                            setUpdatedEntity={setUpdatedEntity}
                            options={titleOptions}
                            template={groups[activeIndex]?.group?.template}
                            placeholder={
                              labelText ? 'AdTextPicker.Placeholder.TitleOrdinal' : 'AdTextPicker.Placeholder.Title'
                            }
                            tabName='titles'
                            activeGroup={activeIndex}
                            setOptionDescriptions={setOptionDescriptions}
                            setOptionTitles={setOptionTitles}
                            adGroups={adGroups}
                          />
                        </div>
                      </Box>
                    ),
                  },
                  {
                    label: 'Descriptions',
                    content: (
                      <Box
                        flex={1}
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                        minWidth={0}
                      >
                        {descriptionsAffected && (
                          <div style={{ color: 'red', marginBottom: '15px' }}>
                            Due to the Building update, the descriptions were changed in the new Ad Group
                          </div>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                          }}
                        >
                          <GroupTextPicker
                            selectedGroup={selectedGroup.label}
                            setUpdatedEntity={setUpdatedEntity}
                            labelText={labelText ? 'AdTextPicker.Labels.TitleOrdinal' : 'AdTextPicker.Labels.Title'}
                            name={`${namePrefix}.descriptions`}
                            options={descriptionOptions}
                            template={groups[activeIndex]?.group?.template}
                            placeholder={
                              labelText ? 'AdTextPicker.Placeholder.TitleOrdinal' : 'AdTextPicker.Placeholder.Title'
                            }
                            tabName='descriptions'
                            activeGroup={activeIndex}
                            setOptionDescriptions={setOptionDescriptions}
                            setOptionTitles={setOptionTitles}
                            adGroups={adGroups}
                            activeIndex={activeIndex}
                          />
                        </div>
                      </Box>
                    ),
                  },
                ]}
                tabPosition='top'
              />
            </Box>
          ) : null;
        },
        [
          setUpdatedEntity,
          adGroups,
          setOptionDescriptions,
          setOptionTitles,
          descriptionsAffected,
          keywordsAffected,
          titlesAffected,
          activeIndex,
          titleOptions,
          descriptionOptions,
          activeItem,
          customKeywordOptions,
          getKeywordOptions,
          t,
          values,
          groups,
        ],
      );

      return (
        <RowsField
          addButtonText={t('KeywordsPicker.AddButton')}
          RemoveOldKeywordsText={t('KeywordsPicker.RemoveOldKeywords')}
          renderRow={renderRow}
          options={options}
          hideControl
          addGrid={false}
          {...props}
        />
      );
    },
  ),
);
