import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation, useFilePreview } from 'hooks';
import { useToggle } from 'react-use';
import { GridLayout } from 'components/common/grid-layout';
import placeholderImage from 'assets/images/img-building.png';
import { Box } from 'components/common/box';
import { Typography } from 'components/common/typography';
import { withFormItem } from 'hocs/with-form-item';
import config from 'config';

import { ImageEditModal } from 'components/common/image-edit';
import { constants } from 'components/common/image-edit/constants';
import { Icon } from 'components/common/icon';
import { ImageUploaderDropArea } from './image-uploader-drop-area';
import { ImageUploaderItem } from './image-uploader-item';
import { ImageUploaderNotification } from './image-uploader-notification';
import { useDropAreaProps } from './hooks';
import { ImageUploaderProps, ImageUploaderValueItem } from './types';

const { options } = config;

const iconHolderProps = {
  position: 'absolute',
  marginTop: '40px',
  marginX: '-20px',
  zIndex: 10,
};

const controlProps = {
  size: 25,
  color: 'primaryColor',
  cursor: 'pointer',
};

export const ImageUploader = withFormItem<ImageUploaderProps>()(
  ({
    placeholder = 'Upload',
    placeholderProps,
    imagePreviewStyle,
    multiple = true,
    previewMode,
    hasEditButton = true,
    accept = options.imageUploader.accept,
    ...props
  }) => {
    const { t } = useTranslation();
    const { getFilePreview } = useFilePreview();

    const { value, componentProps, beforeUpload, onRemove, onEditImage, name } = useDropAreaProps({
      multiple,
      accept,
      ...props,
    });

    const [open, toggleOpen] = useToggle(false);
    const [imagesLength, setImagesLength] = useState(0);
    const [hasControls, setHasControls] = useState(false);

    const previewItems = useMemo<
      | {
          src: string;
          originalValue: ImageUploaderValueItem;
        }[]
      | null
    >(
      () =>
        value
          ? value
              .filter((item: any) => (item && 'isOld' in item ? !item.isOld : true))
              .map((valueItem: ImageUploaderValueItem) => {
                const common = { originalValue: valueItem };
                const imagePreview = valueItem instanceof File ? { src: getFilePreview(valueItem) } : valueItem;

                return {
                  ...(imagePreview || { src: '' }),
                  ...common,
                };
              })
          : null,
      [getFilePreview, value],
    );

    const clickLeft = () => {
      const container: any = document.querySelector('#flex-container');
      container.scrollTo({
        left: container.scrollLeft - 200,
        top: 0,
        behavior: 'smooth',
      });
    };

    const clickRight = () => {
      const container: any = document.querySelector('#flex-container');
      container.scrollTo({
        left: container.scrollLeft + 200,
        top: 0,
        behavior: 'smooth',
      });
    };

    useEffect(() => {
      if (imagesLength > window.screen.width - 500) {
        setHasControls(true);
      } else {
        setHasControls(false);
      }
    }, [imagesLength, onRemove]);

    const controls = hasControls ? (
      <>
        <Box left={0} {...iconHolderProps}>
          <Icon {...controlProps} name='arrow_left' onClick={clickLeft} />
        </Box>
        <Box right={0} {...iconHolderProps}>
          <Icon {...controlProps} name='arrow_right' onClick={clickRight} />
        </Box>
      </>
    ) : null;

    return (
      <GridLayout columns={1} gridGap={20}>
        {previewItems
          ? previewItems.map(({ src, originalValue }, index) => (
              <ImageEditModal
                visible={open}
                index={index}
                onRemove={onRemove}
                onCancel={toggleOpen}
                onEdit={onEditImage}
                image={src}
                originalValue={originalValue}
                name={name}
              />
            ))
          : null}
        {previewMode ? null : (
          <ImageUploaderDropArea
            beforeUpload={beforeUpload}
            placeholderProps={placeholderProps}
            multiple={multiple}
            onChange={toggleOpen}
            {...componentProps}
          />
        )}
        {name !== constants.logo ? <ImageUploaderNotification paddingLeft={15} /> : null}
        {previewItems ? (
          <div
            style={{
              padding: '10px',
              paddingBottom: '30px',
              overflowY: 'hidden',
              height: '90%',
              width: '100%',
            }}
          >
            <div>
              {controls}
              <Box
                id='flex-container'
                style={{
                  paddingTop: '10px',
                  paddingLeft: '10px',
                  paddingRigth: '10px',
                  overflowX: 'auto',
                  flexWrap: 'no-wrap',
                }}
                display='flex'
                gridColumnGap={30}
              >
                {previewItems.map(({ src, originalValue }, index) => (
                  <ImageUploaderItem
                    setImagesLength={setImagesLength}
                    key={index}
                    index={index}
                    src={src}
                    originalValue={originalValue}
                    onRemove={onRemove}
                    onEdit={onEditImage}
                    hasRemoveButton={!previewMode && multiple}
                    hasEditButton={hasEditButton}
                    imagePreviewStyle={imagePreviewStyle}
                    name={name}
                  />
                ))}
              </Box>
            </div>
          </div>
        ) : null}
        {previewMode && !previewItems?.length ? (
          <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <Box marginBottom={60}>
              <img src={placeholderImage} height={214} alt='placeholder' />
            </Box>
            <Typography variant='headline-3' color='grey5'>
              {t('ImageUploader.NoPhoto')}
            </Typography>
          </Box>
        ) : null}
      </GridLayout>
    );
  },
);
