import React, { memo } from 'react';
import config from 'config';
import { PriceFieldProps } from './types';
import { NumberInputBudget } from '../number-input/number-input-budget';

const { validations } = config.options;

const numberFormatProps = {
  decimalScale: 2,
  isAllowed: ({ value }: { value: string }) => Number(value) <= validations.priceMaximum,
};

export const PriceFieldBudget = memo<PriceFieldProps>((props) => (
  <NumberInputBudget prefix='$' numberFormatProps={numberFormatProps} {...props} />
));
