import { CampaignApi } from 'types';
import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import { GetAdGroupResponse } from '../response-types';

export const createAdGroupsApi = (http: BaseHttpClient) => ({
  postAdGroups({ id, data }: Pick<CampaignApi, 'id'> & { data: any }) {
    return http.put(urls.googleAdwordsPlatform.putAdGroups.url.replace('{id}', id), data);
  },

  getAdGroups({ id }: Pick<CampaignApi, 'id'>) {
    return http.get<GetAdGroupResponse>(urls.googleAdwordsPlatform.getAdGroups.url.replace('{id}', id));
  },

  getExistingAdGroups({ id }: Pick<CampaignApi, 'id'>) {
    return http.get<GetAdGroupResponse>(urls.googleAdwordsPlatform.getExistingAdGroups.url.replace('{id}', id));
  },

  getExistingAdGroupsBuildingUpdated({ id }: Pick<CampaignApi, 'id'>) {
    return http.get<GetAdGroupResponse>(
      urls.googleAdwordsPlatform.getExistingAdGroupsBuildingUpdated.url.replace('{id}', id),
    );
  },
  deleteExistingAdGroupsCampaign({ id }: Pick<CampaignApi, 'id'>) {
    return http.delete<GetAdGroupResponse>(urls.googleAdwordsPlatform.deleteAdGroups.url.replace('{id}', id));
  },
  pauseExistingAdGroupsCampaign({ id }: Pick<CampaignApi, 'id'>) {
    return http.patch<GetAdGroupResponse>(urls.googleAdwordsPlatform.pauseAdGroups.url.replace('{id}', id));
  },
  unpauseExistingAdGroupsCampaign({ id }: Pick<CampaignApi, 'id'>) {
    return http.patch<GetAdGroupResponse>(urls.googleAdwordsPlatform.unpauseAdGroups.url.replace('{id}', id));
  },
  backSyncGoogleSearch({ id }: Pick<CampaignApi, 'id'>) {
    return http.patch<GetAdGroupResponse>(urls.backSyncGoogleSearch.triggerBackSync.url.replace('{id}', id));
  },
});
