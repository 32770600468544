import { env } from 'utils/env';

export const api = {
  url: env.REACT_APP_API_URL,
  timeout: env.REACT_APP_API_TIMEOUT,
  logEnabled: env.REACT_APP_API_LOG_ENABLED,
  useMock: env.REACT_APP_API_USE_MOCK,
  mockResponseDelay: env.REACT_APP_API_MOCK_RESPONSE_DELAY,
  cacheMaxAge: env.REACT_APP_API_CACHE_MAX_AGE,
  sorting: {
    createdAt: 'created_at',
    updatedCampaign: 'entity_updated',
    isEntityLocationChanged: 'is_entity_location_changed',
  },
  constants: {
    userRoles: {
      noOrganization: {
        id: 'NO_ORGANIZATION',
      },
    },
    // ids could be changed only jointly with BE
    brandingCampaignTargetKinds: {
      agent: {
        id: '1',
        name: 'Agent',
      },
      brokerage: {
        id: '2',
        name: 'Brokerage ',
      },
      company: {
        id: '3',
        name: 'Company branding campaign',
      },
    },
    organizations: {
      // we should always use these labels or we should change them together with BE
      kindOptionLabels: {
        agent: 'Agent',
        brokerage: 'Brokerage',
        multifamily: 'Multi-family',
      },
      // we should always use these values or we should change them together with BE
      kindNames: {
        agent: 'Agent',
        brokerage: 'Brokerage',
        multifamily: 'Multi-family',
      },
      // we should always use these values or we should change them together with BE
      inviteStatuses: {
        sent: { id: 'Sent' },
        accepted: { id: 'Accepted' },
        declined: { id: 'Rejected' },
      },
      roles: {
        superuser: {
          name: 'SUPERUSER',
        },
        owner: {
          name: 'OWNER',
        },
        superAdmin: {
          name: 'SUPERADMIN',
        },
      },
    },
    campaigns: {
      types: {
        building: {
          id: '2',
          name: 'Building',
        },
        listing: {
          id: '3',
          name: 'Listing',
        },
        openHouse: {
          id: '4',
          name: 'Open House',
        },
        branding: {
          id: '1',
          name: 'Branding',
        },
      },
      statuses: {
        published: {
          id: 'status-1',
          name: 'Published',
        },
        finished: {
          id: 'status-2',
          name: 'Finish',
        },
        created: {
          id: 'status-3',
          name: 'Created',
        },
        paused: {
          id: '5',
          name: 'Paused',
        },
        live: {
          id: '8',
          name: 'Live',
        },
        draft: {
          id: '9',
          name: 'Draft',
        },
        closed: {
          id: '4',
          name: 'Closed',
        },
      },
    },
    listings: {
      types: {
        rent: {
          id: '2',
        },
        sale: {
          id: '1',
        },
      },
    },
    platforms: {
      facebook: {
        id: 'facebook-id',
      },
      instagram: {
        id: 'instagram-id',
      },
      googleAdWords: {
        id: 'google-ad-words-id',
      },
      googleAds: {
        id: 'google-ads-id',
      },
    },
    campaignAnalyticReports: {
      categories: {
        spend: {
          name: 'Spend',
        },
      },
    },
  },
};
