import React, { memo } from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import classnames from 'classnames';
import themeColors from 'utils/theme-colors';
import { TooltipProps } from './types';
import styles from './styles.module.scss';

export const Tooltip = memo<TooltipProps>(({ className: classNameFromProps = '', ...props }) => {
  const className = classnames(styles.root, classNameFromProps);

  return <AntdTooltip overlayClassName={className} color={themeColors.white1} placement='bottom' {...props} />;
});
