import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { CampaignTargetApi } from 'types';
import BaseModel from 'stores/models/base';
import ImageWithOldMarker from 'stores/models/image-with-old-marker';
import { MSTAddStaticMethods } from 'stores/utils';
import { nullable } from 'stores/mst-types';
import Image from 'stores/models/image';
import { isEmpty } from 'utils/is';
import { deserializeCampaignApprovedAssetImages } from '../../../utils/deserializers';

const staticMethods = {
  fromResponseData: (data: CampaignTargetApi & { logo?: Nullable<string> }): CampaignTargetSnapshot => ({
    ...pick(data, ['name', 'url']),
    id: String(data.id),
    // @ts-ignore
    logo: isEmpty(data.logo) ? null : { src: data.logo },
    images: data.images?.map(ImageWithOldMarker.fromResponseData) ?? undefined,
    approvedImages: deserializeCampaignApprovedAssetImages(data.approved_creative_asset_images),
    hasAffectedCampaigns: Boolean(data.has_affected_campaigns?.length),
    location: data.location,
    initializing_status: data.initializing_status,
  }),
};

const CampaignTarget = BaseModel.named('CampaignTarget').props({
  id: types.string,
  name: types.string,
  url: nullable(types.string),
  logo: nullable(Image),
  images: types.maybe(types.array(ImageWithOldMarker)),
  approvedImages: types.model({
    facebook: types.array(ImageWithOldMarker),
    googleDisplay: types.array(ImageWithOldMarker),
  }),
  hasAffectedCampaigns: nullable(types.boolean),
  location: types.maybe(types.string),
  initializing_status: nullable(types.string),
});

export type CampaignTargetInstanceType = Instance<typeof CampaignTarget>;
export type CampaignTargetSnapshot = SnapshotOut<typeof CampaignTarget>;

export default MSTAddStaticMethods(CampaignTarget, staticMethods);
