import { IModelType } from 'mobx-state-tree';

export function MSTAddStaticMethods<T extends IModelType<any, any>, TStatics extends Record<string, any>>(
  m: T,
  statics: TStatics,
): T & TStatics {
  Object.keys(statics).forEach((k) => {
    (m as any)[k] = (statics as any)[k];
  });
  return m as T & TStatics;
}
