import { observable, IObservableValue, IObservableMapInitialValues } from 'mobx';
import { StatusEnum } from '../enums';

export { StatusEnum };

// @ts-ignore
export const StatusEnumValues = Object.values(StatusEnum) as StatusEnum[];

export const withStatus = () => {
  const status: IObservableValue<string> = observable.box('idle');
  // @ts-ignore
  const statuses: IObservableMapInitialValues<string, StatusEnum> = observable.map();

  const setStatus = (value: StatusEnum, resourceName?: string) => {
    if (resourceName) {
      // @ts-ignore
      statuses.set(resourceName, value);
    } else {
      status.set(value);
    }
  };

  const getStatus = (resourceName?: string): StatusEnum => {
    if (resourceName) {
      // @ts-ignore
      return statuses.get(resourceName) as StatusEnum;
    }
    return status.get() as StatusEnum;
  };

  const getIsPendingLoadByResourceName = (resourceName?: string): boolean => {
    const status = getStatus(resourceName);

    return status === StatusEnum.PendingLoad;
  };

  const getIsPendingUpdateByResourceName = (resourceName?: string): boolean => {
    const status = getStatus(resourceName);

    return status === StatusEnum.PendingUpdate;
  };

  return {
    views: {
      get _status() {
        return status.get() as StatusEnum;
      },

      set _status(value: StatusEnum) {
        status.set(value);
      },

      get pending(): boolean {
        return [StatusEnum.Pending, StatusEnum.PendingLoad, StatusEnum.PendingUpdate].includes(this._status);
      },

      get pendingLoad(): boolean {
        return this._status === StatusEnum.PendingLoad;
      },

      get pendingUpdate(): boolean {
        return this._status === StatusEnum.PendingUpdate;
      },

      getStatus,

      getIsPendingLoadByResourceName,

      getIsPendingUpdateByResourceName,
    },
    actions: {
      setStatus,

      resetStatus(resourceName?: string) {
        setStatus(StatusEnum.Idle, resourceName);
      },
    },
  };
};
