import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { MSTAddStaticMethods } from 'stores/utils';
import { nullable } from 'stores/mst-types';
import Image from 'stores/models/image';
import { ImageWithOldMarkerFormValueApi } from 'types';

const staticMethods = {
  fromResponseData: (data: ImageWithOldMarkerFormValueApi): ImageWithOldMarkerSnapshot => ({
    ...pick(data, 'src'),
    isOld: data.is_old,
  }),
};

const ImageWithOldMarker = types.model('ImageWithOldMarker', {
  ...Image.properties,
  isOld: nullable(types.boolean),
});

export type ImageWithOldMarkerSnapshot = SnapshotOut<typeof ImageWithOldMarker>;
export type ImageWithOldMarkerInstanceType = Instance<typeof ImageWithOldMarker>;

export default MSTAddStaticMethods(ImageWithOldMarker, staticMethods);
