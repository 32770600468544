import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { OrganizationUserOverviewApi } from 'types';
import BaseModel from 'stores/models/base';
import { MSTAddStaticMethods } from 'stores/utils';
import { nullable } from 'stores/mst-types';

const staticMethods = {
  fromResponseData: (data: OrganizationUserOverviewApi): OrganizationUserOverviewSnapshot => ({
    ...pick(data, ['email']),
    id: String(data.id),
    firstName: data.first_name,
    lastName: data.last_name,
    inviteStatus: data.invite_status,
  }),
};

const OrganizationUserOverview = BaseModel.named('OrganizationUserOverview').props({
  id: types.string,
  firstName: types.string,
  lastName: types.string,
  email: types.string,
  inviteStatus: nullable(types.string),
});

export type OrganizationUserOverviewInstanceType = Instance<typeof OrganizationUserOverview>;
export type OrganizationUserOverviewSnapshot = SnapshotOut<typeof OrganizationUserOverview>;

export default MSTAddStaticMethods(OrganizationUserOverview, staticMethods);
