import React, { ComponentType, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, forwardRef } from 'react';
import themeColors from 'utils/theme-colors';

interface ComponentProps {
  color?: string;
}

export const withThemeColor =
  <P extends ComponentProps>() =>
  (WrappedComponent: ComponentType<P>): ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<any>> =>
    forwardRef<any, P>(({ color, ...props }, ref) => (
      <WrappedComponent ref={ref} color={(color && themeColors[color]) || color} {...(props as P)} />
    ));
