import React, { memo, useMemo } from 'react';
import { Radio as AntdRadio } from 'antd';
import classnames from 'classnames';
import { Typography } from 'components/common/typography';
import { CampaignABTestingResults } from 'components/campaigns/campaign-ab-testing-form/campaign-ab-testing-results';
import { Box } from 'components/common/box';
import { Radio } from 'components/common/form/radio';
import { Layout } from './layout';
import { ContentListProps } from './types';
import styles from './styles.module.scss';

const { Group } = AntdRadio;

export const ContentList = memo<ContentListProps>(
  ({ className: classNameFromProps, content, contentProps, showSelect = false, ...props }) => {
    const className = classnames(styles.root, classNameFromProps);

    const items = useMemo(
      () =>
        content?.map(({ value, label, preview }: any, index) => (
          <Box display='flex' flexDirection='column' alignItems='center' key={index}>
            <Box display='flex' alignItems='center' gap={20} marginBottom={35}>
              {showSelect ? <Radio value={value} /> : null}
              <Typography variant='body-25'>{label}</Typography>
            </Box>
            <Box {...contentProps}>{preview}</Box>
            {showSelect ? <CampaignABTestingResults marginTop={35} /> : null}
          </Box>
        )),
      [content, contentProps, showSelect],
    );

    return showSelect ? (
      <Group className={className} {...props}>
        <Layout justifyContent='space-between'>{items}</Layout>
      </Group>
    ) : (
      <Layout justifyContent='space-evenly'>{items}</Layout>
    );
  },
);
