const isEmpty = (value: unknown): value is NonNullable<typeof value> => value === null || value === undefined;

const isNonValue = (value: unknown): boolean => isEmpty(value) || value === '';

const isEmptyObject = (value: Record<string, any>): boolean => !Object.keys(value).length;

const isArrayOfUndefined = (value: any[]): boolean => value.every((item) => !item);

const isString = (value: unknown): value is string => typeof value === 'string';

// Based on https://github.com/sindresorhus/is-absolute-url but it doesn't support current project node version
// Scheme: https://tools.ietf.org/html/rfc3986#section-3.1
// Absolute URL: https://tools.ietf.org/html/rfc3986#section-4.3
const ABSOLUTE_URL_REGEX = /^[a-zA-Z][a-zA-Z\d+\-.]*?:/;
const isAbsoluteUrl = (url: string): boolean => ABSOLUTE_URL_REGEX.test(url);

const isDeepEqual = (value1: unknown, value2: unknown): boolean => JSON.stringify(value1) === JSON.stringify(value2);

export { isEmpty, isNonValue, isEmptyObject, isString, isAbsoluteUrl, isArrayOfUndefined, isDeepEqual };
