import { types, SnapshotOut, Instance } from 'mobx-state-tree';
import { MSTAddStaticMethods } from 'stores/utils';
import Option from 'stores/models/option';
import Keyword from 'stores/models/keyword';
import { KeywordsGroupFormValueApi } from 'types';
import { deserializeKeywordsGroup } from 'utils/deserializers';

const staticMethods = {
  fromResponseData: (data: KeywordsGroupFormValueApi): KeywordGroupSnapshot => deserializeKeywordsGroup(data),
};

const KeywordGroup = types.model('KeywordGroup', {
  group: Option,
  keywords: types.array(Keyword),
});

export type KeywordGroupSnapshot = SnapshotOut<typeof KeywordGroup>;
export type KeywordGroupInstanceType = Instance<typeof KeywordGroup>;

export default MSTAddStaticMethods(KeywordGroup, staticMethods);
