import React, { ComponentType, memo, useMemo, useCallback } from 'react';
import { useToggle } from 'react-use';
import { useFormContext } from 'hooks';
import { Confirmation, ConfirmationProps } from 'components/common/confirmation';

export interface WithConfirmationProps {
  confirmation?: Partial<ConfirmationProps>;
}

export const withConfirmation =
  <P extends { onClick?: any; disabled?: boolean }>() =>
  (WrappedComponent: ComponentType<P>): ComponentType<WithConfirmationProps & P> =>
    memo<WithConfirmationProps & P>(({ confirmation, onClick: onClickFromProps, disabled, ...componentProps }) => {
      const [open, toggleOpen] = useToggle(false);

      const formContext = useFormContext();

      const onClick = useMemo(
        () => (confirmation && !disabled ? () => toggleOpen(true) : onClickFromProps),
        [toggleOpen, confirmation, disabled, onClickFromProps],
      );

      const onConfirm = useCallback(async () => {
        if (formContext?.handleSubmit && confirmation?.submitButtonProps?.htmlType === 'submit') {
          formContext?.handleSubmit();
        } else if (await onClickFromProps) {
          onClickFromProps();
        }

        toggleOpen(false);
      }, [confirmation?.submitButtonProps?.htmlType, formContext, onClickFromProps, toggleOpen]);

      return (
        <>
          {(<WrappedComponent onClick={onClick} {...(componentProps as P)} />) as any}
          {confirmation ? (
            <Confirmation visible={open} onConfirm={onConfirm as any} onCancel={toggleOpen} {...confirmation} />
          ) : null}
        </>
      );
    });
