import React, { memo, useCallback } from 'react';
import { useAsync } from 'react-use';
import { Select } from 'components/common/form/select';
import { SelectRemoteProps } from './types';

export const SelectRemote = memo<SelectRemoteProps>(
  ({ fetch: fetchFromProps, options: optionsFromProps, ...props }) => {
    const fetch = useCallback(async () => {
      if (fetchFromProps) {
        return fetchFromProps();
      }

      return optionsFromProps;
    }, [fetchFromProps, optionsFromProps]);

    const { value: options, loading } = useAsync(fetch, [fetch]);

    return (
      <Select
        loading={loading}
        options={options}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        {...props}
      />
    );
  },
);
