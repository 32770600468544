import React, { memo } from 'react';
import { Box } from 'components/common/box';
import { AspectRatioProps } from './types';

export const AspectRatio = memo<AspectRatioProps>(({ xRatio = 1, yRatio = 1, children, ...props }) => {
  const height = `${100 * (yRatio / xRatio)}%`;

  return (
    <Box display='flex' position='relative' {...props}>
      <Box width={0} paddingBottom={height} />
      <Box position='absolute' top={0} bottom={0} left={0} right={0}>
        {children}
      </Box>
    </Box>
  );
});
