import React, { useCallback } from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import { useInputProps } from 'components/common/form/text-field/hooks';
import { withFormItem } from 'hocs/with-form-item';
import { mask, resolveValue, hasTypedValue } from './helper';
import { PhoneNumberFieldProps } from './types';

export const PhoneNumberField = withFormItem<PhoneNumberFieldProps>()((props) => {
  const {
    onChange: rawOnChange,
    onBlur: rawOnBlur,
    name,
    className: classNameFromProps,
    value,
    ...inputProps
    // @ts-ignore
  } = useInputProps(props);

  const onChange = useCallback(
    (event) => {
      const { value } = event.target;

      if (rawOnChange) {
        rawOnChange({
          target: {
            value: hasTypedValue(value) ? resolveValue({ value: event.target.value }) : '',
            name,
          },
        });
      }
    },
    [rawOnChange, name],
  );

  const onBlur = useCallback(
    (event) => {
      if (rawOnBlur) {
        rawOnBlur({
          ...event,
          target: {
            ...event.target,
            name,
          },
        });
      }
    },
    [rawOnBlur, name],
  );

  const className = classnames('ant-input', classNameFromProps);

  return (
    <InputMask
      className={className}
      value={value as any}
      mask={mask}
      {...({ maskChar: null } as any)}
      onChange={onChange}
      onBlur={onBlur}
      {...inputProps}
    />
  );
});
