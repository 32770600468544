import React, { useMemo, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation, useStores, usePreloader, useParams } from 'hooks';
import { AuthForm } from 'components/auth/auth-form';
import { Preloader } from 'components/common/preloader';

export const AccountActivation = observer(() => {
  const [pending, setPending] = useState(true);
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);
  const [isActivationFailed, setIsActivationFailed] = useState(false);

  usePreloader({ pending: false });

  const { uid, token } = useParams<{ uid: string; token: string }>();
  const { t } = useTranslation();
  const { session } = useStores();

  const { activateAccount } = session;

  useEffect(() => {
    (async () => {
      try {
        const activationErrors = await activateAccount({
          uid,
          token,
        });

        if (activationErrors) {
          setIsTokenInvalid(activationErrors.isTokenInvalid);
        }
      } catch (error) {
        console.log(error);
        setIsActivationFailed(true);
      } finally {
        setPending(false);
      }
    })();
  }, [activateAccount, token, uid]);

  const activationInfo = useMemo(() => {
    if (isTokenInvalid) {
      return {
        title: t('Auth.AccountActivation.InvalidTokenTitle'),
        titleIcon: {
          color: 'errorColor',
          name: 'close',
        },
        description: t('Auth.AccountActivation.InvalidTokenDescription'),
      };
    }

    if (isActivationFailed) {
      return null;
    }

    return {
      title: t('Auth.AccountActivation.SuccessTitle'),
      titleIcon: {
        color: 'primaryColor',
        name: 'check',
      },
      description: t('Auth.AccountActivation.SuccessDescription'),
    };
  }, [isActivationFailed, isTokenInvalid, t]);

  return (
    <Preloader pending={pending}>
      {activationInfo ? <AuthForm name='account-activation' {...activationInfo} /> : null}
    </Preloader>
  );
});
